import React, { useContext } from 'react';
import {useSelector} from 'react-redux';

import { RootContext } from '../root';

import * as Constant from '../../utilities/constant';
import GeneralModal from '../component/Modal/generalModal';
import { FaExclamationTriangle, FaInfoCircle, FaCheck, FaTicketAlt, FaSave, FaRegFolderOpen, FaFileSignature, FaRegClock } from 'react-icons/fa';
import { getCaptchaChallenge } from './reducers/wfpSessionReducer';
import { removeFormData, updateValidation, clearFormData } from './reducers/formDetail';
import { Field, Validators } from './inputElements';
import browserType from '../../utilities/checkBroswer';

import * as TimeoutUtil from '../../utilities/timeoutUtil';
import iasTap from '../../resources/ias-tap.png';
import Button from '../component/button';
import iasIconDark from '../../resources/ias-icon-dark.png';
import { FormContext } from './layoutElements';
import { cleanseStringForAlphabets } from '../../utilities/commonUtil';
import verifyIdUtil from './utilities/verify-id-util';
import { IAM_SMART_FORM_DATA_KEY } from './formdata-key.const';


const defaultDialogOpts = (dispatch)=>({
    id:"dialog",
    title: <><FaExclamationTriangle /> Validation Error</>,
    // msgArr: [ 'Validation error exists!' ],
    body: '',
    mode: 'message', // not 'confirm' -> two buttons, need to provide handleConfirm and handleCancel functions
    closeLabel: "", //language.close,
    cancelLabel: "", //language.cancel,
    confirmLabel: "", //language.confirm,
    headerClass: '',
    handleConfirm: () => { dispatch(setShowDialog(false)) },
    handleCancel: () => { dispatch(setShowDialog(false)) },
    onHide: () => { dispatch(setShowDialog(false)) },
})

export const closeDialog = () => dispatch => {
    dispatch(setShowDialog(false));
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
    }));
}

export const GENERAL_ERROR_MSG = 'generalErrMsg';
export const GENERAL_ERROR_MSG_WITH_SUBM_ID = 'generalErrMsgWithSubmissionId';

const showErrorDialog = (opts, language) => dispatch => {
    let errMsg = language[opts];

    if (!opts) {
        errMsg = language[GENERAL_ERROR_MSG];
    } else { 
        if (!errMsg) {
            errMsg = opts.err ? (language[opts.err] ?? opts.err) : language[GENERAL_ERROR_MSG];
        }
        if (errMsg === 'null') {
            errMsg = language[GENERAL_ERROR_MSG];
        }
    }
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        body: <p style={{whiteSpace: "pre-wrap"}}>[{language.error}] { errMsg }</p>,
        ...opts,
        title: <><FaExclamationTriangle /> {language.error} {/*<span style={{display:"none"}}>{opts && opts.err ? [opts.err] : null}</span>*/}</>, 
        closeLabel: language.close,
        mode: 'error',
        headerClass: 'validationFailed inputFormValFailed ', 
        handleClose : () => { 
            dispatch(setShowDialog(false)); 
            if (opts && opts.handleClose) {
                opts.handleClose()
            }
        },
    }));
    dispatch(setShowDialog(true));
}

const showMessageDialog = (opts) => dispatch => {
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        ...opts,
        title: <><FaInfoCircle /> {opts.title}</>, 
        mode: 'message',
    }));
    dispatch(setShowDialog(true));
}

const showFontSizeDialog = (opts) => dispatch => {
    const {language, browser} =opts;
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        ...opts,
        title: <><FaInfoCircle /> {language.howToChangeFontSize}</>, 
        mode: 'message',
        closeLabel: language.close,
        children: language.FontSizeContent(browser.name),
    }));
    dispatch(setShowDialog(true));
};
window.showFontSizeDialog = showFontSizeDialog;

const showConfirmDialog = (opts) => dispatch => {
    const {title, confirmLabel, cancelLabel, handleConfirm, handleCancel, language} = opts;
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        ...opts,
        title: <><FaInfoCircle /> {title}</>, 
        mode: 'confirm',
        confirmLabel: confirmLabel ?? language.confirm,
        cancelLabel: cancelLabel ?? language.cancel,
        handleConfirm: () => { dispatch(setShowDialog(false)); if(handleConfirm)handleConfirm() },
        handleCancel: () => { dispatch(setShowDialog(false)); if(handleCancel)handleCancel() },
    }));
    dispatch(setShowDialog(true));
}

const showCompleteDialog = (opts) => dispatch => {
    const {language, includeSignature, performPrint, formCode, lr173IndividualOwner} = opts;
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        ...opts,
        title: <><FaCheck /> {includeSignature ? language.successfullySigned : language.complete}</>, 
        body: includeSignature ? language.saveForSubmission : (formCode === "LR173" 
                                                                        ? (
                                                                            lr173IndividualOwner
                                                                            ? language.formatString(language.printForSubmissionAlt, language.print)
                                                                            : language.formatString(language.printForSubmissionAlt2, language.print)
                                                                          )
                                                                        : language.formatString(language.printForSubmission, language.print)),
        mode: 'confirm',
        confirmLabel: includeSignature ? language.saveSignedForm : language.print,
        cancelLabel: language.cancel,
        handleConfirm: () => { performPrint(true);},
        handleCancel: () => { dispatch(setShowDialog(false));},
    }));
    dispatch(setShowDialog(true));
} 

const showCaptchaDialog = (opts) => dispatch => {
    const {title, confirmLabel, cancelLabel, children, handleConfirm, handleCancel, isCompletedForm, performPrint, language} = opts;

    dispatch(setDialogOpts({ 
        ...defaultDialogOpts(dispatch),
        ...opts,
        title:<><FaTicketAlt /> {language.captcha}</>,
        mode:'confirm',
        children: children,
        headerClass:'dialogHeader',
        confirmLabel:confirmLabel ?? language.confirm,
        cancelLabel:cancelLabel ?? language.cancel,
        handleConfirm:() => {
            handleConfirm ? handleConfirm() : performPrint(isCompletedForm);
        },
        handleCancel:() => { 
            if (handleCancel) handleCancel();
            dispatch(closeDialog());
        },
    }));
    dispatch(getCaptchaChallenge());
    dispatch(setShowDialog(true));
}

const showSaveDialog = (opts) => dispatch => {
    const {title, confirmLabel, cancelLabel, children, handleConfirm, handleCancel, returnObj, language} = opts;
    // let returnObj={};
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch), 
        ...opts,
        returnObj: returnObj,
        title: <><FaSave /> {title ?? language.save}</>,
        mode: 'confirm',
        headerClass: 'dialogHeader',
        body: 
            <>
                {language.providePwECert}
            <div style={{fontWeight:'bold', paddingBottom:'20px'}}>
                {language.dontForgetPw}
            </div>
            </>
        ,
        confirmLabel: confirmLabel ?? language.confirm,
        cancelLabel: cancelLabel ?? language.cancel,
        children: children,
        handleConfirm: () => {  
            const rrobj = {...returnObj};
            dispatch(removeFormData({dialogSavePw: null, dialogSaveConfirmPw: null, eCertSave: null, eCertPin:null}));
            dispatch(updateValidation(null, null, {dialogSavePw:null, dialogSaveConfirmPw:null}));
            
            if(handleConfirm && handleConfirm(rrobj)) { 
                dispatch(closeDialog());
            }
        },
        handleCancel: () => { 
            if(handleCancel)handleCancel();  
            dispatch(removeFormData({dialogSavePw: null, dialogSaveConfirmPw: null, eCertLoad: null, eCertPin:null})); 
            dispatch(updateValidation(null, null, {dialogSavePw:null, dialogSaveConfirmPw:null}));
            dispatch(closeDialog());
        },
        }));
    dispatch(getCaptchaChallenge());
    dispatch(setShowDialog(true));
}

const showLoadDialog = (opts) => dispatch => {
    const {title, confirmLabel, cancelLabel, children, handleConfirm, handleCancel, returnObj, language} = opts;
    // let returnObj={};
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        ...opts,
        returnObj: returnObj,
        title: <><FaRegFolderOpen /> {title ?? language.loadForm}</>,
        mode: 'confirm',
        headerClass: 'dialogHeader',
        confirmLabel: confirmLabel ?? language.confirm,
        cancelLabel: cancelLabel ?? language.cancel,
        children: children,
        handleConfirm: () => { 
            const rrobj = {...returnObj}
            dispatch(removeFormData({savedFormLoad: null, dialogLoadPw: null, eCertLoad: null, eCertPin: null}));
            if(handleConfirm && handleConfirm(rrobj)) {
                dispatch(closeDialog());
            }  
        },
        handleCancel: () => { 
            if(handleCancel) { 
                handleCancel();
            }
            dispatch(removeFormData({savedFormLoad: null, dialogLoadPw: null, eCertLoad: null, eCertPin: null}));
            dispatch(closeDialog());
        },
    }));
    dispatch(getCaptchaChallenge());
    dispatch(setShowDialog(true));
}

const SignECertDialogChildren = props => {
    const {returnObj} = props;
    const {language,captchaToken,captchaGetChallengeInProgress,captchaChallenge} = useSelector(store => store.session);

    return (
        <>
            {/* <Field.FileUpload id='dialogSignECertPublic' label={language.certificatePublic} onValChange={v=>returnObj.ecertPub = v} required /> */}
            <Field.FileUpload id='dialogSignECertPrivate' label={language.certificatePrivate} onValChange={v=>returnObj.ecertPrv = v} 
                            required filter=".p12" limit="10240" noUpload />
            <Field.Password id='dialogSignECertPin' label={language.pin} required maxlength={128} onValChange={v=>returnObj.ecertPin=v} />
            
            <Field.Captcha
                captchaToken={captchaToken}
                captchaGetChallengeInProgress={captchaGetChallengeInProgress}
                captchaChallenge={captchaChallenge}
            /> 
        </>
    );
}

const showSignECertDialog = (opts) => dispatch => {
    const {title, confirmLabel, cancelLabel, handleConfirm, handleCancel, language, picsOnly} = opts;
    let returnObj={};
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        id:"signECertDialog",
        picsOnly: picsOnly,
        body: 
            <>
            <div style={{fontWeight:'bold', paddingBottom:'20px'}}>
                {!picsOnly 
                    ?   
                        <>
                        <p>{language.signECertNote}</p>
                        <p>{language.signECertNote2}</p>
                        </>
                    :
                        <p>{language.signECertNote2_PICSonly}</p>
                }
            </div>
            </>
        ,
        ...opts,
        title: <><FaFileSignature /> {title ?? language.signECert}</>,
        mode: 'confirm',
        headerClass: 'dialogHeader',
        confirmLabel: confirmLabel ?? language.signAndCont,
        cancelLabel: cancelLabel ?? language.cancel,
        children: <SignECertDialogChildren returnObj={returnObj} />,
        handleConfirm: () => {
            const rrobj = {...returnObj}; 
            dispatch(
                removeFormData({
                    // dialogSignECertPublic: null, 
                    dialogSignECertPrivate: null, 
                    dialogSignECertPin: null
                })
            );
            if(handleConfirm && handleConfirm(rrobj)) {
                dispatch(closeDialog());
            } 
        },
        handleCancel: () => {  
            dispatch(removeFormData({
                // dialogSignECertPublic: null, 
                dialogSignECertPrivate: null, 
                dialogSignECertPin: null
            }))
            if(handleCancel)handleCancel(); 
            dispatch(closeDialog());
        },
    }));
    dispatch(setShowDialog(true));
}

const SignEIDDialogChildren = props => { 
    const {language} = useSelector(store => store.session);
    const session = useSelector(store => store.session);
    const formDetail = useSelector(store => store.formDetail);
    const {returnObj} = props;
    const { form, submitTransformFunc } = useContext(FormContext);

    return (
        <>
            <Field.Password id={IAM_SMART_FORM_DATA_KEY} label={language.field_HKID_withCheckDigit} required 
                        minlength={8}
                        maxlength={9}
                        onValChange={v=>returnObj.hkid=v ? v.toUpperCase() : v}
                        validation={[
                            v=>{
                                returnObj.validation2 = form.iamSmartCustomValidation ? form.iamSmartCustomValidation(v, formDetail.formData) : null;
                                return returnObj.validation2;
                            },
                            v=>Validators.isHKID(v),
                            v=>{ 
                                returnObj.validation = verifyIdUtil.verifyIAMSmartIdNum(v,form,formDetail); 
                                return returnObj.validation;
                            }, 
                        ]} />
            <Field.Captcha
                captchaToken={session.captchaToken}
                captchaGetChallengeInProgress={session.captchaGetChallengeInProgress}
                captchaChallenge={session.captchaChallenge}
            /> 
        </>
    );
}

const showSignEIDDialog = ({handleConfirm, nextStep, language}) => dispatch => {
    dispatch(getCaptchaChallenge());
    dispatch(showSignEIDDialogPrivate({
        handleConfirm: handleConfirm,
        nextStep: nextStep,
        language: language,
    }));
}

const showSignEIDDialogPrivate = (opts) => dispatch => {
    const {title, confirmLabel, cancelLabel, children, handleConfirm, handleCancel, language} = opts;
    
    let returnObj = {}
    dispatch(removeFormData({dialogSignIASHKID: null}));
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        id:"signEIDDialog",
        body: 
            <>
            <div style={{fontWeight:'bold', paddingBottom:'20px'}}>
                <p>{language.signEIDNote}</p> 
            </div>
            </>
        ,
        ...opts,
        title: <><FaFileSignature /> {title ?? language.signIASTitle}</>,
        mode: 'confirm',
        headerClass: 'dialogHeader',
        confirmLabel: confirmLabel ?? language.signAndCont,
        cancelLabel: cancelLabel ?? language.cancel,
        children: <SignEIDDialogChildren returnObj={returnObj} />,
        handleConfirm: () => {
            const rrobj = {...returnObj};
            if (rrobj
                && rrobj.hkid
                && Validators.isHKID(rrobj.hkid) === null
                && !rrobj.validation2
                && !rrobj.validation) {
                
                // dispatch(removeFormData({dialogSignIASHKID: null}));
                if(handleConfirm && handleConfirm(rrobj)) {	
                    // showSignEIDWaitingDialog({nextStep:opts.nextStep});
                }
            }

            },
        handleCancel: () => { 
            if(handleCancel)handleCancel();
            dispatch(closeDialog());
            },
    }));
    dispatch(setShowDialog(true));
}

const showSignEIDConfirmDialog = (opts) => dispatch => {
    const {handleConfirm, signCode, isSigning, language} = opts;
    const source = browserType();
    const id="signEIDConfirmDialog"; 
    

    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        id:"signEIDConfirmDialog",
        body: null,
        ...opts,	
        title: <><FaFileSignature /> {language.signIASTitle}</>,
        mode: 'custom',
        headerClass: 'dialogHeader',
        cancelLabel: language.cancel,
        children: 
        <div className="form-inline" style={{textAlign:"center"}} >
            <Field.OutputText id={id+"-dept-name"} 		label={language.departmentName} value={language.landRegistry} />
            <Field.OutputText id={id+"-service-name"} 	label={language.serviceName}	value={language.webFormPortal} />
            <Field.OutputText id={id+"-doc-name"} 		label={language.documentName}	value={language.formTitle} />
            <Field.OutputText id={id+"-sign-code"} 		label={language.signCode}		value={signCode} />

            
            {
                isSigning
                ?
                language.signEIDWaiting
                :
                <>
                    <div style={{textAlign:'left', padding:'0px 20px'}}>
                        <p>{language.ias_text}</p>
                    </div>
                    <div style={{flex:"1"}}>
                        <button id={"iasSignBtn_" + id} type="button" className="btn normalButton primaryButton iasSignButton"
                            onClick={()=>{handleConfirm(source !== "PC_Browser");dispatch(showSignEIDConfirmDialog({...opts, isSigning:true}));}}>
                            <img src={iasIconDark} alt="" /> {language.ias_continue}
                        </button>
                    </div>
                </>
            }
        </div>,
        handleCancel: () => { 
            dispatch(closeDialog());
            },
    }));
    dispatch(setShowDialog(true));
} 

const showCustomDialog = (opts) => dispatch => {
    const {handleConfirm, handleCancel} = opts;
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        ...opts,
        handleConfirm: () => { 
            dispatch(closeDialog()); if(handleConfirm)handleConfirm() },
        handleCancel: () => { 
            dispatch(closeDialog()); if(handleCancel)handleCancel() },
    }));
    dispatch(setShowDialog(true));
}

const showSessionTimeoutWarningDialog = (language, sec) => dispatch => {
    const id = "SessionTimeoutWarningDialog";

    const handleConfirm = () => { 
        TimeoutUtil.UPDATE_TIMER(dispatch, language);
        dispatch(closeDialog());
    }
    
    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        id: id,
        body:   <div>
                    {language.sessionGoingToTimeout}
                    <div style={{paddingTop:"15px"}}>
                        {language.formatString(language.timeRemaining, sec)}
                    </div>
                </div>,
        title: <><FaRegClock /> {language.sessionTimeout}</>,
        mode: 'custom',
        headerClass: 'dialogHeader',
        customFooter: 
        <span>
            <Button label={language.confirm}  buttonClass={id+"-confirm-btn"}onClick={handleConfirm} />
        </span>,
    }));
    dispatch(setShowDialog(true));
}

const showSessionTimedoutDialog = (language) => dispatch => {
    const id = "showSessionTimedoutDialog";		
    
    const curLanguage = language.getLanguage();	

    const handleConfirm = () => { 
        dispatch(clearFormData());
        // const query = new URLSearchParams(window.location.search);
        // query.set("lang", curLanguage);
        // window.location.reload();
        try {
            let url = new URL(window.location.href);
            url.searchParams.set('lang', curLanguage);

            if (window.location.href === url.href) {
                window.location.reload();
            }
            else {
                window.location.href = url.href;
            }
        }
        catch(e) {
            window.location.reload();
        }
    }

    if (document.getElementsByClassName("modal-backdrop")[0]) {
        document.getElementsByClassName("modal-backdrop")[0].style.opacity = 1
    }

    dispatch(setDialogOpts({
        ...defaultDialogOpts(dispatch),
        id: id,
        body: language.sessionHasTimedout,
        title: <><FaRegClock /> {language.sessionTimeout}</>,
        mode: 'custom',
        headerClass: 'dialogHeader',
        customFooter: 
        <span>
            <Button label={language.refresh}  buttonClass={id+"-confirm-btn"}onClick={handleConfirm} />
        </span>,
    }));
    dispatch(setShowDialog(true));
}

let funcs = {};

export const Dialog = (props) => {
	// const session = useSelector(store => store.session);
    const { showDialog, dialogOpts } = useSelector(store => store.dialogReducer);
    
    // const { language, curLanguage } = session;
    // const { formData, completedFields, validationErr } = useSelector(store => store.formDetail);   
    
	// const [ dialogOpts, setDialogOpts ] = useState({...defaultDialogOpts});
	// const [ showDialog, setShowDialog ] = useState(false); 

    return (
        <GeneralModal show={showDialog} {...dialogOpts} /> 
    )
}

export const dialogReducerInitialState = {
    showDialog: false,
    dialogOpts: {...defaultDialogOpts(()=>{})}
}

// dialog reducer
const SET_SHOW_DIALOG = "SET_SHOW_DIALOG";
const SET_DIALOG_OPTS = "SET_DIALOG_OPTS";

export const setShowDialog = show => ({type: SET_SHOW_DIALOG, payload: show});
export const setDialogOpts = opts => ({type: SET_DIALOG_OPTS, payload: opts});

export const dialogReducer = (state=dialogReducerInitialState, action) => { 
    switch (action.type) { 
        case SET_SHOW_DIALOG:
            return { ...state, showDialog: action.payload };
            break;
        case SET_DIALOG_OPTS:
            return { ...state, dialogOpts: action.payload };
            break;
        default:
            return state;
            break;
    }
}

export const dialogHelper = {
    showErrorDialog: showErrorDialog,
    showCaptchaDialog: showCaptchaDialog,
    showCompleteDialog: showCompleteDialog,
    showConfirmDialog: showConfirmDialog,
    showCustomDialog: showCustomDialog,
    showLoadDialog: showLoadDialog,
    showMessageDialog: showMessageDialog,
    showSaveDialog: showSaveDialog,
    showSessionTimedoutDialog: showSessionTimedoutDialog,
    showSessionTimeoutWarningDialog: showSessionTimeoutWarningDialog,
    showSignECertDialog: showSignECertDialog,
    showSignEIDConfirmDialog: showSignEIDConfirmDialog,
    showSignEIDDialog: showSignEIDDialog,
    // showSignEIDWaitingDialog: showSignEIDWaitingDialog,
    closeDialog: closeDialog,
    setShowDialog: setShowDialog,
    showFontSizeDialog: showFontSizeDialog
}

export default Dialog;