import { instanceToPlain, plainToInstance } from 'class-transformer';

import { EnumTransform } from './enum-transform.util';

import { PaymentMethodSubtype } from '../enums';
import { QueryPaymentAvailabilityResponse as Response } from '../api/services/response';
import { getCurrentDisabledPaymentMethods } from './get-current-disabled-payment-methods.util';

const instanceToInstance = (inst: any) => plainToInstance(Response, instanceToPlain(inst));

export function removeDisabledPaymentMethods(resp: Response) {
    const paymentMethods = getCurrentDisabledPaymentMethods();
    let copy = instanceToInstance(resp);

    for (const paymentMethod of paymentMethods) {
        const subtype = EnumTransform.paymentMethodToPaymentMethodSubtype(paymentMethod);
        if (subtype === PaymentMethodSubtype.CREDIT_CARD) {
            const cardType = EnumTransform.paymentMethodToCardType(paymentMethod);
            copy.removeCard(cardType);
        } else {
            copy.removeSubType(subtype);
        }
    }

    return copy;
}
