export const SimplifiedChineseDict = {
    TO: '至',
    AROUND: '大约',
    YEAR: '年',
    MONTH: '月',
    DATE: '日',
    SUNDAY: '星期日',
    MONDAY: '星期一',
    TUESDAY: '星期二',
    WEDNESDAY: '星期三',
    THURSDAY: '星期四',
    FRIDAY: '星期五',
    SATURDAY: '星期六',
    EARLY_MORNING: '凌晨',
    MORNING: '上午',
    AFTERNOON: '下午',
    EVENING: '晚上',
};
