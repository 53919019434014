import { Expose, Transform, Type } from 'class-transformer';
import { Stringable } from '../../types';

export class BasePremisesAddress {
    @Expose({ name: 'BuildingName' })
    @Type(() => BuildingNameWrapper)
    @Transform(({ value }) => new BuildingNameWrapper(value))
    buildingName?: BuildingNameWrapper;
}

class BuildingNameWrapper implements Stringable {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    getString(): string {
        return (this.name);
    }
}
