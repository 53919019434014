import { queryPaymentMethod } from '../api/services';
import { QueryPaymentAvailabilityRequest } from '../api/services/requests';
import { PaymentData, PaymentMethodData, TransactionData } from '../entities';
import { PaymentMethodSubtype } from '../enums';
import { isEmpty, isMobile } from '../given';
import { Payment } from '../payment.proxy';

export async function getAvailablePaymentMethods(
    paymentData: PaymentData,
    transactionData: TransactionData
): Promise<PaymentMethodData[]> {
    const methodData = await queryPaymentMethod(
        new QueryPaymentAvailabilityRequest({
            amount: paymentData.order.totalAmount,
            locale: Payment.getLocale(),
            preferredPaymentMethods: Payment.getPreferredPaymentMethods(),
        })
    );

    const isAppleReady = await Payment.isApplePayReady(paymentData, transactionData).catch(
        () => false
    );
    const isGoogleReady = await Payment.isGooglePayReady().catch(() => false);

    if (isEmpty(methodData.availablePaymentMethods)) {
        return [];
    }
    return methodData.availablePaymentMethods
        .map((pm) => PaymentMethodData.fromAvailablePaymentMethod(pm))
        .flat(20) // 20 used instead of Infinity because of typescript issue
        .filter(
            (paymentMethod) =>
                paymentMethod.subType !== PaymentMethodSubtype.ANDROID_PAY || isGoogleReady // Android Pay is filtered away unless isGoogleReady
        )
        .filter(
            (paymentMethod) =>
                paymentMethod.subType !== PaymentMethodSubtype.APPLE_PAY || isAppleReady // Apple Pay is filtered away unless isAppleReady
        )
        .filter(
            (paymentMethod) => paymentMethod.subType !== PaymentMethodSubtype.PPS || !isMobile() // PPS is filtered away unless !isMobile()
        );
}
