import React, { useContext } from 'react';
import { FormContext } from '../../layoutElements';

export const StepMenuItem = (props: Props) => {
    const { l10n, title, titleDirect, index, changeStep, canBack } = props;
    const { stepIdx } = useContext(FormContext);

    return (
        <button
            className={'stepMenuItem ' + (stepIdx === index ? 'selectedSideMenuButton ' : '')}
            onClick={() => {
                if (canBack(stepIdx)) {
                    changeStep(index);
                }
            }}
            disabled={stepIdx <= index ? true : undefined}
        >
            <div className="d-flex">
                <div className="note-bullet">{index + 1}.</div>
                <div style={{ paddingLeft: '5px', textAlign: 'left' }}>
                    <span className={stepIdx === index ? 'selectedSideMenuText ' : ''}>
                        {titleDirect ? titleDirect : l10n.getString(title)}
                    </span>
                    {stepIdx > index ? <div className="icon icon-tick "></div> : null}
                </div>
            </div>
        </button>
    );
};

type Props = { l10n: any; title: any; titleDirect: any; index: any; changeStep: any; canBack: any };
