import forge from 'node-forge';
    
const arrayBufferToString = ( buffer ) => {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return binary;
}

const handleReadData = (result) => {
    
    var contents = result;
    var pkcs12Der = arrayBufferToString(contents)
    var pkcs12B64 = forge.util.encode64(pkcs12Der);     
    var privateKey = null;
    var publicKey = null;
    //do something else...

    //p12 certificate stored in Base64 format
    var pkcs12Der = forge.util.decode64(pkcs12B64);
    var pkcs12Asn1 = forge.asn1.fromDer(pkcs12Der);

    return pkcs12Asn1;
}

const handleBase64Data = (result) => {
    var pkcs12Der = forge.util.decode64(result);
    var pkcs12Asn1 = forge.asn1.fromDer(pkcs12Der);
    return pkcs12Asn1;
}

const getPrivateKey = (pkcs12Asn1, password) => {
    let privateKey = null;
    var pkcs12 = forge.pkcs12.pkcs12FromAsn1(pkcs12Asn1, true, password);

    for(var sci = 0; sci < pkcs12.safeContents.length; ++sci) {
        var safeContents = pkcs12.safeContents[sci];
    
        for(var sbi = 0; sbi < safeContents.safeBags.length; ++sbi) {
            var safeBag = safeContents.safeBags[sbi];
    
            // this bag has a private key
            if(safeBag.type === forge.pki.oids.keyBag) {
                //Found plain private key
                privateKey = safeBag.key;
            } else if(safeBag.type === forge.pki.oids.pkcs8ShroudedKeyBag) {
                // found encrypted private key
                privateKey = safeBag.key; 
            }
        }
    }

    if (privateKey == null) throw 'Unable to get private key from selected file.';

    return privateKey;    
}

const getPublicKey = (pkcs12Asn1, password) => {
    let publicKey = null;
    var pkcs12 = forge.pkcs12.pkcs12FromAsn1(pkcs12Asn1, false, password);

    for(var sci = 0; sci < pkcs12.safeContents.length; ++sci) {
        var safeContents = pkcs12.safeContents[sci];
    
        for(var sbi = 0; sbi < safeContents.safeBags.length; ++sbi) {
            var safeBag = safeContents.safeBags[sbi];
                                                          // not a CA / intermediate CA cert
            if(safeBag.type === forge.pki.oids.certBag && !safeBag.cert.extensions.some(o => o.keyCertSign)) {
                // this bag has a certificate...        
                publicKey = safeBag.cert;
            }   
        }
    }

    if (publicKey == null) throw 'Unable to get public key from selected file.';

    return publicKey;    
}


function hex2str(str1){
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

export default {
    hashString: (str) => {
        let hashObject = forge.md.sha512.create();
        //update the hash object with data as often as required
        hashObject.update(str);

        return forge.util.encode64(hashObject.digest().data);
    },
    loadPublicKey: (fileResult, password) => {
        const pk = getPublicKey(handleBase64Data(fileResult), password);
        const cert = forge.pki.certificateToPem(pk);  
        return forge.util.encode64(cert);
    },
    loadPrivateKey: (fileResult, password) => {
        return getPrivateKey(handleReadData(fileResult), password);
    },
    // loadPublicKeyFromBase64: base64 => {
    //     const b64 = forge.util.decode64(base64);
    //     // const bb = Buffer.from(b64);
    //     return forge.pki.certificateFromPem(b64).publicKey;
    // },
    getPDFSignatureCertificates: (pdf) => {
        return pdf.split(/endobj/).filter(l=>l.indexOf('adbe.pkcs7.detached')>-1).map(d=>{
            let sig = {};
            d = d.trim();
            let date = d.match(/\/M\(D:(.*?)\)/)[1].replace("'",'').match(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(.{5})/);
            let reason = d.match(/\/Reason\((.*?)\)\//);
            let location = d.match(/\/Location\((.*?)\)\//);
            sig.raw = d;
            sig.header = d.match(/(\d) (\d) obj/)[0];
            sig.ByteRange = d.match(/\/ByteRange.*?\[(.*?)\]/)[1].trim().split(' ').map(i=>+i);
            sig.Date = new Date(`${date[1]}-${date[2]}-${date[3]}T${date[4]}:${date[5]}:${date[6]}${date[7]}`);
            sig.Contents = d.match(/\/Contents *?\<(.*?)\>/)[1].replace(/(?:00)*$/, '');
            sig.Signature = {Der: hex2str(sig.Contents)};
            sig.Signature.ASN1 = forge.asn1.fromDer(sig.Signature.Der);
            sig.Signature.message = forge.pkcs7.messageFromAsn1(sig.Signature.ASN1);
        
            if(reason){
                sig.Reason = reason[1];
            }else{
                sig.Reason = '';
            }
            if(location){
                sig.Location = location[1];
            }else{
                sig.Location = '';
            }
            
            return sig.Signature.message.certificates;
        });
      },
    loadPublicKeyFromBase64: (base64, pin) => {
        // const privateKey = getPrivateKey(handleBase64Data(base64), pin);
        // const publicKey = forge.pki.publicKeyToPem(forge.pki.rsa.setPublicKey(privateKey.n, privateKey.e));
        // return forge.util.encode64(publicKey.replace(/PUBLIC KEY/g, "CERTIFICATE"));
        const pk = getPublicKey(handleBase64Data(base64), pin);
        return pk.publicKey;
    },
    loadPrivateKeyFromBase64: (base64, pin) => {
        return getPrivateKey(handleBase64Data(base64), pin);
    },
    encrypt: (puk, dataString) => {
        const enc = Buffer.from(dataString);
        return forge.util.encode64(puk.encrypt(enc));
    },
    decrypt: (prk, dataString) => {
        return prk.decrypt(
            forge.util.decode64(dataString)
        );
    },
    signWithPrivateKey: (prk, dataString) => {
        let md = forge.md.sha256.create();
        md.update(dataString, "utf8");
        return forge.util.encode64(prk.sign(md));
    },
    verifyWithPublicKey: (puk, dataString, signature) => {
        let md = forge.md.sha256.create();
        md.update(dataString, "utf8");
        return puk.publicKey.verify(
            md.digest().getBytes(),
            forge.util.decode64(signature)
        );
    },
}