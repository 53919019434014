import React from 'react';
import { FaPrint } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/wfp-core-store.type';
import { DefaultButton } from './default-button';

type Props = { onPrint: () => void };

export function PrintButton(props: Props) {
    const { onPrint } = props;
    const language = useSelector((store: RootState) => store.session.language);
    return (
        <DefaultButton id={'printBtn'} onClick={onPrint}>
            <FaPrint /> {language.print}
        </DefaultButton>
    );
}
