import { Expose, Type } from 'class-transformer';
import { AddressSearchLanguage } from '../../service/address-lookup.request';
import { GeoAddress } from '../../types';
import { GeoSpatialInformation } from '../low-level';
import { ChiPremisesAddress } from './chinese-premises-address.entity';
import { EngPremisesAddress } from './english-premises-address.entity';
import { traditionalToSimplified } from '../../../utils/simplified-traditional-chinese-convert.util';

export class PremisesAddressWrapper {
    @Expose({ name: 'ChiPremisesAddress' })
    @Type(() => ChiPremisesAddress)
    chinese: ChiPremisesAddress;

    @Expose({ name: 'EngPremisesAddress' })
    @Type(() => EngPremisesAddress)
    english: EngPremisesAddress;

    @Expose({ name: 'GeoAddress' })
    geoAddress: GeoAddress;

    @Expose({ name: 'GeospatialInformation' }) // Not Typo
    @Type(() => GeoSpatialInformation)
    geoSpatialInformation: GeoSpatialInformation;

    getString(language: AddressSearchLanguage) {
        switch (language) {
            case AddressSearchLanguage.ENGLISH:
                return this.english?.getString();
            case AddressSearchLanguage.CHINESE:
                return this.chinese?.getString();
            case AddressSearchLanguage.SIMPLIFIED_CHINESE:
                return traditionalToSimplified(this.chinese?.getString());
            default:
                return this.english?.getString();
        }
    }

    getObject(language: AddressSearchLanguage) {
        switch (language) {
            case AddressSearchLanguage.ENGLISH:
                return this.english;
            case AddressSearchLanguage.CHINESE:
                this.chinese.setSimpChn(false);
                return this.chinese;
            case AddressSearchLanguage.SIMPLIFIED_CHINESE:
                this.chinese.setSimpChn(true);
                return this.chinese;
            default:
                return this.english;
        }
    }
}
