import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../layout/core/reducers/wfp-core-store.type';
import { OnePointToNote as Entity } from '../../../entities';
import { OnePointToNote } from './one-point-to-note';

export function PointsToNote() {
    const { availablePaymentMethods = [], paymentMethodIndex } =
        useSelector((state: RootState) => state.payment) || {};

    const pointsToNote: Entity[] = availablePaymentMethods[paymentMethodIndex]?.pointsToNote || [];
    const hasNote = !!pointsToNote && pointsToNote.length > 0;

    return (
        <div
            className={`alert alert-warning alert-dismissible fade-in showPaymentMethodNoteGroup${
                hasNote ? '' : ' hidden'
            }`}
            role="alert"
            id="showPaymentMethodNoteGroup"
        >
            <strong>
                <span
                    id="showPaymentMethodNote"
                    className={`showPaymentMethodNote${hasNote ? '' : ' hidden'}`}
                >
                    <ul>
                        {pointsToNote.map((data, idx) => (
                            <OnePointToNote key={idx} data={data} />
                        ))}
                    </ul>
                </span>
            </strong>
        </div>
    );
}
