import React, { useState, useContext, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { useSelector } from 'react-redux';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { lookupAddress } from '../api/service/address-lookup.service';
import { simplifiedToTraditional } from '../utils/simplified-traditional-chinese-convert.util';
import { websiteLocaleToAddressLocale } from '../utils/website-locale-to-address-locale.util';
import { Suggestion } from './suggestion';
import { FieldContext } from '../../layout/core/context';
import { SuggestedAddress } from '../api/entities';

export function LookupAddressSuggestion(props:any) {
    const { maxlength, onSelectAddress } = props;
    const {datumId, fvalue, setFValue, setCompleted} = useContext(FieldContext);
    const [suggestions, setSuggestions] = useState([]);
    const [timer, setTimer] = useState(null);
    const websiteLang = useSelector((store: RootState) => store.session.curLanguage);
    const resetTimeout = () => {
        if (timer) {
            clearTimeout(timer);
        }
    };
    const updateSuggestions = (latest: string) => async () => {
        if (latest && latest !== '') {
            const res = await lookupAddress({ address: simplifiedToTraditional(latest) });
            // console.log('parsed', res);
            if (res.suggestedAddresses && res.suggestedAddresses?.length > 0) {
                setSuggestions(res.suggestedAddresses);
            } else {
                setSuggestions([]);
            }
        }
    };
    const debouncedUpdate = (latest: string) => {
        resetTimeout();
        setTimer(setTimeout(updateSuggestions(latest), 1000));
    };

    // 2023-10-10 by Jess Hui
    // There is a side effect while using Chinese IME, that the Chinese charater will be overwritten by the update of 'fvalue'.
    // Therefore, 'value' in state is used instead of putting the fvalue directly into the <Autosuggest />, and the fvalue will be set only when the focus is lost.
    const [value, setValue] = useState("");
    const onChange = (e: any, ret: any) => {
        setValue(ret.newValue);
    };
    const onBlur = (e: any, ret: any) => { 
        setFValue(value);
        setCompleted(true);
    }
    useEffect(() => {
        setValue(fvalue??"");
    }, [fvalue]);
    // 2023-10-10 by Jess Hui

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={(fetch) => {
                // console.log('Fetch Requested for string: ', fetch.value);
                debouncedUpdate(fetch.value);
            }}
            onSuggestionsClearRequested={() => {
                // console.log('removing suggestions');
            }}
            getSuggestionValue={(v) => {
                //console.log('hovering over suggestion value:', v);
                if(onSelectAddress) {
                    const _selectedSugguestionAddressData = v.getObject(websiteLocaleToAddressLocale(websiteLang));
                    const _value = onSelectAddress(_selectedSugguestionAddressData);
                    // Override default auto fill
                    return _value;
                }
                return v.getString(websiteLocaleToAddressLocale(websiteLang));
            }}
            renderSuggestion={(v) => <Suggestion suggestion={v} />}
            inputProps={{ value, onChange, onBlur, className: "form-control " + datumId + '_lookup', maxLength: maxlength}}
            containerProps={{  }}
        />
    );
}
