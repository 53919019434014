import React from 'react';
import lo from 'lodash';
import { Navbar, Row } from 'react-bootstrap';
import { detect } from 'detect-browser';
import { useDispatch, useSelector } from 'react-redux';

import { dialogHelper } from './core/dialogHelper';
import { setLanguageThunk } from './core/reducers/setLanguage.thunk';
import SysParam from '../utilities/sysParam';

export default ({logo, language, curLanguage, maintenance}) => {
    const dispatch = useDispatch();

    return (
        <Navbar className={window.WFP_CONFIG?.ENV == "UAT" ? "headerBackground-uat" : "headerBackground"}>
            <Row className="topBar alignRight d-flex align-items-end justify-content-end">
                <div className="topBarLeftContainer">
                    <a target="_blank" href ={SysParam.LR_WEBSITE_HOME(curLanguage)} className="lrLogo">
                        <img src={logo} className="lrLogo" alt={language.logoAltText} url={window.location.href} />
                    </a>
                </div>
                <div key="root_topBarRightContainer" className="topBarRightContainer">
                    <span>
                        { maintenance ? null :
                            <button className="nav_fontSize" style={{cursor:"pointer"}} onClick={() => dispatch(dialogHelper.showFontSizeDialog({language:language, browser: detect()}))}>
                                { language.nav_fontSize }
                            </button>
                        }

                        {
                            window.location.href.toUpperCase().includes('/LREEA1') || maintenance ? 
                            null
                            :
                            <>
                                &emsp;|&emsp;
                                { curLanguage !== "TradChn" ?
                                    <button 
                                        className="normalFont langButton wfp-langButton"
                                        onClick={() => dispatch(setLanguageThunk("TradChn"))} >繁</button> : null }
                                { curLanguage !== "SimpChn" ?
                                    <button 
                                        className="normalFont langButton wfp-langButton"
                                        onClick={() => dispatch(setLanguageThunk("SimpChn"))} >简</button> : null }
                                { curLanguage !== "Eng" ?
                                    <button 
                                        className="normalFont langButton wfp-langButton"
                                        onClick={() => dispatch(setLanguageThunk("Eng"))} >Eng</button> : null }
                            </>
                        }
                    </span>
                </div>
            </Row>
        </Navbar>
    );
}