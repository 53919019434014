import moment from 'moment';
import { Period } from './period.type';
import { SimplifiedChineseDict as Dict } from './simplified-chinese.dict';

export class SimpChnTimeHelper {
    static format(s: Period) {
        const { from, to } = s;
        const fromDate = this.formatDate(from);
        const toDate = this.formatDate(to);
        const fromTime = this.formatTime(from);
        const toTime = this.formatTime(to);

        return `${fromDate}${fromTime} ${Dict.TO} ${fromDate !== toDate ? toDate : ''}${
            Dict.AROUND
        }${toTime}`;
    }

    static formatDate(d: Date) {
        return `${d.getFullYear()}${Dict.YEAR}${d.getMonth() + 1}${Dict.MONTH}${d.getDate()}${
            Dict.DATE
        } (${this.getDayOfWeek(d)}) `;
    }

    static formatTime(d: Date) {
        return `${this.getTimePrefix(d)} ${moment(d).format('h:mm')}`;
    }
    static getDayOfWeek(d: Date) {
        switch (d.getDay()) {
            case 0:
                return Dict.SUNDAY;
            case 1:
                return Dict.MONDAY;
            case 2:
                return Dict.TUESDAY;
            case 3:
                return Dict.WEDNESDAY;
            case 4:
                return Dict.THURSDAY;
            case 5:
                return Dict.FRIDAY;
            case 6:
                return Dict.SATURDAY;
        }
    }

    static getTimePrefix(d: Date) {
        if (d.getHours() < 6) return Dict.EARLY_MORNING;
        if (d.getHours() < 13) return Dict.MORNING;
        if (d.getHours() < 16) return Dict.AFTERNOON;
        return Dict.EVENING;
    }
}
