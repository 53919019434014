import { plainToInstance } from 'class-transformer';
import { instance } from '../../instance';
import { GetTransactionIdResponse as Response } from './response';
import { WfpApiUrl } from '../../../constants';

const url = WfpApiUrl.GET_TRANSACTION_ID;

export async function getTransactionId(amount: number): Promise<Response> {
    const data = await instance.get(`${url}/${amount}`);
    return plainToInstance(Response, data);
}
