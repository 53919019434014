import { createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dialogHelper } from '../../layout/core/dialogHelper';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { GetCaptchForm } from '../../layout/core/layoutElements';
import { setCaptchaToken } from '../../layout/core/reducers/wfpSessionReducer';

export function useShowCaptcha() {
    const dispatch = useDispatch();
    const language = useSelector((store: RootState) => store.session.language);

    return (callback = () => {}) => {
        // Assuemes current token is invalid whenever called
        // Need to clear existing token manually, otherwise Captcha dialog is empty
        dispatch(setCaptchaToken(null));
        dispatch(
            dialogHelper.showCaptchaDialog({
                children: createElement(GetCaptchForm),
                language,
                handleConfirm: callback,
            })
        );
    };
}
