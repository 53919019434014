import { plainToInstance } from 'class-transformer';
import { instance } from '../instance';
import {
    AddressLookupRequest,
    AddressLookupRequest as Input,
    AddressSearchLanguage,
} from './address-lookup.request';
import { LookupAddressResponse } from './address-lookup.response';

export async function lookupAddress(input: Input) {
    const { address } = input;
    const request = new AddressLookupRequest({
        address,
        preferredLanguage: AddressSearchLanguage.BOTH,
    });
    // console.log(request);
    const data = await instance.post(
        '/lookup', 
        request,
        {
            headers: {
                'Accept-Language': 'en-US,en;q=0.9,zh-TW;q=0.8,zh;q=0.7'
            }
        }
    );
    // console.log('raw');
    // console.log(data);
    return plainToInstance(LookupAddressResponse, data);
}
