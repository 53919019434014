import React from 'react';
import { Payment, TextKey } from '../../../payment.proxy';
import '../payby.css';

export function CloseButton(props: Props) {
    // 202309: add custom style for fps
    const { onClose, disabled, customClass } = props;
    return (
        <div className={`${customClass} col-xs-6 col-md-6 close-group`}>
            <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={onClose}
                id="btnClose"
                disabled={disabled}
            >
                <span className="glyphicon glyphicon-remove"></span>{' '}
                <span id="btnCloseLable">{Payment.getText(TextKey.CLOSE)}</span>
            </button>
        </div>
    );
}

type Props = {
    onClose: () => void;
    disabled?: boolean;
    customClass?: string;
};
