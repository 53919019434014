import React from 'react';
import SysParam from '../../../../utilities/sysParam';
import { GCIS_PAYMENT_HANDLER } from '../../../../gcis-payment/constants';
import { GCIS_PAYMENT_EN as GCIS_PAYMENT } from './gcis-payment';

export default {
	loadingPleaseWait: "Loading...",
	nav_fontSize: "Text Size",
	logoAltText: "The Land Registry - The Government of the Hong Kong Special Administrative Region",
	title_formMenu: "Public Forms",
	title_TandC: "Terms and Conditions",
	requirements: "Required items for this application",
	requirements_printer: "Printer",
	requirements_printer_desp: "For printing the form",
	requirements_reader: "Adobe Reader",
	requirements_reader_desp: "For viewing / printing the completed form after downloading.",	
	requirements_esign: "Electronic Signature (If applicable)",
	requirements_esign_desp: ()=> "Applicant must use “iAM Smart+” or digital certificate issued by the Hongkong Post Certification Authority / Digi-Sign Certification Services Limited for signing the online form.",
	//requirements_ecertOnly_desp: "Applicant must use digital certificate issued by the Hongkong Post Certification Authority / Digi-Sign Certification Services Limited for signing the online form.",
	// requirements_ecert: "Digital Certificate issued by the Hongkong Post Certification Authority or Digi-Sign Certification Services Limited.",
	// requirements_ecert_desp: ()=>"For online submission with Digital Signature using Digital Certificate.",
	// requirements_iamsmart: ()=>"Mobile device with the iAM Smart App and iAM Smart+ user account",
	// requirements_iamsmart_desp: "For online submission with Digital Signature using iAM Smart.",
	requirements_echeque: "Online Payment",
	requirements_echeque_desp: "We accept PPS, credit card (Mastercard / VISA / UnionPay / JCB), FPS, Apple Pay or Google Pay (Mastercard / VISA only) and e-Cheque for settling service charges.",

	documentTitle: "The Land Registry",

	steps_Notes: "Important Notes",
	steps_Particulars: "Application Particulars",
	steps_UploadECheque: "Upload e-Cheque",
	steps_Confirmation: "Review",
	steps_Submission:"Submission",
	steps_Signing:"Please Sign Form",
	steps_PaymentMethod: "Payment Method",
	steps_Payment: "Online Payment",

	wantTo: "I Want To",
	wantTo_startFilling : "Start Filling in a New Form",
	wantTo_continueFilling : "Continue Filling in Form",
	wantTo_loadSaved: "Fill in a Saved Form",

	backToTop: "Back to top",
	acknowledgement: "Acknowledgement",
	importantNotes: "Introduction",
	mandatoryHint: "Mandatory fields",
	submissionAcknowledgement: "Acknowledgement e-mail of submission",
	receiveAckEmail:"I would like to receive an acknowledgement e-mail for this submission.",
	ackEmailIncludeFilledForm:"I would like to receive the completed form as an attachment to the acknowledgement e-mail.",
	submissionRecord: "Submission record",
	submissionTime: "Submission Time",
	signAndSubmit: "Sign and submit application ",
	signOnly: "Sign Form",
	successfullySigned: "Successfully Signed",
	saveForSubmission: "Thank you for filling our form.  Please click the \"Download Signed Form\" button to download the signed application form for submission to the Land Registry through email.",
	printForSubmission: "Thank you for filling our form.  Please click the {0} button to print the completed application form for submission to the Land Registry.",
	printForSubmissionAlt: "Thank you for filling our form. This application has to be submitted in person. Please click the {0} button to print the completed form and submit the application to Land Registry's Customer Centre or New Territories Search Offices.",
	printForSubmissionAlt2: "Thank you for filling our form. This application has to be submitted in person. Please click the {0} button to print the completed form and submit the application together with relevant supporting documents to Land Registry's Customer Centre or New Territories Search Offices.",
	notesSection: "Notes",

	error: "Error",
	generalErrMsg: "There is a problem with the system.  Please contact the Customer Service help desk at 3105-0000.",
	generalErrMsgWithSubmissionId: "There is a problem with the system.  Please contact the Customer Service help desk at 3105-0000. Reference ID: {0}",
	correctValidationError: "Please correct the errors to proceed",
	savePrintValidationError: "Please correct the errors before printing/saving.",
	passwordError: "Failed to load form data.  Please verify the selected file is a saved form data file, and the password is the one used when saving the form data.",
	fileNotSelected: "Please select a saved form data file.",
	captchaMessage: "CAPTCHA verification required.  Please enter the characters of the image.   ",
	captchaRequired: "Input characters do not match with the image. Please enter again.",
	captchaNotMatch: "Input characters do not match with the image. Please enter again.",
	captchaError: "Input characters do not match with the image. Please enter again.",
	captchaExpired: "CAPTCHA verification expired.  Please enter again.",
	captchaRefresh1: "(Please click \"",
	captchaRefresh2: "\" to refresh the image if it is unclear.)",
	captchaRetry: "Unable to get CAPTCHA from server.  Please click here to retry.",

	loadFormCodeError: (sourceType, formRef, formCode) => <span>This form data file cannot be loaded because it is saved from another form (<a target="_blank" href={"/"+sourceType+"/form/"+formCode}>{formRef}</a>).</span>,
	loadFormOldVersion: "This form data file is for an older version of the form.  Some of the data may not be loaded.  Please check the loaded data for correctness.",
	saveCertPasswordError: "Failed to save form data.  Password for the digital certificate is incorrect.",
	savePasswordNotConfirm: "Failed to save form data.  The Password and Confirm Password do not match.",
	passwordEmpty: "Failed to save form data.  The input for password is empty.",
	passwordWrongLength: "Failed to save form data.  The input for password should be between {0} and {1} characters.",

	chooseAFileToUpload: "Select a file to upload.",
	acceptFileFormat: "Accept file format",
	sizeUpTo: "Size up to ",

	captcha:"CAPTCHA",
	clear:"Clear",
	new:"New",
	save: "Save",
	saveAgain: "Please Print Again",
	saveSignedForm: "Download Signed Form",
	load: "Load",
	return: "Return",
	returnToFormList: "Return To Form List",
	print: "Print",
	loadForm: "Open a saved form",
	providePwECert: "Please enter a password to save the form data file. ",
	dontForgetPw: "NOTE: Please keep your password for loading the saved data file.",
	back: "Back",
	next: "Next",
	close: "Close",
	zoomIn: "Zoom In",
	zoomOut: "Zoom Out",
	confirm: "Confirm",
	footNote: "Notes",
	complete: "Complete",
	clickToExpand: "Learn more",
	clickToCollapse: "Hide details",
	fillIn: "Start filling in form ",
	head_formTitle: "Form Title",
	formNo: "Form Number",
	startFilling: "Start Filling",
	submit: "Submit",
	signIAS: "Signing with iAM Smart",
	signIASTitle: "Sign your form with \"iAM Smart\"",
	ias_text: "Make sure the identification code shown in \"iAM Smart\" is the same and tap on \"Sign\" to complete the digital signing",
	ias_continue: "Continue with iAM Smart",
	signECert: "Signing with Digital Certificate",
	signLater: "Save for next signatory",
	signPrinted: "Preview / Sign on Paper",
	saveECert: "Save with e-Cert",
	importantTitle: "*** IMPORTANT ***",
	submissionMessage1: "*** IMPORTANT ***",
	submissionMessage2: "Please click “Submit” button to complete your online submission.",
	submissionMessage2A: "Please click the "+String.fromCharCode(10004)+" button to save the signed form.",
	submissionMessage2B: "Please click the “Next” button to proceed online payment (PPS / Credit Card / FPS / Mobile Payment) to complete your online submission. ",
	echequeMessage2: "If you choose e-Cheque as the payment method, please upload your e-Cheque file in this step.  Otherwise, please click \"Next\" button to proceed, and submit your payment to Land Registry separately.",
	echequeMessage3: "Please do not mail-in cash to the Land Registry.",
	uploadECheque:"Upload e-Cheque",
	eCheque: "e-Cheque",
	cheque: "Cheque",
	cash: "Cash",
	bankDraft: "Bank Draft",
	cancel: "Cancel",
	addARecord: "Add a record",
	removeARecord: "Remove last record",
	pleaseChoose: "Please choose...",
	password: "Password",
	confirmPassword: "Confirm Password",
	passwordCondition: "({0}-{1} characters)",
	uploadECert:"Select Digital Certificate file (*.p12)",
	certificate:"Certificate",
	certificatePublic:"Public Key File (*.cer)",
	certificatePrivate:"Select Digital Certificate File",
	pin:"Input Password of Digital Certificate",
	signAndCont:"Sign and Continue",
	savedFormFile:"Saved Form File",
	signECertNote:"I have read and agree to the above \"Terms and Conditions\" and \"Personal information Collection Statement\"",
	signECertNote2_PICSonly:"I have read and agree to \"Personal Information Collection Statement\".",
	signEIDNote:"Please provide your Hong Kong Identity Card number for proceeding to use iAM Smart+ to sign your submission.  The number will only be processed on your computer and will not be sent to our System.  Please ensure you have registered with iAM Smart+ and installed the iAM Smart mobile application on your mobile phone.",
	signEIDWaiting:"Please complete the iAM Smart authentication procedure in the popup window.  This window will disappear when the signing process is completed.",
	generalFaq: "General FAQ",
	generalTerms: "General Terms of Use",
	termsAndConditions: "Terms and Conditions",
	termsAndConditionsOf: "Terms and Conditions of this form",
    readAndUnderstandTerms: "I/We have read and understand the above \"General Terms of Use\" and \"Terms and Conditions of this form\", and agree to be bound by them.",
    readAndUnderstandGeneralTerms: "I/We have read and understand the above \"General Terms of Use\", and agree to be bound by it.",
	PICS: "Personal Information Collection Statement",
	readAndUnderstandPICS: "I/We have read and understand the above \"Personal Information Collection Statement\", and agree to be bound by it.",
	noteTitle: "Notes",
    readAndUnderstandNotes: "I acknowledge that I have read, understood and agreed with the above notes.",
	confirmationMessage: "Please check the following information before submission. You may go back to make changes as needed.",
	confirmationMessageAlt: "Please check the following information before submission. You may go back to make changes if needed.",
	confirmationMessage_signing:  "Once you have submitted this online application form, no online amendments will be allowed.  Any subsequent amendments can only be made in writing to the Land Registry.",
	confirmationMessage_signing2: "If you would like to submit the application by post/courier service, by fax, or in person, please click the \"Print\" button at the bottom to print out a completed application form.",
	confirmationMessage_signing2Alt: "If you would like to submit the application in person, please click the \"Print\" button at the bottom of this page to print out the completed application form.",
	confirmationMessage_signing3_inPersonOnly: "If you would like to submit the application in person, please click the \"Print\" button at the bottom of the page to print out a completed application form.",
	acknowledgement_offline_para: ()=>(
		<>
			<p>Your form has been successfully printed.</p>
			<p>To complete your application, please deliver the printed form to the Land Registry by post/courier service, by fax, or in person.</p>
		</>
	),
	acknowledgement_para1: "Your application has been submitted and will be processed by the Land Registry.",
	acknowledgement_para2: ()=> (<>For any enquiries on your application, please quote the following reference number:</>),
	ack_refNum: "Application Reference Number",
	paymentTxnNum: "Payment Transaction Number",
	paymentMethod: "Payment Method",
	paymentAmount: "Payment Amount",
	printOrSavePage: "Print / Save this page",
	noFileSelected: "No File (0KB)",
	ackEmail: "Acknowledgement E-mail address",
	signed: "Signed",
	signedBy: "Signed by {0}",
	signError: "Error",
	changeSignature: "Change Signature",
	departmentName: "Department Name",
	serviceName: "Service Name",
	documentName: "Document Name",
	signCode: "Identification Code",
	landRegistry:"The Land Registry",
	webFormPortal:"Public Forms of the Land Registry",
	sameDevice:"Sign with same device",
	diffDevice:"Sign with a different device",
	eCertError:"The digital certificate or password is incorrect.",
	additionalDocuments: "Additional Documents",
	sessionTimeout: "Session Timeout",
	sessionGoingToTimeout: "Your session is going to time out in 1 minute.  Please click \"Confirm\" to continue.",
	timeRemaining: "Time Remaining: {0} seconds",
	sessionHasTimedout: "Your session has timed out.  Please refresh.",
	refresh: "Refresh",
	refreshPdf: "Refresh Form",
	serverBusy: "The system is currently busy.  Please wait a moment and try again.",

	paymentMethod: "Payment Method",
	paymentMethod_list:[
		{name:"e-Cheque"},
	],

	emailForAcknowledgement: "Please provide your e-mail address for receiving the acknowledgement e-mail.",
	passwordForAcknowledgement: "Please provide a password for encrypting the filled form.",
	
	steps_Acknowledgement: "Acknowledgement",

	field_ChnName: "Chinese Name (if applicable)",
	field_EngName: "English Name",
	field_Salutation: "Salutation",
	field_DayTimePhone: "Day-time Contact Phone Number",
	field_HomePhone: "Home Phone Number",
	field_WorkPhone: "Office Phone Number",
	field_MobilePhone: "Mobile Phone Number",
	field_ContactPhone: "Contact Phone Number",
	field_Fax: "Fax Number",        
	field_Email: "E-mail Address",
	field_RetypeEmail: "Re-type E-mail Address",
	field_HKID: "Hong Kong Identity Card / identity document",
	field_HKID_withCheckDigit: "Hong Kong Identity Card Number (without bracket, e.g. A1234567)",
	field_IdType: "ID Type",
	field_IdNum: "ID Number",
	field_Gender: "Gender",
	field_DOB: "Date of Birth",
	field_CorrespondenceAddress: "Correspondence Address",
	field_acceptNotes: "I acknowledge that I have read, understood and agreed with the above notes.",
	field_Date: "Date",
	field_Time: "Time",
	field_Name: "Name",
	field_NameOfDeclarant: "Name of Declarant",
	field_BusinessReg: "Business Registration / Company Registration",

	field_Salutation_Mr: "Mr.",
	field_Salutation_Mrs: "Mrs.",
	field_Salutation_Ms: "Ms.",
	field_Salutation_Miss: "Miss",
	
	field_signatoryName: "Full Name of Signatory",

	field_AddressFlat: "Flat",
	field_AddressFloor: "Floor",
	field_AddressBlock: "Block",
	field_AddressBuilding: "Building Name",
	field_AddressEstate: "Estate Name",
	field_AddressStreet: "No. and name of Street",
	field_AddressDistrict: "District",
	field_AddressHongKong: "Hong Kong",
	field_AddressKowloon: "Kowloon",
	field_AddressNewTerritories: "New Territories",

	field_IdType_HKID: "HK ID Card", 
	field_IdType_PRCID: "People's Republic of China ID Card", 
	field_IdType_PRCHKMAC: "People's Republic of China Hong Kong and Macau Re-entry Permit", 
	field_IdType_CR: "Company Registration", 
	field_IdType_PASSPORT: "Passport", 
	field_IdType_NA: "N/A", 
	Signature: "Signature",

	field_paymentMethod: "Payment Method",
	field_paymentMethod_list:(extraFieldIds) => [
		{value:"cash", text:"Cash"},
		{value:"cheque", text:"Cheque", extraField:"Cheque Number", extraFieldId: extraFieldIds[0], extraFieldProps: { numericHyphen: true }},
		// {value:"cheque", text:"e-Cheque"},
		{value:"bank draft", text:"Bank Draft", extraField:"Number", extraFieldId: extraFieldIds[1], extraFieldProps: { numericHyphen: true }},
	],
	field_paymentMethod_list_negotiable:(extraFieldIds) => [
		{value:"cheque", text:"Cheque", extraField:"Cheque Number", extraFieldId: extraFieldIds[0], extraFieldProps: { numericHyphen: true }},
		{value:"bank draft", text:"Bank Draft", extraField:"Number", extraFieldId: extraFieldIds[1], extraFieldProps: { numericHyphen: true }},
	],
	field_paymentMethod_list_cash:(extraFieldIds) => [
		{value:"cash", text:"Cash"},
	],
	field_onlinePayment_list:[
		{text: GCIS_PAYMENT.METHOD_SUMMARY, meta: {handler: GCIS_PAYMENT_HANDLER}},
		{value: "cheque", text: "e-Cheque (Online Submission)"}
	],
	field_amount: "Payment Amount",
	field_amount_prepend: "HK$", 
	field_Gender_List: [
		{name:"Male"},
		{name:"Female"},
		{name:"Other"}
	],

	field_AddressFlat: "Flat",
	field_AddressFloor: "Floor",
	field_AddressBlock: "Block",
	field_AddressBuilding: "Building",
	field_AddressEstate:"Estate",
	field_AddressStreet:"Street name and Number",
	field_AddressDistrict:"District",
	field_AddressRegionHongKong:"Hong Kong",
	field_AddressRegionKowloon:"Kowloon",
	field_AddressRegionNT:"New Territories",

	validation_required: "Mandatory",
	validation_file_required: "Mandatory",
	validation_lengthMax: "Should not be more than {0} characters",
	validation_lengthMin: "Should not be less than {0} characters",
	validation_lengthRange: "Should be between {0} and {1} characters",
	validation_lengthExact: "Should be exactly {0} characters long",
	validation_decimalMax: "Should not be more than {0}",
	validation_decimalMin: "Should not be less than {0}",
	validation_decimalRange: "Should be between {0} and {1}",
	validation_chineseOnly: "Should only contain Chinese characters",
	validation_englishOnly: "Should only contain English characters",
	validation_numberOnly: "Should only contain numbers",
	validation_integerOnly: "Should only contain integers",
	validation_phonenumber: "Not a valid phone number",
	validation_faxnumber: "Not a valid fax number",
	validation_addressBldgEstateOrStreet:  "Either \"Building Name\", \"Estate Name\", \"No. & Name of Street,\" is required",
	validation_districtRequired: "District is required",
	validation_multiSelected: "Should not be more than 1 selection",
	validation_invalidHKID: "Invalid HKID number",
	validation_invalidEmail: "Invalid Email Address",
	validation_invalidDate: "Should be a valid date of format {0}",
	validation_invalidTime: "Should be a valid time of format h:mm AM/PM",
	validation_confirmPasswordDiff: "Password and Confirm Password are different",
	validation_alphanumeric: "Should only contain alphanumeric characters (0-9, A-Z, a-z)",
	validation_alphanumericWithHyphen: "Should only contain alphanumeric characters (0-9, A-Z, a-z) and hyphens (-)",
	validation_onOrAfterToday: "Should be on or after today",
	validation_onOrBeforeToday: "Should be on or before today",
	validation_onOrBeforeDate: "Should be on or before {0}",
	validation_numericWithComma: "Should only contain numbers and commas (,)",
	validation_paymentAmount: "Invalid format",
	validation_numericWithHyphen: "Should only contain numbers and hyphen (-)",
	validation_fileSizeExceed:"File size should not exceed {0}",
	validation_wrongFileType:"File format is incorrect",
	validation_futureEnough: "Should be {0} day(s) after today",
	validation_selectAtLeastOne:"Please select at least one option.",

	validation_eChequeInvalid:"The submitted e-Cheque is invalid. Please try again.",
	validation_eChequeInvalidAmount:"Payment amount of the submitted e-Cheque is incorrect. Please try again.",
	validation_eChequeInvalidCurrency:"Currency of the submitted e-Cheque is not Hong Kong dollar. Please try again.",
	validation_eChequeInvalidPayee:"Payee of the submitted e-Cheque is incorrect. Please try again.",
	validation_eChequeExpired:"The submitted e-Cheque is expired. Please try again.",

	validation_eCertInvalid:"The digital certificate is invalid. Please try again.",
	validation_eCertExpired:"The digital certificate is expired. Please try again.",
	validation_eCertRevoked:"The digital certificate is revoked. Please try again.",
	validation_eCertNotEffective:"The digital certificate is not effective yet. Please try again.",

	validation_ias_unknownException:"[D20000] Unknown error when communicating with iAM Smart.  Please contact the Customer Service help desk at 3105-0000 for assistance.",
	validation_ias_accTokenNotExist:"[D20009] Authentication Error when communicating with iAM Smart.  Please contact the Customer Service help desk at 3105-0000 for assistance.",
	validation_ias_authTimeout:"[D40003] Authentication timeout.  Please try again.",
	validation_ias_signingCanceled:"[D70000] Signing request has been cancelled.",
	validation_ias_signingRejected:"[D70001] Signing request has been rejected.",
	validation_ias_signingFailed:"[D70002] Signing request is failed.  Please try again.",
	validation_ias_signingTimeout:"[D70003] Signing request is timed out.  Please try again.",
	validation_ias_signingNotAllowed:"[D70004] The user is not allowed to sign by iAM Smart.",
	validation_ias_signingWrongHKID:"[D70005] Incorrect HKID Number. Please confirm the identity of the signatory and try again.",
	validation_ias_signingProcessFailed:"[D70006] Failed to process the signature. Please try again.",
	validation_ias_signingGetResultError:"[D79402] Failed to get the signature result. Please try again.",

	validation_signature_verificationFailed:"Signing request is failed.  Please try again.",

	validation_signature_signerNameMismatch:"Name of the digital signature does not match with the applicant’s English Name",
	validation_signature_signerIdMismatch:"The identity document number does not match with the applicant’s identity document number",
	validation_signature_nonPersonal:"Please use a personal digital certificate for the digital signature of this application",
	validation_signature_nonOrganisation:"Please use a organisational digital certificate for the digital signature of this application",
	validation_signature_specifyNonHKID:"Please select \"Hong Kong Identity Card\" in the above field, or amend the input identity document type",
	validation_signature_signerOrgNameMismatch:"Organisation Name of the digital signature does not match with the applicant’s Organisation Name",
	validation_signature_signerOrgAuthNameMismatch:"Authorised User Name of the digital signature does not match with the applicant’s Authorised User Name",
	validation_signature_signerOrgIdNumMismatch:"Organisational identity number of the digital signature does not match with the applicant’s organisational identity number",
	validation_signature_signerOrgBRNMismatch:"Business registration certificate number of the digital signature does not match with the applicant’s business registration certificate number",
	validation_signature_signerOrgCICRMismatch:"Company registration certificate number of the digital signature does not match with the applicant’s company registration certificate number",

	validation_max_records: "Max Records: ",

	personalParticulars: "Personal Particulars",

	CDPaymentMethod: "Payment Method",
	CDChequeOrBankDraftNo: "Cheque or Bank Draft Number", 

	confirmationMessage_signOnly: ()=> [<>Please check the following information before signing. You may go back to make changes as needed.</>],
	confirmationMessage_offlineSubmit: ()=> [<>Please check the following information, and click the {String.fromCharCode(10004)} button to print out the filled form for submission in paper when ready.</>],
	confirmationMessage_noESubmit: ()=> [<>This form does not accept online submission.  Please check the following information, and click the {String.fromCharCode(10004)} button to print out the filled form for submission in paper when ready.</>],
        
	PICSContent: ()=>      [
	<ol> 
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Purpose of Collection</span>
			<p>
				The personal data collected in this form will be used by the Land Registry for the following purposes :-
				<ol style={{paddingTop:"15px", listStyleType: "lower-latin"}}> 
					<li>to carry out activities relating to the provision of services by the Land Registry;</li>
					<li>to facilitate communications; and</li>
					<li>to produce statistics relating to the Land Registry's services.</li>
				</ol>
			</p>
			<p>
				You understand that the provision of your personal data is voluntary. If you fail to provide information as required in this form, the Land Registry may not be able to provide the requested service.
			</p>
			<p>
				Please do NOT provide any personal data (including personal data relating to third parties) which are not specifically required to be submitted. Where information of any third party is included in this form or any document(s) filed in relation to it, the Land Registry will treat that you have obtained consent from such third party to disclose such information for the purposes above.
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Classes of Transferees</span>
			<p>
				You understand that the personal data provided in this form may be disclosed or transferred to parties relevant where such disclosure or transfer is necessary for any purposes as stated in paragraph 1 above.
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Access to Personal Data</span>
			<p>
				You understand that pursuant to Sections 18 and 22 and Principle 6 of Schedule 1 of the Personal Data (Privacy) Ordinance (Cap. 486) (<span style={{fontWeight:"bold"}}>"PDPO"</span>), you have the right to request access to and correction of your personal data held by the Land Registry. Under PDPO, the Land Registry is entitled to charge a fee to process the said request. Any such request shall be made to the Personal Data (Privacy) Officer of the Land Registry at 28th Floor, Queensway Government Offices, 66 Queensway, Hong Kong.
			</p>
		</li>
		
	</ol>],

	BMOPICSContent: ()=> [<ol> 
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Purpose of Collection</span>
			<p>
				The personal data collected in this form will be used by the Land Registry for the following purposes:
				<ol style={{paddingTop:"15px", listStyleType: "lower-latin"}}> 
					<li>to carry out activities and the Land Registry’s functions relating to the provision of services by the Land Registry;</li>
					<li>to facilitate communications; and</li>
					<li>to produce statistics relating to the Land Registry’s service;</li>
				</ol>
			</p>
			<p>
				You understand that the provision of personal data is obligatory. . If you fail to provide information as required in this form, the Land Registry may not be able to provide the requested service.
			</p>
			<p>
				Please do NOT provide any personal data (including personal data relating to third parties) which are not specifically required to be submitted.  Where information of any third party is included in this form or in any document(s) filed in relation to it, the Land Registry will treat that you have obtained consent from such third party to disclose such information for the purposes above.
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Disclosure of Personal Data</span>
			<p>
				You understand that the personal data provided in this form may be disclosed or transferred to relevant parties where such disclosure or transfer is necessary for  the purposes as stated in paragraph 1 above.  Such personal data may also be disclosed or transferred to law enforcement agencies as permitted under the Personal Data (Privacy) Ordinance (Cap. 486) (“PDPO”).
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Access to Personal Data</span>
			<p>
			You understand that pursuant to Sections 18 and 22 and Principle 6 of Schedule 1 of the PDPO, you have the right to request access to and correction of your personal data held by the Land Registry. The Land Registry may charge a fee to process the said request in accordance with the PDPO. Any such request shall be made to the Personal Data (Privacy) Officer of the Land Registry at 28th Floor, Queensway Government Offices, 66 Queensway, Hong Kong.
			</p>
		</li>
	</ol>],

	SSS_PICSContent: ()=>      [<> 
		<p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Purpose of Collection</span></p>
		<p>
			1. The personal data collected will be used by the Land Registry for the following purposes:
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.1</div>
				<div>to carry out activities and the Land Registry’s functions relating to the provision of services by the Land Registry;</div>
			</div>
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.2</div>
				<div>to facilitate communications; and</div>
			</div>
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.3</div>
				<div>to produce statistics relating to the Land Registry's services.</div>
			</div>
		</p>
		<p>
			You understand that the provision of personal data is obligatory. If you fail to provide information as required, the Land Registry may not be able to provide the requested service.
		</p>
		<p>
			Please do NOT provide any personal data (including personal data relating to third parties) which are not specifically required to be submitted.  Where information of any third party is included in this form or any document(s) filed in relation to it, the Land Registry will treat that you have obtained consent from such third party to disclose such information for the purposes above.
		</p>

		<p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Disclosure of Personal Data</span></p>
		<p>
			2. You understand that the personal data provided may be disclosed or transferred to relevant parties where such disclosure or transfer is necessary for the purposes as stated in paragraph 1 above. Such personal data may also be disclosed or transferred to law enforcement agencies as permitted under the Personal Data (Privacy) Ordinance (Cap. 486) (“PDPO”).
		</p>
		
		<p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Access to Personal Data</span></p>
		<p>
			3. You understand that pursuant to Sections 18 and 22 and Principle 6 of Schedule 1 to the PDPO, you have the right to request access to and correction of your personal data held by the Land Registry. The Land Registry may charge a fee to process the said request in accordance with the PDPO. Any such request shall be made to the Personal Data (Privacy) Officer of the Land Registry at 28th Floor, Queensway Government Offices, 66 Queensway, Hong Kong.
		</p>
	</>],

	FSD_PICSContent: ()=> [
		<>
			<p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Purpose of Collection</span></p>
			<p>
				1.	The personal data collected will be used by the Land Registry for the following purposes:-
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.1</div>
					<div>to carry out activities and the Land Registry’s functions relating to the provision of services by the Land Registry;</div>
				</div>
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.2</div>
					<div>to facilitate communications; and</div>
				</div>
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.3</div>
					<div>to produce statistics relating to the Land Registry’s services.</div>
				</div>
			</p>
			<p>
				You understand that the provision of personal data is obligatory.  If you fail to provide information as required, the Land Registry may not be able to provide the requested service.
			</p>
			<p>
				Please do NOT provide any personal data (including personal data relating to third parties) which are not specifically required to be submitted. Where information of any third party is included in this form or any document(s) filed in relation to it, the Land Registry will treat that you have obtained consent from such third party to disclose such information for the purposes above.
			</p>

			<p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Disclosure of Personal Data</span></p>
			<p>
				2. You understand that the personal data provided may be disclosed or transferred to relevant parties where such disclosure or transfer is necessary for the purposes as stated in paragraph 1 above. Such personal data may also be disclosed or transferred to law enforcement agencies as permitted under the Personal Data (Privacy) Ordinance (Cap.486) (“PDPO”).
			</p>
			
			<p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Access to Personal Data</span></p>
			<p>
				3. You understand that pursuant to Sections 18 and 22 and Principle 6 of Schedule 1 to the PDPO, you have the right to request access to and correction of your personal data held by the Land Registry. The Land Registry may charge a fee to process the said request in accordance with the PDPO. Any such request shall be made to the Personal Data (Privacy) Officer of the Land Registry at 28th Floor, Queensway Government Offices, 66 Queensway, Hong Kong.
			</p>
		</>
	],

	howToChangeFontSize: "How to Change Text Size",
	FontSizeContent: (browserName) => [ 
		<>
			<p>Now with this responsive web design, you can easily change the text size to fit your eyesight needs.</p>
			<h4>Shortcut</h4>
			<p>If you browse on Land Registry (LR) Homepage on a desktop computer, a modern web browser will allow you to hold down the Ctrl key (Command key on a Macintosh) 
				and press the + or - key to increase or decrease the text size, and 0 to go back to the default size. On a smartphone or a tablet, you 
				can simply pinch open on the screen to zoom in (i.e. to enlarge) and pinch close to zoom out (i.e. to reduce) everything.</p>
			<h4>Menu</h4>
			<p>You may also change the text size from the menu by following these instructions:</p>
		</>,
		browserName === "chrome" ? <><h5>Chrome</h5><p>On the Page menu, select Zoom, then Larger. </p></> : null,
		browserName === "firefox" ? <><h5>Firefox</h5><p>On the View menu, select Zoom, then Zoom In.  
										Or you may click on the Menu icon and select + or - to make the text size bigger or smaller. </p></> : null,
		browserName === "edge" ? <><h5>Edge</h5>
			<p>On the View menu, select Text Size, then Largest.</p>
			<p>Or you may click on the Edge Settings icon, select Zoom, then choose a percentage or click Zoom In. </p></> : null,
		browserName === "opera" ? <><h5>Opera</h5>
			<p>On the View menu, select Zoom. Then choose a percentage.</p></> : null,
		browserName === "safari" ? <><h5>Safari</h5>
			<p>On the View menu, select Make Text Bigger. </p></> : null,
		<>
			<p style={{fontStyle:"italic"}}>
				Endorsement Disclaimer<br />
				Land Registry does not endorse specific web browsers or recommend one browser over another. While some popular browsers are included here, mention of a specific browser does not imply any endorsement or recommendation.
			</p>
		</>
	],

	generalTermsContent: (inSection)=> [
		<>
			{ inSection ?
				// <p style={{fontWeight: "bold"}}>
				// 	The Land Registry Web Form Portal (“The Portal”)<br/>
				// 	General Terms of Use (“ General Terms”)
				// </p>
				null
				:
				<h1 className="formTitle">
					The Land Registry Online Form<br/>
					General Terms of Use (“General Terms”)
				</h1>
			}
			<ol>
				<li>The Online Forms provided by the Land Registry enable members of the public to make online applications for  or in relation to services and/or products of the Land Registry by electronic filling at the Land Registry’s website (“LR website”) and, where applicable, submission of respective Online Forms with e-payment of related service fees.</li>
				<li>
					In these General Terms,
					<ol>
						<li>“Online Form” means any application form that is electronically fillable at the LR website and provided for or in relation to provision of public services and/or products by the Land Registry;</li>
						<li>“service application” includes application for or in relation to services and/or products provided by the Land Registry, which may vary from time to time at the discretion of the Land Registry;</li>
						<li>“Important Notes”, in relation to a service application, means the specific Terms &amp; Conditions (“T&amp;C”) and the Personal Information Collection Statement (“PICS”) for that service application;</li>
						<li>“applicant” refer to any person who accesses or uses any Online Form (including but not limited to using any Online Form provided at the LR website for service application or any purposes that the form is designated for).</li>
					</ol>
				</li>
				<li>Applicants are deemed to have agreed to and accepted the General Terms unconditionally and irrevocably upon access to or use of any Online Form. The Land Registry may amend or revise the General Terms from time to time without prior notice.  Subsequent access to or use of any Online Form will constitute applicants’ acceptance of the General Terms as amended or revised.</li>
				<li>Applicants must read the “Important Notes” and the General Terms and confirm their agreement to be bound by the General Terms, and the specific T&amp;C and the PICS for each service application shown under the “Important Notes” by checking the boxes therein before they can proceed to fill in application particulars.</li>
				<div className="generalTermsSubtitle">Connection to the Online Form</div>
				<li>
					<ol>
						<li>If the applicants need to gain access to any Online Form through the Internet, they shall at their own costs make their own arrangements with the Internet, mobile or other telecommunication service providers for the connection and disconnection of such access to the Online Form.  The applicants shall also at their own costs make arrangements for the necessary computer hardware, software, communications link, equipment and/or devices (“hardware and/or software”) as may be required for the access to the Online Form and the Land Registry shall not be responsible for any obligations, expenses or liabilities incurred or suffered by the applicants in connection therewith.</li>
						<li>The Land Registry may at any time deny or restrict an applicant’s use of any Online Form if, in its opinion:
							<div className="d-flex">
								<div className="note-bullet">(i)</div>
								<div>the applicant’s hardware and/or software has caused or is likely to cause any failures, interruptions, errors in or damage to the Land Registry’s information system; or</div>
							</div>
							<div className="d-flex">
								<div className="note-bullet">(ii)</div>
								<div>the applicant breaches any of the General Terms.</div>
							</div>
						</li>
						<li>The applicants shall not use or attempt to use or permit the use of any automated software or malware to use any Online Form.</li>
					</ol>
				</li>
				<li>Except for maintenance work, the Online Form will normally be accessible on a twenty four hours seven days a week (24 hours for 7 days) basis.  Subject to any change without prior notice, the availability of the Online Form will be published by the Land Registry from time to time on the Land Registry’s website at <a target="_blank" href="https://www.landreg.gov.hk/en/home/index.htm">http://www.landreg.gov.hk</a>.</li>

				<div className="generalTermsSubtitle">Electronic Submission (e-submission)</div>
				<li>
					<ol>
						<li>The Online Forms can be used for e-submission of service applications.</li>
						<li>The Land Registry has absolute discretion to determine what service applications may be submitted online by using the Online Forms.  For service applications that cannot be submitted online, printing and downloading of the respective forms in PDF format are allowed so that applicants may download, print, sign and submit the completed forms by post or by electronic mail or in person in accordance with the applicable statutory requirements and the prevailing practice of the Land Registry.</li>
					</ol>
				</li>

				<div className="generalTermsSubtitle">Electronic Signature</div>
				<li>An Online Form may be signed by two types of digital signing, i.e. by personal or organizational digital certificate issued by recognised certification authority such as the Hongkong Post or Digi-Sign Certification Services Limited; and by iAM Smart+. For e-submissions of forms for which signatures are required, applicants shall either acquire a digital certificate, or register with iAM Smart+ and install the iAM Smart mobile application on their mobile phone to perform the digital signing.  For system requirements of using "iAM Smart" mobile application, applicants can refer to the link: <a target="_blank" href="https://www.iamsmart.gov.hk/en/">https://www.iamsmart.gov.hk/en/</a>.</li>

				<div className="generalTermsSubtitle">Payment</div>
				<li>The Land Registry accepts online payment by e-Cheque, credit card (Mastercard, VISA, UnionPay, JCB), FPS or PPS for e-submission of service applications by online forms in the desktop version. As for the mobile version, the Land Registry accepts payment by e-Cheque, credit card (Mastercard, VISA, UnionPay, JCB), FPS, Apple Pay or Google Pay (Mastercard and VISA only). For more information about e-Cheque, applicants should contact his/her bank or visit the Hong Kong Monetary Authority website (<a target="_blank" href="https://www.hkma.gov.hk/eng/key-functions/international-financial-centre/financial-market-infrastructure/other-retail-payment-infrastructure/">https://www.hkma.gov.hk/eng/key-functions/international-financial-centre/financial-market-infrastructure/other-retail-payment-infrastructure/</a>).</li>
				<div className="generalTermsSubtitle">Applicants’ Obligations</div>
				<li>It is the applicants’ responsibility to provide clear, full, accurate and true information in the Online Form and attach all the necessary supporting documents as specified by the Land Registry.  Any misrepresentation or omission of information required may lead to deferral or rejection of applications.</li>
				<li>Provision of information required by the Online Form during the processing of the service application is voluntary.  However, if the applicants do not provide the required information, the Land Registry may not be able to process the application.  In any event, the Land Registry reserves the right to reject any service application.  The Land Registry is not bound to accept any service application made by an Online Form.</li>
				<li>The applicants shall not use any Online Form or permit it to be used for any fraudulent or unlawful purpose or activity.</li>
				<li>The applicants agree to indemnify and keep indemnified the Land Registry from and against any claims, damages, losses and liabilities whatsoever arising from or as a result of:
					<ol>
						<li>the applicants’ use of any Online Form; or</li>
						<li>the applicants’ breach of any of the General Terms.</li>
					</ol>
				</li>
				<li>The applicant acknowledges that in the circumstances where the applicant cannot use the Online Form for whatever reasons, the applicant is not relieved of any of the applicant’s obligation under any enactment.</li>

				<div className="generalTermsSubtitle">Disclaimer &amp; Limitation of Liability</div>
				<li>The Land Registry does not guarantee the reliability of the online network through which information, messages or instructions are transmitted to and from the Land Registry.  Neither the Land Registry nor their officers or employees shall be responsible for any delays and failure in transmission, receipt or execution of information, messages or instructions due to a breakdown or failure of communication facilities or to any other cause whatsoever and they shall not be liable for any losses, fees, costs, expenses, damages and liabilities suffered or incurred as a result thereof.</li>
				<li>The Land Registry reserves the right to modify, vary or discontinue any facility, function, and provision of the Online Forms or any of them at its sole discretion and without prior notice.  The Land Registry shall not be liable to the applicants or any third party for any variation, suspension or discontinuance of use of any Online Form.</li>
				<li>The Online Form is provided without any warranty of any kind and shall not constitute or create any warranty or other obligation of any kind by the Land Registry. The Land Registry is not responsible for any loss arising out of or in relation to the use or provision of the Online Forms at the LR website.  The Land Registry reserves the right to omit, suspend or edit any Online Form by the Land Registry at any time in its sole discretion without giving any reason or prior notice.  The applicants are responsible for making their own assessment of all information contained in the Online Form.</li>
				<li>The Land Registry is not responsible for any damage or claim or loss whatsoever, including but not limited to damage to applicants’ hardware and/or software or loss of or damage to data stored therein, arising directly or indirectly from the applicants’ access to or use of any Online Form .</li>
				
				<div className="generalTermsSubtitle">Other Matters</div>
				<li>For general enquiries or comments on the Online Forms, please contact the Land Registry by e-mail (email address: <a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>).</li>
				<li>The General Terms shall be governed by the laws of the Hong Kong Special Administrative Region.</li>
				<li>If there is any inconsistency or ambiguity between the English version and the Chinese version of the General Terms, the English version shall prevail.</li>

			</ol>
		</>
	],

	wfpMaintenanceContent : () => [
		<p>The online forms of the Land Registry are under maintenance.</p>, 
		<p>Please download the forms at the website of the Land Registry (<a target="_blank" href={SysParam.LR_WEBSITE_FORM("Eng")}>{SysParam.LR_WEBSITE_FORM("Eng")}</a>).</p>,
		<p>For any enquiries, please call us at 3105-0000 or <a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>.</p>,
		<p>&nbsp;</p>,
		<p>土地註冊處的網上表格服務正在進行維修。</p>, 
		<p>請於土地註冊處網站 (<a target="_blank" href={SysParam.LR_WEBSITE_FORM("TradChn")}>{SysParam.LR_WEBSITE_FORM("TradChn")}</a>) 下載表格。</p>,
		<p>如有任何查詢，請致電 3105-0000 或電郵至<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>與我們聯絡。</p>,
		<p>&nbsp;</p>,
        <p>土地注册处的网上表格服务正在进行维修。</p>, 
        <p>请於土地注册处网站 (<a target="_blank" href={SysParam.LR_WEBSITE_FORM("SimpChn")}>{SysParam.LR_WEBSITE_FORM("SimpChn")}</a>) 下载表格。</p>,
        <p>如有任何查询，请致电 3105-0000 或电邮至<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>与我们联络。</p>,
        <p>&nbsp;</p>,
	],

	wfpMaintenanceContent_LSS : () => [
		<h4 style={{textDecoration:"underline"}}>Service Suspension</h4>,
		<p>We are experiencing problem with online forms and all services have temporarily been suspended.  We are looking into the problem and doing everying possible to quickly restore online forms back to normal service.  We apologize for the inconvenience caused.</p>,
		<p>For enquiries and/or assistance, please contact our Customer Service Manager through:</p>,
		<p>(1) sending e-mail to <a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>.<br />
		   (2) calling 3105 0000.</p>,
		<p>&nbsp;</p>,
		<h4 style={{textDecoration:"underline"}}>服務暫停</h4>,
		<p>本處「網上表格平台」發生問題，導致所有服務暫停。我們現正全力搶修系統，務求使該服務盡快回復正常。不便之處，懇請見諒。</p>,
		<p>如有任何查詢或需要協助，請以下列方式與本處客戶服務經理聯絡：</p>,
		<p>(一) 發送電郵至<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>或<br />
		   (二) 致電 3105 0000</p>,
		<p>&nbsp;</p>,
		<h4 style={{textDecoration:"underline"}}>服务暂停</h4>,
        <p>本处「网上表格平台」发生问题，导致所有服务暂停。我们现正全力抢修系统，务求使该服务尽快回复正常。不便之处，恳请见谅。</p>,
        <p>如有任何查询或需要协助，请以下列方式与本处客户服务经理联络：</p>,
        <p>(一) 发送电邮至<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>或<br />
           (二) 致电 3105 0000</p>,


	],
	
	GcisPayment: GCIS_PAYMENT,

	broadcastMessage: form => window.WFP_CONFIG.BROADCAST_MSG_HTML_EN ? window.WFP_CONFIG.BROADCAST_MSG_HTML_EN(form) : null,
	broadcastMessageContent: (index) => window.WFP_BROADCAST.broadcastList[index].EN ?? null,
	ios_online_disabled_title: 'Online application on iOS devices is not supported',
	ios_online_disabled_content: 'Online application for subscription to Property Alert on iOS devices is currently not supported. If you would like to submit the application online, please change to use a personal computer or an Android device for submission. For enquiries, please call the Land Registry Customer Service Hotline at 3105 0000.',
};
