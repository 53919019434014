import { Currency, PaymentLocale } from '../enums';
import { isEmpty } from '../given';

import { Order } from './order.entity';
import { OrderDetail } from './order-detail.entity';

export class PaymentData {
    constructor(input: Partial<PaymentData> = {}) {
        Object.assign(this, input);
    }

    order: Order;

    locale: PaymentLocale;

    isValid(): boolean {
        if (isEmpty(this.locale)) return false;
        if (isEmpty(this.order.totalAmount)) return false;
        return true;
    }

    static fromEssentials(input: Input): PaymentData {
        const { totalAmount, locale } = input;
        const orderDetail = new OrderDetail({
            amount: totalAmount,
        });
        const order = new Order({
            totalAmount,
            currency: Currency.HKD,
            orderDetails: [orderDetail],
        });
        return new PaymentData({ order, locale });
    }
}

type Input = {
    totalAmount: number;
    locale: PaymentLocale;
};
