import React, { useEffect, useContext } from 'react';
import {RootContext} from '../root';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector, useDispatch } from 'react-redux';
import { getForms } from './reducers/wfpSessionReducer';
import lo from 'lodash';

import onlineFormIcon from '../../resources/Online_Form.png';

export default () => {
	const session = useSelector(store => store.session, lo.isEqual);
	const {language} = session;
	const {recaptcha} = useContext(RootContext);
	const dispatch = useDispatch();

	useEffect(() => {
		window.grecaptchaOnVerify = token => {
			dispatch(getForms(token));
		}
		recaptcha().reset();
		recaptcha().execute();
	}, [dispatch]);

	return (
		<Row className="body wfpRootContainer">
			<div className="topNavBar">
				<h1 className="formTitle">
					{ language.title_formMenu }
				</h1>
			
			</div>


			<Row className="d-flex justify-content-center">
				<Col className="col-sm-11">
						<Row className="form-menu-header">
							<Col className="col-sm-1 col-head-index">#</Col>
							<Col className="col-sm-7">{language.head_formTitle}</Col>
							<Col className="col-sm-2">{language.formNo}</Col>
							<Col className="col-sm-2">{language.startFilling}</Col>
						</Row>
						{ session.formList ? 
							session.formList.map((form, index) => (
								<Row key={form.reference} className="form-menu-entry">
									<Col className="col-sm-1 col-index">{index+1}.</Col>
									<Col className="col-sm-7 col-title-eng">{session.curLanguage === "Eng" ? form.titleEng : session.curLanguage === "TradChn" ? form.titleTradChn : form.titleSimpChn}</Col>
									<Col className="col-sm-2 col-ref">{form.reference}</Col>
									{/* <Col className="col-sm-2 col-fill">{form.url ? <a href={"/form/" + form.url}><img style={{zoom:0.6}} src={onlineFormIcon} alt={language.fillIn + form.reference} /></a> : null }</Col> */}
									<Col className="col-sm-2 col-fill"> <a href={"/form/" + form.code}><img style={{zoom:0.6}} src={onlineFormIcon} alt={language.fillIn + form.reference} /></a> </Col>

								</Row>
								))
							: <p>Error retrieving forms list.</p>
						}
				</Col>
			</Row>
		</Row>
		
	);
	
};