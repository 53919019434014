import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/wfp-core-store.type';
import { PrimaryButton } from './primary-button';

type Props = { onSubmit: () => void };

export function SubmitButton(props: Props) {
    const { onSubmit } = props;
    const language = useSelector((store: RootState) => store.session.language);
    return (
        <PrimaryButton id={'submitBtn'} onClick={onSubmit}>
            <FaCheck /> {language.submit}
        </PrimaryButton>
    );
}
