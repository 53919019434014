export enum PaymentMethodSubtype {
    APPLE_PAY = 'applepay',
    ANDROID_PAY = 'androidpay',
    VISA = 'visa', // FI = BOCI
    MASTER_CARD = 'mastercard', // FI = BOCI
    CREDIT_CARD = 'creditcard', // FI = BCMP => Visa / Master / JCB / UnionPay
    PPS = 'pps',
    E_CHEQUE = 'echq',
    FPS = 'fps', // 202309, FI = BCFP
}
