import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';

import { FormContext } from '../layoutElements';
import { formatPeriod, Period } from '../time-helpers';
import { RootState } from '../reducers/wfp-core-store.type';
import { getLocalizedPaymentMethod } from '../../../gcis-payment/utils';
import { PaymentMethod } from '../../../gcis-payment/enums';

type Props = {
    show?: boolean;
    paymentMethodSetToDisable: Array<PaymentMethod>;
    disablePeriods: Period[];
    allPaymentsDisabled: boolean;
};

export function PaymentDisabledBroadcastMessage(props: Props) {
    const { show, paymentMethodSetToDisable, disablePeriods, allPaymentsDisabled } = props;
    const { curLanguage } = useSelector((store: RootState) => store.session);

    const { formLocalization = {} } = useContext(FormContext);
    const { GcisPayment = {} } = formLocalization;
    let {
        PAYMENT_DISABLED_BROADCAST_TITLE: title,
        PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER: header,
        PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER: footer,
        ALL_PAYMENT_DISABLED_BROADCAST_TITLE: allTitle,
        ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER: allHeader,
        ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER: allFooter,
    } = GcisPayment;

    if (!show || !disablePeriods || disablePeriods.length === 0) return null;
    if (
        !allPaymentsDisabled &&
        (!paymentMethodSetToDisable || paymentMethodSetToDisable.length === 0)
    )
        return null;
    if (allInPast(disablePeriods)) return null;

    const format = formLocalization.formatString;
    const paymentMethods = paymentMethodSetToDisable.map((pm) =>
        getLocalizedPaymentMethod(null, pm, formLocalization)
    );
    const joined = paymentMethods.join(', ');

    if (allPaymentsDisabled) {
        title = allTitle;
        footer = allFooter;
        header = allHeader;
    }

    return (
        <Alert variant={'warning'}>
            <h4>{format(title, joined)}</h4>
            <p>{format(header, joined)}</p>
            <ul>
                {disablePeriods.map((s: Period, i: number) => (
                    <li key={i}>{formatPeriod(s, curLanguage)}</li>
                ))}
            </ul>
            <p>{format(footer, joined)}</p>
        </Alert>
    );
}

function allInPast(periods: Period[]) {
    for (const p of periods) {
        if (p.to > new Date()) {
            return false;
        }
    }
    return true;
}
