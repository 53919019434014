export default () => { }

export const ENG = "Eng";
export const TRADCHN = "TradChn";
export const SIMPCHN = "SimpChn";


export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_MONTH_FORMAT = "MM/YYYY";
export const TIME_FORMAT = "h:mm a";

export const DIALOG_HIDE = -1;
export const DIALOG_ERR = 0;
export const DIALOG_MSG = 1;
export const DIALOG_CONFIRM = 2;
export const DIALOG_SAVE = 3;
export const DIALOG_LOAD = 4;
export const DIALOG_CUSTOM = 5;
export const DIALOG_SIGNECERT = 6;
export const DIALOG_SIGNEID = 7;
export const DIALOG_FONTSIZE = 8;
export const DIALOG_SIGNEID_CONFIRM = 9;
export const DIALOG_COMPLETE = 10;
export const DIALOG_PRINT = 11;

export const ALLOW_ESUBM = 0;
export const OFFLINE_SUBM = 1;
export const NO_ESUBM = 2;
export const SIGN_ONLY = 3;

export const UPDATE_VALIDATE_DELAY = 10; //ms

export const UPLOAD_FILE_LIMIT = 31457280;

export const HASH_ALGORITHM = "SHA-256";

export const RECAPTCHA_SITE_KEY = "6LeYo-wZAAAAAOj6w4lYEjih6jvZdLoK1lOU7YNw";
export const MONTH_NAMES = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const DAY_NAMES = [
    "", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th",
    "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th",
    "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th",
    "31st"
];

export const NO_CAPTCHA = (/*!window.location.href.includes(".landreg.gov.hk") && */window.location.search.includes("nocaptcha=true"));
export const QUERY_FOR_NO_CAPTCHA = (/*(!window.location.href.includes(".landreg.gov.hk") &&  */window.location.search.includes("nocaptcha=true")) ? "?nocaptcha=true" : "";

export const RECLIST_ID_SEP = "_____";
export const MULTI_FORM_ID_SEP = "-----";

export const SKIP_INTRO_STEP = "skipIntro";

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};