import { Expose } from 'class-transformer';

export class PayByFpsResponse {
    @Expose({ name: 'paymentid' }) // payment ID used to cancel the FPS payment
    paymentid: string;

    @Expose({ name: 'paymentstatus' }) // payment status of the transcation, please refer to GCIS e-Payment Service Interface Developer Guide for the possible value
    paymentstatus: string;
    
    @Expose({ name: 'fpsmerchanttimeoutdatetime' }) // timestamp of the FPS QR code timeout time
    fpsmerchanttimeoutdatetime: string;

    @Expose({ name: 'fpsqrcodeimgbase64' }) // FPS QR code image in base64string format
    fpsqrcodeimgbase64: string;

    @Expose({ name: 'fpsqrcodeurl' }) //  FPS QR code URL to get the payload
    fpsqrcodeurl: string;
}
