import React, { createContext, useState, useContext, useEffect, lazy, Suspense  } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import lo, { update } from 'lodash'; 
import {Collapse} from 'react-collapse';


import { FaRegFolderOpen, FaRegFile, FaInfoCircle, FaAngleDoubleDown,
		 FaAngleDoubleUp, FaPrint, FaFilePdf, FaCertificate,
		 FaMoneyCheck, FaQuestionCircle, FaSave, FaSpinner } from 'react-icons/fa';
import eChequeIcon from '../../resources/echeque_upload.png';

import { clearFormData, initialState, printOnPaper, genPdfForReview, clearPDF, updateFormData } from '../core/reducers/formDetail';
import { dialogHelper } from './dialogHelper';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import * as Constant from '../../utilities/constant';

import {  PersonalInfo, Field, Validators } from './inputElements';
import { RootContext } from '../root';
import Row from 'react-bootstrap/Row';
import iasIconDark from '../../resources/ias-icon-dark.png';

import logoEn from '../../resources/lr_branding_en.png';
import logoTc from '../../resources/lr_branding_tc.png';
import logoSc from '../../resources/lr_branding_sc.png';

import { extractPaymentMethods, isOnlinePayMethod, getOnlinePaymentMethods } from './utilities/form-context';
import { useGetFinalSummary, useIsPaymentHandledByGcis } from '../../gcis-payment/hooks';
import { clearPaymentSlice } from '../../gcis-payment/redux/slice';
import { PaymentDisabledBroadcastMessage } from './broadcast-messages';

import { getPaymentMethodsSetToDisable, getLocalizedPaymentMethod } from '../../gcis-payment/utils';
import { isLREAL1 } from '../LREAL1/util';
import { isiOS } from '../../utilities';
import { IosOnlineSubmissionDisabledMessage } from './broadcast-messages/ios-online-submission-disabled-message';

const PDFContent = lazy(() =>  import( './pdfContent' ));
export const FormContext = createContext({
	grecaptcha: null,
	setGrecaptchaOnVerify: (token) => {},
	steps: [],
	stepIdx: 0,
	paymentRequired: false,
	paymentMethodId: undefined,
	paymentAmountId: undefined,
	signRequired: false,
	skipAckEmail: false,
	showPayee: ()=>{},
	signDeclaration: () => "",
	signFields: [],
	currentStepTitle: "", 
	formCode:"",
	formValErrs:null, 
	formLocalization: null,
	resumeFromStepIdx: 0,
	resumeRetainFormDataKeys: [],
	submissionType: Constant.ALLOW_ESUBM,
	activeFieldIds: new Set(),
	submitTransformFunc: (formData, dispatch) => {},
	submitDateId: (formData) => {},
	form:{},
	startNewForm: () => {},
	showDialog: () => {},
	handleLoad: () => {},
	previousStep: () => {},
	nextStep: () => {},
	updateStepIdx: () => {},
	updateCurrentStepTitle: () => {}, 
	updateFormValErrs: () => {}, 
	updateAndValidate: () => {},
	registerValidationRule: () => {},
	deregisterValidationRule: () => {},
	dialog: null,
});
export const StepContext = createContext({stepId: null, title: null});
export const SectionContext = createContext({sectionId: null, titleKey: null, sectionValErrs: null });
export const RecordContext = createContext({recordListId: null, index: null});

export const RequirementsSection = (props) => {
	const { form, stepIdx, nextStep, showDialog, formLocalization, readOnly } = useContext(FormContext)
	const {formData} = useSelector(store => store.formDetail, lo.isEqual);

	return (
		<NotesSection id="requirementsSection" titleText={formLocalization.requirements} numbered={false}>
			<Row>
				<Col lg="1">
					<div style={{zoom:"2"}}><FaPrint /></div>
				</Col>
				<Col>
					<p><span style={{fontWeight:"bold"}}>{formLocalization.requirements_printer}</span><br />
					{formLocalization.requirements_printer_desp}</p>
				</Col>
			</Row>
			<Row>
				<Col lg="1">
					<div style={{zoom:"2"}}><FaFilePdf /></div>
				</Col>
				<Col>
					<p><span style={{fontWeight:"bold"}}>{formLocalization.requirements_reader}</span><br />
					{formLocalization.requirements_reader_desp}</p>
				</Col>
			</Row>
			{ form.signRequired ?
				<>
					<Row>
						<Col lg="1">
							<div style={{zoom:"2"}}><FaCertificate /></div>
						</Col>
						<Col>
							<p><span style={{fontWeight:"bold"}}>{formLocalization.requirements_esign}</span><br />
							{formLocalization.requirements_esign_desp()}
							</p>
						</Col>
					</Row>
					{/* {form.disableIAMSmart && form.disableIAMSmart(formData) ? 
						null
						:
						<Row>
							<Col lg="1">
								<div style={{zoom:"2"}}><img style={{filter:"invert(1)", zoom:"0.6"}} src={iasIconDark} alt="iAM Smart logo" /></div>
							</Col>
							<Col>
								<p><span style={{fontWeight:"bold"}}>{formLocalization.requirements_iamsmart()}</span><br />
								{formLocalization.requirements_iamsmart_desp}</p>
							</Col>
						</Row>
					} */}
				</>
				:null
			}
			{ form.paymentRequired ? 	
				<Row>
					<Col lg="1">
						<div style={{zoom:"2"}}><FaMoneyCheck /></div>
					</Col>
					<Col>
						<p><span style={{fontWeight:"bold"}}>{formLocalization.requirements_echeque}</span><br />
						{formLocalization.requirements_echeque_desp}</p>
					</Col>
				</Row>
				:null
			}
		</NotesSection> );
}

export const IntroStep = (props) => ({
	title: "importantNotes",
	content: IntroPage,
	validations: {},
	hideNavigation: true,
});

export const IntroPage = (props) => {
	// const { language } = useSelector(store => store.session);
	const { stepIdx, nextStep, form, formLocalization, handleLoad, readOnly } = useContext(FormContext);

	let _broadcastComponents = [];
	for (let i = 0; i < window.WFP_BROADCAST.broadcastList.length; i++) {
		let _broadcastObj = GetBroadcasts(i);
		if(_broadcastObj) _broadcastComponents.push(_broadcastObj);
	}

    // HOTFIX: Stop LREAL1 online submission through iPhone since failure rate too high
	const iosMessage = ()=>{
		// if (isLREAL1(form) && isiOS()){
		// 	return IosOnlineSubmissionDisabledMessage();
		// }
		return null;
	}
	
	return readOnly ? null : (
		<> 
			{ _broadcastComponents}

			<PaymentDisabledBroadcastMessage
				show={window.WFP_CONFIG.BROADCAST_GCIS_PAYMENT_DISABLED}
				allPaymentsDisabled={window.WFP_CONFIG.DISABLE_ALL_GCIS_PAYMENT}
				paymentMethodSetToDisable={getPaymentMethodsSetToDisable()}
				disablePeriods={window.WFP_CONFIG.DISABLE_GCIS_PAYMENT_PERIOD}
			/>

			{iosMessage()}

			<RequirementsSection />
			
			{form.additionalNotes ? 
					
				<Section titleText={form.additionalNotesTitle ? form.localization[form.additionalNotesTitle] : form.localization.notesSection}>
					<div className="row">{form.additionalNotes(form.localization)}</div>
				</Section>

			:null}
			
			<WantToSection 
				startFilling={() => { 
					nextStep(stepIdx+1) 
				}}
				loadSaved={() => {
					handleLoad();
				}} />
		</>
	)
};

export const GetBroadcasts = (index) => {
	const {  formLocalization, form } = useContext(FormContext)
	let _broadcast = window.WFP_BROADCAST.broadcastList[index];

	let _matchDisplayCondtion = true;
	// Condition fields
	if(_broadcast.conditions) {
		for (let i = 0; i < _broadcast.conditions.length; i++) {
			if(!form[_broadcast.conditions[i]]) {
				_matchDisplayCondtion = false;
				break;
			}
		}
	}

	// Display date
	const date = new Date();
	const start = new Date(_broadcast.fromDate);
	const end 	= new Date(_broadcast.toDate);
	let _matchDisplayDate = (date >= start && date <= end);

	// Show message
	let _showMessage = _matchDisplayCondtion && _matchDisplayDate;
	let _messageContent = formLocalization.broadcastMessageContent(index);

	return ( _showMessage ?
		<><Alert id="broadcastMessageAlert" key="broadcastMessage" variant={_broadcast.type}>
			<div dangerouslySetInnerHTML={{ __html: _messageContent }} />
		</Alert></> 
		: null);
}

export const GetCaptchForm = () => {
	const session = useSelector(store => store.session);
	return (
		<div className="form-inline" >
			{/* CAPTCHA */ }
			<Field.Captcha
				captchaToken={session.captchaToken}
				captchaGetChallengeInProgress={session.captchaGetChallengeInProgress}
				captchaChallenge={session.captchaChallenge}
				/>
		</div>
	);
}

const performReview = (dispatch, form, language) => {
	const { closeDialog, showErrorDialog, showCaptchaDialog: showPrintDialog } = dialogHelper;
	
	const handleCancelReview = () => {
		dispatch(clearPDF());
	}

	try {
		dispatch(genPdfForReview(
			form, 
			() => { dispatch(closeDialog()); }, 
			err => { dispatch(showErrorDialog(err, language)) }, 
			(o) => {
				dispatch(showErrorDialog({
					err: o.err,
					handleClose: () => dispatch(showPrintDialog({children: <GetCaptchForm />, language: language, performPrint: ()=>{performReview(dispatch, form, language)}, handleCancel: handleCancelReview})),
					onHide: () => dispatch(showPrintDialog({children: <GetCaptchForm />, language: language, performPrint: ()=>{performReview(dispatch, form, language)}, handleCancel: handleCancelReview}))
			}, language))
		}));
	} catch (err) {
		console.log(err);
		dispatch(showErrorDialog({err: err}, language));
	}
}

const PaymentSection = (props) => {
	const { dropdownId, payMethods, amountId, language, inline, isOnlinePayMethod, paymentMethodSublabel, paymentMethodGroupNotes, isPassFormData, showPayee, payeeId } = props;
	const { form } = useContext(FormContext);
	const dispatch = useDispatch();

	const {formData} = useSelector(store => store.formDetail, lo.isEqual);
	const {captchaToken} = useSelector(store => store.session);
	const isAltNote = typeof formData.LicenceComputerTerminals !== 'undefined';
	const isLic = formData.LicenceComputerTerminals !== "0";

	const isOfflineSubmit = form.submissionType && form.submissionType(formData) === Constant.OFFLINE_SUBM;

	const delayPerformReview = () => {
		if (form.ignorePDFUpdate && form.ignorePDFUpdate(formData)) return;
		
		if (!captchaToken
			&& !Constant.NO_CAPTCHA) {
			dispatch(dialogHelper.showCaptchaDialog({
				children: <GetCaptchForm />, 
				language: language,
				performPrint: ()=>{performReview(dispatch, form, language)},
			}));
		} else {
			performReview(dispatch, form, language);
		}
		
	}

	useEffect(()=>{
		// Remove stale payment methods (which is disabled) that may be loaded from saved form data
		if (!formData[dropdownId]) return;
		if (payMethods.filter(pm => pm.value === formData[dropdownId]).length === 0){
			dispatch(updateFormData(null, null, {[dropdownId]: null}));
		}
	}, [formData[dropdownId], payMethods]);

	// return ({ readOnly, inline }) => {
		// const { language } = useSelector(store => store.session);

	return  <Section id="paymentMethod" titleText={language["steps_PaymentMethod"]} inline={inline}>
			{/* {<ExpandableMessagePane>
				<p style={{fontWeight:"bold"}}>{language.}</p>
				<p>{language.echequeMessage2}</p>
				<p>{language.echequeMessage3}</p>
			</ExpandableMessagePane> } */}
			<FormContext.Consumer>{formContext => (
				<>
					{!isOnlinePayMethod && !isOfflineSubmit ?
						<MessagePane>
							{formContext.formCode === "LR173" ?
								language.formatString(language.printForSubmissionAlt, String.fromCharCode(10004))
								:
								language.formatString(language.printForSubmission, String.fromCharCode(10004))
							}
						</MessagePane>
						:
						null}
					<Field.Dropdown id={dropdownId} label={language[dropdownId]} options={payMethods} required respectValue
						// onValChange={v => {
						// 	if (!isNaN(parseInt(v))) {
						// 		window.requestAnimationFrame(() => updateFormData(null, null, { [dropdownId]: (v-onlinePayMethodsLength) +"" }))
						// 	}
						// }} 
						onValChange={delayPerformReview} onExtraValBlur= {delayPerformReview}
						sublabel={paymentMethodSublabel && formContext.formLocalization[paymentMethodSublabel]
									? 
										(typeof(formContext.formLocalization[paymentMethodSublabel]) === 'function' 
											? formContext.formLocalization[paymentMethodSublabel](isPassFormData ? (formData) : null) 
											: formContext.formLocalization[paymentMethodSublabel]
										)
									: null}
						groupNotes={paymentMethodGroupNotes && formContext.formLocalization[paymentMethodGroupNotes]
									? 
										(typeof(formContext.formLocalization[paymentMethodGroupNotes]) === 'function'
											? 	formContext.formLocalization[paymentMethodGroupNotes](
													isAltNote 
													? (isLic ? formContext.formLocalization["lic"] : formContext.formLocalization["crt"]) 
													: (isPassFormData ? (formData) : null)
												) 
											: formContext.formLocalization[paymentMethodGroupNotes] 
										)
									: null}
					/>
						<Field.Dropdown id={payeeId} label={language[payeeId]} options={language.payableList} required 
										onValBlur={delayPerformReview} hideWhen={!(showPayee && showPayee(formData))}/>
						
					<FormContext.Provider value={{...formContext, readOnly:true}}>
						<div className="form-inline">
							<Field.Text id={amountId} label={language[amountId]} prepend={language.field_amount_prepend} numericComma />
						</div>
					</FormContext.Provider>
				</>
			)}
			</FormContext.Consumer>
			
		</Section>
// };
};

// export const PaymentStep = (dropdownId, extraFieldIds, amountId, language) => ({
//     stepId: PAY_STEPID,
//     title: PAY_TITLE,
// 	content: PaymentPage(dropdownId, extraFieldIds, amountId, language),
//     validations: {},
// });


const CONFIRM_STEPID = "confirmation";
const CONFIRM_TITLE = "steps_Confirmation";

const Confirmation = (props) => {
	const { language, contents, form} = props;
	const {formData, pdfData, pdfNeedUpdate} = useSelector(store => store.formDetail, lo.isEqual);
	const {captchaToken} = useSelector(store => store.session, lo.isEqual);
	const dispatch = useDispatch();

	const getPaymentSection = (formContext) => {
		// <UploadEChequePage key="pay" />
		if (formContext.paymentRequired && (!formContext.form.ignorePayment || !formContext.form.ignorePayment(formData))) {
			const payMethods = extractPaymentMethods({formContext, formData, language});

			const payProps = formContext.payProps ?? { 
				dropdownId:formContext.paymentMethodId ?? "CDPaymentMethod",
				payMethods:payMethods, 
				amountId:formContext.paymentAmountId ?? "CDAmount",
				language:formContext.formLocalization,
				readOnly:false,
				inline:false,
			};
			return <PaymentSection {...payProps}
						paymentMethodSublabel={formContext.form.paymentMethodSublabel}
						paymentMethodGroupNotes={formContext.form.paymentMethodGroupNotes}
						isPassFormData={formContext.form.isPassFormData}
						showPayee={formContext.form.showPayee}
						payeeId={formContext.form.payeeId}
						isOnlinePayMethod={isOnlinePayMethod({formContext, formData, language})}
						onlinePayMethodsLength={getOnlinePaymentMethods({formContext, formData, language}).length} />;
		}
		else {
			return null;
		}
	}

	const getWarningMessage = (idx, alt) => {
		switch(idx) {
		case Constant.ALLOW_ESUBM:
			if (alt) {
				return language.confirmationMessageAlt;
			}
			return language.confirmationMessage;
		case Constant.OFFLINE_SUBM:
			return language.confirmationMessage_offlineSubmit();
		case Constant.NO_ESUBM:
			return language.confirmationMessage_noESubmit();
		case Constant.SIGN_ONLY:
			return language.confirmationMessage_signOnly();
		default:
			return language.confirmationMessage;
		}
	}

	return (
		<div id="confirmation-step-body">
			<RootContext.Consumer>{({recaptcha}) => (
				<FormContext.Consumer>{formContext=>(
					<StepContext.Provider value={{stepId: CONFIRM_STEPID, title: CONFIRM_TITLE, validations: {}}}>
						<FormContext.Provider value={{...formContext, readOnly: false }}> 	
							{getPaymentSection(formContext, formData.submitOnline)}	 		
						</FormContext.Provider>
					<FormContext.Provider value={{...formContext, readOnly: true }}>
							<Section id={CONFIRM_STEPID} titleText={language[CONFIRM_TITLE]}>
								<MessagePane>{formContext.submissionType 
									? getWarningMessage(formContext.submissionType(formData), formContext.formCode === "LR173")
									: language.confirmationMessage}</MessagePane>
								{ (formContext.form.isTextOnly?? true ) ?
									contents 
									:
									<><Suspense fallback={<FaSpinner />}>
											<PDFContent
												isSafari={false}
												pdfData={pdfData}
												matchedSeq={-1}
												language={language}
												performReview={()=>{
													if (!captchaToken 
														&& !Constant.NO_CAPTCHA) {
														dispatch(dialogHelper.showCaptchaDialog({
															children: <GetCaptchForm />, 
															language: language,
															performPrint: ()=>{performReview(dispatch, formContext.form, language)},
														}));
													} else {
														performReview(dispatch, formContext.form, language);
													}
												}}
											/>
									</Suspense> </>
								}
							</Section>
						</FormContext.Provider>
					</StepContext.Provider>
				)}</FormContext.Consumer>
			)}</RootContext.Consumer> 
		</div>
	)
}

const ConfirmationPage = (props) => {

	const {steps} = props;

	let contents = [];
	for (let i = 0; i < steps.length; i++) {
		if (steps[i].skipConfirm) continue;
		contents.push(React.createElement(steps[i].content, { readOnly:true, inline:false }))
	}

	return (props) => (<Confirmation {...props} contents={contents} />);
}

export const ConfirmationStep = (steps, signRequired, paymentRequired, formLocalization, formCode, signFields, form) => ({
	stepId: CONFIRM_STEPID,
	title: CONFIRM_TITLE,
	content: ConfirmationPage({
		steps:steps,
		signRequired:signRequired,
		paymentRequired:paymentRequired,
		language:formLocalization,
		formCode:formCode,
		signFields:signFields,
		form:form,
	}),
	// hideNext: true,
	validations: {},
});

const PAY_STEPID = "payment";
const PAY_TITLE = "steps_Payment";

const PaymentPage = (props) => {
	// Online Payment
	const language = useSelector(store => store.session.language, lo.isEqual);
	const isHandledByGcis = useIsPaymentHandledByGcis();

    return ( 
        <StepContext.Provider value={{stepId: PAY_STEPID, title: PAY_TITLE, validations: {}}}>
            <Section id={PAY_STEPID} titleText={language[PAY_TITLE]}>
                {
					isHandledByGcis ?
					<div>{language?.GcisPayment?.PRE_PAYMENT_MESSAGE}</div> :
					(
						<>
							<Field.FileUpload id="uploadECheque" label={language.uploadECheque} filter=".pdf" required/>
							<img src={eChequeIcon} width="50%" height="50%" alt="" />
						</>
					)
				}
                
            </Section> 
        </StepContext.Provider>
    ) 
};

export const PaymentStep = {
    stepId: PAY_STEPID,
    title: PAY_TITLE,
    content: PaymentPage,
	validations: {},
	hideBack:true,
};

const SUBMISSION_STEPID = "submission";
const SUBMISSION_TITLE = "steps_Submission";
const SIGNING_TITLE = "steps_Signing";

const SubmissionPage = (props) => {
	const {language} = useSelector(store => store.session, lo.isEqual);
	const {formData, signedFields} = useSelector(store => store.formDetail, lo.isEqual);
	const {showErrorDialog, setShowDialog} = dialogHelper;
	const {captchaToken,captchaGetChallengeInProgress,captchaChallenge} = useSelector(store => store.session, lo.isEqual);
	const formContext = useContext(FormContext);
	const dispatch = useDispatch();

	const isSignOnly = formContext.form.submissionType && formContext.form.submissionType(formData) === Constant.SIGN_ONLY;
	const notYetSigned = !Object.keys(signedFields).length > 0;
	const isHandledByGcis = useIsPaymentHandledByGcis();

	const getAckSecion = (formContext) => {
		return (formContext.skipAckEmail || (formContext.submissionType && formContext.submissionType(formData) !== Constant.ALLOW_ESUBM)) ? null : <Section id={"submissionAck"} titleKey="submissionAcknowledgement" key="ack">
			<Field.Checkbox id="receiveAckEmail" label={language.receiveAckEmail} />
				<p className={!formData.receiveAckEmail ? "display-hidden":""}>{language.emailForAcknowledgement}</p>
				
				<PersonalInfo.Email id="ackEmail" label={language.email} hideWhen={!formData.receiveAckEmail} required />
				<Field.Checkbox id="ackEmailIncludeFilledForm" label={language.ackEmailIncludeFilledForm} hideWhen={!formData.receiveAckEmail} />
				
				<p className={!formData.receiveAckEmail || !formData.ackEmailIncludeFilledForm ? "display-hidden":""} >{language.passwordForAcknowledgement} </p>
				<Field.Password id="password" label={language.password} minlength={8} maxlength={25} hideWhen={!formData.receiveAckEmail || !formData.ackEmailIncludeFilledForm} required />
				<Field.Password id="confirmPassword" label={language.confirmPassword}
								minlength={8} maxlength={25} required
								validation={
									[ v => { // formData from useSelector hook does not work in this closure! need to use querySelector
										const filledFormPassword=document.querySelector("input#password").value;
										if (filledFormPassword !== "" && v !== filledFormPassword) {
											return {key:'validation_confirmPasswordDiff', args:[]} 
										} else {
											return Validators.noOp();
										}
									}]}
								hideWhen={!formData.receiveAckEmail || !formData.ackEmailIncludeFilledForm}
								/>
				
		</Section> 
	}
	const getSignatureSection = (formContext, formData) => {
		const form = formContext.form;
		const titelKey = form.submissionType && form.submissionType(formData) === Constant.SIGN_ONLY ? "signOnly":"signAndSubmit";
		const inPersonOnly = formContext.formCode === "LREEA1";
		const signing2Alt = formContext.formCode === "LR173";
		return formContext.signRequired
		?
			<Section id={"sign"} titleKey={titelKey} key="sign" 
				titleSubLabel={formContext.form.signNotesTitle ? formContext.form.signNotesTitle(formData) : null}
				groupNotes={formContext.form.signNotes ? formContext.form.signNotes(formData) : null}>
				<MessagePane>
					{ isSignOnly ? 
						null
						:
						<p>{form.localization.confirmationMessage_signing}</p>
					}
					{ inPersonOnly ? 
						<p>{form.localization.confirmationMessage_signing3_inPersonOnly}</p>
						:
						<p>{signing2Alt?form.localization.confirmationMessage_signing2Alt:typeof(form.localization.confirmationMessage_signing2) == "function" ? form.localization.confirmationMessage_signing2(formData) : form.localization.confirmationMessage_signing2}</p>
					}
				</MessagePane>
				{ !formContext.signRequired ? 
					null:
					<div>
						{formContext.form.signDeclaration ? formContext.form.signDeclaration(formData) : null}
						{formContext.signFields && formContext.signFields(formData) ? formContext.signFields(formData).map(o => 
							{
								let signatureRequired = true;
								if (formContext.form.signOptional
									&& formContext.form.signOptional(formData).includes(o)) {
									signatureRequired = false;
								}

								let disabled = false;
								if (formContext.form.disableSignature
									&& formContext.form.disableSignature(formData, o)) {
										disabled = true
								}

								return <Field.Signature id={o} transparentLabel={formContext.form.signLabelTransparent} 
												label={formContext.formLocalization[o]} formCode={formContext.formCode} required={signatureRequired}
												validateName={formContext.form.verifySignNameId ? (typeof formContext.form.verifySignNameId === 'function' ? formContext.form.verifySignNameId(formData): formContext.form.verifySignNameId ) : null}
												validateIdNum={formContext.form.verifySignIdNumId ? (typeof formContext.form.verifySignIdNumId === 'function' ? formContext.form.verifySignIdNumId(formData): formContext.form.verifySignNameId ) : null}
												disabled={disabled}
												onComplete={()=>{dispatch(setShowDialog(false))}}
												onError={r => { 
													console.log(r);
													dispatch(showErrorDialog({err:r ? (r.err ?? r) : r}, language));
													// formContext.showDialog(Constant.DIALOG_ERR, {err:r.err ?? r});
												}} />
							}
						) : null}
					</div> 
				}
			</Section>
		:
			null
			
	}

    return ( 
        <>
		{/* <FormContext.Consumer>{formContext=>( */}
			<StepContext.Provider value={{stepId: SUBMISSION_STEPID, title: SUBMISSION_TITLE, validations: {}}}>
				{ isSignOnly && notYetSigned
					?
					null
					:
					<MessagePane mode="warning" defaultExpand={true}>
						{formContext.formLocalization.submissionMessage1 ? 
						<p style={{fontWeight:"bold", fontSize:'x-large'}}>{formContext.formLocalization.submissionMessage1}</p>
						: null}
						<p style={{fontWeight:"bold"}}>{isSignOnly ? 
																	formContext.formLocalization.submissionMessage2A 
																	: 
																	(isHandledByGcis ? 
																					formContext.formLocalization.submissionMessage2B
																					:
																					formContext.formLocalization.submissionMessage2)}</p>
					</MessagePane>
				}
				{ getAckSecion(formContext) }
				{ getSignatureSection(formContext, formData) }
				{ formContext.paymentRequired && (!formContext.form.ignorePayment || !formContext.form.ignorePayment(formData)) && (!formContext.submissionType || formContext.submissionType(formData) === Constant.ALLOW_ESUBM) ?
					<PaymentPage />
					:
					null
				}

				{
					!Constant.NO_CAPTCHA && !captchaToken && (!formContext.form.submissionType || !formContext.form.submissionType(formData) === Constant.ALLOW_ESUBM)
					?
					<Section titleKey="captcha">
						<Field.Captcha 
							captchaToken={captchaToken}
							captchaGetChallengeInProgress={captchaGetChallengeInProgress}
							captchaChallenge={captchaChallenge}
						/> 
					</Section>
					: null
				}			
			</StepContext.Provider>
		{/* )}</FormContext.Consumer> */}
			
		</>
    ) 
};

export const SubmissionStep = (form, formData)=> ({
    stepId: SUBMISSION_STEPID,
    title: (form.submissionType && form.submissionType(formData) === Constant.SIGN_ONLY) ? SIGNING_TITLE:SUBMISSION_TITLE,
    content: SubmissionPage,
	validations: {},
});

const ACHK_STEPID = "acknowledgement";
const ACHK_TITLE = "steps_Acknowledgement";

const AcknowledgementPage = ({numbered}) => {
	const { formData } = useSelector(store => store.formDetail, lo.isEqual);
	const { startNewForm, handleLoad, formCode, form } = useContext(FormContext);

	const { transactionId,
			referenceNumber,
			gcisWallet,
			paymentMethod,
			submissionTime,
			paymentAmount,
		} = useGetFinalSummary();

	return (
		<StepContext.Provider value={{stepId: ACHK_STEPID, title: ACHK_TITLE, validations: {}}}>
			<AcknowledgementSection titleKey="acknowledgement" formCode={formCode} 
				refNo={form.customRefNumber ? form.customRefNumber(formData) : referenceNumber} submissionTime={submissionTime}
				paymentTxnNum={transactionId} gcisWallet={gcisWallet} paymentMethod={paymentMethod} paymentAmount={paymentAmount} 
				/>
			<WantToSection noContinue={true} startFilling={startNewForm} loadSaved={handleLoad} formCode={formCode} />
		</StepContext.Provider>
	)
}

export const AcknowledgementStep = {
	stepId: ACHK_STEPID,
	title: ACHK_TITLE,
	content: AcknowledgementPage,
	validations: {},
	hideBack:true,
	hideNext:true,
	hideNavigation:true,
};


export const MessagePane = ({id, mode, children, hideWhen}) => {
	const variant= mode ?? 'info';

	return (
		
		(hideWhen === undefined || hideWhen === false)
		?
			<Alert key={id+"_alert"} variant={variant} className={"row " + id} style={{display:"flex", alignItems:'start'}}>
				<Col className="col-12 col-lg-1" style={{fontSize:'x-large'}}>
					<FaInfoCircle />
				</Col>
				<Col className="col-12 col-lg-11">
					{children}
				</Col>
			</Alert>
		:null
		
	);
};

export const ExpandableMessagePane = ({id, mode, title, children, defaultExpand, shouldShow}) => {
	const variant= mode ?? 'info';
	const { language } = useSelector(store => store.session);

	const {readOnly} = useContext(FormContext);
	const [show, setShow] = useState(defaultExpand ?? false);

	useEffect(() => {
		if (mode === "plain") {
			setShow(shouldShow);
		}
	}, [shouldShow, mode]);
	

	const content = <Collapse
						isOpened={show}>
						<Col className="col-12 col-lg-11" style={{paddingTop:'10px', paddingBottom:'1px'}}>
							{children}
						</Col>
					</Collapse>

	return !readOnly ?
				mode === "plain" ?
					content
					:
					<Alert key={id+"_alert"} variant={variant} className="row" style={{alignItems:'start'}} onClick={()=>setShow(!show)}>
						<Col className="col-12 col-lg-1 d-flex align-items-center" style={{fontSize:'x-large'}} >
							<FaInfoCircle style={{marginRight:"10px"}} />
							<div style={{flex:1}}>{title??language.footNote}</div>
							{ show ?
								<div style={{fontSize:'medium', marginRight:'-25px'}}>
									{language.clickToCollapse} <FaAngleDoubleUp />
								</div>
								:
								<div style={{fontSize:'medium', marginRight:'-25px'}}>
									{language.clickToExpand} <FaAngleDoubleDown />
								</div>
							}
						</Col>
						{content}
					</Alert>
				:
				null
	;
	
}

export const Section = (props) => {
	const {id, titleText, titleKey, titleSubLabel, classes, inline, children, groupNotes, groupNotesTitle, defaultShowNotes, hideWhen} = props;
	const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false); 
	const { language } = useSelector(store => store.session);
	const { stepId } = useContext(StepContext);
	const { validationErr } = useSelector(store => store.formDetail, lo.isEqual);
	const { formValErrs, readOnly } = useContext(FormContext);
	// const [ sectionValErrs, setSectionValErrs ] = useState(formValErrs[titleKey]);
	const [valErrs, setValErrs] = useState([]);
	const [hasErrs, setHasErrs] = useState(false);

	const refreshValErrs = (validationErr) => Object.keys(validationErr).filter(d => {
		let elemArr=[];
		document.querySelectorAll('#stepBody #'+id+' input, #stepBody #'+id+' select, #stepBody #'+id+' textarea, #stepBody #'+id+' div.form-group.error').forEach((o,i) => {
			elemArr.push(o.id)
		});

		return elemArr.includes(d) || elemArr.includes(d + "_panel");
	}).map(k => validationErr[k]); 

	// const valErrs = Object.keys(validationErr).filter(d => d.startsWith(stepId + '.' + id)).map(k => validationErr[k]); 
	// useEffect(() => {
	// 	setValErrs(refreshValErrs(validationErr));
	// 	setHasErrs(Object.values(valErrs).flat().filter(a => a).length > 0);
	// });

	useEffect(() => {
		const ve = refreshValErrs(validationErr);
		setValErrs(ve);
		setHasErrs(Object.values(ve).flat().filter(a => a).length > 0);
	}, [validationErr]);

	return (
		<SectionContext.Provider value={{sectionId: id, titleKey: titleKey, sectionValErrs:valErrs }}>
			<div id={id} className={ "form-section " + (readOnly ? " read-only " : " ") + (hasErrs  ? "has-error " : "") + (hideWhen?"display-hidden ":" ") + classes  }>
				<h3>
					{titleText ?? language[titleKey]}
					{titleSubLabel ? <small style={{paddingLeft:"15px"}}>{titleSubLabel}&emsp;</small>:null}
					{ groupNotes && !readOnly ? 
						<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
						// <FaQuestionCircle className="extra-info-btn" onClick={()=>setShowNotes(!showNotes)}/>
						:
						null
					}
				</h3>
				{ groupNotes && !readOnly ? <ExpandableMessagePane id={id+"_messagePane"} title={groupNotesTitle??language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}
				<div className={inline ? "form-inline" : ""}>
					{children}
				</div>
			</div>
		</SectionContext.Provider>
	);
}

export const NotesSection = ({id, titleKey, titleText, classes, children, notes, numbered}) => {
	return (
		<Section id={id??'notes'} titleKey={titleKey} titleText={titleText} classes={"notesSection " + classes}>
			{ 
				notes 
				? (numbered 
					? <ol>{notes.map((n, index) => <li key={titleKey+"_li_"+index}>{n}</li>)}</ol>
					: notes.map((n, index) => n)
					)
				: null
			}
			{children}
		</Section>
	);
}

export const ScrollableSection = ({id, titleKey, titleText, classes, children, notes, numbered, height, termsAndConditionsHeading, generalTerms}) => {
	height = height ?? 200;
	const {form} = useContext(FormContext);
	return (
			<Section id={id??'notes'} titleKey={titleKey} titleText={titleText} classes={"notesSection " + classes}>
				{ generalTerms === false ? null:
					<>
						<h6 className="d-flex" style={{paddingBottom:"5px"}}>
							<div style={{flex:"1"}}>{form.localization.generalTerms} </div>
							{/* <div style={{color:"#0d8b22"}}>{show2 ? <FaMinus/> : <FaPlus/>}</div> */}
						</h6>
						<div tabIndex="0" style={{overflowY:"scroll", maxHeight:height,position:"relative", marginBottom:"15px",border:"1px solid black", padding:"1em"}}>
							{ (form.localization.generalTermsContent(true).map((n, index) => n)) }
						</div>
					</>
				}
				 
				{ termsAndConditionsHeading ? 
					<h6 className="d-flex" style={{paddingBottom:"5px"}}>
						<div style={{flex:"1"}}>{termsAndConditionsHeading === true ? form.localization.termsAndConditionsOf : termsAndConditionsHeading}</div> 
					</h6>
					:
					null
				}
				{notes?
					<div tabIndex="0" style={{overflowY:"scroll", maxHeight:height,position:"relative", marginBottom:"15px",border:"1px solid black", padding:"1em"}}>
						{ numbered 
								? <ol>{notes.map((n, index) => <li key={titleKey+"_li_"+index}>{n}</li>)}</ol>
								: notes.map((n, index) => n)
						}
					</div>
					: null
				}
				{children}
			</Section>
		
	);
}

export const AcknowledgementSection = ({titleKey, titleText, classes, children, formCode, refNo, submissionTime, paymentTxnNum, gcisWallet, paymentMethod, paymentAmount}) => {
	const {language} = useSelector(store => store.session, lo.isEqual);
	const session = useSelector(store => store.session);
	const {form, formLocalization} = useContext(FormContext);
	const {formData} = useSelector(store => store.formDetail, lo.isEqual);
	const dispatch = useDispatch();

	useEffect(() => {
		const originalTitle = document.title;
		document.title = "Acknowledgement" + (refNo ? `_${refNo}` : '');
		return ()=>{
			document.title=originalTitle;
		};
	},[]);

	useEffect(() => {
		const priContent = document.getElementById("ifmcontentstoprint");
		if (priContent) {
			try {
				const content = document.getElementById("acknowledgement");
				const doc = priContent.contentWindow?.document ?? priContent.contentDocument;
				const _str = "<div><img src=" + logo + " style='width: 218.75px;height: 56px' alt=''/></div>" + content.innerHTML;
				doc.body.innerHTML = _str;
			} catch {
				console.error('Failure in setting innerHTML to print');
			}
		}
	});

	const PrintDialogChildren = (props) => {

		return (
			<div className="form-inline" >
				{/* CAPTCHA */ }
				<Field.Captcha
					captchaToken={session.captchaToken}
					captchaGetChallengeInProgress={session.captchaGetChallengeInProgress}
					captchaChallenge={session.captchaChallenge}
					/>
			</div>
		);
	}

	const printThisPage = () => {
		const pri = document.getElementById("ifmcontentstoprint").contentWindow;
		// const originalTitle = document.title;
		// document.title="Acknowledgement_"+refNo;
		pri.document.title = "Acknowledgement" + (refNo ? `_${refNo}` : '');
		pri.print();
		// document.title=originalTitle;
	}


	const performPrintAgain = () => {
		try {
			dispatch(printOnPaper(
				form,
				true,
				() => {}, 
				err => { dispatch(dialogHelper.showErrorDialog(err, language)) }, 
				(o) => {
					dispatch(dialogHelper.showErrorDialog({
						err: o.err,
						handleClose: () => dispatch(dialogHelper.showCaptchaDialog({children: <PrintDialogChildren />, language: language, performPrint: performPrintAgain}))
				}, language))
			}));
		} catch (err) {
			console.log(err);
			dispatch(dialogHelper.showErrorDialog({err: err}, language));
		}
	}

	let logo = logoEn;
	switch(language.getLanguage()) {
		case "TradChn":
			logo = logoTc;
			break;
		case "SimpChn":
			logo = logoSc;
			break;
		case "Eng":
			logo = logoEn;
			break;
	}
	
	return (
		<>
		<Section id='acknowledgement' titleKey={titleKey} titleText={titleText} classes={"acknowledgementSection " + classes}>
			{form.acknowledgementWarning
			?
			<MessagePane  mode="warning">
				<p style={{fontWeight:"bold", fontSize:'x-large', marginTop:"0px"}}>{language.importantTitle}</p>
				{form.acknowledgementWarning(language)}
			</MessagePane>
			:
			null
			}
		
			{ !refNo ?
				<>
					{form.localization.acknowledgement_offline_para(formData)}
					<button className="btn normalButton primaryButton" onClick={performPrintAgain}><FaSave /> {form.localization.saveAgain}</button>
				</>
				:
				<>
					{!form.localization.onlineSubmAckParagraphs && (<>
						<p>{form.localization.acknowledgement_para1}</p>
						<p>{typeof(form.localization.acknowledgement_para2) == "function" ? form.localization.acknowledgement_para2() : form.localization.acknowledgement_para2 }</p>
					</>)}
					{form.localization.onlineSubmAckParagraphs && (<>{
						form.localization.onlineSubmAckParagraphs.map(p => <p>{p}</p>)
					}</>)}
					<Col>
						{/* <Row>
							<p className="ack-label">{language.formNo + ":"}</p>
							<p className="ack-text">{form.formNumberAtAcknowlegement ?? formCode }</p>
						</Row> */}
						<Row>
							<p className="ack-label">{form.localization.ack_refNum + ":"}</p>
							<p className="ack-text">{refNo}</p>
						</Row>
						<Row>
							<p className="ack-label">{language.submissionTime + ":"}</p>
							<p className="ack-text">{submissionTime}</p>
						</Row>
						{
							paymentTxnNum ? 
							<>
								<Row>
									<p className="ack-label">{form.localization.paymentTxnNum + ":"}</p>
									<p className="ack-text">{paymentTxnNum}</p>
								</Row>
							</>
							:
							null
						}
						{
							paymentMethod ? 
							<>
								<Row>
									<p className="ack-label">{form.localization.paymentAmount + ":"}</p>
									<p className="ack-text">{`HK$ ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(paymentAmount)}`}</p>
								</Row>
								<Row>
									<p className="ack-label">{form.localization.paymentMethod + ":"}</p>
									<p className="ack-text">{getLocalizedPaymentMethod(gcisWallet, paymentMethod, formLocalization)}</p>
								</Row>
							</>
							:
							null
						}
					</Col>
					
					<button className="btn normalButton primaryButton" onClick={printThisPage}><FaPrint /> {language.printOrSavePage}</button>
				</>
			}
			
		</Section>
		<div className="display-hidden">
			<iframe id="ifmcontentstoprint" style={{height:"0px", width:"0px", position:"absolute"}}>
				
			</iframe>
		</div>
		</>
	)
}

export const DeptLogoSection = ({titleKey, classes, children}) => {
	return (<Section id='deptLogo' titleKey={titleKey} classes={classes}>{children}</Section>)
}

export const WantToSection = ({title, startFilling = () => {} , loadSaved = () => {}, noContinue}) => {
	const { language } = useSelector(store => store.session, lo.isEqual);
	const { formData } = useSelector(store => store.formDetail, lo.isEqual);
	const { resumeRetainFormDataKeys, formCode, form  } = useContext(FormContext);
	const dispatch = useDispatch();

	const isFormDirty = !lo.isEqual(formData, initialState.formData); //!lo.isEqual(formData, {});
	
	const onNewForm = (noContinue) => () => {
		if (noContinue) {
			dispatch(clearFormData(resumeRetainFormDataKeys));
		}
		else {
			dispatch(clearFormData());
		}
		dispatch(clearPaymentSlice());
		startFilling();
	};

	const onLoadSave = () => {
		loadSaved();
		dispatch(clearPaymentSlice());
	}

	return (
		<div className="form-section">
			<div className="row">
				<div className="wantTo col-12 col-sm-3">
					<h3>{language.wantTo}</h3>
				</div>
				<div className="wantToList col-12 col-sm-9">
					{ !noContinue ? // Can Continue
						<div><a role="button" onClick={startFilling} onKeyDown={v => { if (v.key === 'Enter') startFilling() }} className="wantToItem" tabIndex="0">
							<FaRegFile />
							<div className="wantToItem-text">
								{isFormDirty ? language.wantTo_continueFilling : language.wantTo_startFilling}
							</div>
						</a></div>
						: null
					}
					{ noContinue || isFormDirty ? // Last page but form not dirty? Can probably delete after investigation
						<div><a role="button" onClick={onNewForm(noContinue)} onKeyDown={v => { if (v.key === 'Enter')  onNewForm(noContinue)() }} className="wantToItem" tabIndex="0">
							<FaRegFile />
							<div className="wantToItem-text">{language.wantTo_startFilling}</div>
						</a></div>
						: null }
					{ !form.simplifiedForm ? // Unsavable form
						<div><a role="button" onClick={onLoadSave} onKeyDown={v => { if (v.key === 'Enter') onLoadSave()}} className="wantToItem"  tabIndex="0">
							<FaRegFolderOpen />
							<div className="wantToItem-text">{language.wantTo_loadSaved}</div>
						</a></div>
						: null }
				</div>
			</div>
		</div>
	);
}