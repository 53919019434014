import { AxiosRequestConfig } from 'axios';
import { instanceToPlain } from 'class-transformer';
import store from '../../../layout/core/reducers/wfpCoreStore';
import { constructAccessToken } from '../utils';

export const onRequest = (config: AxiosRequestConfig) => {
    const { data, headers, ...rest } = config;
    const {
        captchaToken,
        captchaChallenge = {},
        captchaChallengeAnswer,
    } = store.getState()?.session || {};

    const secret = captchaChallenge?.secret;
    const accessToken = constructAccessToken(captchaToken, captchaChallengeAnswer, secret);

    return {
        ...rest,
        data: data ? instanceToPlain(data) : data,
        headers: { ...headers, ...accessToken },
    };
};
