import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers/wfp-core-store.type';
import { saveDataFile, } from '../reducers/formDetail';
import { dialogHelper } from '../dialogHelper';
import { setMessagePaneVisible, scrollToTop } from '../wfpFrame/utils';
import { SaveDialogChildren } from '../wfpFrame/components/save-dialog-children';
import { FormSpec, SaveInfo } from '../class';
import { useUpdateAndValidate } from './use-update-and-validate.hook';

interface ErrorWithMessage {
    message: string;
    args: any;
}

function hasMessage(err:any) : err is ErrorWithMessage {
    return err?.message;
}

export function useSaveForm(form : FormSpec, saveWithSignature? : boolean) {

    const dispatch = useDispatch();
    const { formData } = useSelector((store:RootState) => store.formDetail);
	const session = useSelector((store:RootState) => store.session);
    const language = session.language;
    const { updateAndValidate, updateValidationErrMsg } = useUpdateAndValidate(form, );

    const {
		showErrorDialog,
		showSaveDialog,
		closeDialog,
	 } = dialogHelper;

     const onSave = () => {
		if (
			updateValidationErrMsg(true, true, true, true)
				.length > 0
		) {
			scrollToTop();
		} else {
			let obj = new SaveInfo();
			dispatch(
				showSaveDialog({
					language: language,
					handleConfirm: saveWithSignature ? performSaveWithSignature : performSave,
					returnObj: obj,
					children: <SaveDialogChildren returnObj={obj} updateAndValidate={updateAndValidate} />,
				})
			);
		}
	}

    const performSave = (obj:SaveInfo)=>{
        return saveData(obj, false);
    }

    const performSaveWithSignature = (obj:SaveInfo)=>{
        return saveData(obj, true);
    }

    const saveData = (obj:SaveInfo, withSignature:boolean)=>{
        setMessagePaneVisible(true);
        try {

            if (!obj.savePw) {
                throw { message:"passwordEmpty"};
            }
            if (obj.savePw.length < 8 || obj.savePw.length > 25) {
                throw { message:"passwordWrongLength", args:[8,25]}
            }
            if (obj.savePw !== obj.savePwConfirm) {
                throw { message:"savePasswordNotConfirm"};
            }
            
            dispatch(saveDataFile(form, formData, language, showErrorDialog, obj, (err:any) => {
                    dispatch(showErrorDialog(err, language)); 
                },
                (o?:any) => {
                    dispatch(showErrorDialog({
                        err: o.err,
                        handleClose: () => {
                            dispatch(showSaveDialog({
                                children: <SaveDialogChildren returnObj={obj} updateAndValidate={updateAndValidate} />, 
                                returnObj: obj,
                                handleConfirm: performSave,
                                language: language 
                            }))
                        }
                    }, language));
                },
                withSignature
            ));
        } catch (err) {
            if (hasMessage(err)) {
                let msg:any = language.getString(err.message) ?? err.message;
                if (err.args){
                    msg = language.formatString(language.getString(err.message), ...err.args)
                } 
                
                console.log(err); 
                
                window.requestAnimationFrame(() => dispatch(showErrorDialog({err: msg},language)));

            } else {
                window.requestAnimationFrame(() => dispatch(showErrorDialog({err: err}, language)));
                console.log(err);
            }
        } 
        return true; 
    };

    return {
        onSave:onSave,
        performSave:performSave,
        performSaveWithSignature:performSaveWithSignature,
    };
}