import { createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogOpts, setShowDialog } from '../../layout/core/dialogHelper';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { PayByContent } from '../components/pay-by';
import { getAvailablePaymentMethods } from '../redux/thunks';

export function useShowPaymentOptions() {
    const dispatch = useDispatch();
    const language = useSelector((state: RootState) => state.session.language);

    return () => {
        dispatch(getAvailablePaymentMethods());
        dispatch(
            setDialogOpts({
                title: language?.GcisPayment?.MODAL_HEADER,
                mode: 'custom',
                children: createElement(PayByContent, {
                    closeModal: () => {
                        dispatch(setShowDialog(false));
                    },
                }),
                headerClass: 'dialogHeader',
                disableHeaderClose: true,
                id: 'dialog',
                body: '',
                onHide: () => {
                    dispatch(setShowDialog(false));
                },
            })
        );
        dispatch(setShowDialog(true));
    };
}
