import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { onSuccess } from './on-success.interceptor';
import { onRequest } from './on-request.interceptor';
import { WfpApiUrl } from '../../constants';
import { PaymentStatusCode } from '../../types';

export const instance = axios.create();

instance.interceptors.response.use(onSuccess);

instance.interceptors.request.use(onRequest);

export const mock = new MockAdapter(instance, { delayResponse: 100 });

mock.onGet(new RegExp(`${WfpApiUrl.GET_TRANSACTION_ID}\/?.*`)).reply((config) => {
    const amount = config.url.split('/').pop();
    return [
        200,
        {
            transactionid: 'mock-trans-id',
            webtoken: 'mock-token',
        },
    ];
});

// mock.onGet(new RegExp(`${WfpApiUrl.POLL}\/?.*`)).networkError();

// mock.onGet(new RegExp(`${WfpApiUrl.POLL}\/?.*`)).networkErrorOnce();

mock.onGet(new RegExp(`${WfpApiUrl.POLL}\/?.*`)).reply((config) => {
    const transactionId = config.url.split('/').pop();
    return [
        200,
        {
            status: PaymentStatusCode.APPROVED,
            description: 'APPROVED',
        },
    ];
});
