import LocalizedStrings from 'react-localization';
import Eng from './localization/coreLocalization.en';
import TradChn from './localization/coreLocalization.tc';
import SimpChn from './localization/coreLocalization.sc';  

export const CoreStrings = {
	Eng:Eng,
	TradChn:TradChn,
	SimpChn:SimpChn,
};
export default new LocalizedStrings(CoreStrings, {logsEnabled:true});