import { createElement, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';

import { submitForm as submitFormRaw, submitFormByTxnId as submitFormByTxnIdRaw } from '../../layout/core/reducers/formDetail';
import { closeDialog, dialogHelper } from '../../layout/core/dialogHelper';
import { FormContext, GetCaptchForm } from '../../layout/core/layoutElements';

export function useSubmitForm() {
    const formObj = useSelector((state: RootState) => state.session.formObj);
    const language = useSelector((store: RootState) => store.session.language);
    const transactionId = useSelector((state: RootState) => state.payment?.transactionData?.transactionId);
    const { nextStep } = useContext(FormContext);

    const dispatch = useDispatch();
    const submitForm = () => {
        dispatch(
            submitFormRaw(
                formObj,
                () => {
                    dispatch(closeDialog());
                    nextStep();
                },
                (err: any) => {
                    // PPL-8466 HOTFIX: 
                    submitFormByTxnId();
                    // dispatch(dialogHelper.showErrorDialog(err, language));
                },
                () =>
                    dispatch(
                        dialogHelper.showCaptchaDialog({
                            children: createElement(GetCaptchForm),
                            language: language,
                            handleConfirm: submitForm,
                        })
                    )
            )
        );
    };
    const submitFormByTxnId = () => {
        dispatch(
            submitFormByTxnIdRaw(
                transactionId,
                formObj,
                () => {
                    dispatch(closeDialog());
                    nextStep();
                },
                (err: any) => {
                    dispatch(dialogHelper.showErrorDialog(err, language));
                },
                () =>
                    dispatch(
                        dialogHelper.showCaptchaDialog({
                            children: createElement(GetCaptchForm),
                            language: language,
                            handleConfirm: submitForm,
                        })
                    )
            )
        );
    };

    return submitForm;
}
