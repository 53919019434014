// This enum is NOT defined at GCIS level but is instead custom designed for common usage
export enum PaymentMethod {
    APPLE_PAY = 'APPLE_PAY',
    ANDROID_PAY = 'ANDROID_PAY',
    VISA = 'VISA',
    MASTER_CARD = 'MASTER_CARD',
    JCB = 'JCB',
    UNION_PAY = 'UNION_PAY',
    PPS = 'PPS',
    E_CHEQUE = 'E_CHEQUE',
    FPS = 'FPS', // 202309
}
