import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../../layout/core/reducers/wfp-core-store.type';

import { PaymentMethodData } from '../entities';
import { useShowPaymentError, useShowGeneralError } from '../hooks-dialog';
import { getAvailablePaymentMethods } from '../redux/thunks';
import { ERROR_NO_PAYMENT_METHODS_AVAILABLE } from '../constants';

import { useGetPaymentLocale } from './use-get-payment-locale.hook';

export function useGetAvailablePaymentMethods(): [boolean, PaymentMethodData[]] {
    const [loading, setLoading] = useState(true);
    const availablePaymentMethods =
        useSelector<RootState, PaymentMethodData[]>(
            (state: RootState) => state.payment.availablePaymentMethods
        ) || [];
    const transactionData = useSelector((state: RootState) => state.payment.transactionData);
    const paymentData = useSelector((state: RootState) => state.payment.paymentData);
    const showPaymentError = useShowPaymentError();
    const showGeneralError = useShowGeneralError();
    const locale = useGetPaymentLocale();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!paymentData || !paymentData.isValid()) return;
        if (!transactionData || !transactionData.isValid()) return;

        setLoading(true);
        dispatch(getAvailablePaymentMethods())
            .catch((e: any) => {
                if (e === ERROR_NO_PAYMENT_METHODS_AVAILABLE) {
                    showPaymentError(ERROR_NO_PAYMENT_METHODS_AVAILABLE);
                } else {
                    showGeneralError();
                }
            })
            .finally(() => setLoading(false));
    }, [paymentData, transactionData, locale]);

    return [loading, availablePaymentMethods];
}
