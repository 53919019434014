import { Expose, Transform, TransformationType, TransformFnParams } from 'class-transformer';
import { CardType, PaymentMethodCode, PaymentMethodSubtype } from '../enums';
import { OnePointToNote } from './one-point-to-note.entity';

export class AvailablePaymentMethods {
    constructor(input: Partial<AvailablePaymentMethods> = {}) {
        Object.assign(this, input);
    }
    code: PaymentMethodCode;

    @Expose({ name: 'subtype' })
    @Transform(transformSubtype)
    subType: PaymentMethodSubtype;

    @Transform(transformActive)
    active: boolean; // Y / N

    @Expose({ name: 'pointstonote' })
    pointsToNote: OnePointToNote[];

    @Expose({ name: 'supportedcard' })
    supportedCards: CardType[];

    // Properties for local use. Not from response.
    label: string;

    // Properties for local use. Not from response.
    id: string;

    removeCard(card: CardType) {
        if (this.supportedCards) {
            this.supportedCards = this.supportedCards.filter((c) => c !== card);
        }
    }
}

function transformSubtype(params: TransformFnParams) {
    const { value } = params;
    return value?.toLowerCase();
}

function transformActive(params: TransformFnParams) {
    const { value, type } = params;
    if (type === TransformationType.CLASS_TO_PLAIN) {
        return value ? 'Y' : 'N';
    }
    if (type === TransformationType.PLAIN_TO_CLASS) {
        return value === 'Y';
    }
    return value;
}
