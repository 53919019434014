import moment from 'moment';
import { EnglishDict } from './english.dict';
import { Period } from './period.type';

export class EngTimeHelper {
    static format(s: Period) {
        const { from, to } = s;
        const fromDate = this.formatDate(from);
        const toDate = this.formatDate(to);
        const fromTime = this.formatTime(from);
        const toTime = this.formatTime(to);

        return `${fromDate}${fromTime} ${EnglishDict.TO} ${fromDate !== toDate ? toDate : ''}${
            EnglishDict.AROUND
        } ${toTime}`;
    }

    static formatTime(d: Date) {
        return `${moment(d).format('h:mm')} ${this.getTimeSuffix(d)}`;
    }

    static formatDate(d: Date) {
        return `${moment(d).format('D MMMM YYYY (dddd) ')} `;
    }

    static getTimeSuffix(d: Date) {
        const suff = moment(d).format('a');
        return suff.substring(0, 1) + '.' + suff.substring(1) + '.';
    }
}
