import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        isMessagePaneVisible: false,
    },
    reducers: {
        setMessagePaneVisible: (state, action) => {
            const { payload: isVisible } = action;
            state.isMessagePaneVisible = isVisible;
        },
    },
});

export const { setMessagePaneVisible } = uiSlice.actions;

export type UiSliceType = typeof uiSlice.reducer;

export default uiSlice.reducer;
