import { EnumTransform } from './enum-transform.util';

import { PaymentMethod } from '../enums';
import { PaymentData, PaymentMethodData } from '../entities';
import { Payment } from '../payment.proxy';
import { getCurrentDisabledPaymentMethods } from './get-current-disabled-payment-methods.util';
import { getNonExpectedPaymentMethods } from './get-non-expected-payment-methods.util';

export function calculateUnavailablePaymentMethods(
    given: PaymentMethodData[],
    paymentData?: PaymentData
) {
    const checkMap = getMethodsToCheck(paymentData);
    return checkMap.filter((k) => !hasMethod(given, k));
}

export function getMethodsToCheck(paymentData?: PaymentData): PaymentMethod[] {
    const checkMap: Record<PaymentMethod, boolean> = {
        [PaymentMethod.APPLE_PAY]: false,
        [PaymentMethod.ANDROID_PAY]: false,
        [PaymentMethod.VISA]: false,
        [PaymentMethod.MASTER_CARD]: false,
        [PaymentMethod.UNION_PAY]: false,
        [PaymentMethod.JCB]: false,
        [PaymentMethod.PPS]: false,
        [PaymentMethod.E_CHEQUE]: false,
        [PaymentMethod.FPS]: false, // 202309
    };

    // Check payment methods that we wants
    for (const subtype of Payment.getPreferredPaymentMethods()) {
        const paymentMethods = EnumTransform.paymentMethodSubtypeToPaymentMethods(subtype);
        paymentMethods.forEach((pm) => (checkMap[pm] = true));
    }

    // Not check payment methods disabled by us
    const disabled = getCurrentDisabledPaymentMethods();
    for (const key of disabled) {
        checkMap[key] = false;
    }

    const nonExpected = getNonExpectedPaymentMethods(paymentData);
    for (const key of nonExpected) {
        checkMap[key] = false;
    }

    return Object.entries(checkMap)
        .filter(([_, b]) => b)
        .map(mapper);
}

function mapper(kb: [string, boolean]): PaymentMethod {
    const [k, b] = kb;
    return k as unknown as PaymentMethod;
}

function hasMethod(data: PaymentMethodData[], pm: PaymentMethod) {
    for (const d of data) {
        if (d.getPaymentMethod() === pm) {
            return true;
        }
    }
    return false;
}
