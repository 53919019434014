import { Expose, Type } from 'class-transformer';
import { Phase } from '../low-level';
import { BaseEstate } from './base-estate.entity';
import { joinString } from '../../../../utilities/commonUtil';

export class ChiEstate extends BaseEstate {
    @Expose({ name: 'ChiPhase' })
    @Type(() => Phase)
    phase?: Phase;

    getString() {
        return joinString([this.name, this.phase?.phaseName], '');
    }
}