import { AppDispatch, RootState } from '../../../layout/core/reducers/wfp-core-store.type';
import { ERROR_NO_PAYMENT_METHODS_AVAILABLE } from '../../constants';
import { getAvailablePaymentMethods as api } from '../../utils';
import { setAvailablePaymentMethods } from '../slice';

import { AvailablePaymentMethods }  from '../../entities/available-payment-methods.entity';
import { PaymentMethodData }        from '../../entities/payment-method-data.entity';

import { CardType, MessageType, PaymentMethodCode, PaymentMethodSubtype } from '../../enums';

export function getAvailablePaymentMethods() {
    return async function (dispatch: AppDispatch, getState: () => RootState) {
        const transactionData = getState()?.payment.transactionData;
        const paymentData = getState()?.payment.paymentData;

        if (!paymentData || !paymentData.isValid()) return;
        if (!transactionData || !transactionData.isValid()) return;

        return await api(paymentData, transactionData).then((avail) => {
            // 202309: [temp==start==] simulate repsonse - available method: PPS
            // const availablePaymentMethod = new AvailablePaymentMethods({
            //     code: PaymentMethodCode.FPS,
            //     subType: PaymentMethodSubtype.FPS,
            //     active: true,
            //     pointsToNote: [
            //         {
            //             type: MessageType.INFO,
            //             content: 'Test Content',
            //             order: 1,
            //         },
            //     ],
            // });

            // const _tempList = PaymentMethodData.fromAvailablePaymentMethod(availablePaymentMethod);
            // console.log("getAvailablePaymentMethods:");
            // console.log([...avail, ..._tempList]);
             // 202309: [temp==end==] simulate simulate repsonse - available method: PPS
             console.log(avail);
            dispatch(setAvailablePaymentMethods(avail));
            if (avail.length === 0) {
                throw ERROR_NO_PAYMENT_METHODS_AVAILABLE;
            }
        });
    };
}