import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { updateTransaction } from '../../utils';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Ack() {
    const query = useQuery();
    const receiptToken = query.get('RECEIPT_TOKEN');
    const transactionId = query.get('TRANSACTION_ID');

    useEffect(() => {
        updateTransaction({ receiptToken, transactionId });
    }, [receiptToken, transactionId]);

    return <></>;
}
