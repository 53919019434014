import { Expose, Transform } from 'class-transformer';
import { booleanDigitTransform } from '../../utils';

export class AddressLookupRequest {
    constructor(input: Partial<AddressLookupRequest> = {}) {
        Object.assign(this, input);
    }
    @Expose({ name: 'q' })
    address: string;

    //min 1; max 200
    @Expose({ name: 'n' })
    maxNumberOfRecords?: number = 10;

    @Expose({ name: 'language' })
    preferredLanguage?: AddressSearchLanguage;

    //min: 0; max: 80; default: 20
    // ie by default, score (max - 20 TO max) will be returned
    @Expose({ name: 't' })
    tolerance?: number = 50;

    @Expose({ name: 'b' })
    @Transform(booleanDigitTransform)
    isBasicSearchingMode?: boolean = false;

    @Expose({ name: '3d' })
    @Transform(booleanDigitTransform)
    is3d?: boolean = false;

    floor?: string;

    unit?: string;
}

export enum AddressSearchLanguage {
    ENGLISH = 'en',
    CHINESE = 'zh-Hant',
    BOTH = 'en, zh-Hant',
    ANY = '*',

    // For WFP only, not applicable to Lookup Address Service
    SIMPLIFIED_CHINESE = 'zh-Hans'
}
