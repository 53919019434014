export function filterEmptyValue(obj: Record<string, any>) {
    const ret: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
        if (value !== undefined && value !== null) {
            ret[key] = value;
        }
    }
    return ret;
}

export function clonePartialObject(
    obj: Record<string, any>,
    keys: string[],
    labels: Record<string, any> = {}
) {
    return keys
        .map((key) => [key, obj[key]])
        .reduce((acc, [k, v]) => ({ ...acc, [labels[k] ?? k]: v }), {});
}
