import { getOnlinePaymentMethods } from './get-online-payment-methods.util';
import { getPaymentMethod } from './get-payment-method.util';
import { FormContext, FormData, Language } from './types';

type Input = {
    formContext: FormContext;
    formData: FormData;
    language: Language;
};

export function isOnlinePayMethod(input: Input) {
    const { formData, formContext } = input;
    const paymentMethod = getPaymentMethod({ form: formContext, formData });
    if (!paymentMethod) return true;
    return parseInt(paymentMethod) < 0;
}
