import React from 'react';
import lo from 'lodash';
import { Container, Navbar, Row, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { detect } from 'detect-browser';

import SysParam from '../utilities/sysParam';
import { dialogHelper } from './core/dialogHelper';
import Header from './header';
import Footer from './footer';

export default ({isIE, logo}) => {
    const {language, curLanguage, isFetching, loadingKey, loadingData, loadingMessage} = useSelector(store => store.session, lo.isEqual);
    const dispatch = useDispatch();

    return (
            <Container className={"rootContainer " + (isIE?"ieStyle":"")}>
			    <Header logo={logo} language={language} curLanguage={curLanguage}  maintenance={true} />    
				<div className="bodyBackground" >
                    <div variant="warning" style={{padding:"50px", minWidth: "600px", alignSelf:"center", maxWidth: "50%", width: "50%", margin: "0 auto"}}>
                        <p>{language.wfpMaintenanceContent()}</p>
                        </div>
                </div>
                <Footer language={language}
					formScreenId="MAINT"
					formStepIndex={0}
					/>
			
            </Container>

    );
}