import { plainToInstance } from 'class-transformer';
import { instance } from '../../instance';
import { PollStatusResponse as Response } from './response';
import { WfpApiUrl } from '../../../constants';

const url = WfpApiUrl.POLL;

export async function pollStatus(transactionId: string): Promise<Response> {
    const data = await instance.get(`${url}/${transactionId}`);
    return plainToInstance(Response, data);
}
