import { Expose } from 'class-transformer';
import { toTitleCase } from '../../../../utilities';
import { AddressSearchLanguage } from '../../service/address-lookup.request';
import { NumberString } from '../../types';
import { joinString } from '../../../../utilities/commonUtil';

export class Street {
    @Expose({ name: 'BuildingNoFrom' })
    buildingNoFrom: NumberString;

    @Expose({ name: 'BuildingNoTo' })
    buildingNoTo?: NumberString;

    @Expose({ name: 'StreetName' })
    streetName: string;

    @Expose({ name: 'LocationName' })
    locationName?: string;

    getString(language: AddressSearchLanguage = AddressSearchLanguage.ENGLISH) {
        if (language === AddressSearchLanguage.ENGLISH) {
            return this.getEnglishString();
        } else {
            return this.getChineseString();
        }
    }

    getStreetNumber() {
        /*let ret = this.buildingNoFrom;
        if (this.buildingNoTo) {
            ret += `-${this.buildingNoTo}`;
        }
        return ret;*/
        return joinString([this.buildingNoFrom, this.buildingNoTo], '-');
    }

    getStreetName(language: AddressSearchLanguage = AddressSearchLanguage.ENGLISH) {
        if (language === AddressSearchLanguage.ENGLISH) {
            return this.getEnglishStreetName();
        } else {
            return this.getChineseStreetName();
        }
    }

    // English
    getEnglishStreetName() {
        /* let ret = '';
        ret += toTitleCase(this.streetName);
        if (this.locationName) {
            ret += ` ${toTitleCase(this.locationName)}`;
        } */
        return joinString([this.streetName], ' ');
    }

    getEnglishString() {
        /* let ret = this.getStreetNumber();
        ret +=  ' ' + this.getEnglishStreetName();
        return ret; */
        return joinString([this.getStreetNumber(), this.getEnglishStreetName()], ' ');
    }

    // Chinese
    getChineseStreetName() {
        /*let ret = '';
        if (this.locationName) {
            ret += `${this.locationName}`;
        }
        ret += this.streetName;
        return ret;*/
        return joinString([this.streetName], ' ');
    }

    getChineseString() {
        /*let ret = this.getChineseStreetName();
        ret += this.getStreetNumber();
        return ret;*/

        return joinString([this.getChineseStreetName(), this.getStreetNumber()], '');
    }
}
