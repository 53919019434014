import React from 'react';
import { CloseButton } from './close-button';

export function CancelFooter(props: Props) {
    // 202309: added custom style for fps
    const { onCancel, customClass } = props;
    return (
        <div id="modalFooter">
            <hr />
            <div className="row">
                <CloseButton customClass={customClass} onClose={onCancel} />
            </div>
        </div>
    );
}

type Props = {
    customClass?: string;
    onCancel: () => void;
};
