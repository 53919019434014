export function constructAccessToken(token?: string, answer?: string, secret?: string) {
    if (token) {
        return {
            'Access-Token': token,
        };
    } else if (answer) {
        return {
            challengeRs: `${secret};${answer}`,
        };
    } else {
        return {};
    }
}
