import { Expose, instanceToPlain, Type } from 'class-transformer';
import { PaymentMethodCode, PaymentLocale, WalletType } from '../../../enums';
import { CombinedData, Order } from '../../../entities';

export class PayByWalletRequest {
    constructor(input: Partial<PayByWalletRequest> = {}) {
        Object.assign(this, input);
    }

    @Expose({ name: 'transactionid' })
    transactionId: string;

    @Expose({ name: 'apprefid' })
    appRefId?: string; // transactionId will be used if empty

    @Expose({ name: 'webtoken' })
    webToken: string;

    @Expose({ name: 'paymentmethod' })
    paymentMethod: PaymentMethodCode;

    @Type(() => Order)
    order: Order;

    @Expose({ name: 'paymentdata' })
    paymentData: any; // payment data returned from ApplePay / AndroidPay

    locale: PaymentLocale;

    @Expose({ name: 'eserviceid' })
    eServiceId?: string;

    type: WalletType;

    toRaw() {
        return instanceToPlain(this);
    }

    extractType(): WalletType {
        const type = this.type;
        delete this.type;
        return type;
    }

    static fromData(data: CombinedData) {
        const { returnUrl, ...rest } = data;
        return new PayByWalletRequest(rest);
    }

    setLocale(locale: PaymentLocale) {
        this.locale = locale;
    }
}
