import React from 'react';

type Props = React.PropsWithChildren<{ onClick: () => void; className?: string; id: string }>;

export function DefaultButton(props: Props) {
    const { onClick, className = '', children, id } = props;
    return (
        <button id={id} type="button" className={`btn normalButton ${className}`} onClick={onClick}>
            {children}
        </button>
    );
}
