import React, { lazy, Suspense, useEffect, useState, createContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Recaptcha from 'react-recaptcha';
import {detect} from 'detect-browser'; 
import ReactDOMServer from 'react-dom/server';
import '../css/wfp/input.css';

import { setLanguageThunk } from './core/reducers/setLanguage.thunk';

import { Route, Switch, Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactTooltip from 'react-tooltip'

import "react-datepicker/dist/react-datepicker.css";

import SysParam from '../utilities/sysParam';
import { NO_CAPTCHA, QUERY_FOR_NO_CAPTCHA } from '../utilities/constant';
import recaptcha_FAKE from '../resources/recaptcha.png';
import Button from './component/button.js';
import { dialogHelper } from './core/dialogHelper';

import { FaLaptop } from 'react-icons/fa';
// import RootLR152C from './root.lr152c';
import GeneralTerms from './generalTerms';
import Header from './header';
import Footer from './footer';
import FormMenu from './core/formMenu';
import SysRequirements from './sysRequirements';
import TermsAndConditions from './terms';
import lo from 'lodash';

import Form from './form';
import { Ack } from '../gcis-payment/components/debug';
import { LookupAddressSuggestion } from '../ogcio-address-lookup/';

const RootLR152C = lazy(() => import('./root.lr152c'));

export const RootContext = createContext({
	setFormScreenId: null, 
	setFormStepIndex: null,
	recaptcha: null,
	sourceType: '',
})

const Root = ( {isIE, logo} ) => { 
	const session = useSelector(store => store.session, lo.isEqual);
	const [ formScreenId, setFormScreenId ] = useState('');
	const [ formStepIndex, setFormStepIndex ] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {

		const title = process.env.REACT_APP_TITLE ?? "[UAT] Electronic Forms | Land Registry";
		document.title = title;

		// When the user scrolls down 20px from the top of the document, show the button
		window.onscroll = function() {scrollFunction()};

		function scrollFunction() {

			//Get the button:
			const mybutton = document.querySelector("button.myBtn");

			if (!mybutton) return;
			if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
				mybutton.style.display = "block";
			} else {
				mybutton.style.display = "none";
			}
		} 
	}, []);

	const checkNoCaptchaAndReturn = () => NO_CAPTCHA ? { reset: () => {}, execute: () => window.grecaptchaOnVerify(() => {}) } : window.grecaptcha;

	const topFunction = () => {

		// When the user clicks on the button, scroll to the top of the document
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	
	}

	let recaptchaRef = null;
	const grecaptchaVerifyCallback = token => {
		console.log("gcaptchaVerifyCallback"+token);
		if (window.grecaptchaOnVerify) {
			console.log("gcaptchaOnVerify not null");
			window.grecaptchaOnVerify(token);
			window.grecaptchaOnVerify = null;
		}
	};

	const {language, curLanguage, isFetching, loadingKey, loadingData, loadingMessage} = session;
 
	let loadingMsg = null;

	if (loadingMessage) {
		loadingMsg = loadingMessage;
	} else if (loadingKey) {
		loadingMsg = language[loadingKey];
	}

	if (loadingMsg) { 
		loadingMsg = loadingMsg.replace("{}", curLanguage === 0 ? (loadingData[0]) : loadingData[0])
								.replace("{}", loadingData[1]); 
	} 

	const query = new URLSearchParams(window.location.search);

	if (curLanguage === "") {
		if (query.get("lang")){
			dispatch(setLanguageThunk(query.get("lang")))
		}
		else {
			dispatch(setLanguageThunk("Eng"))
		}
		return null;
	}

	return (
		
		<Suspense fallback={<p>{language.loadingPleaseWait ?? "Loading..."}</p>}>
		<Container className={"rootContainer " + (isIE?"ieStyle":"")}>
			<div className="loading-overlay" 
				style={{
					backgroundColor: "rgba(0,0,0,0.5)", 
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					position: "fixed",
					zIndex: 9999999,
					display: (isFetching) ? "block" : "none"
					}}>
				<div className="loader"></div>
				<div className="loader-message" style={{						
					width: "calc(100% - 40vw)",
					height: "50%",
					left: "20vw",
					position: "absolute",
					right: "20vw"
				}}>
					{loadingMsg}
					<div>&emsp;</div>
					{ loadingMsg ? <ProgressBar variant="success" now={loadingData[1]} max={loadingData[0]} animated /> : null }
				</div>
			</div>
			<Header logo={logo} language={language} curLanguage={curLanguage} /> 
			<RootContext.Provider value={{
												setFormScreenId: setFormScreenId, 
												setFormStepIndex: setFormStepIndex, 
												recaptcha: checkNoCaptchaAndReturn
											}}>
				<div className="bodyBackground">
					<Row></Row>
					<Switch>
						{process?.env?.NODE_ENV === 'development' && (
							<Route path="/debug" exact>
								<LookupAddressSuggestion/>
							</Route>
						)}
						<Route path="/ack">
							<Ack/>
						</Route>
						<Route path="/form/terms" exact>
							<GeneralTerms />
						</Route>
						<Route path="/:sourceType/form/:formId" exact render={(props) => {
								const formId = props.match.params.formId;
								// let lang = props.match.params.lang;
								// if (!lang || !SysParam.LANG_OPTIONS.includes(lang)) {
								// 	lang = "Eng";
								// }

								const ThisForm = SysParam.FORM_MAP[formId];
								const sourceType = props.match.params.sourceType ?? 'lrw';
								

								if (!ThisForm) window.location.href = SysParam.SOURCE_TYPE[sourceType].url(curLanguage);

								setFormScreenId(formId);
								// dispatch(setFormObj(form));
								// dispatch(setLanguage(lang));

								return (
									<RootContext.Provider value={{setFormScreenId: setFormScreenId, setFormStepIndex: setFormStepIndex, recaptcha: checkNoCaptchaAndReturn, sourceType:sourceType}}>
										{/* <Form formId={formId} 
												form={{
													...form, 
													submitTransformFunc:form.submitTransformFunc ?? ((formData, dispatch) => {})
												}} 
												currentLang={curLanguage} 
												 /> */}
											<ThisForm currentLang={curLanguage} />
									</RootContext.Provider>
								)}
							} />
						<Route path="/form/:formId" exact render={(props) => {
								const formId = props.match.params.formId;
								// let lang = props.match.params.lang;
								// if (!lang || !SysParam.LANG_OPTIONS.includes(lang)) {
								// 	lang = "Eng";
								// }


								const ThisForm = SysParam.FORM_MAP[formId];
								const sourceType = props.match.params.sourceType ?? 'lrw';

								if (!ThisForm) window.location.href = SysParam.SOURCE_TYPE[sourceType].url(curLanguage);

								setFormScreenId(formId);
								// dispatch(setFormObj(form));
								// dispatch(setLanguage(lang));

								return (
									<RootContext.Provider value={{setFormScreenId: setFormScreenId, setFormStepIndex: setFormStepIndex, recaptcha: checkNoCaptchaAndReturn, sourceType:sourceType}}>
										{/* <Form formId={formId} 
												form={{
													...form, 
													submitTransformFunc:form.submitTransformFunc ?? ((formData, dispatch) => {})
												}} 
												currentLang={curLanguage}  /> */}
												
											<ThisForm currentLang={curLanguage} />
									</RootContext.Provider>
								)}
							} />
						<Route path="/notRecognized" render={(props) => { window.location.href=SysParam.SOURCE_TYPE.lrw.url(curLanguage) ; return <></>; }} />
						<Redirect to="/notRecognized" />
						
{/* 						
						<Route path="/" exact>
							{
								!session.isPassedBrowserRequirement 
								? <SysRequirements />
								: (!session.isAcceptTAndC
									? <TermsAndConditions />
									: <FormMenu />)
							}
						</Route> */}
					</Switch>
				</div>    	
			</RootContext.Provider>
			<Footer language={language}
					formScreenId={formScreenId}
					formStepIndex={formStepIndex}
					/>
			
			<Button onClick={topFunction} buttonClass="myBtn" label={"▲ " + language.backToTop} />
		</Container>
		</Suspense>
	);
}

export default Root;