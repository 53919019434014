import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethod } from '../payment-method';
import {
    setFinancialInstitute,
    setPaymentGateway,
    setPaymentMethodIndex,
} from '../../../redux/slice';
import { PaymentMethodData } from '../../../entities';
import { RootState } from '../../../../layout/core/reducers/wfp-core-store.type';

type Props = {
    availablePaymentMethods: PaymentMethodData[];
};

export function PaymentPanel(props: Props) {
    const { availablePaymentMethods } = props;

    const dispatch = useDispatch();
    const payment = useSelector((state: RootState) => state.payment);

    return (
        <div className="paymentBtnGroup text-left" data-toggle="buttons">
            {availablePaymentMethods.map((paymentMethod, idx) => (
                <PaymentMethod
                    key={idx}
                    data={paymentMethod}
                    selected={payment.paymentMethodIndex === idx}
                    onSelected={() => {
                        dispatch(setPaymentMethodIndex(idx));
                        dispatch(setPaymentGateway(paymentMethod.getId()));
                        dispatch(setFinancialInstitute(paymentMethod.code));
                    }}
                />
            ))}
        </div>
    );
}
