import { instanceToPlain, plainToInstance } from 'class-transformer';
import { instance } from '../../instance';
import { Payment } from '../../../payment.proxy';
import { QueryPaymentAvailabilityRequest as Request } from './request';
import { QueryPaymentAvailabilityResponse as Response } from './response';
import { PaymentApiUrl } from '../../../constants';
import { removeDisabledPaymentMethods } from '../../../utils';

const url = PaymentApiUrl.QUERY_PAYMENT_METHOD;

export async function queryPaymentMethod(request?: Request): Promise<Response> {
    const data = await instance.post(url, request);
    let obj = plainToInstance(Response, data);
    obj = removeDisabledPaymentMethods(obj);
    Payment.recordAvailablePaymentMethods(instanceToPlain(obj));
    return obj;
}
