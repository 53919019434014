import { Expose, Type } from 'class-transformer';
import { Floor } from '../floor.entity';
import { Unit } from '../unit.entity';

export class EngThreeDAddress {
    @Expose({ name: 'EngUnit' })
    @Type(() => Unit)
    unit: Unit;

    @Expose({ name: 'EngFloor' })
    @Type(() => Floor)
    floor: Floor;
}
