import React from 'react';
import { CloseButton } from './close-button';
import { SubmitButton } from './submit-button';

export function NormalFooter(props: Props) {
    const { canClose, onClose, onSubmit, canSubmit } = props;
    return (
        <div id="modalFooter">
            <hr />
            <div className="row">
                <CloseButton onClose={onClose} disabled={!canClose} />
                <SubmitButton onSubmit={onSubmit} disabled={!canSubmit} />
            </div>
        </div>
    );
}

type Props = {
    canClose: boolean;
    onClose: () => void;
    canSubmit: boolean;
    onSubmit: () => void;
};
