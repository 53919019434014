import mfiHelper from './mfiHelper';
import * as moment from 'moment';
import * as Constant from '../utilities/constant';

export const LEN_SOLICITORS_CODE = 8;
export const LEN_MEMORIAL_NUM = 14;
export const LEN_PRN = 8;
export const LEN_REGFEE = 7;
export const LEN_CONSIDERATION = 16;
export const LEN_STAMP_OFFICE_REF = 17;
export const LEN_STAMP_DUTY = 40;
export const LEN_DATE_OF_INSTRU_REMARKS = 34;

export const MAX_REGFEE = 998000;
export const MAX_CONSIDERATION = 9999999999999.99;

const REGEX_CHINESE = /^[\s\u4e00-\u9fff]+$|^[\s\u3400-\u4dbf]+$|^[\s\u{20000}-\u{2a6df}]+$|^[\s\u{2a700}-\u{2b73f}]+$|^[\s\u{2b740}-\u{2b81f}]+$|^[\s\u{2b820}-\u{2ceaf}]+$|^[\s\uf900-\ufaff]+$|^[\s\u3300-\u33ff]+$|^[\s\ufe30-\ufe4f]+$|^[\s\uf900-\ufaff]+$|^[\s\u{2f800}-\u{2fa1f}]/u;
const REGEX_EMOJI = /[^\p{L}\p{N}\p{P}\p{Z}\n\s]/gu
const REGEX_LATIN_WITH_ACCENT = /^[A-Za-zÀ-ÖØ-öø-ÿ\-'\s,.]+$/u
export const REGEX_TIME = /^[0-9]{1,2}:[0-9]{2} (am|pm|AM|PM|上午|下午)$/;
export const isChinese = (str) => REGEX_CHINESE.test(str);
export const isLatin = (str) => REGEX_LATIN_WITH_ACCENT.test(str);
export const isEnglish = (str) => isLatin(str); ///^[A-Za-z\-'\s,]+$/u.test(str); 
export const isEmoji = (str) => REGEX_EMOJI.test(str);

export const requiredCheck = (v) => v != null;
export const optional = (v) => !v || (v.trim && v.trim().length === 0);
export const lengthExactCheck = (v, len) => (optional(v) || v.length === len);
export const lengthMaxCheck = (v, len) => (optional(v) || v.length <= len);
export const lengthMinCheck = (v, len) => (optional(v) || v.length >= len);
export const lengthRangeCheck = (v, lenMin, lenMax) => (optional(v) || (v.length >= lenMin && v.length <= lenMax));
export const decimalMaxCheck = (v, val) => (numericCheck(v) && parseFloat(v) <= parseFloat(val));
export const decimalMinCheck = (v, val) => (numericCheck(v) && parseFloat(v) >= parseFloat(val));
export const decimalRangeCheck = (v, decimalMin, decimalMax) => (numericCheck(v) && (parseFloat(v) >= parseFloat(decimalMin) && parseFloat(v) <= parseFloat(decimalMax)));
export const integerCheck = (v) => optional(v) || (!isNaN(v) && parseFloat(v) == parseInt(v) && (!v || v.toString().indexOf('.') < 0));
export const numericCheck = (v) => optional(v) || !isNaN(v);
export const naturalNumberCheck = (v) => optional(v) || integerCheck(v) && (!v || parseInt(v) >= 0)
export const divisibleCheck = (v, divisor) => optional(v) || (!isNaN(v) && (parseInt(v) % divisor) === 0);
export const numericMaxCheck = (v, max) => optional(v) || (numericCheck(v) && parseInt(v) <= max);
export const numericMinCheck = (v, min) => optional(v) || (numericCheck(v) && parseInt(v) >= min); 
export const dateCheck = (v, customDateFormat=Constant.DATE_FORMAT) => (lengthExactCheck(v,customDateFormat.length) && moment(v, customDateFormat, true).isValid()) ;
export const timeCheck = v => optional(v) || (vv => { 
    if (!REGEX_TIME.test(vv)) return false;
    const parts = vv.split(":");
    if (parts.length !== 2) return false;
    const hourPart = parseInt(parts[0]);
    const minutePart = parseInt(parts[1].substring(0,2));
    if (hourPart < 0 || hourPart > 23 || minutePart < 0 || minutePart > 59) return false;

    return true;
})(v);
export const HKIDCheck = str => {
    var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    if (str.length < 8) {
        return {key:"validation_invalidHKID", args: []};
    }

    // handling bracket
    if (str.charAt(str.length-3) === '(' && str.charAt(str.length-1) === ')')
    str = str.substring(0, str.length - 3) + str.charAt(str.length -2);

    // convert to upper case
    str = str.toUpperCase();

    // regular expression to check pattern and split
    var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    var matchArray = str.match(hkidPat);

    // not match, return false
    if (matchArray == null) {
        return {key:"validation_invalidHKID", args: []};
    }

    // the character part, numeric part and check digit part
    var charPart = matchArray[1];
    var numPart = matchArray[2];
    var checkDigit = matchArray[3];

    // calculate the checksum for character part
    var checkSum = 0;
    if (charPart.length === 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    }

    // calculate the checksum for numeric part
    for (var i = 0, j = 7; i < numPart.length; i++, j--) {
        checkSum += j * numPart.charAt(i);
    }

    // verify the check digit
    var remaining = checkSum % 11;
    var verify = "" + (remaining === 0 ? 0 : 11 - remaining);

    if (verify === checkDigit || (verify === '10' && checkDigit === 'A')){
        return null;
    }
    else {
        return {key:"validation_invalidHKID", args: []};
    }
}

export const emailCheck = v => {

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z0-9-]+)*$/.test(v)) {
        return null;
    }
    return {key:"validation_invalidEmail", args: []};

}

export const fileSizeMaxCheck = (v, limit, limitStr) => {
    return v.size < limit ? null : { key: "validation_fileSizeExceed", args: [limitStr] }
}
export const fileTypeCheck = (v, filter) => {
    const fileType = v.name.substring(v.name.lastIndexOf("."), v.name.length)
    return filter.split(",").includes(fileType) ? null : { key: "validation_wrongFileType", args: [] }
}

const solicitorsCodeValidator = form => {
    const { solicitorsCode } = form;
    return (( lengthExactCheck(solicitorsCode, 0) || (lengthExactCheck(solicitorsCode, LEN_SOLICITORS_CODE) && numericCheck(solicitorsCode)) )
        ? null 
        : "validationErr_solicCode"
    );
}; 

const registrationFeeValidator = form => { 
    // if (form.registrationFee.optionKey !== "other") return null;

    const { optionKey, optionValue } = form.registrationFee;
    const optionValueNoSep = optionValue.replace(/,/g, '');
    
    if (optionKey === "") return "validationErr_regFeeRequired";
    if (form.registrationFee.optionKey === "other") {
        if (!numericCheck(optionValueNoSep) || !numericMinCheck(optionValueNoSep, 0) || !integerCheck(optionValueNoSep)) return "validationErr_regFeeInvalid";
        if (!lengthMaxCheck(optionValueNoSep, LEN_REGFEE)) return "validationErr_regFeeMaxLen";
        if (!numericMaxCheck(optionValueNoSep, MAX_REGFEE)) return "validationErr_regFeeTooLarge";
        if (!divisibleCheck(optionValueNoSep, 2000)) return "validationErr_regFee2000";
    }

    return null;
 };

const natureValidator = form => { return null; };
const dateOfInstrumentValidator = form => { 
    const { dateOfInstrument } = form;

    if (optional(dateOfInstrument)) return null;

    if (!dateCheck(dateOfInstrument))
        return "validationErr_invalidDateOfInstr";

    return null; 
};

const considerationValidator = form => { 
    const { optionKey, optionValue } = form.consideration;
    const optionValueNoSep = optionValue.replace(/,/g, '');
    let result = null;
    if (optionKey === "amount") {
        if (lengthExactCheck(optionValueNoSep, 0))
            result = null;
        else if (numericCheck(optionValueNoSep)) {
            if (!numericMinCheck(optionValueNoSep, 0) || (optionValueNoSep.indexOf(".") >= 0 && (optionValueNoSep.substring(optionValueNoSep.indexOf(".")+1).length > 2)))
                result = { "0" : "validationErr_considerationInvalid" };
            else if (!numericMaxCheck(optionValueNoSep, MAX_CONSIDERATION)) 
                result = { "0" : "validationErr_considerationTooLarge" };
        } else 
            result = { "0" : "validationErr_considerationInvalid" };
    }

    return result; 
};

const memorialNumberValidator = form => { return null; }; // no specific check other than max length 
const stampOfficeRefValidator = form => { return null; }; // no specific check other than max length 
const stampDutyValidator = form => { return null; }; // no specific check other than max length 

const prnValidator = form => { 
    let pv = {};
    let previousPRNs = {};
    for (let i=0; i<form.prn.length; i++) {
        if (lengthExactCheck(form.prn[i], 0)) continue; 
        
        if (form.prn[i] === "-") continue;

        if (!lengthExactCheck(form.prn[i], LEN_PRN) || !mfiHelper.verifyMfiCheckDigit(form.prn[i])) {
            pv[i] = "validationErr_prnFormat";
            continue;
        }

        if (previousPRNs[form.prn[i]] !== undefined) {
            pv[i] = "validationErr_duplicatePrn";
            pv[previousPRNs[form.prn[i]]] = "validationErr_duplicatePrn";
        }            

        previousPRNs[form.prn[i]] = i;
    }
    return Object.keys(pv).length === 0 ? null : pv; 
};

const prnTextValidator = form => { return null; };
const undividedSharesValidator = form => { return null; };
const partiesValidator = form => { 
    let {parties} = form;
    let pv = {};

    if (parties.length === 0) return null;

    for (let i=0; i<parties.length; i++) {
        let p = parties[i]

        if ( // empty object
            lengthExactCheck(p.partyNameEng, 0)
            && lengthExactCheck(p.partyNameChi, 0)
            && lengthExactCheck(p.idType, 0)
            && lengthExactCheck(p.idNum, 0)
            && lengthExactCheck(p.statusType, 0)
            && lengthExactCheck(p.capacity, 0)
            && lengthExactCheck(p.shares, 0)
        )
            continue;

        if (lengthExactCheck(p.partyNameEng, 0) && lengthExactCheck(p.partyNameChi, 0)) 
            pv[i.toString()] = "validationErr_nameOfPartiesRequired";
        // else if (p.idType === "Others")
            // pv[i.toString()] = "validationErr_idOthersRequired";
        // else if (p.idType !== "N/A" && p.idType !== "" && lengthExactCheck(p.idNum, 0))
            // pv[i.toString()] = "validationErr_idNumRequired";
    }

    return Object.keys(pv).length === 0 ? null : pv;
};
const declarationDateValidator = form => { 
    const m = moment;
    const sp = Constant;
    const { declarationDate } = form;

    if (optional(declarationDate)) return null;

    if (!dateCheck(declarationDate))
        return "validationErr_invalidCertDate";

    if (parseInt(m(declarationDate, sp.DATE_FORMAT).year()  / 100) !== 20)
        return "validationErr_certYear";

    return null; 
};

const dateOfInstrumentRemarksValidator = form => {
    const {dateOfInstrumentRemarks} = form;

    if (!lengthExactCheck(dateOfInstrumentRemarks, 0) && !lengthMaxCheck(dateOfInstrumentRemarks, LEN_DATE_OF_INSTRU_REMARKS))
        return "validationErr_dateOfInstrRemarksMaxLen";

    return null;
}
const certOfficerValidator = form => { return null; };
const certDelegationValidator = form => { return null; };

export const attributeValidators = {
    solicitorsCode: solicitorsCodeValidator,
    registrationFee: registrationFeeValidator,
    nature: natureValidator,
    dateOfInstrument: dateOfInstrumentValidator,
    consideration: considerationValidator,
    memorialNumber: memorialNumberValidator,
    stampOfficeRef: stampOfficeRefValidator,
    stampDuty: stampDutyValidator,
    prn: prnValidator,
    prnText: prnTextValidator, 
    undividedShares: undividedSharesValidator, 
    parties: partiesValidator,
    declarationDate: declarationDateValidator, 
    dateOfInstrumentRemarks: dateOfInstrumentRemarksValidator,
    certOfficer: certOfficerValidator,
    certDelegation: certDelegationValidator,
};

export default attributeValidators;