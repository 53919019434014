import { Expose } from 'class-transformer';

export class OrderDetail {
    constructor(input: Partial<OrderDetail> = {}) {
        Object.assign(this, input);
    }

    @Expose({ name: 'itemid' })
    itemId?: string;

    @Expose({ name: 'qty' })
    quantity?: number;

    @Expose({ name: 'unitprice' })
    unitPrice?: number;

    amount: number;
}
