import React from 'react';
import { Payment, TextKey } from '../../../payment.proxy';
import '../payby.css';

export function SubmitButton(props: Props) {
    const { onSubmit, disabled } = props;
    return (
        <div className="col-xs-6 col-md-6">
            <button
                type="button"
                className={`btn btn-primary btn-block${disabled ? ' disabled' : ''}`}
                data-dismiss="modal"
                onClick={onSubmit}
                id="btnPay"
                disabled={disabled}
            >
                <span id="btnPayLable">{Payment.getText(TextKey.PAY)}</span>{' '}
                <span className="glyphicon glyphicon-chevron-right"></span>
            </button>
        </div>
    );
}

type Props = {
    onSubmit: () => void;
    disabled: boolean;
};
