import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'reflect-metadata';
import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
// import store from "./store";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import WfpCoreStore from './layout/core/reducers/wfpCoreStore';
import Root from './layout/root';
import MaintenanceScreen from './layout/maint';
import './css/index.css';
import './css/spinner.css';

import logoEn from './resources/lr_branding_en.png';
import logoTc from './resources/lr_branding_tc.png';
import logoSc from './resources/lr_branding_sc.png';
import { WebsiteLocale } from './layout/core/website-locale.enum';

const Body = () => {
    const { curLanguage } = useSelector((store) => store.session);

    var isIE = false || !!document.documentMode;
    let logo = logoEn;
    switch (curLanguage) {
        case WebsiteLocale.TRADITIONAL_CHINESE:
            logo = logoTc;
            break;
        case WebsiteLocale.SIMPLIFIED_CHINESE:
            logo = logoSc;
            break;
        case WebsiteLocale.ENGLISH:
            logo = logoEn;
            break;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const queryMaint = urlParams.get('__________maintenance');
    const queryHc = urlParams.get('hc');

    return (window.WFP_MAINTENANCE_MODE || queryMaint) && !queryHc ? (
        <MaintenanceScreen isIE={isIE} logo={logo} />
    ) : (
        <Root isIE={isIE} logo={logo} />
    );
};

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            {/* <WfpContextWrapper> */}
            <Provider store={WfpCoreStore}>
                <Body />
            </Provider>

            {/* </WfpContextWrapper> */}
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);

axios.defaults.headers.common['Content-Type'] = 'application/json';
