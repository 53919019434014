import { PaymentMethod } from '../../../../../gcis-payment/enums';

const paymentMethods: Record<PaymentMethod, string> = {
    PPS: '网上缴费灵',
    JCB: '信用卡 (JCB)',
    MASTER_CARD: '信用卡 (万事达卡)',
    UNION_PAY: '信用卡 (银联)',
    VISA: '信用卡 (VISA)',
    E_CHEQUE: '电子支票',
    ANDROID_PAY: 'Google Pay',
    APPLE_PAY: 'Apple Pay',
    FPS: '转数快',// 202309
};

export const GCIS_PAYMENT_SC = {
    PRE_PAYMENT_MESSAGE:
        '你已选择网上付款(缴费灵／信用卡／转数快／流动支付)，请按下方的「继续」按钮确认继续。',
    METHOD_SUMMARY: '缴费灵／信用卡／转数快／流动支付 (网上递交申请)',
    MODAL_HEADER: '网上付款',
    ERROR_PAYMENT_EXPIRED: '操作逾时，请稍后再试。',
    ERROR_PAYMENT_REJECTED: '你的付款已被支付系统拒绝，请联络你的发卡银行。\n\n详情：{0}',
    ERROR_PAYMENT_CANCELLED: '付款已被取消，请稍后再试。\n\n详情：{0}',
    ERROR_PAYMENT_CANCELLED_USER: '付款已被取消，请稍后再试。',
    ERROR_NO_PAYMENT_METHODS_AVAILABLE: '网上缴费服务暂停，你仍可以使用电子支票缴费或稍后再试。',
    PAYMENT_METHODS: paymentMethods,
    PAYMENT_DISABLED_BROADCAST_TITLE: '网上缴费方式暂停使用 - {0}',
    PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER:
        '由于系统维护，网上缴费方式 - {0} 在以下时段将不能使用：',
    PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER:
        '在上述期间，你仍可以使用其他网上缴费服务缴费。你亦可储存已输入的资料，然后在系统维护时段之后使用 {0} 进行缴费。如有查询，请致电土地注册处客户服务热线 3105 0000。',
    ALL_PAYMENT_DISABLED_BROADCAST_TITLE: '网上缴费方式暂停使用',
    ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER:
        '由于系统维护，网上缴费方式在以下时段将不能使用：',
    ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER:
        '在上述期间，你仍可以储存已输入的资料，然后在系统维护时段之后进行缴费。如有查询，请致电土地注册处客户服务热线 3105 0000。',
    PAYMENT_DISABLED_MESSAGE_TEMPLATE:
        '网上付款方式 - {0} 暂时未能提供服务。你仍可以使用其他网上付款方式缴费或稍后再试。如有查询，请致电土地注册处客户服务热线 3105 0000。',
        
    // 202309
    FPS_PAYMENT_MOBILE_FOOTER: "请按「开启流动应用程式」按钮直接在流动装置上开启银行流动应用程式或电子钱包。",
    FPS_PAYMENT_MOBILE_BUTTON: "开启流动应用程式",
    FPS_PAYMENT_SEPARATE: "- 或 -",
    FPS_PAYMENT_FOOTER: "请使用支援转数快二维码付款的银行流动应用程式或电子钱包，扫描二维码以进行网上付款。\n\n二维码将于3分钟后失效。",
};
