import { useDispatch, useSelector } from 'react-redux';
import { upsertPaymentData } from '../redux/slice';
import { useGetTotalFee } from './use-get-total-fee.hook';
import { useGetPaymentLocale } from './use-get-payment-locale.hook';
import { PaymentData } from '../entities';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';

export function useGetPaymentData() {
    const cachedPaymentData = useSelector((state: RootState) => state.payment.paymentData);
    const dispatch = useDispatch();

    // Hooks are always placed before first premature return
    const locale = useGetPaymentLocale();
    const totalAmount = useGetTotalFee();

    if (cachedPaymentData) return cachedPaymentData;

    const paymentData = PaymentData.fromEssentials({ totalAmount, locale });
    dispatch(upsertPaymentData(paymentData));
    return paymentData;
}
