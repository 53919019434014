import { CardType, PaymentMethod, PaymentMethodCode, PaymentMethodSubtype } from '../enums';
import { Payment } from '../payment.proxy';
import { AvailablePaymentMethods } from './available-payment-methods.entity';
import { OnePointToNote } from './one-point-to-note.entity';

export class PaymentMethodData {
    constructor(input: Partial<PaymentMethodData> = {}) {
        Object.assign(this, input);
    }
    code: PaymentMethodCode;

    subType: PaymentMethodSubtype;

    active: boolean; // Y / N

    pointsToNote: OnePointToNote[];

    card?: CardType;

    static fromAvailablePaymentMethod(pm: AvailablePaymentMethods): PaymentMethodData[] {
        const { code, subType, active, pointsToNote, supportedCards = [] } = pm;
        if (subType !== PaymentMethodSubtype.CREDIT_CARD) {
            return [
                new PaymentMethodData({
                    code,
                    subType,
                    active,
                    pointsToNote,
                }),
            ];
        }
        return supportedCards.map(
            (card) =>
                new PaymentMethodData({
                    code,
                    subType,
                    active,
                    pointsToNote,
                    card,
                })
        );
    }

    isCreditCard(): boolean {
        return this.subType === PaymentMethodSubtype.CREDIT_CARD;
    }

    getLabel(): string {
        if (this.isCreditCard()) {
            return Payment.getText(this.card.toLowerCase());
        } else {
            return Payment.getText(this.getId());
        }
    }

    getId(): string {
        if (this.isCreditCard()) {
            return `${this.subType}-${this.card}`.toLowerCase();
        } else {
            return this.subType.toLowerCase();
        }
    }

    getPaymentMethod(): PaymentMethod | void {
        switch (this.subType) {
            case PaymentMethodSubtype.ANDROID_PAY:
                return PaymentMethod.ANDROID_PAY;
            case PaymentMethodSubtype.APPLE_PAY:
                return PaymentMethod.APPLE_PAY;
            case PaymentMethodSubtype.E_CHEQUE:
                return PaymentMethod.E_CHEQUE;
            case PaymentMethodSubtype.MASTER_CARD:
                return PaymentMethod.MASTER_CARD;
            case PaymentMethodSubtype.PPS:
                return PaymentMethod.PPS;
            case PaymentMethodSubtype.VISA:
                return PaymentMethod.VISA;
            case PaymentMethodSubtype.FPS:
                return PaymentMethod.FPS;// 202309
        }
        switch (this.card) {
            case CardType.JCB:
                return PaymentMethod.JCB;
            case CardType.MASTER_CARD:
                return PaymentMethod.MASTER_CARD;
            case CardType.UNION_PAY:
                return PaymentMethod.UNION_PAY;
            case CardType.VISA:
                return PaymentMethod.VISA;
        }
    }
}
