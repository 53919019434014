import { instance } from '../../instance';
import { plainToInstance } from 'class-transformer';
import { PayByWalletInput as Input } from './input';
import { PayByWalletResponse as Response } from './response';
import { PaymentApiUrl } from '../../../constants';

const url = PaymentApiUrl.PAY_BY_WALLET;

export async function payByWallet(input: Input): Promise<Response> {
    const type = input.extractType();
    const plain = instance.post(`${url}/${type}`, input);
    return plainToInstance(Response, plain);
}
