import { Expose } from 'class-transformer';
import { Easting, Latitude, Longitude, Northing } from '../../types';

export class GeoSpatialInformation {
    @Expose({ name: 'Northing' })
    northing: Northing;

    @Expose({ name: 'Easting' })
    easting: Easting;

    @Expose({ name: 'Longitude' })
    longitude: Longitude;

    @Expose({ name: 'Latitude' })
    latitude: Latitude;
}
