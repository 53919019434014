import { createContext } from 'react';

export const FieldContext = createContext(
    { 
        datumId: "",
        fvalue: "",
        setFValue: (v: any) => { },
        completed: false,
        setCompleted: (v: any) => { },
        onValChange: (v: any) => { },
        onValBlur: (v: any) => { }
    }
);