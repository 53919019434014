import React, { useEffect, useContext, useState, createContext, forwardRef } from 'react';
import {
	lengthMinCheck, lengthMaxCheck, lengthRangeCheck, lengthExactCheck, decimalMinCheck, decimalMaxCheck, decimalRangeCheck, optional,
	isChinese, isEnglish, numericCheck, integerCheck, naturalNumberCheck, HKIDCheck, emailCheck, dateCheck, fileSizeMaxCheck, fileTypeCheck, timeCheck
} from '../../utilities/formDetailAttributeValidator';
import { isiOS } from '../../utilities';
import { useSelector, useDispatch } from 'react-redux';
import { dialogHelper } from './dialogHelper';
import { updateFormData, updateFileList, updateValidation, submitSigning, submitSigningPdf,
		 submitIAMSmart, submitIAMSmartPdf, getIAMSmartStatus, getIAMSmartStatusPdf, removeFormData,
		 callWithRecaptcha, restorePreviousPdf, removeSignedField,
		} from '../core/reducers/formDetail';
import {getCaptchaChallenge, setCaptchaChallengeAnswer} from '../core/reducers/wfpSessionReducer';
import iasIconDark from '../../resources/ias-icon-dark.png';
import { FaSave,FaCaretUp,FaCaretDown } from 'react-icons/fa';
import { LookupAddressSuggestion } from '../../ogcio-address-lookup';
import {FieldContext, CompoundFieldContext} from './context';

import DatePicker, { registerLocale } from "react-datepicker";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import lo, { identity, lastIndexOf } from 'lodash';

import moment from 'moment';
// import 'moment/locale/zh-cn';
// import en-gb from 'moment/locale/en-gb';
import en from 'date-fns/locale/en-GB';
import ch from 'date-fns/locale/zh-TW';
import zh from 'date-fns/locale/zh-CN';

import iconAudio from '../../resources/icon_audio.gif';
import iconRefresh from '../../resources/icon_refresh.gif';

import * as Constant from '../../utilities/constant';
import SysParam from '../../utilities/sysParam';

import { FaExclamationTriangle, FaFileUpload, FaPaperclip, FaTimesCircle, FaCalendarAlt, FaQuestionCircle, FaRegClock, FaSpinner, FaCheck, FaTimes } from 'react-icons/fa';

import { MessagePane, ExpandableMessagePane, RecordContext, FormContext, SectionContext, StepContext } from './layoutElements';
import { RootContext } from '../root';
import pkiHelper from './utilities/pkiHelper';

import { useSaveForm } from './hooks/use-save-form.hook';

const MAX_LENGTH_CHN_NAME = 16;
const MAX_LENGTH_ENG_NAME = 35;


registerLocale('en', en);
registerLocale('ch', ch);
registerLocale('zh', zh);

export const DEFAULT_FIELD_IDS = {
	ID_PASSWORD: "password",
	ID_PERSONAL_ENG_NAME: "engName",
	ID_PERSONAL_CHN_NAME: "chnName",
	ID_PERSONAL_HKID: "hkid",
	ID_PERSONAL_IDTYPE: "idtype",
	ID_PERSONAL_IDNUM: "idnum",
	ID_PERSONAL_GENDER: "gender",
	ID_PERSONAL_SALUTATION: "salutation",
	ID_PERSONAL_HOME_PHONE: "homePhone",
	ID_PERSONAL_WORK_PHONE: "workPhone",
	ID_PERSONAL_MOBILE_PHONE: "mobilePhone",
	ID_PERSONAL_CONTACT_PHONE: "contactPhone",
	ID_PERSONAL_FAX: "fax",
	ID_PERSONAL_EMAIL: "email",
};

export const Validators = {
	noOp: () => null,
	required: v => !optional(v) ? null : { key: "validation_required", args: [] },
	fileRequired: v => !optional(v) ? null : { key: "validation_file_required", args: [] },
	lengthMax: (v, len) => lengthMaxCheck(v, len) ? null : { key: "validation_lengthMax", args: [len] },
	lengthMin: (v, len) => lengthMinCheck(v, len) ? null : { key: "validation_lengthMin", args: [len] },
	lengthRange: (v, lenMin, lenMax) => lengthRangeCheck(v, lenMin, lenMax) ? null : { key: "validation_lengthRange", args: [lenMin, lenMax] },
	lengthExact: (v, len) => lengthExactCheck(v, len) ? null : { key: 'validation_lengthExact', args: [len] },
	isChinese: v => optional(v) || isChinese(v) ? null : { key: "validation_chineseOnly", args: [] },
	isEnglish: v => optional(v) || isEnglish(v) ? null : { key: "validation_englishOnly", args: [] },
	isChecked: v => (v !== undefined && v) ? null : { key: "validation_required", args: [] },
	isMultiSelect: v => (v&&v.length > 1) ? { key: "validation_multiSelected", args: [] } : null,
	decimalMax: (v, decimalVal) => (numericCheck(v) && decimalMaxCheck(v, decimalVal)) ? null : { key: "validation_decimalMax", args: [decimalVal] },
	decimalMin: (v, decimalVal) => (numericCheck(v) && decimalMinCheck(v, decimalVal)) ? null : { key: "validation_decimalMin", args: [decimalVal] },
	decimalRange: (v, decimalMin, decimalMax) => decimalRangeCheck(v, decimalMin, decimalMax) ? null : { key: "validation_decimalRange", args: [decimalMin, decimalMax] },
	numberonly: v => optional(v) || (numericCheck(v)) ? null : { key: "validation_numberOnly", args: [] },
	integeronly: v => optional(v) || (numericCheck(v) && integerCheck(v)) ? null : { key: "validation_integerOnly", args: [] },
	naturalNumberOnly: v => optional(v) || (naturalNumberCheck(v)) ? null : { key: "validation_integerOnly", args: [] },
	phonefax: v => optional(v) || v.match(/^([\+\(\)\-\ 0-9]+)$/) !== null ? null : { key: "validation_phonenumber", args: [] },
	faxnumber: v => optional(v) || v.match(/^([\+\(\)\-\ 0-9]+)$/) !== null ? null : { key: "validation_faxnumber", args: [] },
	isAlphanumeric: v => optional(v) || v.match(/^([0-9A-Za-z]+)$/) !== null ? null : { key: "validation_alphanumeric", args: [] },
	isAlphanumericWithHyphen: v => optional(v) || v.match(/^([0-9A-Za-z-]+)$/) !== null ? null : { key: "validation_alphanumericWithHyphen", args: [] },
	isNumericWithHyphen: v => optional(v) || v.match(/^([0-9-]+)$/) !== null ? null : { key: "validation_numericWithHyphen", args: [] },
	isNumericWithComma: v => optional(v) || v.match(/^([0-9,]+)$/) !== null ? null : { key: "validation_numericWithComma", args: [] },
	isPaymentAmount: v => optional(v) || v.match(/^\d{1,3}(?:,\d{3})*(?:\.\d{0,2})?$|^\d+(?:\.\d{0,2})?$/) !== null ? null : { key: "validation_paymentAmount", args: [] },
	isHKID: v => optional(v) ? null : HKIDCheck(v),
	isEmail: v => optional(v) ? null : emailCheck(v),
	dateCheck: (v, customDateFormat=Constant.DATE_FORMAT) => optional(v) || dateCheck(v,customDateFormat) ? null : { key: "validation_invalidDate", args: [customDateFormat] },
	timeCheck: v => optional(v) || timeCheck(v) ? null : { key: "validation_invalidTime", args: [] },
	onOrAfterToday: v => optional(v) || moment(v, Constant.DATE_FORMAT).isSame(moment(), "day") || moment(v, Constant.DATE_FORMAT).isAfter(moment(), Constant.DATE_FORMAT) ? null : { key: "validation_onOrAfterToday", args: [] },
	onOrBeforeToday: v => optional(v) || moment(v, Constant.DATE_FORMAT).isSame(moment(), "day") || moment(v, Constant.DATE_FORMAT).isBefore(moment(), Constant.DATE_FORMAT) ? null : { key: "validation_onOrBeforeToday", args: [] },
	fileSizeMax: (v, limit, limitStr) => optional(v) ? null : fileSizeMaxCheck(v, limit, limitStr),
	fileTypeIncluded: (v, filter) => optional(v) ? null : fileTypeCheck(v, filter),
	isFutureEnough: (v, days) => optional(v) || moment({hours:0}).diff(moment(v, Constant.DATE_FORMAT), 'days') <= (-1*days) ? null : { key: "validation_futureEnough", args:[days]},
}

export const CommonValidators = {
	[DEFAULT_FIELD_IDS.ID_PERSONAL_CHN_NAME]:
		({ required, maxlength }) => [
			v => required ? Validators.required(v) : Validators.noOp(),
			v => maxlength ? Validators.lengthMax(v, maxlength) : Validators.lengthMax(v, MAX_LENGTH_CHN_NAME),
			v => Validators.isChinese(v),
		],
	[DEFAULT_FIELD_IDS.ID_PERSONAL_ENG_NAME]:
		({ required, maxlength }) => [
			v => required ? Validators.required(v) : Validators.noOp(),
			v => maxlength ? Validators.lengthMax(v, maxlength) : Validators.lengthMax(v, MAX_LENGTH_ENG_NAME),
			v => Validators.isEnglish(v),
		],
}

export const FieldBase = (props) => {
	const { id, validation, required, notes, children, onValChange, onValBlur, hideWhen, label, maxlength, bare, noAsterisk } = props;
	const { stepId } = useContext(StepContext);
	const { sectionId } = useContext(SectionContext);
	const { formLocalization, readOnly, activeFieldIds, registerValidationRule, deregisterValidationRule, updateAndValidate } = useContext(FormContext);
	const { notifyValidationError } = useContext(CompoundFieldContext);
	const { recordListId, index } = useContext(RecordContext);
	const { language, curLanguage } = useSelector(store => store.session);
	const { formData, validationErr, completedFields } = useSelector(store => store.formDetail, lo.isEqual);
	const dispatch = useDispatch();

	// const datumId = stepId + (sectionId ? "." + sectionId : '') + (recordListId ? '.' + recordListId + '.' + index : '' ) + '.' + id;

	// const [fvalue, setFValue] = useState(formData[datumId] && formData[datumId] != null ? formData[datumId] : "");
	const [fvalue, setFValue] = useState(formData[id] && formData[id] != null ? formData[id] : "");
	const [completed, setCompleted] = useState(completedFields[id]); // completed if fvalue is not empty
	// const [valid, setValid] = useState(false);

	const validate = (fvalue) => {
		// console.log("in validate [" + id + "], fvalue="+fvalue);
		if (completed) {
			// console.log("in validate, completed [" + id + "], fvalue="+fvalue);

			const validationResults = updateAndValidate(id, fvalue);
		}
	}

	useEffect(() => {
		if (hideWhen === true) {
			dispatch(removeFormData({ [id]: undefined }));
		}
	}, [hideWhen]);

	useEffect(() => {
		// console.log("useEffect formData["+id+"] triggered, ="+formData[id]);
		setFValue(formData[id])
		setCompleted(!!formData[id]);
	}, [formData[id]]);
	useEffect(() => {
		// window.requestAnimationFrame(() => {
		// console.log("useEffect fvalue ["+id+"] triggered, fvalue="+fvalue +",completed="+completed);
		if (completed) {
			validate(fvalue);
		}
		// });
	}, [fvalue, completed]);
	useEffect(() => {
		registerValidationRule({ datumId: id, validations: validation });

		return () => {
			deregisterValidationRule({ datumId: id });
		}

	}, [required,]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (props.readonly || readOnly) {
			activeFieldIds.delete(id);
		}
		else {
			activeFieldIds.add(id);
		}
		return () => { activeFieldIds.delete(id) };
	})

	const fieldValidationErrs = validationErr[id] ? validationErr[id].filter(ve => ve) : []; // ignore undefined / null entries
	const invalid = fieldValidationErrs.length > 0;


	return (
		<FieldContext.Provider value={{ datumId: id, fvalue: fvalue, setFValue: setFValue, completed: completed, setCompleted: setCompleted, readOnly: readOnly, onValChange: onValChange, onValBlur: onValBlur }}>
			{

				(hideWhen === undefined || hideWhen === false)
					?
					(bare === true
						?
						<>{children}</>
						:
						<div id={id + "_panel"} className={"field form-group " + (required && !noAsterisk ? "required " : "") + (invalid ? "error " : (!readOnly && completed ? "completed " : ""))}>
							<label htmlFor={id} className="sr-only">
								{required ? <div id={id + "_label"} className="required sr-only">{language.validation_required}</div> : null}
								{notes ? <MessagePane>{notes}</MessagePane> : null}
								{label}
							</label>
							{children}
							<div id={id + "_errors"} className="input-errors">
								{
									invalid && !notifyValidationError
										? fieldValidationErrs.map((ve, i) => (ve.key !== "") ? <div key={id + "_errors_" + i} className="text-danger "><FaExclamationTriangle /><span className="sr-only"> {language.validationError}</span> {formLocalization.formatString(formLocalization[ve.key], ...ve.args)}</div> : null)
										: null
								}
							</div>
						</div>
					)
					: null
			}
		</FieldContext.Provider>
	);
}

export const Field = {
	Signature: (props) => {
		const SIGNED_VALUE = "signed";
		const { id, label, required, formCode, onComplete, onError, disabled, validateName, validateIdNum, transparentLabel } = props;
		const { showSignECertDialog, showSignEIDDialog, showErrorDialog, showSignEIDConfirmDialog } = dialogHelper;
		const formContext = useContext(FormContext);
		const { recaptcha } = useContext(RootContext);
		const fieldContext = useContext(FieldContext);
		const { language } = useSelector(store => store.session, lo.isEqual);
		const { formData, signedFieldsFromData } = useSelector(store => store.formDetail, lo.isEqual);
		const dispatch = useDispatch();

		const validationRules = [
			// v => required && v !== "signed" ? {key:"validation_required", args:[]} : null,
			v => required ? Validators.required(v) : Validators.noOp(),
			// v => Validators.lengthExact(v, SIGNED_VALUE.length),
			v => validateName && v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_signerNameMismatch") ? {key:"validation_signature_signerNameMismatch", args:[]} : null,
			v => validateIdNum && v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_signerIdMismatch") ? {key:"validation_signature_signerIdMismatch", args:[]} : null, 
			v => v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_nonPersonal") ? {key:"validation_signature_nonPersonal", args:[]} : null, 
			v => v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_nonOrganisation") ? {key:"validation_signature_nonOrganisation", args:[]} : null, 
			v => v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_signerOrgNameMismatch") ? {key:"validation_signature_signerOrgNameMismatch", args:[]} : null, 
			v => v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_signerOrgAuthNameMismatch") ? {key:"validation_signature_signerOrgAuthNameMismatch", args:[]} : null, 
			v => v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_signerOrgIdNumMismatch") ? {key:"validation_signature_signerOrgIdNumMismatch", args:[]} : null, 
			v => v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_signerOrgBRNMismatch") ? {key:"validation_signature_signerOrgBRNMismatch", args:[]} : null, 
			v => v && v.valErrs && v.valErrs != [] && v.valErrs.includes("validation_signature_signerOrgCICRMismatch") ? {key:"validation_signature_signerOrgCICRMismatch", args:[]} : null, 
		];

		useEffect(() => { fieldContext.setFValue(""); });

		const { onSave } = useSaveForm(formContext.form, true);

		const showIAMSmartConfirmPopup = (rtnObj, setFValue, setCompleted) => {
			dispatch(showSignEIDConfirmDialog(
				{
					handleConfirm: (isSameDevice) => {
						// Get QR Code
						let win = null;
						if (!isSameDevice) {
							win = window.open(rtnObj.data.redirectQRURL);
						}
						else {
							win = window.open(rtnObj.data.redirectAPPURI);
						}

						// Polling
						dispatch(getIAMSmartStatusPdf(rtnObj.data.businessID, rtnObj.signField, rtnObj.signatureTimestamp, rtnObj.signedData, rtnObj.token,
							signature => {
								setFValue(signature);
								setCompleted(true); 
								if (onComplete) { onComplete() }
								if (win) {
									win.close();
								}
							},
							r => {
								console.log(r);
								setFValue("");
								setCompleted(false);
								if (onError) { onError(r); }
								if (win) {
									win.close();
								}
							},
							null)
						);

						return true;
					},
					signCode: rtnObj.data.signCode,
					language: formContext.formLocalization,
				})); 
		}

		const initIAMSmart = (setFValue, setCompleted) => { 
			dispatch(
				submitIAMSmartPdf(formCode, id, formContext.form,
					(rtnObj) => { showIAMSmartConfirmPopup(rtnObj, setFValue, setCompleted) },
					r => {
						console.log(r);
						setFValue("");
						setCompleted(false);
						if (onError) { onError(r); }
					},
					(o) => {
						dispatch(showErrorDialog({
							err: o.err, 
							handleClose: () => {
								dispatch(showSignEIDDialog({ 
									handleConfirm: () => {
										initIAMSmart(setFValue, setCompleted,);
										return true;
									},
									nextStep: formContext.nextStep,
									language: language,
								}));
							}
						}, language));
					}
				) 
			);
		} 

		const signECertDialogConfirm = (returnObj, setFValue, setCompleted) => {
			let ecertPub = null;
			try {
				ecertPub = pkiHelper.loadPublicKey(returnObj.ecertPrv.file, returnObj.ecertPin)
			}
			catch (e) { 
				dispatch(showErrorDialog({
					err: language.eCertError
				}, language));
				return false;
			} 
			// ==== code below is for hash signing ===
			// dispatch(
			// 	submitSigning(formCode, id, { file: ecertPub }, returnObj.ecertPrv.file, returnObj.ecertPin, formContext.form,
			// 		() => {
			// 			setFValue(SIGNED_VALUE);
			// 			setCompleted(true);
			// 			// dispatch(setSignPerformed(
			// 			// 	formContext.form.signFieldsTransformFunc ?
			// 			// 		formContext.form.signFieldsTransformFunc(id, formContext.form.submitTransformFunc(formData))
			// 			// 		:
			// 			// 		id
			// 			// 		));
			// 			if (onComplete) { onComplete() }
			// 		},
			// 		r => {
			// 			setFValue("");
			// 			setCompleted(false);
			// 			if (onError) { onError(r) }
			// 		},
			// 	)
			// );
			// ==== code above is for hash signing ===
			dispatch(
				submitSigningPdf(formCode, id, { file: ecertPub }, returnObj.ecertPrv.file, returnObj.ecertPin, formContext.form,
					(signature) => {
						setFValue(signature);
						setCompleted(true); 
						if (onComplete) { onComplete() }
					},
					r => {
						setFValue("");
						setCompleted(false);
						if (onError) { onError(r) }
					},
					(o) => {
						dispatch(showErrorDialog({
							err: o.err, 
							handleClose: () => {
								dispatch(showSignECertDialog({ 
									handleConfirm: returnObj => signECertDialogConfirm(returnObj, setFValue, setCompleted),
									language: language,
									picsOnly: formCode === "COAP",
								}));
							},
						},
						language));
					}
				)
			);
			
			// );


			return true;
		}; 
		
		const urlParams = new URLSearchParams(window.location.search);
		const queryHc = urlParams.get("hc");

		const disableIAMSmart = 
			// either (
			(window.WFP_CONFIG.DISABLE_IAMSMART  // condition 1: flag is enabled
			&& (!window.WFP_CONFIG.DISABLE_IAMSMART_FROM || new Date() > window.WFP_CONFIG.DISABLE_IAMSMART_FROM) // condition 2: from date is not specified or today falls after the scheduled from date
			&& (!window.WFP_CONFIG.DISABLE_IAMSMART_TO || new Date() < window.WFP_CONFIG.DISABLE_IAMSMART_TO)) // condition 3: to date is not specified or today falls before the scheduled to date
			// ) OR condition 4: iAM Smart is disabled for this specific form
			|| (formContext.form.disableIAMSmart && formContext.form.disableIAMSmart(formData)) ;
		if (queryHc) disableIAMSmart = false; // For health check case

		const enableSignLater = !disabled && formContext.form.enableSignLater && formContext.form.enableSignLater(formData)

		const shouldShowSignLater = id => {
			let shouldShow = true;
			
			if (!enableSignLater) return false;
			
			if (signedFieldsFromData) {
                Object.keys(signedFieldsFromData).forEach(k=>{
                    if (k === id && signedFieldsFromData[k]) {
						shouldShow = false;
					}
                })
            }
			
			return shouldShow;
		}

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange, onValBlur }) => (
					<div className={(shouldShowSignLater(id) && fvalue)?"d-flex ":""}>
						{label && !transparentLabel ?
							<div id={id} className={"control-label signature " + (fvalue)}>
								{label}&emsp;
							</div>
							: <div id={id} className={"control-label display-hidden signature " + (fvalue)}></div>
						}
						{!fvalue || fvalue == null ?
							<div className="signature-button-div">
								<button
									id={"eCertSignBtn_" + id} type="button" className="btn normalButton primaryButton eCertButton"
									disabled={disabled}
									onClick={() => dispatch(showSignECertDialog({
													handleConfirm: returnObj => signECertDialogConfirm(returnObj, setFValue, setCompleted),
													language: language,
													picsOnly: formCode === "COAP",
												})) 
										}> {language.signECert}
								</button>
								<div className="ias-sign-div"> 
									<button id={"iasSignBtn_" + id} type="button" disabled={disableIAMSmart || disabled} className="btn normalButton primaryButton iasSignButton"
										onClick={() => disableIAMSmart ? true : dispatch(showSignEIDDialog({ 
											handleConfirm: () => {
												initIAMSmart(setFValue, setCompleted,);
												return true;
											},
											nextStep: formContext.nextStep,
											language: language,
										}))}>
											<div className="d-flex align-items-center">
												<img src={iasIconDark} alt="" style={{zoom:"0.77"}} />
												<div style={{paddingLeft:"10px"}}>
													{language.signIAS}
												</div>
											</div>
										
									</button>
									<FaQuestionCircle className="ias-sign-more-info" onClick={() => {window.open(SysParam.iAM_SMART_WEBSITE(formContext.formLocalization.getLanguage()))}} />
								</div>
							</div>
							:
							<div style={{ paddingLeft: "20px" }}>
								<div className="signed-div">
									<div className="signed-info-div">
										{!fvalue.valErrs || fvalue.valErrs.length == 0 
											? <>{ fvalue[id]?.signedBy ? language.formatString(language.signedBy, fvalue[id].signedBy) : language.signed } <FaCheck /></>
											: <>{language.signError} <FaTimes /></>
										}
									</div>
									<div role="button" href="#" className="btn-change-signature" onClick={() => {
											setFValue("");
											dispatch(removeSignedField(id));
											dispatch(restorePreviousPdf());
										}}>
										{language.changeSignature}
									</div>
								</div>
								{
									(shouldShowSignLater(id)) ? 
									<div  className="sign-later-div ">
										
										<button
											id={"signlater_" + id} type="button" className="btn normalButton primaryButton"
											disabled={disabled}
											onClick={() => dispatch(onSave) 
												}> <FaSave /> {language.signLater}
										</button>
									</div>
									: null
								}
							</div>
						}
					</div>
				)}</FieldContext.Consumer>
			</FieldBase>
		);
	},
	Label: (props) => {
		const { id, label, sublabel, hideWhen, groupNotes, groupNotesTitle, defaultShowNotes} = props;
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);
		const { language, curLanguage } = useSelector(store => store.session);

		return (hideWhen ? null : 
			<FormContext.Consumer>{({ readOnly }) => (
			<>
				<div id={id + "_label"} className="control-label">
					{label}&emsp;
					{sublabel ?
						<small>{sublabel}&emsp;</small>
						: null
					}
					{groupNotes && !readOnly ?
						<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
						// <FaQuestionCircle className="extra-info-btn" onClick={() => setShowNotes(!showNotes)} />
						:
						null
					}
				</div> 
				{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}
			</>
			)}</FormContext.Consumer>
		);
	},
	Text: (props) => {
		const { id, placeholder, label, sublabel, maxlength, minlength, exactlength, decimalMin, decimalMax, integeronly, alphanumeric, numericHyphen, numericComma, paymentAmount, 
			alphanumericHyphen, phoneNumber, englishonly, chineseonly, required, validation, resize, multiline, hideWhen, numberonly, naturalNumberOnly, phonefax, faxnumber,
			prepend, append, groupNotes, groupNotesTitle, defaultShowNotes, transparentLabel
		} = props;
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);
		const [triggerResize, setTriggerResize] = useState(false);
		const { registerValidationRule } = useContext(FormContext);
		const { language, curLanguage } = useSelector(store => store.session);

		// const validationRules = [
		const getValidationRules = ({ maxlength, minlength, exactlength, decimalMin, decimalMax, integeronly, alphanumeric, numericHyphen, numericComma, paymentAmount,
			alphanumericHyphen, phoneNumber, englishonly, chineseonly, required, validation, resize, multiline, hideWhen, numberonly, naturalNumberOnly, phonefax, }) => [
				v => required ? Validators.required(v) : Validators.noOp(),
				...( // if both max and min length defined, use the range validator instead
					(maxlength && minlength)
						?
						[v => Validators.lengthRange(v, minlength, maxlength)]
						:
						[
							v => maxlength ? Validators.lengthMax(v, maxlength) : Validators.noOp(),
							v => minlength ? Validators.lengthMin(v, minlength) : Validators.noOp(),
						]
				),
				v => exactlength ? Validators.lengthExact(v, exactlength) : Validators.noOp(),
				...( // if both max and min val defined, use the range validator instead
					(decimalMin !== undefined && decimalMax !== undefined)
						?
						[v => Validators.decimalRange(v?.replaceAll(',',''), decimalMin, decimalMax)]
						:
						[
							v => decimalMax ? Validators.decimalMax(v?.replaceAll(',',''), decimalMax) : Validators.noOp(),
							v => decimalMin ? Validators.decimalMin(v?.replaceAll(',',''), decimalMin) : Validators.noOp(),
						]
				),
				v => integeronly ? Validators.integeronly(v) : Validators.noOp(),
				v => englishonly ? Validators.isEnglish(v) : Validators.noOp(),
				v => chineseonly ? Validators.isChinese(v) : Validators.noOp(),
				v => alphanumeric ? Validators.isAlphanumeric(v) : Validators.noOp(),
				v => alphanumericHyphen ? Validators.isAlphanumericWithHyphen(v) : Validators.noOp(),
				v => numberonly ? Validators.numberonly(v) : Validators.noOp(),
				v => numericHyphen ? Validators.isNumericWithHyphen(v) : Validators.noOp(),
				v => numericComma ? Validators.isNumericWithComma("" + v) : Validators.noOp(),
				v => paymentAmount ? Validators.isPaymentAmount("" + v) : Validators.noOp(),
				v => phonefax ? Validators.phonefax(v) : Validators.noOp(),
				v => faxnumber ? Validators.faxnumber(v) : Validators.noOp(),
				v => naturalNumberOnly ? Validators.naturalNumberOnly(v) : Validators.noOp(),
				...(validation ?? [])
			];

		const [validationRules, setValidationRules] = useState(getValidationRules(props));

		useEffect(() => {
			const newRules = getValidationRules({
				maxlength, minlength, exactlength, decimalMin, decimalMax, integeronly, alphanumeric, numericHyphen, numericComma, paymentAmount,
				alphanumericHyphen, phoneNumber, englishonly, chineseonly, required, validation, resize, multiline, hideWhen, numberonly, naturalNumberOnly, phonefax, faxnumber,
			})
			setValidationRules(newRules);
			registerValidationRule({ datumId: id, validations: newRules });

		}, [curLanguage, required]);


		const resizeField = (vtarget) => {
			let val = multiline ? vtarget.value : vtarget.value.replace(/\n/g, "");
			vtarget.value = val;

			vtarget.style.height = "1px";
			vtarget.style.height = (vtarget.scrollHeight + 4) + "px";
		};

		const getOutputStr = fvalue => {
			let outputStr = fvalue;
			if (numericComma && fvalue) {
				outputStr = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(fvalue);
				outputStr = outputStr === 'NaN' ? null : outputStr;
			}
			return outputStr;
		}

		const updateValue = (fvalue) => {
			/* To fix textarea value update - Start */
			const tempId = id.replace('$', '\\$');
			if (document.querySelector('#' + tempId)) {
				document.querySelector('#' + tempId).value = fvalue ?? "";
			}
			/* To fix textarea value update - End */
			return fvalue;
		}

		const getTextAreaElem = () => {
			const tempId = id.replace('$', '\\$');
			const elem = document.querySelector('textarea#' + tempId);
			return elem;
		}
		
		// 2023-10-10 by Jess Hui
		// There is a side effect while using Chinese IME, that the Chinese charater will be overwritten by the update of 'fvalue'.
		// Therefore, 'innerValue' in state will be used as a temp value to store the Chinese input, and the fvalue will be set only when the focus is lost.
		const handleMultiline = v => {return multiline ? v : v.replace(/\n/g, "")}
		const [innerValue, setInnerValue] = useState(formData?.[id]??"");
		const { formData } = useSelector(store => store.formDetail, lo.isEqual);
		useEffect(() => {
			setInnerValue(formData[id]);
		}, [formData[id]]);

		useEffect(() => {
			// After innerValue is applied, the resize function doesn't work in the same scope,
			// it has to be executed after the rendering cycle
			// Also, to avoid crash of the Chinese IME, the active element should not be re-rendered.
			if (document.activeElement != getTextAreaElem()) {
				// Set trigger for resize
				setTriggerResize(true);
			}
		}, [hideWhen, innerValue]);

		useEffect(() => {
			if (triggerResize) {
				const elem = getTextAreaElem();
				if (elem) {
					resizeField(elem);
				}
			}

			setTriggerResize(false);
		}, [triggerResize]);
		// 2023-10-10 by Jess Hui

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange, onValBlur }) => (
					<>
						{label ?
							<div id={id + "_label"} className="control-label">
								{transparentLabel ? null : label}&emsp;
						{sublabel ?
									<small>{sublabel}&emsp;</small>
									: null
								}
								{groupNotes && !readOnly ?
									<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
									// <FaQuestionCircle className="extra-info-btn" onClick={() => setShowNotes(!showNotes)} />
									:
									null
								}
							</div>
							: null
						}
						{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}
						{(props.readonly || readOnly)
							?
							<div className="output-text-div">
								<p className={"output-text " + (append ? "output-text-with-append" : "")}>
									{prepend ? <span className="">{prepend}</span> : null}
									{getOutputStr(fvalue)}
								</p>
								{append ? <div className="input-group-append"><span className="input-group-text">{append}</span></div> : null}
							</div>
							:
							<div className={"input-group "}>
								{prepend ? <div className="input-group-prepend"><span className="input-group-text">{prepend}</span></div> : null}
								<textarea
									id={id} name={id} className={"form-control " + (required ? "required " : "")}
									style={{ resize: resize ?? "none", overflow: "hidden" }}
									value={updateValue(innerValue)}
									placeholder={placeholder} rows={1}
									aria-labelledby={id}

									onBlur={v => {
										setFValue(v.target.value);
										setCompleted(true);
										onValBlur && onValBlur(v.target.value);
									}}
									onChange={v => {
										let val = handleMultiline(v.target.value);
										setInnerValue(val);
										onValChange && onValChange(val);
									}}
									onInput={v => resizeField(v.target)}
								></textarea>
								{append ? <div className="input-group-append"><span className="input-group-text">{append}</span></div> : null}
							</div>
						}
					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		);
	},
	OutputText: (props) => {
		const { id, label, sublabel, value, numerical, hideWhen } = props;

		const validationRules = [];

		let outputStr = value;
		if (numerical && value) {
			outputStr = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
			outputStr = Number.isNaN(outputStr) ? null : outputStr;
		}


		return (
			<FieldBase {...props} validation={validationRules}>
				<>
					{label ?
						<div id={id + "_label"} className="control-label">
							{label}&emsp;
						{sublabel ?
								<small>{sublabel}&emsp;</small>
								: null
							}
						</div>
						: null
					}
					<p className="output-text">{outputStr}</p>
				</>
			</FieldBase>
		);
	},
	Password: (props) => {
		const { placeholder, label, subLabel, maxlength, minlength, exactlength, required, validation, groupNotes, groupNotesTitle, hideWhen } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);

		const validationRules = [
			v => required ? Validators.required(v) : Validators.noOp(),
			...( // if both max and min length defined, use the range validator instead
				(maxlength && minlength)
					?
					[v => Validators.lengthRange(v, minlength, maxlength)]
					:
					[
						v => maxlength ? Validators.lengthMax(v, maxlength) : Validators.noOp(),
						v => minlength ? Validators.lengthMin(v, minlength) : Validators.noOp(),
					]
			),
			v => exactlength ? Validators.lengthExact(v, exactlength) : Validators.noOp(),
			...(validation ?? [])
		];

		const id = props.id ?? DEFAULT_FIELD_IDS.ID_PASSWORD

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange, onValBlur }) => (
					<>
						{label !== "" ?
							<div id={id + "_label"} className="control-label">
								{label ?? language.password}&emsp;
						{subLabel ?
									<small>{subLabel}&emsp;</small>
									: null
								}
							</div>
							: null
						}
						<>
							{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote}>{groupNotes}</ExpandableMessagePane> : null}
						</>
						{readOnly ?
							<p className="output-text">{"•".repeat(fvalue.length)}</p>
							:
							<input type="password" id={id} name={id} className={"form-control " + (required ? "required " : "")} value={fvalue} placeholder={placeholder}
									aria-LabelledBy={id}
								onBlur={v => {
									setFValue(v.target.value);
									setCompleted(true);
									onValBlur && onValBlur(v.target.value);
								}}
								onChange={v => {
									setFValue(v.target.value);
									// setCompleted(false); 
									onValChange && onValChange(v.target.value);
								}}
							/>
						}
					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		);
	},
	TextArea: (props) => {
		const { id, placeholder, label, maxlength, minlength, exactlength, required, validation, hideWhen } = props;

		const validationRules = [
			v => required ? Validators.required(v) : Validators.noOp(),
			...( // if both max and min length defined, use the range validator instead
				(maxlength && minlength)
					?
					[v => Validators.lengthRange(v, minlength, maxlength)]
					:
					[
						v => maxlength ? Validators.lengthMax(v, maxlength) : Validators.noOp(),
						v => minlength ? Validators.lengthMin(v, minlength) : Validators.noOp(),
					]
			),
			v => exactlength ? Validators.lengthExact(v, exactlength) : Validators.noOp(),
			...(validation ?? [])
		];

		const updateValue = (fvalue) => {
			/* To fix textarea value update - Start */
			if (document.querySelector('#' + id)) {
				document.querySelector('#' + id).value = fvalue ?? "";
			}
			/* To fix textarea value update - End */
			return fvalue;
		}

		// 2023-10-10 by Jess Hui
		// There is a side effect while using Chinese IME, that the Chinese charater will be overwritten by the update of 'fvalue'.
		// Therefore, 'innerValue' in state will be used as a temp value to store the Chinese input, and the fvalue will be set only when the focus is lost.
		const [innerValue, setInnerValue] = useState("");
		const { formData } = useSelector(store => store.formDetail, lo.isEqual);
		useEffect(() => {
			setInnerValue(formData[id]);
		}, [formData[id]]);
		// 2023-10-10 by Jess Hui

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, onValChange, onValBlur }) => (
					<>
						<div id={id + "_label"} className="control-label">
							{label}&emsp;
					</div>
						<textarea id={id} name={id} className="form-control" placeholder={placeholder}
							style={{ width: '100%', minHeight: '100px' }}
							value={updateValue(innerValue)}
							onBlur={v => {
								setFValue(v.target.value);
								setCompleted(true);
								onValBlur && onValBlur(v.target.value);
							}}
							onChange={v => {
								setInnerValue(v.target.value);
								// setCompleted(false); 
								onValChange && onValChange(v.target.value);
							}}
						></textarea>
					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		);
	},
	Checkbox: (props) => {
		const { id, label, sublabel, checkedValue, required, validation, children, onClick, strike, groupNotes, groupNotesTitle, defaultShowNotes, hideWhen, noAsterisk,
			    confirmUncheck, style } = props;
		const { formData } = useSelector(store => store.formDetail, lo.isEqual);
		const { language } = useSelector(store => store.session, lo.isEqual);
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);
		const dispatch = useDispatch();

		const validationRules = [
			v => required ? Validators.isChecked(v) : Validators.noOp(),
			...(validation ?? [])

		]

		const processStrike = (forceKeep) => {
			if (strike) {
				let strikeOptions = {};

				let vals = strike.split("|");
				if (forceKeep || !formData[id]) {
					for (let val of vals) {
						strikeOptions[val + "$strike"] = "strike";
					}
				} else {
					for (let val of vals) {
						strikeOptions[val + "$strike"] = "keep";
					}
				}

				dispatch(updateFormData(null, null, strikeOptions));
			}
		}

		useEffect(() => {
			// initially, set the strike status to "keep" for all strike options 
			// so that if no value is selected, no options are crossed out
			processStrike();
		}, []);

		useEffect(() => {
			// if hiding, set strike status to keep
			processStrike(true);
		}, [hideWhen]);

		let value = formData[id] ? true : false;

		return (
			<FieldBase {...props} checkedState={value} validation={validationRules} style={style}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange, onValBlur }) => (
					<div className={"checkbox " + (children ? 'with-option ' : '') + (value ? 'checked ' : '')}>
						<label htmlFor={id} className={noAsterisk?"":"control-label"} style={{ width: 'auto' }}>
							<input type="checkbox" id={id} name={id} defaultChecked={fvalue} disabled={readOnly} checked={value}
								onClick={v => {
									if (confirmUncheck && !v.target.checked) {
										dispatch(dialogHelper.showConfirmDialog({
											body: confirmUncheck,
											title: language.confirm,
											language: language,
											handleConfirm: () => {
												setFValue(v.target.checked ? (checkedValue ?? v.target.checked) : false);	
												setCompleted(true);
												onValChange && onValChange(v.target.checked ? (checkedValue ?? v.target.checked) : false);
												if (onClick) onClick(v.target.checked, id);
											} ,
											handleCancel: () => {
												v.target.checked = !v.target.checked;
											}
										})) 
									} else {
										setFValue(v.target.checked ? (checkedValue ?? v.target.checked) : false);	
										setCompleted(true);
										onValChange && onValChange(v.target.checked ? (checkedValue ?? v.target.checked) : false);
										if (onClick) onClick(v.target.checked, id);
									}
								}}
								onBlur={
									v => {
										onValBlur && onValBlur(v.target.checked ? (checkedValue ?? v.target.checked) : false)

										// process strike options...
										processStrike();
									}
								} />
							<i></i>
							<span>
								{label}&emsp;
								{sublabel ?
									<small>{sublabel}&emsp;</small>
									: null
								}
							</span>
							{groupNotes && !readOnly ?
									<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
									// <FaQuestionCircle className="extra-info-btn" onClick={() => setShowNotes(!showNotes)} />
									:
									null
								}
						</label>
						{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}
						<div className={fvalue && children ? "" : "display-hidden"}>
							{children}
						</div>
					</div>
				)}</FieldContext.Consumer>
			</FieldBase>
		)
	},
	FileUpload: (props) => {
		const { id, label, sublabel, filter, limit, required, validation, groupNotes, groupNotesTitle, defaultShowNotes, hideWhen, noUpload, transparentLabel } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);
		const formContext = useContext(FormContext);
		const dispatch = useDispatch();
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);

		const validationRules = [
			v => required ? Validators.fileRequired(v) : Validators.noOp(),
			v => limit ? Validators.fileSizeMax(v, limit, getFileSize(limit)) : Validators.noOp(),
			v => filter ? Validators.fileTypeIncluded(v, filter) : Validators.noOp(),
			...(validation ?? []),
		];

		const getFileSize = size => {
			const unit = ["B", "KB", "MB", "GB"];
			let index = 0;
			let fsize = size;
			while (fsize / 1024 >= 1) {
				fsize = fsize / 1024;
				index++;
			}
			return "" + fsize.toFixed(2) + unit[index];
		}

		const toBase64 = file => new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

		// const base64toFile = (dataurl, filename) => {

		// 	var arr = dataurl.split(','),
		// 		mime = arr[0].match(/:(.*?);/)[1],
		// 		bstr = atob(arr[1]), 
		// 		n = bstr.length, 
		// 		u8arr = new Uint8Array(n);

		// 	while(n--){
		// 		u8arr[n] = bstr.charCodeAt(n);
		// 	}

		// 	return new File([u8arr], filename, {type:mime});
		// }

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange }) => (
					<>
						{label ? <>
						<label htmlFor={id} className="fileUpload-label" style={{ width: 'auto' }}>
							{required ? <div id={id + "_label"} className="required sr-only">Required</div> : null}
							{/* { notes ? <MessagePane>{notes}</MessagePane> : null } */}
							<div id={id + "_label"} className="control-label" style={readOnly ? { width: '90%' } : { width: '100%' }}>
								{transparentLabel ? null : label}&emsp;
								{sublabel ?
									<small>{sublabel}&emsp;</small>
									: null
								}
							</div>
						</label>
						</>: null} 
						{groupNotes && !readOnly ?
							<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
							// <FaQuestionCircle className="extra-info-btn" onClick={() => setShowNotes(!showNotes)} />
							:
							null
						}
						{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}

						<div className="fileUpload-container" >
							{fvalue || readOnly ?
								<div id={id} className={!readOnly ? "fileUpload-result" : ""} style={{ display: "flex", flexDirection: "row", padding: "10px", textDecoration: "underline", overflowWrap: "anywhere" }}>
									<FaPaperclip />
									<div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
										{fvalue ? fvalue.name + " (" + getFileSize(fvalue.size) + ")" : language.noFileSelected}
									</div>
									{readOnly ?
										null
										:
										<div style={{ color: "red" }} onClick={() => {
											setTimeout(() => {
												setFValue()
											}, 0);/*Prevent onClick event of input*/
										}}
											tabIndex="0"
											onKeyDown={e => { if (e.key === " " || e.key === "Enter") { setFValue() } }}>
											<FaTimesCircle />
										</div>
									}
								</div>
								:
								<div className="fileUpload-content" tabIndex="0"
									onKeyDown={e => { if (e.key === " " || e.key === "Enter") { document.querySelector('label[for=' + id + ']').click() } }}
									onClick={e => document.querySelector('label[for=' + id + ']').click()}>
									<FaFileUpload />
									<div className="fileUpload-text">
										{noUpload ? "" : language.chooseAFileToUpload}<br />
										{filter ? language.acceptFileFormat + ": " + filter : null} <br />
										{limit ? language.sizeUpTo + getFileSize(limit) : null}
									</div>
									<input
										id={id} name={id}
										type="file"
										accept={isiOS() ? null:filter}
										style={{ display: "none" }}
										value={fvalue}
										onChange={e => {
											if (e.target.value != null) {
												const file = e.target.files[0];
												toBase64(file).then(
													data => {
														const dataObj = {
															name: file.name, size: file.size,
															type: data.substring(data.indexOf("data:") + 5, data.indexOf(";")),
															file: data.substring(data.indexOf("base64,") + 7),
														};

														if (limit) {
															const errMsg = Validators.fileSizeMax(dataObj, limit, getFileSize(limit));
															if (errMsg) {
																setFValue();
																dispatch(dialogHelper.showErrorDialog({ err: language.formatString(language[errMsg.key], errMsg.args) }, language));
																return;
															}
														}

														setFValue(dataObj);
														setCompleted(true);
														onValChange && onValChange(dataObj);
														
														// Update blacklist of saving function
														dispatch(updateFileList(id));

													}
												)
											}
											e.target.value = null;
										}}
									/>
								</div>
							}
						</div>
					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		)
	},
	Dropdown: (props) => {
		const { id, id_suffix, label, sublabel, required, options, groupNotes, groupNotesTitle,
				validation, strike, respectValue, defaultShowNotes, hideWhen, onExtraValBlur,
				transparentLabel } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);
		const { formData } = useSelector(store => store.formDetail, lo.isEqual);
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);
		const dispatch = useDispatch();

		useEffect(() => {
			// initially, set the strike status to "keep" for all strike options 
			// so that if no value is selected, no options are crossed out
			if (strike) {
				let strikeOptions = {};
				for (let i = 0; i < options.length; i++) {
					let vals = options[i].value.split("|");
					if (formData[id] === "" + i || !formData[id] || formData[id] === "") {
						for (let val of vals) {
							strikeOptions[val + "$strike" + (id_suffix??"")] = "keep";
						}
					} else {
						for (let val of vals) {
							strikeOptions[val + "$strike" + (id_suffix??"")] = "strike";
						}
					}
				}

				dispatch(updateFormData(null, null, strikeOptions));
			}
		}, []);

		useEffect(() => {
			// if hiding, set strike status to keep
			if (strike) {
				if (hideWhen) {
					let strikeOptions = {};
					for (let i = 0; i < options.length; i++) {
						let vals = options[i].value.split("|");
						// if (formData[id] === ""+i || !formData[id] || formData[id] === "") {
						for (let val of vals) {
							strikeOptions[val + "$strike" + (id_suffix??"")] = "keep";
						}
						// } else {
						// 	for (let val of vals) {
						// 		strikeOptions[val + "$strike"] = "strike"; 
						// 	}
						// }
					}

					dispatch(updateFormData(null, null, strikeOptions));
				}
			}
		}, [hideWhen]);

		const validationRules = [
			v => required ? Validators.required(v) : Validators.noOp(),
			...(validation ?? [])

		];

		const extraField = (value) => {
			let elem = [];
			options.map((element, i) => {
				if (element.extraField !== undefined) {
					let hide = true;
					if (respectValue) {
						if(value === element.value) {
							hide = false;
						}
					}
					else {
						if(i + "" === value) {
							hide = false;
						}
					}
					elem.push(<div key={i} className={hide?"display-hidden":""} style={{ paddingTop: '5px' }}><Field.Text id={element.extraFieldId} label={element.extraField} onValBlur={onExtraValBlur} required hideWhen={hide} {...element.extraFieldProps} /></div>);
				}
			});
			return elem;
		}

		const getReadOnlyDisplay = fvalue => {
			let text = "";
			options.map((element, i) => {

				if (respectValue) {
					if(fvalue === element.value) {
						text = element.text;
						if (element.extraField !== undefined) {
							text += "\n";
							text += element.extraField;
							text += ": ";
							text += formData[element.extraFieldId];
						}
					}
				}
				else {
					if(i + "" === fvalue) {
						text = element.text;
						if (element.extraField !== undefined) {
							text += "\n";
							text += element.extraField;
							text += ": ";
							text += formData[element.extraFieldId];
						}
					}
				}
			});

			return <p className="output-text">{text}</p>
		}
		let value = formData[id] ?? ""; // (strike ? Object.keys(formData[id]).reduce((a,c) => formData[id][c] ? c : a) : formData[id]) : "";

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange, onValBlur }) => (
					<>
						{
						transparentLabel ? 
							null
							:
							<div id={id + "_label"} className="control-label">
								{label}&emsp;
							{sublabel ?
									<small>{sublabel}&emsp;</small>
									: null
								}
								{groupNotes && !readOnly ?
									<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
									// <FaQuestionCircle className="extra-info-btn" onClick={() => setShowNotes(!showNotes)} />
									:
									null
								}
							</div>
						}
						{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}
						<div className={readOnly ? "output-text-div" : ""}>
							{props.readonly || readOnly ?
								getReadOnlyDisplay(fvalue)
								:
								<>
									<select className="form-control dropdown-select" id={id} name={id} value={value}
										onChange={v => {
											setFValue((v.target.value));
											setCompleted(true);
											onValChange && onValChange((v.target.value));
										}}
										onBlur={v => {
											setFValue((v.target.value));
											setCompleted(true);
											onValBlur && onValBlur((v.target.value));

											// process strike options...
											if (strike) {
												let strikeOptions = {};
												for (let i = 0; i < options.length; i++) {
													let vals = options[i].value.split("|");
													if (v.target.value === "" + i || v.target.value === "") {
														for (let val of vals) {
															strikeOptions[val + "$strike" + (id_suffix??"")] = "keep";
														}
													} else {
														for (let val of vals) {
															strikeOptions[val + "$strike" + (id_suffix??"")] = "strike";
														}
													}
												}

												dispatch(updateFormData(null, null, strikeOptions));
											}
										}}>
										<option value="">{language.pleaseChoose}</option>
										{options.map((o, i) => value === o.value
											? (<option key={id + "_option" + i} value={respectValue ? o.value : i}>{o.text}</option>)
											: (<option key={id + "_option" + i} value={respectValue ? o.value : i} selected={true}>{o.text}</option>))}
										<optgroup label=""></optgroup>
									</select>
									{extraField(fvalue)}
								</>
							}
						</div>

					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		)
	},
	TimePicker: (props) => {
		const { id, label, sublabel, required, validation, onOrAfterToday, onOrBeforeToday, hideWhen, groupNotes, groupNotesTitle, defaultShowNotes } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);

		const validationRules = [
			v => required ? Validators.required(v) : Validators.noOp(),
			v => Validators.timeCheck(v),
			// v => onOrAfterToday ? Validators.onOrAfterToday(v): Validators.noOp(),
			// v => onOrBeforeToday ? Validators.onOrBeforeToday(v): Validators.noOp(),
			...(validation ?? [])

		];

		const DateIcon = forwardRef(({ onClick, value }, ref) => (
			<button className="date-picker-icon-btn " onClick={onClick} ref={ref} >
				<FaRegClock />
			</button>
		));


		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange }) => (
					<>
						{label ?
							<div id={id + "_label"} className="control-label">
								{label}&emsp;
							{sublabel ?
									<small>{sublabel}&emsp;</small>
									: null
								}
								{groupNotes && !readOnly ?
									<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
									// <FaQuestionCircle className="extra-info-btn" onClick={() => setShowNotes(!showNotes)} />
									:
									null
								}
							</div>
							: null
						}
						{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}

						{readOnly ?
							<p className="output-text">{fvalue}</p>
							:
							<div className="date-picker-container" >
								<input
									className="form-control date-picker-text"
									maxLength={10}
									value={fvalue || ''}
									id={id}
									onChange={v => {
										// const dateStr = handleChange(e);
										setFValue(v.target.value);
										setCompleted(true);
										onValChange && onValChange(v.target.value);
									}} />
								<span className="date-picker-span">
									<DatePicker
										timeFormat={Constant.TIME_FORMAT}
										customInput={<DateIcon />}
										showTimeSelect
										locale={language.getLanguage() === "Eng" ? "en" : "ch"}
										// locale="ch"
										showTimeSelectOnly
										onChange={v => {
											const meridiem = moment(v, 'H:mm').format('A');
											const meridiemStr = language.getLanguage() === "Eng" ? meridiem : (meridiem === 'AM' ? '上午' : '下午');
											const timeStr = (moment(v).format("h:mm ")) + meridiemStr;
											setFValue(timeStr);
											setCompleted(true);
											onValChange && onValChange(timeStr);
										}} />
								</span>
							</div>
						}

					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		)
	},
	DatePicker: (props) => {
		const { id, label, sublabel, required, validation, onOrAfterToday, onOrBeforeToday, maxDateFromNow, minDateFromNow, hideWhen, groupNotes, groupNotesTitle, defaultShowNotes, defaultDate,
			minYearFromNow, maxYearFromNow, customDateFormat=Constant.DATE_FORMAT } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);

		const { formData } = useSelector(store => store.formDetail, lo.isEqual);

		const dispatch = useDispatch();
		useEffect(() => {
			if (defaultDate && !formData["Date"]) {
				dispatch(updateFormData(null,null,{ ["Date"]: defaultDate})); 
			} 
		}, []);

		const validationRules = [
			v => required ? Validators.required(v) : Validators.noOp(),
			v => Validators.dateCheck(v, customDateFormat),
			v => onOrAfterToday ? Validators.onOrAfterToday(v) : Validators.noOp(),
			v => onOrBeforeToday ? Validators.onOrBeforeToday(v) : Validators.noOp(),
			v => minDateFromNow ? Validators.isFutureEnough(v, minDateFromNow) : Validators.noOp(),
			...(validation ?? [])
		];

		const DateIcon = forwardRef(({ onClick, value }, ref) => ( 
			<button className="date-picker-icon-btn " onClick={onClick} ref={ref} >
				<FaCalendarAlt />
			</button>
		));

		let getDate = (value) => {
			let date = null;
			if (value) {
				const isValide = moment(value, Constant.DATE_FORMAT, true).isValid();
				if (isValide) {
					let arr = value.split("/");
					let day = arr[0];
					let month = arr[1] - 1;
					let year = arr[2];

					date = new Date(year, month, day);
				}
			}
			return date;
		}

		const handleChange = e => {
			let val = e.target.value;


			val = val.replace("//", "/");

			var count = (val.match(/\//g) || []).length;

			switch (customDateFormat) {
				case Constant.DATE_MONTH_FORMAT:
					switch (count) {
						case 0:
							if (val.length >= 3) {
								val = val.substring(0, 2) + "/" + val.substring(2);
							}
							break;
						case 1:
							if (val.length === 3 && val.slice(-1) === "/") {
								val = val.substring(0, 2);
							}
							break;
						default:
							while (count > 1) {
								val = val.substring(0, val.lastIndexOf("/")) + val.substring(val.lastIndexOf("/") + 1, val.length)
								count--;
							}
							break;
					}
		
					if (count === 1) {
						val = val.substring(0, val.lastIndexOf("/") + 5);
					}

					break;
				case Constant.DATE_FORMAT:
				default:
					switch (count) {
						case 0:
							if (val.length >= 3) {
								val = val.substring(0, 2) + "/" + val.substring(2);
							}
							if (val.length >= 6) {
								val = val.substring(0, 5) + "/" + val.substring(5);
							}
							break;
						case 1:
							if (val.length === 3 && val.slice(-1) === "/") {
								val = val.substring(0, 2);
							}
							else if (val.length >= val.indexOf("/") + 4) {
								val = val.substring(0, val.indexOf("/") + 3) + "/" + val.substring(val.indexOf("/") + 3);
							}
							break;
						case 2:
							if (val.length === 6 && val.slice(-1) === "/") {
								val = val.substring(0, 5);
							}
							break;
						default:
							while (count > 2) {
								val = val.substring(0, val.lastIndexOf("/")) + val.substring(val.lastIndexOf("/") + 1, val.length)
								count--;
							}
							break;
					}
		
					if (count === 2) {
						val = val.substring(0, val.lastIndexOf("/") + 5);
					}
					break;

			}
			
            
			return val;

		}

		const handleSelect = date => {
			let dateStr = "";
			if (date) {
				if (customDateFormat === Constant.DATE_MONTH_FORMAT) {
					dateStr = [
						(date.getMonth() > 8 ? '' : '0') + (date.getMonth() + 1),
						date.getFullYear()
					].join('/')
				} else if (customDateFormat === Constant.DATE_FORMAT) {
					dateStr = [
						(date.getDate() > 9 ? '' : '0') + date.getDate(),
						(date.getMonth() > 8 ? '' : '0') + (date.getMonth() + 1),
						date.getFullYear()
					].join('/')
				}
			}

			return dateStr;
		}

		const getToday = () => {
			const a = new Date();
			a.setUTCHours(0, 0, 0, 0);
			return a;
		}

		let minDate = onOrAfterToday ? getToday() : undefined;
		let maxDate = onOrBeforeToday ? getToday() : undefined;
		if (minDateFromNow !== undefined && minDateFromNow !== null) {
			minDate = getToday();
			minDate.setDate(minDate.getDate() + minDateFromNow);
		}
		if (maxDateFromNow !== undefined && maxDateFromNow !== null) {
			maxDate = getToday();
			maxDate.setDate(maxDate.getDate() + maxDateFromNow);
		}
 
		const minYear = !lo.isNil(minYearFromNow) ? new Date().getFullYear() + minYearFromNow : 1900;
		const maxYear = !lo.isNil(maxYearFromNow) ? new Date().getFullYear() + maxYearFromNow : new Date().getFullYear() + 2;

		const years = lo.range(minYear, maxYear);
		const months = [
		  "January",
		  "February",
		  "March",
		  "April",
		  "May",
		  "June",
		  "July",
		  "August",
		  "September",
		  "October",
		  "November",
		  "December"
		];

		const monthsTC = [
			"一月",
			"二月",
			"三月",
			"四月",
			"五月",
			"六月",
			"七月",
			"八月",
			"九月",
			"十月",
			"十一月",
			"十二月"
		];

		let dateFormatForDatePicker;
		switch (customDateFormat) {
			case Constant.DATE_MONTH_FORMAT:
				dateFormatForDatePicker = "MM/yyyy";
				break;
			case Constant.DATE_FORMAT:
			default:
				dateFormatForDatePicker = "dd/MM/yyyy";
				break;
		}
  
		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, readOnly, onValChange }) => (
					<>
						{label ?
							<div id={id + "_label"} className="control-label">
								{label}&emsp;
							{sublabel ?
									<small>{sublabel}&emsp;</small>
									: null
								}
								{groupNotes && !readOnly ?
									<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
									// <FaQuestionCircle className="extra-info-btn" onClick={() => setShowNotes(!showNotes)} />
									:
									null
								}
							</div>
							: null
						}
						{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}

						{readOnly ?
							<p className="output-text">{fvalue}</p>
							:
							<div className="date-picker-container" >
								<input placeholder={customDateFormat ?? Constant.DATE_FORMAT}
									className="form-control date-picker-text"
									maxLength={customDateFormat.length}
									value={fvalue || ''}
									id={id}
									onChange={e => {
										const dateStr = handleChange(e);
										setFValue(dateStr);
										setCompleted(true);
										onValChange && onValChange(dateStr);
									}} />
								<span className="date-picker-span">
									<DatePicker
										showMonthYearPicker={customDateFormat === Constant.DATE_MONTH_FORMAT} 
										dateFormat={dateFormatForDatePicker}
										customInput={<DateIcon />}
										selected={getDate(fvalue)}
										minDate={minDate}
										maxDate={maxDate}
										locale={language.getLanguage() === "Eng" ? "en" : "ch"}
										
										onSelect={e => {
											const dateStr = handleSelect(e);
											setFValue(dateStr);
											setCompleted(true);
											onValChange && onValChange(dateStr);
										}} 
										
										renderCustomHeader={({
											date,
											changeYear,
											changeMonth,
											decreaseMonth,
											increaseMonth,
											prevMonthButtonDisabled,
											nextMonthButtonDisabled
										  }) => (
											<div
											  style={{
												margin: 10,
												display: "flex",
												justifyContent: "center"
											  }}
											>
											  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
												{"<"}
											  </button>
											  <select
												value={(date).getFullYear()}
												onChange={({ target: { value } }) => changeYear(value)}
											  >
												{years.map(option => (
												  <option key={option} value={option}>
													{option}
												  </option>
												))}
											  </select>
									
											  <select
												value={language.getLanguage() === "Eng" ? months[(date.getMonth())] : monthsTC[(date.getMonth())]}
												onChange={({ target: { value } }) =>
												  changeMonth(language.getLanguage() === "Eng" ? months.indexOf(value) : monthsTC.indexOf(value))
												}
											  >
												{
												  language.getLanguage() === "Eng" ?
													months.map(option => (
													<option key={option} value={option}>
														{option}
													</option>
													))
													:
													monthsTC.map(option => (
														<option key={option} value={option}>
															{option}
														</option>
														))
												}
											  </select>
									
											  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
												{">"}
											  </button>
											</div>
										  )}
										/>
								</span>
							</div>
						}

					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		)
	},
	Captcha: (props) => {
		const { captchaToken, captchaGetChallengeInProgress, captchaChallenge, ignoreExistingToken } = props;
		const { language } =  useSelector(store => store.session);
		const dispatch = useDispatch();
		const [ captchaAudioSeq, setCaptchaAudioSeq ] = useState(-1);
		const [ captchaAudio, setCaptchaAudio] = useState(null);
	
		useEffect(() => {
			if (captchaAudioSeq >= 0) {
				document.getElementById('captchaAudio').play();
			}
		}, [captchaAudioSeq])

		return (
			(!captchaToken || ignoreExistingToken) && !Constant.NO_CAPTCHA
			? (
				captchaGetChallengeInProgress 
				?
					<p><FaSpinner className="captcha-loading-icon" /></p>
				: (
					captchaChallenge 
					? 	
					<>
						<p className="captcha-required ">{language.captchaMessage}</p>
						<div style={{width:"100%",display: "flex"}}>
							<audio 
								id="captchaAudio"
								src={captchaAudio} 
								onEnded={() => {
									if (captchaAudioSeq < 3) {
										setCaptchaAudio(captchaChallenge["captchaAudio"+(captchaAudioSeq+1)])
										setCaptchaAudioSeq(captchaAudioSeq+1); 
									} else {
										setCaptchaAudio(null);
										setCaptchaAudioSeq(-1);
									}
								}} />
							<div style={{width:"210px", display: "inline-block"}}>
								<img src={captchaChallenge.captcha} />
							</div>
							<div style={{display:"inline-block", width: "min-content"}}>
								<button onClick={() => {
									setCaptchaAudio(captchaChallenge.captchaAudio0);
									setCaptchaAudioSeq(0);
								}}><img src={iconAudio} /></button>
								<button onClick={() => { 
									dispatch(getCaptchaChallenge()); 
								}}><img src={iconRefresh} /></button>
							</div>
						</div> 
						<input id="captchaAnswer" style={{margin:" 1em 0em" }} 
								onBlur={v => dispatch(setCaptchaChallengeAnswer(v.target.value ? v.target.value.toUpperCase() : v.target.value))} />
						<p style={{display:"block", width:"100%", textAlign:"left"}}>{language.captchaRefresh1}<img src={iconRefresh} />{language.captchaRefresh2}</p>
					</>
					: <a style={{
						textDecoration: "underline", 
						color:"green",
						cursor: "pointer",
					}} onClick={ () => dispatch(getCaptchaChallenge()) } >{language.captchaRetry}</a> 
				)
			)
			: null
		);
	},
	Validation:  (props) => {
		const { id, validation } = props;
		const { validationErr } = useSelector(store => store.formDetail, lo.isEqual);

		const validationRules = [
			...validation
		];

		const fieldValidationErrs = validationErr[id] ? validationErr[id].filter(ve => ve) : []; // ignore undefined / null entries
		const invalid = fieldValidationErrs.length > 0;

		return (
			<div className={invalid ? "" : "display-hidden"}>
				<FieldBase {...props} validation={validationRules}>
					<input id={id} name={id} className={"display-hidden"}/>
				</FieldBase>
			</div>
		);
	},
	LookupAddress: (props) => {
		const { id, label, sublabel, numericComma, maxlength, minlength,
			required, validation,  
			prepend, append, groupNotes, groupNotesTitle, defaultShowNotes, transparentLabel, onSelectAddress
		} = props;
		const [showNotes, setShowNotes] = useState(defaultShowNotes ?? false);
		const { registerValidationRule } = useContext(FormContext);
		const { language, curLanguage } = useSelector(store => store.session);

		// const validationRules = [
		const getValidationRules = ({ maxlength, minlength, required, validation, }) => [
				v => required ? Validators.required(v) : Validators.noOp(),
				...( // if both max and min length defined, use the range validator instead
					(maxlength && minlength)
						?
						[v => Validators.lengthRange(v, minlength, maxlength)]
						:
						[
							v => maxlength ? Validators.lengthMax(v, maxlength) : Validators.noOp(),
							v => minlength ? Validators.lengthMin(v, minlength) : Validators.noOp(),
						]
				),
				...(validation ?? [])
			];

		const [validationRules, setValidationRules] = useState(getValidationRules(props));

		const getOutputStr = fvalue => {
			let outputStr = fvalue;
			if (numericComma && fvalue) {
				outputStr = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(fvalue);
				outputStr = outputStr === 'NaN' ? null : outputStr;
			}
			return outputStr;
		}

		useEffect(() => {
			const newRules = getValidationRules({
				maxlength, minlength, required, validation
			})
			setValidationRules(newRules);
			registerValidationRule({ datumId: id, validations: newRules });

		}, [curLanguage, required]);

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ datumId, fvalue, readOnly }) => (
					<>
						{label ?
							<div id={id + "_label"} className="control-label">
								{transparentLabel ? null : label}&emsp;
						{sublabel ?
									<small>{sublabel}&emsp;</small>
									: null
								}
								{groupNotes && !readOnly ?
									<button className="extra-info-btn" onClick={() => setShowNotes(!showNotes)}><FaQuestionCircle /></button>
									:
									null
								}
							</div>
							: null
						}
						{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote} mode="plain" shouldShow={showNotes} >{groupNotes}</ExpandableMessagePane> : null}
						{(props.readonly || readOnly)
							?
							<div className="output-text-div">
								<p className={"output-text " + (append ? "output-text-with-append" : "")}>
									{prepend ? <span className="">{prepend}</span> : null}
									{getOutputStr(fvalue)}
								</p>
								{append ? <div className="input-group-append"><span className="input-group-text">{append}</span></div> : null}
							</div>
							:
							<div className={"input-group " + datumId + "_lookup"}>
								{prepend ? <div className="input-group-prepend"><span className="input-group-text">{prepend}</span></div> : null}
								<LookupAddressSuggestion maxlength={maxlength} onSelectAddress={onSelectAddress} />
								{append ? <div className="input-group-append"><span className="input-group-text">{append}</span></div> : null}
							</div>
						}
					</>
				)}</FieldContext.Consumer>
			</FieldBase>
		);
	}
}

export const CompoundField = {

	RecordList: ({ 
			id, label, addButtonLabel, removeButtonLabel, template,
			readOnly, onRecCountChange, onRecCountAdd, minrecords,
			maxrecords, hideWhen, singlePage, noButtons, transparentLabel,
			showMaxRecCount, idListForSwapping }) => {
		const MAX_RECORD_HARD_LIMIT = 50;
		const { language } = useSelector(store => store.session);
		const { formData, validationErr } = useSelector(store => store.formDetail, lo.isEqual);
		const dispatch = useDispatch();

		const { stepId, title } = useContext(StepContext);
		const { sectionId, titleKey } = useContext(SectionContext);

		// if formData is not yet initialized with a list, add a stub empty object for displaying first rec
		const _minrecords = minrecords ?? 0;
		const [recCount, setRecCount] = useState(formData[id + "_recCount"] ?? _minrecords);
		const [currentRec, setCurrentRec] = useState(formData[id + "_currentRec"] ?? _minrecords);
		const [trigger, setTrigger] = useState(false);

		const _maxrecords = maxrecords ?? MAX_RECORD_HARD_LIMIT;

		const { formLocalization } = useContext(FormContext);

		useEffect(
			() => { 
				if (onRecCountChange) {
					onRecCountChange(recCount);
				} 
			},
			[recCount, formData[id + "_recCount"]]
		)

		useEffect(()=>setRecCount(formData[id + "_recCount"]??0), [formData[id + "_recCount"]])

		useEffect(() => {
			if (_minrecords && (!formData[id + "_recCount"] || formData[id + "_recCount"] < _minrecords)) {
				dispatch(updateFormData(null,null,{ [id + "_recCount"]: _minrecords})); 
				if (onRecCountAdd) onRecCountAdd(_minrecords);
			} 
			setRecCount(formData[id + "_recCount"] ?? _minrecords)
		}, []);

		useEffect(() => {
			if (hideWhen) {
				// setRecCount(0);
				// dispatch(removeFormData({ [id + "_recCount"]: undefined }));
				removeRecord(true);
			}
		}, [hideWhen]);

		// const isLastEntryDatumKey = (key) => key.startsWith(id + "." + (recCount - 1));

		const addRecord = () => {
			const newRecCount = recCount + 1;
			if ((_maxrecords) && newRecCount > _maxrecords) return;
			dispatch(updateFormData(null, null, { [id + "_recCount"]: newRecCount }))
			setRecCount(newRecCount);
			if (onRecCountAdd) {
				onRecCountAdd(newRecCount);
			} 
		}

		const removeRecord = (removeAll) => {
			if (recCount > _minrecords) {
				let targetCount = removeAll ? 0 : recCount-1;
				let curCount = recCount;

				let newFormData = {...formData};
				let newValidationErr = {...validationErr};

				while (curCount > targetCount) {
					const recordId = '#' + id + '_' + (curCount);
					const valueKeys = Array.from(document.querySelectorAll(recordId + ' input, ' + recordId + ' select, ' + recordId + ' textarea, ' + recordId + ' .fileUpload-result')).map(k => k.id);

					for (const key of Object.keys(formData)) {
						if (valueKeys.includes(key)) {
							newFormData[key] = undefined;
						}
					}

					for (const key of Object.keys(validationErr)) {
						if (valueKeys.includes(key)) {
							newValidationErr[key] = undefined;
							delete newValidationErr[key];
						}
					}

					curCount = curCount - 1;
				}

				if (removeAll) {
					// Add empty record
					targetCount = _minrecords;
				}

				// dispatch(updateFormData(null, null, { [id + "_recCount"]: newRecCount }));
				dispatch(updateValidation(title, titleKey, newValidationErr));
				dispatch(updateFormData(title, titleKey, { ...newFormData, [id + "_recCount"]: targetCount }));
				setRecCount(targetCount);
				
			}
		}

		const shouldRecordListArrowDisable = (idx, isMoveUp) => {
			if (isMoveUp && idx==1 ) return true;
			if (!isMoveUp && idx==formData[id + "_recCount"]) return true;
			return false;
		}
		
		const getRecordListArrowClass = (idx, isMoveUp) => {
			let className = "record-list-arrow ";
			if (shouldRecordListArrowDisable(idx, isMoveUp)) className += "record-list-arrow-disabled";
			return className;
		}

		const onClickRecordListArrow = (idx, isMoveUp) => {
			const count = formData[id + "_recCount"];
			if (isMoveUp) {
				if (idx==1) return;

				idListForSwapping.forEach(k=>{
					const temp = formData[k + Constant.RECLIST_ID_SEP + idx];
					formData[k + Constant.RECLIST_ID_SEP + idx] = formData[k + Constant.RECLIST_ID_SEP + (idx-1)];
					formData[k + Constant.RECLIST_ID_SEP + (idx-1)] = temp;
				})
			}

			if (!isMoveUp) {
				if (idx==count) return;

				idListForSwapping.forEach(k=>{
					const temp = formData[k + Constant.RECLIST_ID_SEP + idx];
					formData[k + Constant.RECLIST_ID_SEP + idx] = formData[k + Constant.RECLIST_ID_SEP + (idx+1)];
					formData[k + Constant.RECLIST_ID_SEP + (idx+1)] = temp;
				})
			}
			setTrigger(!trigger);
		}

		let recordFieldsets = [];
		let optionsList = [];
		for (let i = 0; i < formData[id + "_recCount"]; i++) {
			const ii = i + 1;
			recordFieldsets.push(
				<div className="d-flex" style={{ maxHeight: (singlePage && ii != formData[id + "_currentRec"]) ? '0em' : 'initial' }}>
					{ 
						!readOnly
						&& idListForSwapping && (formData[id + "_recCount"]) > 1 ?
						<div className="record-list-arrow-div">
							<button class="btn record-list-arrow-btn" disabled={shouldRecordListArrowDisable(ii, true)} onClick={()=>{onClickRecordListArrow(ii, true)}}>
								<FaCaretUp className={getRecordListArrowClass(ii, true)} />
							</button>
							<button class="btn record-list-arrow-btn" disabled={shouldRecordListArrowDisable(ii, false)} onClick={()=>{onClickRecordListArrow(ii, false)}}>
								<FaCaretDown className={getRecordListArrowClass(ii, false)} />
							</button>
						</div>
						:
						null
					}
					<fieldset key={id + "_" + ii} id={id + "_" + ii} className="labelTopLeft record-list-fieldset">
						<legend>
							<span className="sr-only">Row</span>
							#{ii}
						</legend>
						<RecordContext.Provider value={{ recordListId: id, index: ii }}>
							{template({ key: id + "_" + ii + "_key" })}
						</RecordContext.Provider>
					</fieldset>
				</div>
			);
			optionsList.push(
				<option value={ii}>{ii}</option>
			)
		}

		return (
			<div id={id} className={(readOnly && recordFieldsets.length === 0) || hideWhen ? "display-hidden" : ""} >
				{label !== "" ?
					<div id={id + "_label"} className="control-label">
						{transparentLabel ? null : label ?? language.password}&emsp;
					</div>
					: null
				}

				{singlePage ?
					<div>
						<span class="control-label">Showing record: </span>
						<select onChange={v => {
							dispatch(updateFormData(title, titleKey, { ...formData, [id + "_currentRec"]: parseInt(v.target.value) }));
						}}>
							{optionsList}
						</select>
					</div>
					: null
				}

				{trigger?recordFieldsets:recordFieldsets}

				{!readOnly && !noButtons ?
					<div className="btns">
						{(!_maxrecords || recCount < _maxrecords) ?
							<button id={id + "_addBtn"} type="button" className="btn normalButton" onClick={() => { addRecord() }}>{addButtonLabel ?? language.addARecord}</button>
							: (showMaxRecCount? formLocalization.validation_max_records + _maxrecords : null)}
						{recCount >= 1 ? <button id={id + "_removeBtn"} type="button" className="btn normalButton" onClick={() => { removeRecord() }}>{removeButtonLabel ?? language.removeARecord}</button> : null}
					</div>
					: null}
			</div>
		);
	},
	Address: (props) => {
		const { id, label, notifyValidationError, hideWhen } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);
		const { formData, completedFields, validationErr } = useSelector(store => store.formDetail, lo.isEqual);
		const { stepId } = useContext(StepContext);
		const { sectionId } = useContext(SectionContext);
		const { recordListId } = useContext(RecordContext);

		const dataPrefix = (stepId ? stepId + '.' : '') + (sectionId ? sectionId + '.' : '') + (recordListId ? recordListId + '.' : '') + id;

		const notifyValidationErrorLocal = notifyValidationError === undefined ? true : notifyValidationError;

		const isBldgEstateStreetError =
			(
				completedFields[dataPrefix + '.building']
				&& completedFields[dataPrefix + '.estate']
				&& completedFields[dataPrefix + '.street']
			)
			&&
			(
				(optional(formData[dataPrefix + '.building']))
				&& (optional(formData[dataPrefix + '.estate']))
				&& (optional(formData[dataPrefix + '.street']))
			);

		const contextValdationErrs = Object.fromEntries(Object.entries(validationErr).filter(([key, value]) => key.startsWith(dataPrefix)));

		return (
			<CompoundFieldContext.Provider value={{ notifyValidationError: notifyValidationErrorLocal }}>
				<FieldBase {...props}>
					{label ?
						<div id={id + "_label"} className="control-label">
							{label}&emsp;
						</div>
						: null
					}
					<Row>
						<div className="col-sm-1">{language.field_AddressFlat}</div>
						<div className="col-sm-2"><Field.Text id={id + '.flat'} maxlength={8} /></div>
						<div className="col-sm-1">{language.field_AddressFloor}</div>
						<div className="col-sm-2"><Field.Text id={id + '.floor'} maxlength={3} /></div>
						<div className="col-sm-1">{language.field_AddressBlock}</div>
						<div className="col-sm-2"><Field.Text id={id + '.block'} maxlength={5} /></div>
					</Row>
					<Field.Text id={id + '.building'} placeholder={language.field_AddressBuilding} maxlength={40} validation={[v => isBldgEstateStreetError ? { key: "", args: [] } : null]} />
					<Field.Text id={id + '.estate'} placeholder={language.field_AddressEstate} maxlength={40} validation={[v => isBldgEstateStreetError ? { key: "", args: [] } : null]} />
					<Field.Text id={id + '.street'} placeholder={language.field_AddressStreet} maxlength={40} validation={[v => isBldgEstateStreetError ? { key: "", args: [] } : null]} />
					<Field.Text id={id + '.district'} placeholder={language.field_AddressDistrict} maxlength={40}
						validation={[v => optional(v) ? { key: "validation_districtRequired", args: [] } : null]} />

					<div id={id + "_errors"} className="input-errors">

						{isBldgEstateStreetError ?
							<div key={id + "_errors_"} className="text-danger "><FaExclamationTriangle /><span className="sr-only"> {language.validationError}</span> {language.validation_addressBldgEstateOrStreet}</div>
							: null
						}
						{
							Object.entries(contextValdationErrs).map(([key, value]) =>
								value.map(vs =>
									<div key={id + "_errors_"} className="text-danger "><FaExclamationTriangle /><span className="sr-only"> {language.validationError}</span> {key}: {language.formatString(language[vs.key], vs.args)}</div>
								)
							)
						}
					</div>

				</FieldBase>
			</CompoundFieldContext.Provider>
		)
	},
	CheckboxGroup: (props) => {
		const { id, label, required, validation, multiSelect, options, groupNotes, groupNotesTitle, itemsPerRow, hideWhen, transparentLabel } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);
		const { formData } = useSelector(store => store.formDetail, lo.isEqual);
		const dispatch = useDispatch();

		const validationRules = [
			v => required ? Validators.required(v&&v.length>0?v:null) : Validators.noOp(),
			v => multiSelect ? Validators.noOp() : Validators.isMultiSelect(v),
			...(validation ?? [])

		]

		const constructCheckboxList = (options, fvalue, setFValue, setCompleted) => {
			let elems = [];
			const perRow = itemsPerRow ?? 2;
			let currentRowElems = [];
			const colFactor = (12 / perRow);

			const onClick = (checked, id) => {
				let v = fvalue;
				if (v === null || v === undefined) {
					v = new Set();
				} else {
					v = new Set(v);
				}

				if (checked) {
					v.add(id);
				}
				else {
					v.delete(id);
				}
				setFValue(Array.from(v));
				setCompleted(true);
			}

			for (let i = 0; i < options.length; i++) {
				const o = options[i];
				currentRowElems.push(
					<Col className={"col-lg-" + colFactor}>
						{
							<Field.Checkbox noAsterisk key={id + "_option" + i} id={o.id} checkedValue={o.value} label={o.name} onClick={onClick} hideWhen={hideWhen} >
								{o.extraField ?
									<Field.Text id={o.extraFieldId} label={o.extraField} required {...o.extraFieldProps} hideWhen={!formData[o.id]} />
									:
									null
								}
							</Field.Checkbox>
						}
					</Col>
				);

				if (i % perRow === (perRow - 1)) {
					elems.push(<Row>{currentRowElems}</Row>);
					currentRowElems = [];
				}
			}
			if (currentRowElems.length !== 0) {
				elems.push(<Row>{currentRowElems}</Row>);
			}

			return elems;
		}

		const getReadOnlyDisplay = (options) => {
			let str = "";
			for (let i = 0; i < options.length; i++) {
				const o = options[i];
				if (formData[o.id]) {
					str += o.name;
					if (o.extraField) {
						// str += ' ';
						// str += o.extraField;
						str += ' ';
						str += formData[o.extraFieldId];
					}
					if (i < options.length - 1)
						str += ';\n';
				}
			}

			return <p className="output-text">{str}</p>;

		}

		useEffect(() => {
			if (hideWhen) {
				let removeList = {};
				for (let i = 0; i < options.length; i++) {
					const o = options[i];
					removeList[o.id] = undefined;
				}
				dispatch(removeFormData(removeList));
			}
		}, [hideWhen]);

		return (
			<FieldBase {...props} validation={validationRules}>
				<FieldContext.Consumer>{({ fvalue, setFValue, setCompleted, completed, readOnly }) => (
					<>
						<label className={"checkbox-group "} htmlFor="" role="group" aria-labelledby={id + "_label"}>
							{label ? 
								<div id={id + "_label"} className="control-label">
									{transparentLabel ? null : label}&emsp;
								</div>
								:
								null
							}
							<>
								{groupNotes && !readOnly ? <ExpandableMessagePane id={id + "_messagePane"} title={groupNotesTitle ?? language.footNote}>{groupNotes}</ExpandableMessagePane> : null}
							</>
							{readOnly ?
								getReadOnlyDisplay(options)
								:
								constructCheckboxList(options, fvalue, setFValue, setCompleted)
							}
						</label>
						<input id={id} className="display-hidden" value={fvalue}/>
					</>
				)}</FieldContext.Consumer>
				
			</FieldBase>
		)
	},
}

export const PersonalInfo = {
	ChnName: ({ id, maxlength, required, onChange, hideWhen }) => {

		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_CHN_NAME}
				label={language.field_ChnName}
				required={required}
				maxlength={maxlength ?? MAX_LENGTH_CHN_NAME}
				chineseonly
				onChange={onChange}
				hideWhen={hideWhen}
			/>
		)
	},
	EngName: ({ id, maxlength, required, onChange, hideWhen }) => {

		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_ENG_NAME}
				label={language.field_EngName}
				required={required}
				maxlength={maxlength}
				englishonly
				onChange={onChange}
				hideWhen={hideWhen}
			/>
		)
	},
	HKID: ({ id, required, onChange, hideWhen }) => {

		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_HKID}
				label={language.field_HKID}
				required={required}
				minlength={8}
				maxLength={9}
				validation={[
					v => Validators.isHKID(v)
				]}
				onChange={onChange}
				hideWhen={hideWhen}
			/>
		)
	},
	Identification: ({ ddid, textid, options, required, hideWhen }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		options = options ?? [
			{ value: 'hkid', text: language.field_IdType_HKID },
			{ value: 'prcid', text: language.field_IdType_PRCID },
			{ value: 'prchkmac', text: language.field_IdType_PRCHKMAC },
			{ value: 'cr', text: language.field_IdType_CR },
			{ value: 'passport', text: language.field_IdType_PASSPORT },
			{ value: 'na', text: language.field_IdType_NA },
		]

		const type = document.getElementById(ddid ?? DEFAULT_FIELD_IDS.ID_PERSONAL_IDTYPE);
		const textRequired = (type !== null
			&& type.value !== ''
			&& type.value !== 'na');

		return (
			<>
				<Field.Dropdown
					id={ddid ?? DEFAULT_FIELD_IDS.ID_PERSONAL_IDTYPE}
					label={language.field_IdType}
					required={required}
					options={options}
					hideWhen={hideWhen}
				/>
				<Field.Text
					id={textid ?? DEFAULT_FIELD_IDS.ID_PERSONAL_IDNUM}
					label={language.field_IdNum}
					required={textRequired}
					hideWhen={hideWhen && !textRequired}
				/>
			</>
		)
	},
	Gender: ({ id, required, hideWhen }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<CompoundField.CheckboxGroup
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_GENDER}
				label={language.field_Gender}
				options={language.field_Gender_List}
				required={required}
				hideWhen={hideWhen}
			/>

		);
	},
	Salutation: ({ id, required, label, onChange, hideWhen, strike, options }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);
		const { formLocalization } = useContext(FormContext);

		return (
			<Field.Dropdown
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_SALUTATION}
				label={label ?? formLocalization.field_Salutation}
				required={required}
				strike={strike}
				options={options ?? [
					{ value: 'Mr', text: language.field_Salutation_Mr },
					{ value: 'Mrs', text: language.field_Salutation_Mrs },
					{ value: 'Miss', text: language.field_Salutation_Miss },
					{ value: 'Ms', text: language.field_Salutation_Ms },
				]}
				onChange={onChange}
				hideWhen={hideWhen}
			/>
		);
	},
	HomePhone: ({ id, required, hideWhen }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_HOME_PHONE}
				label={language.field_HomePhone}
				required={required}
				maxlength={20}
				numberonly
				hideWhen={hideWhen}
			/>
		);
	},
	WorkPhone: ({ id, required, hideWhen }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_WORK_PHONE}
				label={language.field_WorkPhone}
				required={required}
				maxlength={20}
				numberonly
				hideWhen={hideWhen}
			/>
		);
	},
	// Use PhoneFax for validation
	MobilePhone: ({ id, required, label, hideWhen }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_MOBILE_PHONE}
				label={label ?? language.field_MobilePhone}
				required={required}
				maxlength={20}
				hideWhen={hideWhen}
			/>
		);
	},
	// Use PhoneFax for validation
	ContactPhone: ({ id, label, required, onChange, hideWhen }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_CONTACT_PHONE}
				label={label ?? language.field_ContactPhone}
				required={required}
				maxlength={20}
				onChange={onChange}
				hideWhen={hideWhen}
			/>
		);
	},
	Fax: ({ id, label, required, onChange, hideWhen }) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_FAX}
				label={label ?? language.field_Fax}
				required={required}
				maxlength={20}
				faxnumber
				onChange={onChange}
				hideWhen={hideWhen}
			/>
		);
	},
	PhoneFax: (props) => {
		const { id, label, sublabel, required, onChange, groupNotes, defaultShowNotes, hideWhen, maxlength } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				{...props}
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_CONTACT_PHONE}
				label={label ?? language.field_ContactPhone}
				sublabel={sublabel ?? null}
				required={required}
				maxlength={maxlength ?? 20}
				phonefax
				onChange={onChange}
				hideWhen={hideWhen}
			/>
		);
	},
	Email: (props) => {
		const { id, label, sublabel, required, onChange, groupNotes, defaultShowNotes, hideWhen, maxlength, validation } = props;
		const { language } = useSelector(store => store.session, lo.isEqual);

		return (
			<Field.Text
				{...props}
				id={id ?? DEFAULT_FIELD_IDS.ID_PERSONAL_EMAIL}
				label={label ?? language.field_Email}
				sublabel={sublabel ?? null}
				required={required}
				maxlength={maxlength}
				validation={
					validation ? [...validation, v => Validators.isEmail(v)] : [v => Validators.isEmail(v)]
				}

				onChange={onChange}
				hideWhen={hideWhen}
			/>
		);
	}
}


export const StandardFields = {
	CompanyId: (props) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return <Field.Text {...props} maxlength={30} alphanumericHyphen
			label={props.label ?? language.field_BusinessReg} />
	},
	PersonalId: (props) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return <Field.Text {...props} maxlength={100}
			label={props.label ?? language.field_IdNum} />
	},
	Name: (props) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return <Field.Text {...props} maxlength={100}
			label={props.label ?? language.field_Name} />
	},
	NameOfDeclarant: (props) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return <Field.Text {...props} maxlength={100}
			label={props.label ?? language.field_NameOfDeclarant} />
	},
	PhoneNumber: (props) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return <Field.Text {...props} maxlength={20} phoneNumber
			label={props.label ?? language.field_ContactPhone} />
	},
	FaxNumber: (props) => {
		const { language } = useSelector(store => store.session, lo.isEqual);

		return <Field.Text {...props} maxlength={20} phoneNumber
			label={props.label ?? language.field_Fax} />
	},

}