import { TransformationType, TransformFnParams } from 'class-transformer';

type Config = {
    trueValue: string;
    falseValue: string;
};

export const booleanTextTransform = (config: Config) => (input: TransformFnParams) => {
    const { trueValue, falseValue } = config;
    const { value, type } = input;

    switch (type) {
        case TransformationType.CLASS_TO_PLAIN:
            return value ? trueValue : falseValue;
        case TransformationType.PLAIN_TO_CLASS:
            return value === trueValue;
        case TransformationType.CLASS_TO_CLASS:
        default:
            return value;
    }
};

export const booleanDigitTransform = booleanTextTransform({ trueValue: '1', falseValue: '0' });

export const booleanYNTransform = booleanTextTransform({ trueValue: 'Y', falseValue: 'N' });
