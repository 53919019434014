import { AxiosRequestConfig } from 'axios';
import { instanceToPlain } from 'class-transformer';

export const onRequest = (config: AxiosRequestConfig) => {
    const { data, ...rest } = config;
    return {
        ...rest,
        data: data ? instanceToPlain(data) : data,
    };
};
