import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Validators } from '../../inputElements';
import { RootState } from '../../reducers/wfp-core-store.type';
import { SaveInfo } from '../../class';

type Props = {
    returnObj: SaveInfo;
    updateAndValidate: any;
};

export function SaveDialogChildren(props: Props) {
    const { returnObj, updateAndValidate } = props;
    const { language } = useSelector((store: RootState) => store.session);
    const session = useSelector((store: RootState) => store.session);

    return (
        <>
            <Field.Password
                id="dialogSavePw"
                subLabel={language.formatString(language.passwordCondition, 8, 25)}
                minlength={8}
                maxlength={25}
                nonformdata
                onValChange={(v: any) => {
                    returnObj.savePw = v;
                    if (returnObj.savePwConfirm)
                        updateAndValidate('dialogSaveConfirmPw', returnObj.savePwConfirm);
                }}
                required
            />
            <Field.Password
                id="dialogSaveConfirmPw"
                minlength={8}
                maxlength={25}
                label={language.confirmPassword}
                nonformdata
                onValChange={(v: any) => (returnObj.savePwConfirm = v)}
                validation={[
                    (v: any) =>
                        returnObj.savePw
                            ? v !== returnObj.savePw
                                ? { key: 'validation_confirmPasswordDiff', args: [] }
                                : Validators.noOp()
                            : Validators.noOp(),
                ]}
            />
            <Field.Captcha
                captchaToken={session.captchaToken}
                captchaGetChallengeInProgress={session.captchaGetChallengeInProgress}
                captchaChallenge={session.captchaChallenge}
            />
        </>
    );
}
