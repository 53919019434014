import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { PrimaryButton } from './primary-button';

type Props = { onComplete: () => void };

export function CheckButton(props: Props) {
    const { onComplete } = props;
    return (
        <PrimaryButton id={'nextBtn'} onClick={onComplete}>
            {' '}
            <FaCheck />
        </PrimaryButton>
    );
}
