import { Expose, Type } from 'class-transformer';
import { AvailablePaymentMethods } from '../../../entities';
import { CardType, PaymentMethodSubtype } from '../../../enums';

export class QueryPaymentAvailabilityResponse {
    constructor(input: Partial<QueryPaymentAvailabilityResponse> = {}) {
        Object.assign(this, input);
    }
    @Expose({ name: 'availablepaymentmethods' })
    @Type(() => AvailablePaymentMethods)
    availablePaymentMethods: AvailablePaymentMethods[];

    removeSubType(subType: PaymentMethodSubtype) {
        if (this.availablePaymentMethods) {
            this.availablePaymentMethods = this.availablePaymentMethods.filter(
                (apm) => apm.subType !== subType
            );
        }
    }

    removeCard(card: CardType) {
        if (this.availablePaymentMethods) {
            this.availablePaymentMethods.forEach((apm) => apm.removeCard(card));
        }
    }
}
