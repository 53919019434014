import axios from 'axios';
import { onSuccess } from './on-success.interceptor';
import { onError } from './on-error.interceptor';
import { onRequest as _onRequest } from './on-request.interceptor';

export class InstanceFactory {
    static getInstance(input: any) {
        const { baseUrl: baseURL, onRequest = _onRequest } = input;
        const instance = axios.create({
            baseURL,
        });
        instance.interceptors.response.use(onSuccess, onError);
        instance.interceptors.request.use(onRequest);
        return instance;
    }
    static getMockInstance() {
        const instance = axios.create();
        instance.interceptors.response.use(onSuccess, onError);
        instance.interceptors.request.use(_onRequest);
        return instance;
    }
}
