import React from 'react';
import { FaSave } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/wfp-core-store.type';
import { DefaultButton } from './default-button';

type Props = { onSave: () => void };

export function SaveButton(props: Props) {
    const { onSave } = props;
    const language = useSelector((store: RootState) => store.session.language);
    return (
        <DefaultButton id={'saveBtn'} onClick={onSave}>
            <FaSave /> {language.save}
        </DefaultButton>
    );
}
