export const PAYMENT_BASE_URL = !document.URL.startsWith('http://localhost:3000')
    ? '/rm-payment'
    : 'http://localhost:3000/payment';

export const API_PREFIX = '/api/payment';

export const IS_AXIOS_MOCK =
    process.env.REACT_APP_IS_AXIOS_MOCK &&
    process.env.REACT_APP_IS_AXIOS_MOCK.toUpperCase() == 'TRUE';
//@ts-ignore
export const GOOGLE_PAY_ENV = window.WFP_CONFIG.GOOGLE_PAY_ENV;
