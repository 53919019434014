import { WebsiteLocale } from '../../layout/core/website-locale.enum';
import { PaymentLocale } from '../enums';

export function websiteLocaleToPaymentLocale(locale: string): PaymentLocale {
    switch (locale) {
        case WebsiteLocale.SIMPLIFIED_CHINESE:
            return PaymentLocale.CHINESE_SIMPLIFIED;
        case WebsiteLocale.TRADITIONAL_CHINESE:
            return PaymentLocale.CHINESE_TRADITIONAL;
        case WebsiteLocale.ENGLISH:
        default:
            return PaymentLocale.ENGLISH;
    }
}
