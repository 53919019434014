import { PaymentMethod } from '../../../../../gcis-payment/enums';

const paymentMethods: Record<PaymentMethod, string> = {
    PPS: '網上繳費靈',
    JCB: '信用卡 (JCB)',
    MASTER_CARD: '信用卡 (萬事達卡)',
    UNION_PAY: '信用卡 (銀聯)',
    VISA: '信用卡 (VISA)',
    E_CHEQUE: '電子支票',
    ANDROID_PAY: 'Google Pay',
    APPLE_PAY: 'Apple Pay',
    FPS: '轉數快',// 202309
};

export const GCIS_PAYMENT_TC = {
    PRE_PAYMENT_MESSAGE:
        '你已選擇網上付款(繳費靈／信用卡／轉數快／流動支付)，請按下方的「繼續」按鈕確認繼續。',
    METHOD_SUMMARY: '繳費靈／信用卡／轉數快／流動支付 (網上遞交申請)',
    MODAL_HEADER: '網上付款',
    ERROR_PAYMENT_EXPIRED: '操作逾時，請稍後再試。',
    ERROR_PAYMENT_REJECTED: '你的付款已被支付系統拒絕，請聯絡你的發卡銀行。\n\n詳情：{0}',
    ERROR_PAYMENT_CANCELLED: '付款已被取消，請稍後再試。\n\n詳情：{0}',
    ERROR_PAYMENT_CANCELLED_USER: '付款已被取消，請稍後再試。',
    ERROR_NO_PAYMENT_METHODS_AVAILABLE: '網上繳費服務暫停，你仍可以使用電子支票繳費或稍後再試。',
    PAYMENT_METHODS: paymentMethods,
    PAYMENT_DISABLED_BROADCAST_TITLE: '網上繳費方式暫停使用 - {0}',
    PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER:
        '由於系統維護，網上繳費方式 - {0} 在以下時段將不能使用：',
    PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER:
        '在上述期間，你仍可以使用其他網上繳費方式繳費。你亦可儲存已輸入的資料，然後在系統維護時段之後使用 {0} 進行繳費。如有查詢，請致電土地註冊處客戶服務熱線 3105 0000。',
    ALL_PAYMENT_DISABLED_BROADCAST_TITLE: '網上繳費方式暫停使用',
    ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER:
        '由於系統維護，網上繳費方式在以下時段將不能使用：',
    ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER:
        '在上述期間，你仍可以儲存已輸入的資料，然後在系統維護時段之後進行繳費。如有查詢，請致電土地註冊處客戶服務熱線 3105 0000。',
    PAYMENT_DISABLED_MESSAGE_TEMPLATE:
        '網上付款方式 - {0} 暫時未能提供服務。你仍可以使用其他網上付款方式繳費或稍後再試。如有查詢，請致電土地註冊處客戶服務熱線 3105 0000。',
    
    // 202309
    FPS_PAYMENT_MOBILE_FOOTER: "請按「開啟流動應用程式」按鈕直接在流動裝置上開啟銀行流動應用程式或電子錢包。",
    FPS_PAYMENT_MOBILE_BUTTON: "開啟流動應用程式",
    FPS_PAYMENT_SEPARATE: "- 或 -",
    FPS_PAYMENT_FOOTER: "請使用支援轉數快二維碼付款的銀行流動應用程式或電子錢包，掃描二維碼以進行網上付款。\n\n二維碼將於3分鐘後失效。",
};
