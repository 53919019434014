import React from 'react';
import { FaEraser } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/wfp-core-store.type';
import { DefaultButton } from './default-button';

type Props = { onClear: () => void };

export function ClearButton(props: Props) {
    const { onClear } = props;
    const language = useSelector((store: RootState) => store.session.language);
    return (
        <DefaultButton id={'clearBtn'} onClick={onClear}>
            <FaEraser /> {language.clear}
        </DefaultButton>
    );
}
