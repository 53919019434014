export enum EngDistrict {
    'CENTRAL & WESTERN DISTRICT',
    'EASTERN DISTRICT',
    'ISLANDS DISTRICT',
    'KOWLOON CITY DISTRICT',
    'KWAI TSING DISTRICT',
    'KWUN TONG DISTRICT',
    'NORTH DISTRICT',
    'SAI KUNG DISTRICT',
    'SHA TIN DISTRICT',
    'SHAM SHUI PO DISTRICT',
    'SOUTHERN DISTRICT',
    'TAI PO DISTRICT',
    'TSUEN WAN DISTRICT',
    'TUEN MUN DISTRICT',
    'WAN CHAI DISTRICT',
    'WONG TAI SIN DISTRICT',
    'YAU TSIM MONG DISTRICT',
    'YUEN LONG DISTRICT',
}
