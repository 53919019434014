import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormContext } from '../../layout/core/layoutElements';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';

export function useGetTotalFee() {
    const {paymentAmountId} = useContext(FormContext);
    const totalFee = useSelector((store: RootState) => store.formDetail?.formData?.[paymentAmountId]);
    return totalFee;
}
