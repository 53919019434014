export function isEmpty(value) {
    return (
        (typeof value == 'string' && !value.trim()) ||
        typeof value == 'undefined' ||
        value === null
    );
}

export function isMobile() {
    return /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent);
}

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(
        new RegExp(re, 'g'),
        '$&' + (s || ',')
    );
};

export function getCurrency(code) {
    var symbols = {
        USD: '', // US Dollar
        HKD: 'HK$ ', // HKD Dollar
        EUR: '��', // Euro
        RMB: '�D', // RMB
        CRC: '?', // Costa Rican Colon
        GBP: '�G', // British Pound Sterling
        ILS: '?', // Israeli New Sheqel
        INR: '?', // Indian Rupee
        JPY: '�D', // Japanese Yen
        KRW: '?', // South Korean Won
        NGN: '?', // Nigerian Naira
        PHP: '?', // Philippine Peso
        PLN: 'z?', // Polish Zloty
        PYG: '?', // Paraguayan Guarani
        THB: '?', // Thai Baht
        UAH: '?', // Ukrainian Hryvnia
        VND: '?', // Vietnamese Dong
    };

    var result = symbols[code];

    if (result === undefined) {
        return null;
    }
    return result;
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const poll = async ({ fn, validate, interval, timeout }) => {
	// let attempts = 0;

	const executePoll = async (resolve, reject) => {
		const result = await fn();
		// attempts++;

		if (validate(result)) {
			return resolve(result);
		} else if (timeout && new Date().getTime() > timeout) {
			return reject(new Error('QR Code Timeout'));
		} else {
			setTimeout(executePoll, interval, resolve, reject);
		}
	};

	return new Promise(executePoll);
};