import React, { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers/wfp-core-store.type';
import { updateFormData, updateValidation } from '../reducers/formDetail';
import * as TimeoutUtil from '../../../utilities/timeoutUtil';
import * as Constant from '../../../utilities/constant';
import { FormContext } from '../layoutElements';

import { getStepFieldIds } from '../wfpFrame/utils';
import { LocalizedStrings } from 'react-localization';

import { FormSpec } from '../class';

/* This timer is set to group all dispatch events within 50ms into one single event */
/* We use var instead of useState() because the setter of state has a delay and sometimes might affect the reset logic of the timer. */
var updateTimer:NodeJS.Timeout = null;

export function useUpdateAndValidate(form?: FormSpec) {

    const dispatch = useDispatch();
	const session = useSelector((store:RootState) => store.session);
	const { formData, completedFields, signedFields } = useSelector((store:RootState) => store.formDetail);
    const { language } = session;
	
	const [ validationErrMsg, setValidationErrMsg ] = useState([]);
	const [ isSavePrintValErr, setSavePrintValErr ] = useState(false);
	const [ registeredValidations, setRegisteredValidations ] = useState({}); // validation rules register for all fields on the form

	const resetTimeout = () => {
        if (updateTimer) {
			clearTimeout(updateTimer);
            updateTimer=null;
        }
    };
	
	const [updateValidateObj, setUpdateValidateObj] = useState({ufdObj:{}, uvObj:{}});

    const validate = (datumId:string, value:any) => {
		
		const validations = (registeredValidations as any)[datumId] ?? [];
		
		let vr = validations.reduce((acc:any, cur:any) => { 
			const r = cur(value);
			if (r) acc.push(r) ;
			return acc;
		}, []);

		return vr;
	}

	const dispatchUpdateAndValidate = ()=> async () => {{
		dispatch(updateFormData(null, null, updateValidateObj.ufdObj));
		dispatch(updateValidation(null, null, updateValidateObj.uvObj));

		setUpdateValidateObj({ufdObj:{}, uvObj:{}});
	}}

    const updateAndValidate = (datumId:string, value:any) => {
		const vr = validate(datumId, value);

		updateValidateObj.ufdObj = {...updateValidateObj.ufdObj, [datumId]: value};
		updateValidateObj.uvObj = {...updateValidateObj.uvObj, [datumId] : (vr.length === 0) ? null : vr};

		resetTimeout();
        updateTimer = (setTimeout(dispatchUpdateAndValidate(), Constant.UPDATE_VALIDATE_DELAY));

		TimeoutUtil.UPDATE_TIMER(dispatch, language);

		return vr;
	}

	const updateValidationErrMsg = (shouldUpdate:boolean, shouldSetValidationErrMsg:boolean, checkCompletedOnly:boolean, ignoreRequired:boolean) => {
		const stepItems = Object.keys(registeredValidations).filter(getStepFieldIds);
		let validationResult:Map<string,any> = new Map<string,any>();

		let tempFormData = {...formData};

		// ----
		// 20240524 Added by Jess
		// After the implementation of updateTimer, the below code has to be applied to instantly update the form data for validation
		// Otherwise the updateValidateObj will be overrided unexpectedly
		if (updateTimer) {
			resetTimeout();
			dispatchUpdateAndValidate()();
			tempFormData = {...tempFormData, ...updateValidateObj.ufdObj};
		}
		// ----

		for (let datumId of (stepItems))
		{
			if (checkCompletedOnly && !(completedFields as any)[datumId]) continue;
			let results = shouldUpdate ? updateAndValidate(datumId, tempFormData[datumId]) : validate(datumId, tempFormData[datumId]);
			validationResult.set(datumId, (results.length === 0 || (ignoreRequired && results.filter((o:any) => !o.key.includes("required")).length === 0)) ? null : results); 
			if (ignoreRequired && validationResult.get(datumId)) {
				validationResult.set(datumId, validationResult.get(datumId).filter((o:any) => !o.key.includes("required")));
			}
		}

		// const activeErrs = Object.keys(validationResult).filter(getStepFieldIds).filter(key => validationResult.get(key));
		const activeErrs:Array<string> = new Array<string>();
		validationResult.forEach((v,k,m)=>{
			if (getStepFieldIds(k) && v)
			{activeErrs.push(k)}
		})

		let children:any[] = [];

		if (activeErrs.length > 0) {
			children = 	[];

			let idx = 0;
			for(let i=0;i<activeErrs.length;i++) {
				const err = validationResult.get(activeErrs[i]);
				const getName = (formLocalization:LocalizedStrings<any>) =>{
					const key = activeErrs[i].split(Constant.RECLIST_ID_SEP)[0];
					
					let name = formLocalization[key];
					// Try to remove "AI_" if it share with main form
					if(!name && key.includes("AI_")) name = formLocalization[key.split("AI_")[1]];
					if(!name && key.includes("AI_") && key.includes("_1")) name = formLocalization[key.split("AI_")[1].replace("_1", "")];
					if(!name && key.includes("AI_") && key.includes("_2")) name = formLocalization[key.split("AI_")[1].replace("_2", "")];
					
					if (typeof(name) === "function") {name = name()}

					if (activeErrs[i].split(Constant.RECLIST_ID_SEP)[1]
						&& parseInt(activeErrs[i].split(Constant.RECLIST_ID_SEP)[1])) {
							name += " ["
							if (form.recordListTitles) {
								Object.keys(form.recordListTitles).forEach(k=>{
									if (activeErrs[i].includes(k)) {
										name += formLocalization[form.recordListTitles[k]] + " ";
									}
								})
							}

							name += (parseInt(activeErrs[i].split(Constant.RECLIST_ID_SEP)[1]))+"]";
					}
					return name;
				}
				
				err.forEach((e:any)=>{
					children.push(
						<FormContext.Consumer>{(({formLocalization}:any) => (
							<div key={activeErrs[i]+"_"+idx} style={{display:"flex", flexDirection:"row"}}>
								<p style={{flex:1}}>{ getName(formLocalization) }</p>
								<p style={{flex:1, display:'flex', alignItems:'flex-end'}}>{": " + formLocalization.formatString(formLocalization[e.key], ...e.args)}</p>
							</div>
						))}</FormContext.Consumer>
					);
					idx++;
				})
				
			}
		}
		// if (shouldSetValidationErrMsg === undefined || shouldSetValidationErrMsg) {
		if (shouldSetValidationErrMsg) {
			setValidationErrMsg(children);
			setSavePrintValErr(ignoreRequired);
		}
		// }
		return children;
	}

    return { 
        updateAndValidate:updateAndValidate,
        validate:validate,
		updateValidationErrMsg:updateValidationErrMsg,
		validationErrMsg:validationErrMsg,
		setValidationErrMsg:setValidationErrMsg,
		isSavePrintValErr:isSavePrintValErr,
		setSavePrintValErr:setSavePrintValErr,
		registeredValidations:registeredValidations,
		setRegisteredValidations:setRegisteredValidations,
    };
}