import React from 'react';
import lo from 'lodash';
import { useSelector } from 'react-redux';

import {Row, Col} from 'react-bootstrap';

import '../css/index.css';
import '../css/wfp/index.css';
import '../css/wfp/input.css';
import '../css/wfp/nav.css';

export const GeneralTerms = ({ currentLang }) => {
	const { language } = useSelector(store => store.session, lo.isEqual);


	return (
		<Row className="body wfpRootContainer">

			<div className="topNavBar">
				{/* <div className="small" style={{cmarginBottom: '0.5em'}}><FaRegFileAlt />&nbsp;</div> */}
									
			</div>
            <Col id="stepBody">
                <div id="stepBody-form" className="form-section notesSection">
                    {language.generalTermsContent(false)}
                </div>
            </Col>
            
		</Row>
	)
}

export default GeneralTerms;