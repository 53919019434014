import { isDuringPeriod } from './is-during-period.util';

export function isDuringDisablePeriod() {
    //@ts-ignore
    const { WFP_CONFIG = {} } = window;
    const { DISABLE_GCIS_PAYMENT_PERIOD: period = [] } = WFP_CONFIG;
    for (const p of period) {
        if (isDuringPeriod(p.from, p.to)) {
            return true;
        }
    }
    return false;
}
