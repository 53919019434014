import { useSelector } from 'react-redux';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { NO_CAPTCHA } from '../../utilities/constant';
import { useShowCaptcha } from '../hooks-dialog/use-show-captcha.hook';
import { useShowPaymentOptions } from '../hooks-dialog/use-show-payment-options.hook';

export function useStartGcisPayment() {
    const captchaToken = useSelector((state: RootState) => state.session.captchaToken);
    const showPaymentOptions = useShowPaymentOptions();
    const showCaptcha = useShowCaptcha();

    const onClick = () => {
        if (captchaToken || NO_CAPTCHA) {
            showPaymentOptions();
        } else {
            showCaptcha(() => {
                showPaymentOptions();
            });
        }
    };
    return onClick;
}
