import { instance } from '../../instance';
import { plainToInstance } from 'class-transformer';
import { PayByOnlineMethodRequest as Input } from './request';
import { PayByOnlineMethodResponse as Response } from './response';
import { PaymentApiUrl } from '../../../constants';

const url = PaymentApiUrl.PAY_ONLINE;

export async function payByOnlineMethod(input: Input) {
    const type = input.extractType();
    const data = await instance.post(`${url}/${type}`, input);
    return plainToInstance(Response, data);
}
