import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { CombinedData } from '../entities';

import { pollStatus, startPayment, updateTransaction } from '../utils';

import { useGetPaymentData } from './use-get-payment-data.hook';
import { useGetTransactionData } from './use-get-transaction-data.hook';
import { clearTransactionData, markTransactionDataAsUsed, updateTransactionDataFpsResponse } from '../redux/slice';
import { UNKNOWN_ERROR } from '../constants';

export function useGcisPayment() {
    const { fi, paymentGateway } = useSelector((state: RootState) => state.payment);
    const stopPolling = useRef<(u?: unknown) => void>();
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const { loading: loadingTransactionData, transactionData } = useGetTransactionData();
    const paymentData = useGetPaymentData();

    const cancelPayment = () => {
        stopPolling.current();
        dispatch(clearTransactionData());
    };

    const submitPayment = async (windowRef?: Window) => {
        if (
            !transactionData ||
            !transactionData.isValid() ||
            !paymentData ||
            !paymentData.isValid() ||
            !fi
        ) {
            throw UNKNOWN_ERROR;
        }

        const combinedData = CombinedData.fromPaymentTransactionData(paymentData, transactionData);
        combinedData.paymentMethod = fi;

        setSubmitting(true);
        dispatch(markTransactionDataAsUsed());
        return await startPayment(combinedData, paymentGateway)
            .then((res) => {
                if (res.redirectUrl && windowRef) {
                    windowRef.location.href = res.redirectUrl;
                    return { windowRef };
                } else if (res.fpsResponse) {
                    // 202309: received fps response, and return data for further action
                    // Open scan QR code dialog
                    // if(platform == mobile && res.fpsqrcodeurl) {
                    //     openDialog(res.fpsqrcodeimgbase64, res.fpsqrcodeurl);
                    // } else {
                    //     openDialog(res.fpsqrcodeimgbase64);
                    // }
                    dispatch(updateTransactionDataFpsResponse(res.fpsResponse));

                    windowRef?.close();
                    return;
                } else {
                    windowRef?.close();
                    return;
                }
            })
            .then(async (value) => {
                const [poll, stop] = pollStatus(transactionData.transactionId)(value);
                stopPolling.current = stop;
                return await poll();
            })
            .finally(() => setSubmitting(false));
    };

    return {
        submitPayment,
        loading: submitting || loadingTransactionData,
        loadingTransactionData,
        transactionData,
        cancelPayment,
    };
}
