import { useSelector } from 'react-redux';
import { GENERAL_ERROR_MSG, GENERAL_ERROR_MSG_WITH_SUBM_ID } from '../../layout/core/dialogHelper';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { useShowError } from './use-show-error.hook';

export function useShowGeneralError() {
    const submissionId = useSelector((state: RootState) => state.payment.submissionId);
    const showError = useShowError();
    const key = submissionId ? GENERAL_ERROR_MSG_WITH_SUBM_ID : GENERAL_ERROR_MSG;

    return () => {
        return showError(key, submissionId);
    };
}
