import { Expose, Transform, Type } from 'class-transformer';
import { TcDistrict, TcRegion } from '../../../enums';
import { Street } from '../low-level';
import { ChiEstate } from '../estate';
import { BasePremisesAddress } from './base-premises-address.entity';
import { Block } from '../block.entity';
import { ChiThreeDAddress } from '../three-d-address';
import { Stringable } from '../../types';
import { AddressSearchLanguage } from '../../service/address-lookup.request';
import { joinString } from '../../../../utilities/commonUtil';
import { traditionalToSimplified } from '../../../utils/simplified-traditional-chinese-convert.util';

export class ChiPremisesAddress extends BasePremisesAddress {
    @Expose({ name: 'ChiDistrict' })
    @Type(() => DistrictWrapper)
    district: DistrictWrapper;

    @Expose({ name: 'ChiEstate' })
    @Type(() => ChiEstate)
    estate: ChiEstate;

    @Expose({ name: 'ChiStreet' })
    @Type(() => Street)
    street: Street;

    @Expose({ name: 'ChiBlock' })
    @Type(() => Block)
    block: Block;

    @Expose({ name: 'Region' })
    @Transform(({ value }) => new TcRegionWrapper(value))
    @Type(() => TcRegionWrapper)
    region: TcRegionWrapper;

    @Expose({ name: 'Chi3dAddress' })
    @Type(() => ChiThreeDAddress)
    threeDAddress?: ChiThreeDAddress[];

    isSimpChn: Boolean=false;

    setSimpChn(flag:Boolean) {
        this.isSimpChn=flag;
    }

    getObject() {
        const blockDescArr = this.block?.isDescriptorGoFirst?
        {
            blockDesc : this.block?.descriptor??"",
            blockNum : this.block?.number??""
        }
        :
        {
            blockNum : this.block?.number??"",
            blockDesc : this.block?.descriptor??""
        }
        const obj = {
            "region": this.region?.getString()??"",
            "district": this.district?.getString()??"",
            "streetName": this.street?.getChineseStreetName()??"",
            "streetNumber": this.street?.getStreetNumber()??"",
            "estate": this.estate?.getString()??"",
            ...blockDescArr,
            "buildingName": this.buildingName?.getString()??"",
        }

        if(this.isSimpChn) {
            Object.keys(obj).forEach(k=>{
                const key = k as keyof typeof obj
                obj[key] = traditionalToSimplified(obj[key])
            })
        }

        return obj;
    }

    getString() {
        const fragments = this.getObject();
        const _addressValues = Object.values(fragments).filter((s) => !!s);
        return joinString(_addressValues, " ");
    }

    getDevelopmentName() {
        return joinString([this.estate?.getString(), this.buildingName?.getString()], ", ");
    }
}

class DistrictWrapper implements Stringable {
    @Expose({ name: 'DcDistrict' })
    district: TcDistrict;

    getString() {
        // console.log('this.district', this.district);
        return (this.district as any as string);
    }
}

class TcRegionWrapper implements Stringable {
    region: string;
    constructor(region: string) {
        this.region = region;
    }

    getString() {
        return this.region;
    }
}
