import axios from 'axios';
import moment, { RFC_2822 } from 'moment';
import store from './wfpCoreStore'
import {setIsFetching, unsetIsFetching, getCaptchaChallenge, setCaptchaToken, SET_LANGUAGE} from './wfpSessionReducer'
import forge from 'node-forge';

import hashUtil from '../utilities/hashUtil';
import pkiHelper from '../utilities/pkiHelper';
import verifyIdUtil from '../utilities/verify-id-util';

import cryptojs_aes from 'crypto-js/aes';
import cryptojs_enc_utf8 from 'crypto-js/enc-utf8';
import { QUERY_FOR_NO_CAPTCHA, DATE_FORMAT, UPDATE_VALIDATE_DELAY } from '../../../utilities/constant';
import browserType from '../../../utilities/checkBroswer';
import {HASH_ALGORITHM} from '../../../utilities/constant';
import { cleanseStringForAlphabets } from '../../../utilities/commonUtil';
import { upsertSubmissionId } from '../../../gcis-payment/redux/slice';

const CHANGE_STEP = 'CHANGE_STEP';
const CLEAR_SIGNED_FIELDS = 'CLEAR_SIGNED_FIELDS';
const REMOVE_SIGNED_FIELD = 'REMOVE_SIGNED_FIELD';

const UPDATE_STEP = 'UPDATE_STEP';
const UPDATE_VALIDATION = 'UPDATE_VALIDATION';
const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const CACHE_FORM_PAGE = 'CACHE_FORM_PAGE';
const UPDATE_FILE_LIST = 'UPDATE_FILE_LIST';
const REMOVE_FORM_DATA = 'REMOVE_FORM_DATA';
const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';
const LOAD_SIGNED_PDF = 'LOAD_SIGNED_PDF';
const CLEAR_PDF = 'CLEAR_PDF';
const RESTORE_PREVIOUS_PDF = 'RESTORE_PREVIOUS_PDF'

const GEN_PDF_FOR_REVIEW_START = "WFP_GEN_PDF_FOR_REVIEW_START";
const GEN_PDF_FOR_REVIEW_DONE = "WFP_GEN_PDF_FOR_REVIEW_DONE";
const GEN_PDF_FOR_REVIEW_ERROR = "WFP_GEN_PDF_FOR_REVIEW_ERROR";
const CLEAR_PDF_NEED_UPDATE = "CLEAR_PDF_NEED_UPDATE";

const PRINT_ON_PAPER_START = "WFP_PRINT_ON_PAPER_START";
const PRINT_ON_PAPER_DONE = "WFP_PRINT_ON_PAPER_DONE";
const PRINT_ON_PAPER_ERROR = "WFP_PRINT_ON_PAPER_ERROR";

const SUBMIT_FORM_START = "WFP_SUBMIT_FORM_START";
const SUBMIT_FORM_DONE = "WFP_SUBMIT_FORM_DONE";
const SUBMIT_FORM_ERROR = "WFP_SUBMIT_FORM_ERROR"; 

const SAVE_FORM_START = "WFP_SAVE_FORM_START";
const SAVE_FORM_DONE = "WFP_SAVE_FORM_DONE";
const SAVE_FORM_ERROR = "WFP_SAVE_FORM_ERROR"; 

const LOAD_FORM_START = "WFP_LOAD_FORM_START";
const LOAD_FORM_DONE = "WFP_LOAD_FORM_DONE";
const LOAD_FORM_ERROR = "WFP_LOAD_FORM_ERROR"; 

const GET_IAMSMART_RESULT_START = "GET_IAMSMART_RESULT_START";
const GET_IAMSMART_RESULT_DONE = "GET_IAMSMART_RESULT_DONE";
const GET_IAMSMART_RESULT_ERROR = "GET_IAMSMART_RESULT_ERROR"; 

const SUBMIT_IAMSMART_START = "WFP_SUBMIT_IAMSMART_START";
const SUBMIT_IAMSMART_DONE = "WFP_SUBMIT_IAMSMART_DONE";
const SUBMIT_IAMSMART_ERROR = "WFP_SUBMIT_IAMSMART_ERROR"; 

const SUBMIT_SIGNING_START = "WFP_SUBMIT_SIGNING_FORM_START";
const SUBMIT_SIGNING_DONE = "WFP_SUBMIT_SIGNING_FORM_DONE";
const SUBMIT_SIGNING_ERROR = "WFP_SUBMIT_SIGNING_FORM_ERROR"; 

const CLEAR_SIGNATURE = "CLEAR_SIGNATURE";

const GET_CAPTCHA_CHALLENGE = "GET_CAPTCHA_CHALLENGE";
const GET_CAPTCHA_CHALLENGE_START = "GET_CAPTCHA_CHALLENGE_START";
const GET_CAPTCHA_CHALLENGE_DONE = "GET_CAPTCHA_CHALLENGE_DONE";
const GET_CAPTCHA_CHALLENGE_ERROR = "GET_CAPTCHA_CHALLENGE_ERROR";

const REQUEST_DELAY = 50; //ms

// action creators
export const changeStep = (steps, toIdx) => ({ type: CHANGE_STEP, payload:{steps: steps, toIndex: toIdx} });
export const updateStep = (idx) => ({ type: UPDATE_STEP, payload: idx });
export const updateValidation = (title, sectionTitleKey, v) => ({ type: UPDATE_VALIDATION, payload: { title: title, sectionTitleKey: sectionTitleKey, results: v } });
export const updateFormData = (title, sectionTitleKey, v) => ({ type: UPDATE_FORM_DATA, payload: { title: title, sectionTitleKey: sectionTitleKey, values: v } });
export const cacheFormPage = (id, html) => ({type: CACHE_FORM_PAGE, payload: {id:id, html:html}});
export const updateFileList = (v) => ({ type: UPDATE_FILE_LIST, payload: v });
export const clearFormData = (resumeRetainFormDataKeys) => ({type: CLEAR_FORM_DATA, payload: {retainKeys: resumeRetainFormDataKeys}});
export const updatePDFData = (pdfData, signedFields) => ({type: LOAD_SIGNED_PDF, payload:{pdfData:pdfData, signedFields:signedFields}});
export const clearPDF = () => ({type: CLEAR_PDF});
export const restorePreviousPdf = () => ({type: RESTORE_PREVIOUS_PDF});
export const removeFormData = (keys) => ({type: REMOVE_FORM_DATA, payload: keys});
// export const setSignPerformed = (signedFields) => ({type: SET_SIGN_PERFORMED, payload: signedFields});
export const clearSignedFields = () => ({type: CLEAR_SIGNED_FIELDS});
export const removeSignedField = (id) => ({type: REMOVE_SIGNED_FIELD, payload: id});


export const genPdfForReviewStart = () => ({type: GEN_PDF_FOR_REVIEW_START});
export const genPdfForReviewSuccess = (data) => ({type:GEN_PDF_FOR_REVIEW_DONE, payload: data});
export const genPdfForReviewError = (err) => ({type: GEN_PDF_FOR_REVIEW_ERROR, payload: err});
export const clearPdfNeedUpdate = () => ({type: CLEAR_PDF_NEED_UPDATE});

export const printOnPaperStart = () => ({type: PRINT_ON_PAPER_START});
export const printOnPaperSuccess = (data) => ({type:PRINT_ON_PAPER_DONE, payload: data});
export const printOnPaperError = (err) => ({type: PRINT_ON_PAPER_ERROR, payload: err});

export const submitFormStart = () => ({type: SUBMIT_FORM_START});
export const submitFormSuccess = (data) => ({type:SUBMIT_FORM_DONE, payload: data});
export const submitFormError = (err) => ({type: SUBMIT_FORM_ERROR, payload: err});

export const getIAMSmartStatusStart = () => ({type: GET_IAMSMART_RESULT_START});
export const getIAMSmartStatusSuccess = (data) => ({type:GET_IAMSMART_RESULT_DONE, payload: data});
export const getIAMSmartStatusError = (err) => ({type: GET_IAMSMART_RESULT_ERROR, payload: err});

export const submitIAMSmartStart = () => ({type: SUBMIT_IAMSMART_START});
export const submitIAMSmartSuccess = (data) => ({type:SUBMIT_IAMSMART_DONE, payload: data});
export const submitIAMSmartError = (err) => ({type: SUBMIT_IAMSMART_ERROR, payload: err});

export const submitSigningStart = () => ({type: SUBMIT_SIGNING_START});
export const submitSigningSuccess = (data) => ({type:SUBMIT_SIGNING_DONE, payload: data});
export const submitSigningError = (err) => ({type: SUBMIT_SIGNING_ERROR, payload: err});

export const saveFormStart = () => ({type: SAVE_FORM_START});
export const saveFormSuccess = (data) => ({type:SAVE_FORM_DONE, payload: data});
export const saveFormError = (err) => ({type: SAVE_FORM_ERROR, payload: err});

export const loadFormStart = () => ({type: LOAD_FORM_START});
export const loadFormSuccess = (data) => ({type:LOAD_FORM_DONE, payload: data});
export const loadFormError = (err) => ({type: LOAD_FORM_ERROR, payload: err});

export const clearSignature = () => ({type: CLEAR_SIGNATURE});

// util
const beforeUnload = (e) => {
    e.preventDefault();
    (e || window.event).returnValue = "Leave site? Changes you made may not be saved.";     // Gecko + IE
    return "";                                // Webkit, Safari, Chrome etc.
};

const bytesFromBase64String = base64String => {
    const byteArray = Uint8Array.from(
        atob(base64String)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    return byteArray;
//    return new Blob([byteArray], { type: 'application/pdf' });
    };

export const callWithRecaptcha = (recaptcha, dispatch, toDispatch) => {
    // dispatch(setIsFetching());
    try {
        // window.grecaptchaOnVerify = token => { dispatch(toDispatch(token)); };
        dispatch(toDispatch());
        // recaptcha().reset();
        // recaptcha().execute();
    } finally {
        // dispatch(unsetIsFetching());
    }
}
    
export const saveFile = (fileBase64, mimeType, filename) => {
    if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(new Blob([bytesFromBase64String(fileBase64)],{type: mimeType }), filename);  
    }
    else {
        // var objectUrl = URL.createObjectURL(blob);
        // window.open(objectUrl);  
        const url = (window.URL || window.webkitURL).createObjectURL(new Blob([bytesFromBase64String(fileBase64)]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
}

//
const preprocessFormData = (formSpec, submitFormData) => {
    const signFields = formSpec.signFields;
    const signFieldsTransformFunc = formSpec.signFieldsTransformFunc;
    let formData = {...submitFormData};
    let submissionDetail = {};

    // Remove Signature Fields
    if (signFields) {
        signFields(formData).map(o => {
            let fieldName = o;
            formData[fieldName] = undefined;

            if (signFieldsTransformFunc) {
                fieldName = signFieldsTransformFunc(o, formData)
                formData[fieldName] = undefined;
            }
        });
    }

    // Attachments
    // const fileList = store.getState().formDetail.fileList;
    // const fileDatas = {};
    // fileList.forEach(k=>{
    //     if (k) {
    //         const file = formData[k];
    //         if (file) {
    //             file.id = k;
    //             const fileJson = JSON.stringify({name: file.name, file: file.file})
    //             const hash = hashUtil.hashStringToHex(fileJson);
    //             formData[k] = {isFile: true, name: file.name, hash:hash};

    //             fileDatas[k] = file;
    //         }
    //     }
    // });
    const fileDatas = {};
    Object.keys(formData).forEach(k=>{
        if (formData[k] && formData[k].file) {
            const file = formData[k];
            if (file) {
                file.id = k;
                const fileJson = JSON.stringify({name: file.name, file: file.file})
                const hash = hashUtil.hashStringToHex(fileJson);
                formData[k] = {isFile: true, name: file.name, hash:hash};

                fileDatas[k] = file;
            }
        }
    });

    
    // Add Form Detail
    formData["formDetail"] = {code: formSpec.code, reference: formSpec.reference}

    // // Add Form Page
    // submissionDetail["formPage"] = store.getState().formDetail.formPage;

    // Remove 
    submissionDetail["receiveAckEmail"] = formData["receiveAckEmail"];
    submissionDetail["ackEmail"] = formData["ackEmail"];
    submissionDetail["ackEmailIncludeFilledForm"] = formData["ackEmailIncludeFilledForm"];
    submissionDetail["password"] = formData["password"];
    submissionDetail["confirmPassword"] = formData["confirmPassword"];
    submissionDetail["pdfData"] = formData["pdfData"];
    submissionDetail["uploadECheque"] = formData["uploadECheque"];
    submissionDetail["attachments"] = fileDatas;
    submissionDetail["dialogSignIASHKID"] = formData["dialogSignIASHKID"];
    submissionDetail["gcisTxnId"] = formData["gcisTxnId"]; // TODO: 

    formData["receiveAckEmail"] = undefined;
    formData["ackEmail"] = undefined;
    formData["ackEmailIncludeFilledForm"] = undefined;
    formData["password"] = undefined;
    formData["confirmPassword"] = undefined;
    formData["pdfData"] = undefined;
    formData["uploadECheque"] = undefined;
    formData["dialogSignIASHKID"] = undefined;
    formData["gcisTxnId"] = undefined;

    return {formData:formData, submissionDetail: submissionDetail}
}


const axiosPostRequest = ({dispatch, url, data, handle200, handleNon200, handleCaptchaChallenge, handleCatch, errorCallback}) => {
    try {
        axios.create({
            validateStatus: function (status)
            {
                return status == 200 || status == 502 || status == 777 || status == 778 || status == 779 || status == 780;
            }
        })
        .post(
            url,
            data,
            store.getState().session.captchaToken 
                ? 
                    { 
                        headers: {
                                    "Access-Token": store.getState().session.captchaToken ?? undefined,
                        } 
                    }
                :               
                    store.getState().session.captchaChallengeAnswer ? { 
                        headers: {
                                    "challengeRs": store.getState().session.captchaChallenge.secret + ";" + store.getState().session.captchaChallengeAnswer
                        } 
                    }
                    :
                    {}
        )
        .then(response => {
            switch(response.status) {
                case 200:
                    if (handle200) { handle200(response); }
                    break; 
                case 502:
                    dispatch(getCaptchaChallenge());
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback({ err: "serverBusy" });
                    break;
                case 777: 
                    dispatch(getCaptchaChallenge());
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback({ err: "captchaError" });
                    if (handleCaptchaChallenge) handleCaptchaChallenge({ err: "captchaError" });

                    break;
                case 778:
                    dispatch(getCaptchaChallenge());
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback({ err: "captchaRequired" });
                    if (handleCaptchaChallenge) handleCaptchaChallenge({ err: "captchaRequired" });

                    break;
                case 779:
                    dispatch(getCaptchaChallenge());
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback({ err: "captchaExpired" });
                    if (handleCaptchaChallenge) handleCaptchaChallenge({ err: "captchaExpired" });

                    break;
                case 780:
                    dispatch(getCaptchaChallenge());
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback({ err: "captchaNotMatch" });
                    if (handleCaptchaChallenge) handleCaptchaChallenge({ err: "captchaNotMatch" });

                    break;
                default:
                    if (handleNon200) { handleNon200(response); } 
                    break;
            }

            if (response.headers["access-token"]) {
                dispatch(setCaptchaToken(response.headers["access-token"]));
                dispatch(getCaptchaChallenge());
            } else {
                dispatch(setCaptchaToken(null));
            }
        })
        .catch (err => { 
            if (handleCatch) { handleCatch(err); }
        });
        
        
    } catch (err) {
        if (handleCatch) { handleCatch(err); }
    }

};

export const genPdfForReview = (formSpec, successCallback, errorCallback, captchaChallengeCallback) => {
    return dispatch => {
        setTimeout(()=>{
            dispatch(genPdfForReviewStart());
            dispatch(setIsFetching());

            let formUrl =  formSpec.code;
            const submitTransformFunc = formSpec.submitTransformFunc;
            const submitDateId = formSpec.submitDateId;
            const totalPaymentId = formSpec.paymentAmountId;

            let submitFormData = {...store.getState().formDetail.formData};
            
            if (submitDateId) {
                submitFormData = {...submitFormData, [submitDateId(submitFormData)]: moment().format(DATE_FORMAT)};
            }
            

            if (submitTransformFunc) {
                submitFormData = submitTransformFunc(submitFormData);
            }

            if (submitFormData.formCodeOverride) {
                formUrl = submitFormData.formCodeOverride;
            }

            let paymentAmount = "0";
            if (totalPaymentId && submitFormData[totalPaymentId]) {
                paymentAmount = ""+submitFormData[totalPaymentId];
            }

            const splittedData = preprocessFormData(formSpec, submitFormData);

            axiosPostRequest({
                dispatch: dispatch,
                url: '/api/submission/review/'+formUrl+"/0"+QUERY_FOR_NO_CAPTCHA, 
                data: {
                    formData: splittedData.formData,
                    submissionDetail: 
                                {
                                    ...splittedData.submissionDetail,
                                    signedFields: store.getState().formDetail.signedFields,
                                    lang: store.getState().session.curLanguage,
                                },
                },
                handle200: response => {
                    dispatch(genPdfForReviewSuccess({formCode: formUrl, data: response.data})); 
                    dispatch(unsetIsFetching())
                    if (successCallback) successCallback();
                },
                handleNon200: response => {
                    dispatch(genPdfForReviewError(response.data));
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback(response.data.message ?? response.data);
                },
                handleCaptchaChallenge: captchaChallengeCallback,
                handleCatch: err => {
                    dispatch(genPdfForReviewError(err));
                    dispatch(unsetIsFetching());
                    let msg = err.message ?? err;
                    if (err.response
                        && err.response.data
                        && err.response.data.result === "error") {
                        msg = err.response.data.message;
                    }
                    console.error("getForms: [ERROR] " + err);
                    if (errorCallback) errorCallback({err:msg});
                    throw err;
                },
                errorCallback: errorCallback
            });
        }, UPDATE_VALIDATE_DELAY + REQUEST_DELAY);
	}
};

// action
// export const printOnPaper = (formUrl, submitTransformFunc, submitDateId, errorCallback, token) => {
export const printOnPaper = (formSpec, isCompletedForm, successCallback, errorCallback, captchaChallengeCallback) => {
    return dispatch => {
        setTimeout(()=>{
            dispatch(printOnPaperStart());
            dispatch(setIsFetching());

            let formUrl =  formSpec.code;
            const submitTransformFunc = formSpec.submitTransformFunc;
            // const submitDateId = formSpec.submitDateId;
            const totalPaymentId = formSpec.paymentAmountId;

            let submitFormData = {...store.getState().formDetail.formData, printOnPaper:true};
            /*
            if (submitDateId) {
                submitFormData = {...submitFormData, [submitDateId(submitFormData)]: moment().format(DATE_FORMAT)};
            }
            */

            if (submitTransformFunc) {
                submitFormData = submitTransformFunc(submitFormData);
            }

            if (submitFormData.formCodeOverride) {
                formUrl = submitFormData.formCodeOverride;
            }

            let paymentAmount = "0";
            if (totalPaymentId && submitFormData[totalPaymentId]) {
                paymentAmount = ""+submitFormData[totalPaymentId];
            }

            const pdfData = store.getState().formDetail.pdfData ?? null ;


            if (isCompletedForm
                    && pdfData) {
                dispatch(printOnPaperSuccess({formCode: formUrl, data: {pdfData:pdfData}})); 
                dispatch(unsetIsFetching())
                if (successCallback) successCallback();
            }
            else {
                const splittedData = preprocessFormData(formSpec, submitFormData);

                axiosPostRequest({
                    dispatch: dispatch,
                    url: '/api/submission/'+formUrl+"/0"+QUERY_FOR_NO_CAPTCHA, 
                    data: {
                        formData: splittedData.formData,
                        submissionDetail: 
                                    {
                                        ...splittedData.submissionDetail,
                                        signedFields: store.getState().formDetail.signedFields,
                                        lang: store.getState().session.curLanguage,
                                        printOnPaper: true,
                                    },
                    },
                    handle200: response => {
                        dispatch(printOnPaperSuccess({formCode: formUrl, data: response.data})); 
                        dispatch(unsetIsFetching())
                        if (successCallback) successCallback();
                    },
                    handleNon200: response => {
                        dispatch(printOnPaperError(response.data));
                        dispatch(unsetIsFetching())
                        if (errorCallback) errorCallback(response.data.message ?? response.data);
                    },
                    handleCaptchaChallenge: captchaChallengeCallback,
                    handleCatch: err => {
                        dispatch(printOnPaperError(err));
                        dispatch(unsetIsFetching());
                        let msg = err.message ?? err;
                        if (err.response
                            && err.response.data
                            && err.response.data.result === "error") {
                            msg = err.response.data.message;
                        }
                        console.error("getForms: [ERROR] " + err);
                        if (errorCallback) errorCallback({err:msg});
                        throw err;
                    },
                    errorCallback: errorCallback
                }); 
            }
        }, UPDATE_VALIDATE_DELAY + REQUEST_DELAY);
        
	}
};

export const saveDataFile = (form, formData, language, showErrorDialog, obj, errorCallback, captchaChallengeCallback, withSignAndAttch) => {
    return async dispatch => {

        const f = { 
            // ...formData, 
            formCode: form.code,
            formRef: form.reference,
            validFrom: form.validFrom, 
            password : obj.saveECert ? undefined : pkiHelper.hashString(obj.savePwConfirm),
            withSignAndAttch : withSignAndAttch,
        };

        const signedFields = store.getState().formDetail.signedFields;
        let newFormData = {...formData};
        let pdfData = undefined;

        if (!withSignAndAttch) {
            // Remove Signature
            if (signedFields) {
                Object.keys(signedFields).forEach(k=>{
                    delete newFormData[k];
                })
            }
        }
        else {
            // Get the signed pdf, if any
            pdfData = store.getState().formDetail.pdfData;
            f.signedFields = signedFields;
        }

        // Encrypt Data
        if (obj.saveECert) {
            f.encryptedFormData = pkiHelper.encrypt(pkiHelper.loadPublicKeyFromBase64(obj.saveECert.file, obj.pin), JSON.stringify(newFormData),"RSA-OAEP");
            if (pdfData) {
                f.pdfData = pkiHelper.encrypt(pkiHelper.loadPublicKeyFromBase64(obj.saveECert.file, obj.pin), pdfData,"RSA-OAEP");
            }

        } else {
            f.encryptedFormData = cryptojs_aes.encrypt(JSON.stringify(newFormData), obj.savePwConfirm).toString();
            if (pdfData) {
                f.pdfData = cryptojs_aes.encrypt(pdfData, obj.savePwConfirm).toString();
            }
        } 
        
        dispatch(setIsFetching()); 
        axiosPostRequest({
            dispatch: dispatch,
            url: "/api/tempsave/save" + QUERY_FOR_NO_CAPTCHA, 
            data: f,
            handle200: response => {
                if (response.data.result === "ok") {
                    const filename=form.code + '_' + moment().format('yyyyMMDDHHmmss') + '.lrwf';
                    saveFile(response.data.data, 'application/octet-stream', filename);
                } else {
                    throw response.data.exc;
                }
            },
            handleNon200: response => {
                window.requestAnimationFrame(() => dispatch(showErrorDialog({err: 'Server responded with ' + response.status}, language)));
            },
            handleCaptchaChallenge: captchaChallengeCallback,
            handleCatch: err => {
                if (err.message) {
                    let msg = language[err.message] ?? err.message;
                    if (err.args){
                        msg = language.formatString(language[err.message], ...err.args)
                    }
    
                    // if (err.message.indexOf(" password") >= 0) {
                    // 	msg = language.saveCertPasswordError;
                    // }
                    
                    console.log(err); 
                    
                    window.requestAnimationFrame(() => dispatch(showErrorDialog({err: msg}, language)));
    
                } else {
                    window.requestAnimationFrame(() => dispatch(showErrorDialog({err: err}, language)));
                    console.log(err);
                }
            },
            errorCallback: errorCallback
        }); 
        dispatch(unsetIsFetching());  

    }
}

export const loadDataFile = (form, formData, language, showErrorDialog, obj, errorCallback, setStepIdx, sourceType, captchaChallengeCallback) => {
    return async dispatch => {

        if(!obj.loadFile) {
            window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:language.fileNotSelected}, language)));
            return;
        }
        
        //dispatch(clearFormData());
        dispatch(setIsFetching());
                
        axiosPostRequest({
            dispatch: dispatch,
            url: "/api/tempsave/load" + QUERY_FOR_NO_CAPTCHA,
            data: 
            {
                formCode : form.code ,
                formRef : form.reference ,
                validFrom : form.validFrom ,
                data: obj.loadFile.file,
                password: obj.loadECert ? undefined : pkiHelper.hashString(obj.loadPw),
            },
            handle200: loadResponse => {
                if (loadResponse.data.result === "ok") {
                    dispatch(clearFormData());

                    const responseData = JSON.parse(loadResponse.data.data);

                    const getDecryptedData = (encryptedData) => {
                        if (!encryptedData) return undefined;
                        if (obj.loadECert) {
                            return pkiHelper.decrypt(pkiHelper.loadPrivateKeyFromBase64(obj.loadECert.file, obj.pin), encryptedData, "RSA-OAEP");
                        } else { 
                            return cryptojs_aes.decrypt(encryptedData, obj.loadPw).toString(cryptojs_enc_utf8);
                        }
                    }
                    
                    const formData = JSON.parse(getDecryptedData(responseData.encryptedFormData));
                    const pdfData = getDecryptedData(responseData.pdfData);
                    const signedFields = responseData.signedFields;
                    const withSignAndAttch = responseData.withSignAndAttch;
                    
                    // restore data to form data
                    // TODO: check form params
                    // if (f.formCode === form.code 
                        // && f.formRef === form.reference
                        // && f.validFrom === formObj.validFrom) {
                        // document.activeElement.blur();
                       
                        dispatch(updateFormData(null, null, formData));
                        if (withSignAndAttch) {
                            dispatch(updatePDFData(pdfData, signedFields));
                            if (form.resumeSignedFormEraseList) {
                                form.resumeSignedFormEraseList.forEach(v=>{dispatch(removeFormData({ [v]: undefined}));})
                            }
                            setStepIdx(1); // T&C page
                        }
                        else {
                            setStepIdx(form.resumeFromStepIdx);
                        }
                        // the following line returns to resume step after loading from file
                        // but it causes issues as the form data is not yet refreshed here...
                        // window.requestAnimationFrame(() =>  changeStep(resumeFromStepIdx, true));'
                        
                    // } 

                    if (loadResponse.data.message && loadResponse.data.message === "formRefError") {
                        window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:language.loadFormOldVersion}, language)));
                    }
                    dispatch(unsetIsFetching());
                } else if (loadResponse.data.result === "error") {
                    dispatch(unsetIsFetching());
                    throw { message: loadResponse.data.exc.message };
                } else {
                    dispatch(unsetIsFetching());
                    throw { message: loadResponse.data.message };
                }
            },
            handleNon200: (loadResponse) => {
                dispatch(unsetIsFetching());
                throw { message: 'Server responded with ' + loadResponse.status };

            },
            handleCaptchaChallenge: captchaChallengeCallback,
            handleCatch: (err) => {
                dispatch(unsetIsFetching());
				const e=err;
				console.log(err);
				if ((err.message && err.message.indexOf('bad key') >= 0)) { 
					window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:language.passwordError}, language)));
				} else if((err.message && err.message.indexOf('file') >= 0)) {  
					window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:language.fileNotSelected}, language)));
				} else if((err.message && err.message.indexOf('formCodeError') >= 0)) { 
					window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:language.loadFormCodeError(sourceType, err.message.split("|")[1], err.message.split("|")[2])}, language)));
				} else if (err.message === null){
					window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:language.passwordError}, language)));
                } else {
					window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:JSON.stringify(err)}, language)));
                }
            },
            errorCallback: errorCallback
        });
           
    }
}


export const submitFormByTxnId = (txnId, formSpec, successCallback, errorCallback, onCaptchaChallenge, ) => {

    return dispatch => {
        setTimeout(()=>{
            dispatch(submitFormStart());
            dispatch(setIsFetching());
            // dispatch(setCaptchaToken(null));

            let formUrl =  formSpec.code;
            const submitTransformFunc = formSpec.submitTransformFunc;
            const submitDateId = formSpec.submitDateId;
            const totalPaymentId = formSpec.paymentAmountId;
            let submitFormData = store.getState().formDetail.formData;
            if (submitDateId) {
                submitFormData = {...submitFormData, [submitDateId(submitFormData)]: moment().format(DATE_FORMAT)};
            }

            let paymentAmount = "0";
            if (totalPaymentId && submitFormData[totalPaymentId]) {
                paymentAmount = ""+submitFormData[totalPaymentId];
            }

            if (submitTransformFunc) {
                submitFormData = submitTransformFunc(submitFormData);
            }        

            if (submitFormData.formCodeOverride) {
                formUrl = submitFormData.formCodeOverride;
            }
    
            const splittedData = preprocessFormData(formSpec, submitFormData);

            splittedData.submissionDetail["pdfData"] = undefined;
            splittedData.submissionDetail["attachments"] = undefined;

            axiosPostRequest({
                dispatch: dispatch,
                url: '/api/submission/submitFormByTxnId'+QUERY_FOR_NO_CAPTCHA, 
                data: {
                    submissionDetail: 
                                {
                                    ...splittedData.submissionDetail,
                                    signedFields: store.getState().formDetail.signedFields,
                                    lang: store.getState().session.curLanguage
                                },
                },
                handle200: response => {
                    switch(response.data.result) {
                    case "ok":
                        dispatch(submitFormSuccess({
                            refNum:response.data.referenceNumber,
                            submissionTime:moment().format('yyyy-MM-DD HH:mm'),
                            paymentTxnNum:response.data.paymentTxnNum,
                            gcisWallet:response.data.gcisWallet,
                            paymentMethod:response.data.paymentMethod,
                            paymentAmount:response.data.paymentAmount
                        })); 
                        dispatch(unsetIsFetching());
                        if (successCallback) successCallback();
                        break;
                    case "signatureFailure":
                        // Some signatures are invalid
                        const language = store.getState().session.language;
                        dispatch(submitFormError(response.data));
                        dispatch(unsetIsFetching())
                        if (errorCallback) errorCallback(language.formatString( "{0}, {1}", ...response.data.invalidSignatures));
                        break;
                    default: 
                        dispatch(submitFormError(response.data));
                        dispatch(unsetIsFetching())
                        if (errorCallback) errorCallback(response.data.message ?? response.data);
                        break;
                    }
                },
                handleNon200: response => {
                    dispatch(submitFormError(response.data));
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback(response.data.message ?? response.data);
                },
                handleCatch: err => {
                    dispatch(submitFormError(err));
                    dispatch(unsetIsFetching())
                    if (errorCallback) {
                        let msg = err.message;
                        if (err.response
                            && err.response.data
                            && err.response.data.result === "error") {
                            msg = err.response.data.message;
                        }
                        errorCallback(msg);
                    }
                    console.error("getForms: [ERROR] " + err);
                },
                errorCallback: errorCallback,
                handleCaptchaChallenge: onCaptchaChallenge,
            });
        }, UPDATE_VALIDATE_DELAY + REQUEST_DELAY);
	}

}


export const submitForm = (formSpec, successCallback, errorCallback, onCaptchaChallenge, ) => {
    return dispatch => {
        setTimeout(()=>{
            dispatch(submitFormStart());
            dispatch(setIsFetching());
            // dispatch(setCaptchaToken(null));

            let formUrl =  formSpec.code;
            const submitTransformFunc = formSpec.submitTransformFunc;
            const submitDateId = formSpec.submitDateId;
            const totalPaymentId = formSpec.paymentAmountId;
            let submitFormData = store.getState().formDetail.formData;
            if (submitDateId) {
                submitFormData = {...submitFormData, [submitDateId(submitFormData)]: moment().format(DATE_FORMAT)};
            }

            let paymentAmount = "0";
            if (totalPaymentId && submitFormData[totalPaymentId]) {
                paymentAmount = ""+submitFormData[totalPaymentId];
            }

            if (submitTransformFunc) {
                submitFormData = submitTransformFunc(submitFormData);
            }        

            if (submitFormData.formCodeOverride) {
                formUrl = submitFormData.formCodeOverride;
            }
    
            const splittedData = preprocessFormData(formSpec, submitFormData);

            axiosPostRequest({
                dispatch: dispatch,
                url: '/api/submission/'+formUrl+"/"+paymentAmount+QUERY_FOR_NO_CAPTCHA, 
                data: {
                    formData: splittedData.formData,
                    submissionDetail: 
                                {
                                    ...splittedData.submissionDetail,
                                    signedFields: store.getState().formDetail.signedFields,
                                    lang: store.getState().session.curLanguage,
                                    pdfData: store.getState().formDetail.pdfData,
                                },
                    
                },
                handle200: response => {
                    switch(response.data.result) {
                    case "ok":
                        dispatch(submitFormSuccess({
                            refNum:response.data.referenceNumber,
                            submissionTime:moment().format('yyyy-MM-DD HH:mm'),
                            paymentTxnNum:response.data.paymentTxnNum,
                            gcisWallet:response.data.gcisWallet,
                            paymentMethod:response.data.paymentMethod,
                            paymentAmount:response.data.paymentAmount
                        })); 
                        dispatch(unsetIsFetching());
                        if (successCallback) successCallback();
                        break;
                    case "signatureFailure":
                        // Some signatures are invalid
                        const language = store.getState().session.language;
                        dispatch(submitFormError(response.data));
                        dispatch(unsetIsFetching())
                        if (errorCallback) errorCallback(language.formatString( "{0}, {1}", ...response.data.invalidSignatures));
                        break;
                    default: 
                        dispatch(submitFormError(response.data));
                        dispatch(unsetIsFetching())
                        if (errorCallback) errorCallback(response.data.message ?? response.data);
                        break;
                    }
                },
                handleNon200: response => {
                    dispatch(submitFormError(response.data));
                    dispatch(unsetIsFetching())
                    if (errorCallback) errorCallback(response.data.message ?? response.data);
                },
                handleCatch: err => {
                    dispatch(submitFormError(err));
                    dispatch(unsetIsFetching())
                    if (errorCallback) {
                        let msg = err.message;
                        if (err.response
                            && err.response.data
                            && err.response.data.result === "error") {
                            msg = err.response.data.message;
                        }
                        errorCallback(msg);
                    }
                    console.error("getForms: [ERROR] " + err);
                },
                errorCallback: errorCallback,
                handleCaptchaChallenge: onCaptchaChallenge,
            });
        }, UPDATE_VALIDATE_DELAY + REQUEST_DELAY);

		// axios
		// .post(
        //     '/api/submission/'+formUrl+"/"+paymentAmount+QUERY_FOR_NO_CAPTCHA, 
        //     {
        //         formData: splittedData.formData,
        //         submissionDetail: 
        //                     {
        //                         ...splittedData.submissionDetail,
        //                         signedFields: store.getState().formDetail.signedFields,
        //                         lang: store.getState().session.curLanguage,
        //                     },
                
        //     },
        //     token ? { headers: {"Access-Token": token} } : null)
		// .then(response => {
		// 	switch(response.status) {
		// 		case 200:
        //             switch(response.data.result) {
        //             case "ok":
        //                 dispatch(submitFormSuccess({refNum:response.data.referenceNumber, submissionTime:moment().format('yyyy-MM-DD HH:mm')})); 
        //                 dispatch(unsetIsFetching());
        //                 if (successCallback) successCallback();
        //                 break;
        //             case "signatureFailure":
        //                 // Some signatures are invalid
        //                 const language = store.getState().session.language;
        //                 dispatch(submitFormError(response.data));
        //                 dispatch(unsetIsFetching())
        //                 if (errorCallback) errorCallback(language.formatString( "{0}, {1}", ...response.data.invalidSignatures));
        //                 break;
        //             default: 
        //                 dispatch(submitFormError(response.data));
        //                 dispatch(unsetIsFetching())
        //                 if (errorCallback) errorCallback(response.data.message ?? response.data);
        //                 break;
        //             }
		// 			break;
		// 		default:
        //             dispatch(submitFormError(response.data));
        //             dispatch(unsetIsFetching())
        //             if (errorCallback) errorCallback(response.data.message ?? response.data);
		// 			break;
		// 	}

		// })
		// .catch (err => {
		// 	dispatch(submitFormError(err));
        //     dispatch(unsetIsFetching())
        //     if (errorCallback) {
        //         let msg = err.message;
        //         if (err.response
        //             && err.response.data
        //             && err.response.data.result === "error") {
        //             msg = err.response.data.message;
        //         }
        //         errorCallback(msg);
        //     }
		// 	console.error("getForms: [ERROR] " + err);
		// });
	}
}

// TODO: Remove unused thunk
export const getIAMSmartStatus = (businessID, signField, signedData, token, successCallback, errorCallback) => {
    return async dispatch => {
        dispatch(getIAMSmartStatusStart());
        // dispatch(setIsFetching());

        const submitError = err => {
            dispatch(getIAMSmartStatusError(err));
            // dispatch(unsetIsFetching());
            if (errorCallback) {
                let msg = err.message;
                if (err.response
                    && err.response.data
                    && err.response.data.result === "error") {
                    msg = err.response.data.message;
                }
                errorCallback(msg);
            }
        }

        try {
            let response = await axios.get('/api/iaspdfsign/poll/'+businessID+'/'+signField+QUERY_FOR_NO_CAPTCHA,
                                        token ? { headers: {"Access-Token": token} } : null);
            
            switch(response.status) {
                case 200:
                    switch(response.data.status) {
                    case "completed":
                        if (successCallback) successCallback();
                        const signature =   {
                            [signField]:    {
                                                algorithm: HASH_ALGORITHM,
                                                method: "iamsmart",
                                                hkid: store.getState().formDetail.formData.dialogSignIASHKID,
                                                pub: response.data.pub,
                                                signedData: signedData,
                                                signedHash: response.data.signature,
                                                time: moment(),
                                            }
                        }

                        dispatch(getIAMSmartStatusSuccess(signature));
                        // dispatch(getIAMSmartStatusSuccess(response.data.signedPDF)); 
                        // dispatch(unsetIsFetching());
                        break;
                    case "pending":
                        setTimeout(
                            ()=>{dispatch(getIAMSmartStatus(businessID, signField, signedData, token, successCallback, errorCallback))},
                            3000
                        )
                        break;
                    case "failed":
                        submitError(response.data);
                        break;
                    }
                    break;
                default:
                    submitError(response.data);
                    break;
            }
        } catch (err) {
            submitError(err);
        }
    }
}


export const getIAMSmartStatusPdf = (businessID, signField, signatureTimestamp, signedData, token, successCallback, errorCallback) => {
    return async dispatch => {
        dispatch(getIAMSmartStatusStart());
        // dispatch(setIsFetching());

        const submitError = err => {
            dispatch(getIAMSmartStatusError(err));
            // dispatch(unsetIsFetching());
            if (errorCallback) {
                let msg = err.message;
                if (err.response
                    && err.response.data
                    && err.response.data.result === "error") {
                    msg = err.response.data.message;
                }
                errorCallback(msg);
            }
        }

        try {
            let response = await axios.get('/api/iaspdfsign/poll/'+businessID+'/'+signField+QUERY_FOR_NO_CAPTCHA,
                                        token ? { headers: {"Access-Token": token} } : null);
            
            switch(response.status) {
                case 200:
                    switch(response.data.status) {
                    case "completed":
                        const formObj = store.getState().session.formObj;
                        const signature =   {
                            [signField]:    {
                                signedBy: response.data.signedBy,
                                signatureName: response.data.signatureName,
                                time: signatureTimestamp,
                            }
                        }

                        // TODO: check ID
                        // assume idPassed must be true as it is checked in EID dialog, and after going through iAM Smart it must be valid.
                        // also, as the private key is not returned, it is not possible to verify here.
                        const {signIdErrs} = verifyIdUtil.verifySignId({
                            formSpec: formObj,
                            submitSigningData: store.getState().formDetail.formData,
                            // pkBase64: pkBase64,
                            // pkpin: pkpin,
                            pdfData: response.data.signedPDF
                        });
                        
                        // IAMSmart already checked: [cert type] and [personal id]
                        if (signIdErrs.includes("validation_signature_signerNameMismatch")) {
                            signature.valErrs = ["validation_signature_signerNameMismatch"];
                            dispatch(getIAMSmartStatusSuccess({pdfData:store.getState().formDetail.pdfData, signature: signature}));
                        } else {
                            dispatch(getIAMSmartStatusSuccess({pdfData:response.data.signedPDF, signature: signature}));
                        }

                        // Saving submissionId prior to payment attempt to associate with customer in case payment fails
                        const submissionId = response.data?.submissionId;
                        dispatch(upsertSubmissionId(submissionId));

                        if (successCallback) successCallback(signature);
                        // dispatch(getIAMSmartStatusSuccess({pdfData:response.data.signedPDF, signature: signature}));
                        break;
                    case "pending":
                        setTimeout(
                            ()=>{dispatch(getIAMSmartStatusPdf(businessID, signField, signatureTimestamp, signedData, token, successCallback, errorCallback))},
                            3000
                        )
                        break;
                    case "failed":
                        submitError(response.data);
                        break;
                    }
                    break;
                default:
                    submitError(response.data);
                    break;
            }
        } catch (err) {
            submitError(err);
        }
    }
}


export const submitIAMSmart = (formUrl, signFieldOrg, formSpec, successCallback, errorCallback) => {
    return async dispatch => {
        setTimeout(()=>{
            dispatch(submitIAMSmartStart());
            dispatch(setIsFetching());

            let submitSigningData = {...store.getState().formDetail.formData};
            if (formSpec.submitDateId) {
                submitSigningData = {...submitSigningData, [formSpec.submitDateId(submitSigningData)]: moment().format(DATE_FORMAT)};
            }

            if (formSpec.submitTransformFunc) {
                submitSigningData = formSpec.submitTransformFunc(submitSigningData);
            }

            let signField = signFieldOrg;
            if (formSpec.signFieldsTransformFunc){
                signField = formSpec.signFieldsTransformFunc(signFieldOrg, submitSigningData);
            }

            const submitError = err => {
                dispatch(submitIAMSmartError(err));
                dispatch(unsetIsFetching());
                console.log(err);
                if (errorCallback) {
                    let msg = err.message;
                    if (err.response
                        && err.response.data
                        && err.response.data.result === "error") {
                        msg = err.response.data.message;
                    }
                    errorCallback(msg);
                }
            }

            const source = browserType();
            const splittedData = preprocessFormData(formSpec, submitSigningData);
            const signedForm = store.getState().formDetail.formPage['confirmation-step-body'];
            const dataToBeSigned = {signedData: splittedData.formData, signedForm: signedForm}

            axiosPostRequest({
                dispatch: dispatch,
                url: '/api/iaspdfsign/init/'+formUrl+'/'+signField+QUERY_FOR_NO_CAPTCHA, 
                data: {
                        dataToBeSigned:JSON.stringify(dataToBeSigned),
                        submissionDetail: 
                                    {
                                        ...splittedData.submissionDetail,
                                        lang: store.getState().session.curLanguage,
                                        source: source,
                                    },
                    },
                handle200: response => {
                    if (response.data.result === "D00000") {
                        dispatch(submitIAMSmartSuccess(response.data)); 
                        dispatch(unsetIsFetching());
                        
                        // Success
                        if (successCallback) {
                            successCallback({data: response.data, signField: signField, signedData: JSON.stringify(dataToBeSigned)});
                        }
                        
                    } else {
                        submitError(response.data);
                    }
                },
                handleNon200: response => submitError(response.data),
                handleCatch: err => submitError(err),
                // handleCaptchaChallenge: err => submitError(err.err),
                errorCallback: errorCallback
            });
        }, UPDATE_VALIDATE_DELAY + REQUEST_DELAY);

        // try {
        //     let response = await axios.post(
        //                                 '/api/iaspdfsign/init/'+formUrl+'/'+signField+QUERY_FOR_NO_CAPTCHA, 
        //                                 {
        //                                     dataToBeSigned:JSON.stringify(dataToBeSigned),
        //                                     submissionDetail: 
        //                                                 {
        //                                                     ...splittedData.submissionDetail,
        //                                                     lang: store.getState().session.curLanguage,
        //                                                     source: source,
        //                                                 },
        //                                 },
        //                                 token ? { headers: {"Access-Token": token} } : null);
                            
        //     switch(response.status) {
        //         case 200:
        //             if (response.data.result === "D00000") {
        //                 dispatch(submitIAMSmartSuccess(response.data)); 
        //                 dispatch(unsetIsFetching());
                        
        //                 // Success
        //                 if (successCallback) {
        //                     successCallback({data: response.data, signField: signField, signedData: JSON.stringify(dataToBeSigned), token: token});
        //                 }
                        
        //             } else {
        //                 submitError(response.data);
        //             }
        //             break;
        //         default:
        //             submitError(response.data);
        //             break;
        //     }
                                    
        // } catch (err) {
        //     submitError(err);
        // }
    }
        
}

export const submitIAMSmartPdf = (formUrl, signFieldOrg, formSpec, successCallback, errorCallback, captchaChallengeCallback) => {
    return async dispatch => {
        setTimeout(()=>{
            dispatch(submitIAMSmartStart());
            dispatch(setIsFetching());

            let submitSigningData = {...store.getState().formDetail.formData};
            if (formSpec.submitDateId) {
                submitSigningData = {...submitSigningData, [formSpec.submitDateId(submitSigningData)]: moment().format(DATE_FORMAT)};
            }

            if (formSpec.submitTransformFunc) {
                submitSigningData = formSpec.submitTransformFunc(submitSigningData);
            }

            let signField = signFieldOrg;
            if (formSpec.signFieldsTransformFunc){
                signField = formSpec.signFieldsTransformFunc(signFieldOrg, submitSigningData);
            }

            const submitError = err => {
                dispatch(submitIAMSmartError(err));
                dispatch(unsetIsFetching());
                console.log(err);
                if (errorCallback) {
                    let msg = err.message;
                    if (err.response
                        && err.response.data
                        && err.response.data.result === "error") {
                        msg = err.response.data.message;
                    }
                    errorCallback(msg);
                }
            }

            const source = browserType();
            const splittedData = preprocessFormData(formSpec, submitSigningData);
            const signedForm = store.getState().formDetail.formPage['confirmation-step-body'];
            const dataToBeSigned = {signedData: splittedData.formData, signedForm: signedForm}
            const {language} = store.getState().session;

            axiosPostRequest({
                dispatch: dispatch,
                url: '/api/iaspdfsign/init/'+formUrl+'/'+signField+QUERY_FOR_NO_CAPTCHA, 
                data: {
                        formData: splittedData.formData,
                        submissionDetail: 
                                    {
                                        ...splittedData.submissionDetail,
                                        pdfData: store.getState().formDetail.pdfData,
                                        lang: store.getState().session.curLanguage,
                                        source: source,
                                        deptName: language.landRegistry,
                                        serviceName: language.webFormPortal,
                                        docName: formSpec.localization.formTitle,
                                    },
                    },
                handle200: response => {
                    if (response.data.result === "D00000") {
                        dispatch(submitIAMSmartSuccess(response.data)); 
                        dispatch(unsetIsFetching());
                        
                        // Success
                        if (successCallback) {
                            successCallback({data: response.data, signField: signField, signedData: JSON.stringify(dataToBeSigned), signatureTimestamp: response.data.signatureTimestamp});
                        }
                        
                    } else {
                        submitError(response.data);
                    }
                },
                handleNon200: response => submitError(response.data),
                handleCatch: err => submitError(err),
                handleCaptchaChallenge: captchaChallengeCallback,
                errorCallback: errorCallback
            });
        }, UPDATE_VALIDATE_DELAY + REQUEST_DELAY);
    }
        
}

// TODO: Remove unused thunk
export const submitSigning = (formUrl, signFieldOrg, pub, pkBase64, pkpin, formSpec, successCallback, errorCallback) => {
    return async dispatch => {
        setTimeout(()=>{
            dispatch(submitSigningStart());
            dispatch(setIsFetching());

            const submitError = err => {
                dispatch(submitSigningError(err));
                dispatch(unsetIsFetching());

                console.log(err);
                if (errorCallback) {
                    let msg = err.message;
                    if (err.response
                        && err.response.data
                        && err.response.data.result === "error") {
                        msg = err.response.data.message;
                    }
                    errorCallback(msg);
                }
            }

            // try {

                let submitSigningData = {...store.getState().formDetail.formData};
                if (formSpec.submitDateId) {
                    submitSigningData = {...submitSigningData, [formSpec.submitDateId(submitSigningData)]: moment().format(DATE_FORMAT)};
                }
                
                if (formSpec.submitTransformFunc) {
                    submitSigningData = formSpec.submitTransformFunc(submitSigningData);
                }

                let signField = signFieldOrg;
                if (formSpec.signFieldsTransformFunc){
                    signField = formSpec.signFieldsTransformFunc(signFieldOrg, submitSigningData);
                }

                const splittedData = preprocessFormData(formSpec, submitSigningData);
                const tempFormData = {...splittedData.formData};
                Object.keys(tempFormData).forEach(key => {
                    if (tempFormData[key] === undefined) {
                        delete tempFormData[key];
                    }
                });

                const signedForm = store.getState().formDetail.formPage['confirmation-step-body'];
                const dataToBeSigned = {signedData: tempFormData, signedForm: signedForm}
                // formDataHashSigned is the sha2 hash of the json data in submitSigningData
                const formDataHashSigned = hashUtil.genSignedHash(dataToBeSigned, pkiHelper.loadPrivateKeyFromBase64(pkBase64, pkpin));

                axiosPostRequest({
                    dispatch: dispatch,
                    url: '/api/submission/signHash/'+formUrl+'/'+signField+QUERY_FOR_NO_CAPTCHA, 
                    data: 
                    { 
                        dataToBeSigned: JSON.stringify(dataToBeSigned),
                        submissionDetail: 
                                    {
                                        ...splittedData.submissionDetail,
                                        dialogSignECertPublic: pub,
                                        signedHash: formDataHashSigned,
                                        lang: store.getState().session.curLanguage 
                                    },
                    },
                    handle200: response => {
                        switch(response.data.result) {
                        case "ok": {
                            const signature =   {
                                                    [signField]:    {
                                                                        algorithm: HASH_ALGORITHM,
                                                                        method: "digital certificate",
                                                                        pub: response.data.pub,
                                                                        signedData: JSON.stringify(dataToBeSigned),
                                                                        signedHash: formDataHashSigned,
                                                                        time: moment(),
                                                                    }
                                                }

                            dispatch(submitSigningSuccess(signature));
                            dispatch(unsetIsFetching());

                            if (successCallback) successCallback();
                        }
                            break;
                        case "fail": {
                            submitError({message:"validation_signature_verificationFailed"});
                            break;
                        }
                        default:
                            submitError(response.data);
                            break;
                        }
                    },
                    handleNon200: response => submitError(response.data),
                    handleCatch: (err) => {
                        submitError(err);
                        console.error("submitSigning: [ERROR] " + err);
                    },
                    errorCallback: errorCallback
                }); 
        }, UPDATE_VALIDATE_DELAY + REQUEST_DELAY);
    }
    
}

export const submitSigningPdf = (formUrl, signFieldOrg, pub, pkBase64, pkpin, formSpec, successCallback, errorCallback, handleCaptchaChallenge) => {
    return async dispatch => {

        setTimeout(()=>{
            dispatch(submitSigningStart());
            dispatch(setIsFetching());

            const submitError = err => {
                dispatch(submitSigningError(err));
                dispatch(unsetIsFetching());

                console.log(err);
                if (errorCallback) {
                    let msg = err.message;
                    if (err.response
                        && err.response.data
                        && err.response.data.result === "error") {
                        msg = err.response.data.message;
                    }
                    errorCallback(msg);
                }
            }

            let submitSigningData = {...store.getState().formDetail.formData};
            if (formSpec.submitDateId) {
                submitSigningData = {...submitSigningData, [formSpec.submitDateId(submitSigningData)]: moment().format(DATE_FORMAT)};
            }

            if (formSpec.submitTransformFunc) {
                submitSigningData = formSpec.submitTransformFunc(submitSigningData);
            }

            let signField = signFieldOrg;
            if (formSpec.signFieldsTransformFunc){
                signField = formSpec.signFieldsTransformFunc(signFieldOrg, submitSigningData);
            }
            
            const splittedData = preprocessFormData(formSpec, submitSigningData);

            axiosPostRequest({
                dispatch: dispatch,
                url: '/api/submission/sign/'+formUrl+'/'+signField+QUERY_FOR_NO_CAPTCHA, 
                data: { 
                    formData: splittedData.formData,
                    submissionDetail: 
                                {
                                    ...splittedData.submissionDetail,
                                    dialogSignECertPublic: pub,
                                    pdfData: store.getState().formDetail.pdfData,
                                    lang: store.getState().session.curLanguage
                                },
                },
                handle200: async response => {
                    if (response.data.result === "ok") {
                        // step 2: get doc hash and submissionId from response, sign using js and submit signed hash to server
                        // call util to sign hash....
                        const submissionId = response.data.submissionId;
                        const docHash = response.data.docHash;
                        const pdfData = response.data.pdfData;
                        const signedHash = hashUtil.signPdfHash(docHash, pkiHelper.loadPrivateKeyFromBase64(pkBase64, pkpin));

                        let r2 = await axios.post(
                            '/api/submission/signedHash/'+signField+QUERY_FOR_NO_CAPTCHA,
                            {
                                submissionId: submissionId,
                                signedHash: signedHash, 
                                ecertPub: pub,
                                pdfData: pdfData,
                                filledFilePasswordConfirm: store.getState().formDetail.filledFilePasswordConfirm
                            }
                        )
                        // .then(r2 => {
                        switch (r2.status) {
                            case 200:
                                if (r2.data.result === "ok") {
                                    // should return reference number
                                    dispatch(upsertSubmissionId(response.data?.submissionId));
                                    const signature =   {
                                        [signField]:    {
                                            signedBy: response.data.signedBy,
                                            signatureName: response.data.signatureName,
                                            time: response.data.signatureTimestamp,
                                        }
                                    }
                                    
                                    const {signIdErrs} = verifyIdUtil.verifySignId({
                                        formSpec: formSpec,
                                        submitSigningData: submitSigningData,
                                        pkBase64: pkBase64,
                                        pkpin: pkpin,
                                        pdfData: r2.data.pdfData
                                    });
                                    
                                    if (signIdErrs && signIdErrs.length > 0) {
                                        // dispatch(updateValidation(null, null, {[signField]: valErrs}))
                                        signature.valErrs = signIdErrs;
                                        dispatch(submitSigningSuccess({pdfData:store.getState().formDetail.pdfData, signature: signature})); 
                                    } else {
                                        dispatch(submitSigningSuccess({pdfData:r2.data.pdfData, signature: signature})); 
                                    }
                                    dispatch(unsetIsFetching());
                                    if (successCallback) successCallback(signature);
                                }
                                break;
                            default:
                                submitError(r2.data);
                                break;
                        } 
                    } else {
                        // error?
                        submitError(response.data);
                    }
                },
                handleNon200: response => submitError(response.data),
                handleCatch: (err) => {
                    submitError(err);
                    console.error("submitSigning: [ERROR] " + err);
                },
                errorCallback: errorCallback,
                handleCaptchaChallenge: handleCaptchaChallenge,
            });  

        }, UPDATE_VALIDATE_DELAY+REQUEST_DELAY);

        
	}
}

// reducer
export const initialState = {
    stepIdx: 0,
    acceptTC: false,
    signedFields: {},
    signedFieldsFromData: {},
    formData: {receiveAckEmail:true},
    formPage: {},
    referenceNumber: null,
    paymentTxnNum: null,
    completedFields: {receiveAckEmail:true},
    validationErr: {},
    fileList: new Set(),
    submissionTime: '', 
    gcisWallet: null, 
    paymentMethod: '', 
    paymentAmount: 0 
};

// const printPreview = (data, type = 'application/pdf') => {
//     let blob = null;
//     blob = b64toBlob(data, type);
//     const blobURL = URL.createObjectURL(blob);
//     const theWindow = window.open(blobURL);
//     const theDoc = theWindow.document;
//     const theScript = document.createElement('script');
//     // function injectThis() {
//     //     window.print();
//     // }
//     // theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
//     theDoc.body.appendChild(theScript);
// };

// const b64toBlob = (content, contentType) => {
//     contentType = contentType || '';
//     const sliceSize = 512;
//      // method which converts base64 to binary
//     const byteCharacters = window.atob(content); 

//     const byteArrays = [];
//     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//         const slice = byteCharacters.slice(offset, offset + sliceSize);
//         const byteNumbers = new Array(slice.length);
//         for (let i = 0; i < slice.length; i++) {
//             byteNumbers[i] = slice.charCodeAt(i);
//         }
//         const byteArray = new Uint8Array(byteNumbers);
//         byteArrays.push(byteArray);
//     }
//     const blob = new Blob(byteArrays, {
//         type: contentType
//     }); // statement which creates the blob
//     return blob;
// };

export default (state=initialState, action) => { 
	switch (action.type) { 
        case UPDATE_STEP: {
            return { ...state, stepIdx: action.payload };
        } break;
        case CLEAR_SIGNED_FIELDS: {
            return {...state, signedFields: {}, signedFieldsFromData: {}, pdfData: undefined, formData: { ...state.formData } };
        } break;
        case REMOVE_SIGNED_FIELD: {
            let {signedFields, signedFieldsFromData} = state;

            const signedFieldId = action.payload;
            signedFields[signedFieldId] = undefined;
            signedFieldsFromData[signedFieldId] = undefined;

            return {...state, signedFields: signedFields, signedFieldsFromData: signedFieldsFromData }
        }
        break;
        case UPDATE_VALIDATION: {
            const {title, sectionTitleKey, results} = action.payload; 
            let newVal = { ...state.validationErr, ...results }
            newVal = Object.fromEntries(Object.entries(newVal).filter(([key, value]) => value));

            return { ...state, validationErr: newVal};
        } break;
        case UPDATE_FORM_DATA: {
            const {title, sectionTitleKey, values} = action.payload; 
            // console.log(values);
            // console.trace();
            let newFormData = { ...state.formData, ...values }
            let newCompletedFields = { ...state.completedFields };
            newFormData = Object.fromEntries(Object.entries(newFormData).filter(([key, value]) => value));
            Object.keys(values).forEach(k => newCompletedFields[k] = true);
            // console.log({ ...state, formData: newFormData, completedFields: newCompletedFields });

            if (newCompletedFields && !state.isFormEdited) {
                window.addEventListener('beforeunload', beforeUnload);
            }

            return { ...state, isFormEdited:true, pdfNeedUpdate:true, formData: newFormData, completedFields: newCompletedFields };
        } break;
        case CACHE_FORM_PAGE: {
            const {id, html} = action.payload;
            let formPage = {...state.formPage};
            formPage[id] = html;

            return {...state, formPage: formPage};
        }
        break;
        case UPDATE_FILE_LIST: {
            let fileList = state.fileList;
            fileList.add(action.payload);

            return {...state, fileList: fileList};
        }
            break;
        case REMOVE_FORM_DATA: {
            let newFormData = {...state.formData};
            let newValidationErr = {...state.validationErr};
            let newCompletedFields = { ...state.completedFields };
            Object.keys(action.payload).forEach(k => { 
                delete newFormData[k];
                delete newValidationErr[k];
                delete newCompletedFields[k];
            });
            
            return { ...state, formData: newFormData, validationErr: newValidationErr, completedFields: newCompletedFields };
        } break;
        case CLEAR_FORM_DATA: {
            let newFormData = {};
            let newValidationErr = {};
            let newCompletedFields = {};
            if (action.payload.retainKeys) {
                for (let s of action.payload.retainKeys) {
                    newFormData[s] = state.formData[s];
                    newValidationErr[s] = state.validationErr[s];
                    newCompletedFields[s] = state.completedFields[s];
                }
            }
            window.removeEventListener('beforeunload', beforeUnload);

            return { ...state, isFormEdited:false, validationErr: newValidationErr , formData: newFormData, completedFields: newCompletedFields, pdfData: undefined, signedFields: {}, signedFieldsFromData: {}, referenceNumber: action.payload.refNum, submissionTime: action.payload.submissionTime, paymentTxnNum: null, gcisWallet: null, paymentMethod: null, paymentAmount: null };
        } break;
        case LOAD_SIGNED_PDF: {
            return { ...state, pdfData: action.payload.pdfData, signedFields: {...action.payload.signedFields}, signedFieldsFromData: {...action.payload.signedFields}};
        }
        case CLEAR_PDF: {
            return { ...state, pdfData: undefined, };
        } break;
        case RESTORE_PREVIOUS_PDF: {
            return { ...state, pdfData: state.previousPdfData, previousPdfData: undefined };
        } break;
        case GEN_PDF_FOR_REVIEW_DONE: {
            return {...state, pdfNeedUpdate:false, pdfData:action.payload.data.pdfData};
        } break;
        case GEN_PDF_FOR_REVIEW_ERROR: {
            console.log(action.payload);
            return state;
        } break;
        case CLEAR_PDF_NEED_UPDATE: {
            return {...state, pdfNeedUpdate:false};
        }
        case PRINT_ON_PAPER_DONE: {
            console.log(action.payload);
            // printPreview(action.payload.pdfData);
            saveFile(action.payload.data.pdfData, "application/pdf", action.payload.formCode + '_' + moment().format('yyyyMMDDHHmmss') + '.pdf');
            
            return state;
        } break;
        case PRINT_ON_PAPER_ERROR: {
            console.log(action.payload);
            return state;

        } break;
        case SUBMIT_FORM_ERROR: {
            const invalidSignatures = action.payload.invalidSignatures;
            let signedFields = state.signedFields;

            if (invalidSignatures) {
                Object.keys(invalidSignatures).forEach(k => {
                    signedFields[k] = undefined;
                });
            }

            return { ...state, signedFields: signedFields }
        } break;
        case SUBMIT_FORM_DONE: {
            window.removeEventListener('beforeunload', beforeUnload);
            return { ...state, referenceNumber: action.payload.refNum, submissionTime: action.payload.submissionTime, paymentTxnNum: action.payload.paymentTxnNum, gcisWallet: action.payload.gcisWallet, paymentMethod: action.payload.paymentMethod, paymentAmount: action.payload.paymentAmount };
        } break;
        case GET_IAMSMART_RESULT_DONE:
        case SUBMIT_SIGNING_DONE: {
            // saveFile(action.payload.pdfData, "application/pdf", moment().format('yyyyMMDDHHmmss') + '.pdf');
            // return { ...state, formData: { ...state.formData}, signedFields: { ...state.signedFields, ...action.payload} };
            return { ...state, formData: { ...state.formData }, previousPdfData:state.pdfData, pdfData: action.payload.pdfData, signedFields: { ...state.signedFields, ...action.payload.signature} };
        } break;
        
		case SET_LANGUAGE: {
            
            return { ...state, pdfNeedUpdate:true}
        } break;
        default:
            return state;
    }
}


///// NEXT:  trigger validation on screen change