import coreLocalization from '../../layout/core/utilities/localization/coreLocalization.en';
import { PaymentMethod } from '../enums';

export function getLocalizedPaymentMethod(
    gcisWallet: PaymentMethod,
    paymentMethod: PaymentMethod,
    formLocalization: typeof coreLocalization
) {
    return (gcisWallet ? (formLocalization.GcisPayment.PAYMENT_METHODS[gcisWallet] + ' - ') : '' )
        + formLocalization.GcisPayment.PAYMENT_METHODS[paymentMethod];
}
