import React from 'react';
import { OnePointToNote as Entity } from '../../../entities';

export function OnePointToNote(props: Props) {
    const { data } = props;
    return <li>{data.content}</li>;
}

type Props = {
    data: Entity;
};
