import { FormContext, Language } from './types';

type Input = {
    formContext: FormContext;
    language: Language;
};

export function getOfflinePaymentMethods(input: Input) {
    const { formContext, language } = input;
    return (
        formContext.formLocalization[formContext.offlinePaymentList] ??
        language.field_paymentMethod_list(['CDChequeOrBankDraftNo', 'CDChequeOrBankDraftNo'])
    );
}
