import React from 'react';
import moment from 'moment';
import * as Constant from './constant';

export default ()=>{}

export const joinString = (_stringArray, _seperate="") => {
    if(!_stringArray) return "";
    const filteredValues = _stringArray.filter(Boolean);
    return filteredValues.join(_seperate);
}

export const getModuleLocalization = (formLocalization, k, FORM_ID, args) => {
    return args 
            ? formLocalization.formatString(formLocalization[k + Constant.MULTI_FORM_ID_SEP + FORM_ID], args) 
            : formLocalization[k + Constant.MULTI_FORM_ID_SEP + FORM_ID];
}

export const renameLocalKey = (form) => {
    const content = {...form.localization.getContent()};

    Object.keys(content).forEach(lang=>{
        const language = {...content[lang]};
        Object.keys(language).forEach(k=>{
            Object.defineProperty(language, k + Constant.MULTI_FORM_ID_SEP + form.code,
                Object.getOwnPropertyDescriptor(language, k));
            delete language[k];
        })
        content[lang] = language;
    })
    
    return content;
}

export const mergeLocalization = (l10n, otherForms) => {
    let renamedLocals = [];
    otherForms.forEach(form => {
        renamedLocals.push(renameLocalKey(form));
    })
    
    let mergedLocal = {};
    const coreForm = otherForms[0];

    Object.keys(l10n.getContent()).forEach(k=>{
        mergedLocal[k] = l10n.getContent()[k];
        for(let i=0; i<renamedLocals.length; i++) {
            mergedLocal[k] = Object.assign(mergedLocal[k], renamedLocals[i][k]);
        }

        // Form Title
        const formTitle = coreForm.localization.getContent()[k]["formTitle"]; // + Constant.MULTI_FORM_ID_SEP + coreForm.code];
        mergedLocal[k].formTitle = formTitle;
    });

    

    l10n.setContent(mergedLocal);

    return l10n;
}

export const parseFormContent = (form, props) => {
    let elems = [];
    for (let j=0; j<form.steps.length; j++) {
        elems.push(
            React.createElement(form.steps[j].content, {form_id: form.code, ...props})
        );
    }
    return elems;
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
};

export const isDateOnOrBefore = (curFormData, thisDateId, beforeDateId, errorKey) => {
    
    if (!curFormData[thisDateId]) return null;

    if (curFormData[beforeDateId]) {
        let dateOfNotice = moment(curFormData[beforeDateId], Constant.DATE_FORMAT);
        let vdate = moment(curFormData[thisDateId], Constant.DATE_FORMAT);
        
        if (vdate.isAfter(dateOfNotice))  {
            return { key: errorKey, args: [] };
        }
    }
    return null;

}

export const cleanseStringForAlphabets = name => name ? name.replace(/[\s,]/g,'').toLowerCase() : name;

export const cleanseIdentityNum = identityNum => identityNum ? identityNum.replace(/[\s,()-]/g,'').toLowerCase() : identityNum;

export const getDatePartsFromDateString = (dateString) => {
    const date = moment(dateString, Constant.DATE_FORMAT);
    return dateString ? { 
        date: date.date().toString(),  
        month: (date.month()+1).toString(),  
        year: date.year().toString()
    } : {date:"",month:"",year:""};
}

export const strikeFieldIf = (formData, id, condition) => {
    formData[id+"$strike"] = condition ? "strike" : "keep";
};
export const setDateFormatForDiffLang_seperate3FieldFormat2 = (_formData, lang, _dateFieldName, _dateValue) => {
    if(!_dateValue) return; 

    if(lang === Constant.ENG) {
        _formData[_dateFieldName + "Year"]   = _dateValue.year().toString();
        _formData[_dateFieldName + "DayMonth"]  = Constant.DAY_NAMES[_dateValue.date()] + " " + Constant.MONTH_NAMES[_dateValue.month() + 1];
    } else {
        _formData[_dateFieldName + "Year"]   = _dateValue.year().toString();
        _formData[_dateFieldName + "Month"]  = (_dateValue.month() + 1).toString();
        _formData[_dateFieldName + "Day"]    = _dateValue.date().toString();
    }
}

export const setDateFormatForDiffLang_seperate3Field = (_formData, lang, _dateFieldName, _dateValue) => {
    if(!_dateValue) return; 

    if(lang === Constant.ENG) {
        _formData[_dateFieldName + "Date"]   = _dateValue.format(Constant.DATE_FORMAT).toString();
    } else {
        _formData[_dateFieldName + "Year"]   = _dateValue.year().toString();
        _formData[_dateFieldName + "Month"]  = (_dateValue.month() + 1).toString();
        _formData[_dateFieldName + "Day"]    = _dateValue.date().toString();
    }
}

export const setDateFormatForDiffLang_FullName = (_formData, lang, _dateFieldName, _dateValue, _isEngYearShort = false) => {
    if(!_dateValue) return;

    if(lang === Constant.ENG) {
        _formData[_dateFieldName + "Year"]   = _isEngYearShort ? _dateValue.year().toString().substring(2,4) : _dateValue.year().toString();
        _formData[_dateFieldName + "Month"]  = Constant.MONTH_NAMES[_dateValue.month() + 1];
        _formData[_dateFieldName + "Day"]    = Constant.DAY_NAMES[_dateValue.date()];
    } else {
        _formData[_dateFieldName + "Year"]   = _dateValue.year().toString();
        _formData[_dateFieldName + "Month"]  = (_dateValue.month() + 1).toString();
        _formData[_dateFieldName + "Day"]    = _dateValue.date().toString();
    }
}