import thunk from 'redux-thunk';
import { applyMiddleware } from 'redux';
import { createStore } from 'redux';

import paymentReducer from '../../../gcis-payment/redux/slice';
import { dialogReducer } from '../dialogHelper';

import { combineReducers } from 'redux';
import session from './wfpSessionReducer';
import formDetail from './formDetail';
import UiReducer from './ui/slice';

export const rootReducer = combineReducers({
    session,
    formDetail,
    dialogReducer,
    payment: paymentReducer,
    ui: UiReducer,
});

export default createStore(rootReducer, applyMiddleware(thunk));