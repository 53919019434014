import forge from 'node-forge';
import { KJUR } from 'jsrsasign';
import {HASH_ALGORITHM} from '../../../utilities/constant';
import crypto from 'crypto';

const signPdfHash = (hash, pkey) => {
    let certBags = null;
    let pkeyBags = null;
    let certBag = null;
    let keybag = null;
    let privateKeyPem = null;
    let certificate = null;

    // document.getElementById('inputfile')
    //     .addEventListener('change', () {

    // var fr = new FileReader();
    // fr.onload = function () {

    // var pkcs12Der = //arrayBufferToString(fr.result);
    // var p12Asn1 = forge.asn1.fromDer(pkcs12Der, true);
    // pin = document.getElementById("pin").value;
    // hash = docHash; //document.getElementById("hash").value;
    // p12Parsed = forge.pkcs12.pkcs12FromAsn1(p12Asn1, true, pin);

    // get bags by type
    // certBags = p12Parsed.getBags({ bagType: forge.pki.oids.certBag });
    // pkeyBags = p12Parsed.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
    // fetching certBag
    // certBag = certBags[forge.pki.oids.certBag][0];
    // fetching keyBag
    // keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
    // generate pem from private key
    privateKeyPem = forge.pki.privateKeyToPem(pkey);
    // generate pem from cert
    // certificate = forge.pki.certificateToPem(certBag.cert);

    //				document.getElementById('output').textContent=certificate;
    // document.getElementById('output').textContent = privateKeyPem;

    // ----

    var sig = new KJUR.crypto.Signature({ "alg": "SHA256withRSA" });
    sig.init(privateKeyPem);
    sig.updateHex(base64ToHex(hash).replace(/\s/g, ''));


    var hSigVal = sig.sign();
    // document.getElementById('signedHashDisplay').textContent = hexToBase64(hSigVal);
    // document.getElementById('signedHash').value = hexToBase64(hSigVal);
    const result = (hexToBase64(hSigVal));
    console.log(result);
    return result;
    // ----
    // }

    // fr.readAsArrayBuffer(this.files[0]);

    // })
}


const signHKIDHash = (hash, pkey) => {
    let certBags = null;
    let pkeyBags = null;
    let certBag = null;
    let keybag = null;
    let privateKeyPem = null;
    let certificate = null; 

    privateKeyPem = forge.pki.privateKeyToPem(pkey);

    var sign = crypto.createSign('RSA-SHA1');
    // return crypto.sign('RSA-SHA1', new TextEncoder().encode(hash),privateKeyPem);
    sign.update(hash);
    // var privateKey = getPrivateKeySomehow()
    var buf = (sign.sign(privateKeyPem));

    var h = crypto.createHash('SHA1');
    h.update(buf);
    return h.digest('base64');
    

    // var sig = new KJUR.crypto.Signature({ "alg": "SHA1withRSA" });
    // sig.init(privateKeyPem);
    // sig.updateHex(base64ToHex(hash).replace(/\s/g, ''));


    // var hSigVal = sig.sign();
    // const result = (hexToBase64(hSigVal));
    // console.log(result);
    // return result;
}

// =========================

// if (!window.atob) {

const tableStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
const table = tableStr.split("");

const atob = (base64) => {
    if (/(=[^=]+|={3,})$/.test(base64)) throw new Error("String contains an invalid character");
    base64 = base64.replace(/=/g, "");
    var n = base64.length & 3;
    if (n === 1) throw new Error("String contains an invalid character");
    for (var i = 0, j = 0, len = base64.length / 4, bin = []; i < len; ++i) {
        var a = tableStr.indexOf(base64[j++] || "A"), b = tableStr.indexOf(base64[j++] || "A");
        var c = tableStr.indexOf(base64[j++] || "A"), d = tableStr.indexOf(base64[j++] || "A");
        if ((a | b | c | d) < 0) throw new Error("String contains an invalid character");
        bin[bin.length] = ((a << 2) | (b >> 4)) & 255;
        bin[bin.length] = ((b << 4) | (c >> 2)) & 255;
        bin[bin.length] = ((c << 6) | d) & 255;
    };
    return String.fromCharCode.apply(null, bin).substr(0, bin.length + n - 4);
};


const btoa = (bin) => {
    
    for (var i = 0, j = 0, len = bin.length / 3, base64 = []; i < len; ++i) {
        var a = bin.charCodeAt(j++), b = bin.charCodeAt(j++), c = bin.charCodeAt(j++);
        if ((a | b | c) > 255) throw new Error("String contains an invalid character");
        base64[base64.length] = table[a >> 2] + table[((a << 4) & 63) | (b >> 4)] +
            (isNaN(b) ? "=" : table[((b << 2) & 63) | (c >> 6)]) +
            (isNaN(b + c) ? "=" : table[c & 63]);
    }
    return base64.join("");
};


// const arrayBufferToString = (buffer) => {
//     var binary = '';
//     var bytes = new Uint8Array(buffer);
//     var len = bytes.byteLength;
//     for (var i = 0; i < len; i++) {
//         binary += String.fromCharCode(bytes[i]);
//     }
//     return binary;
// }

const hexToBase64 = (str) => {
    return btoa(String.fromCharCode.apply(null,
        str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
}

const base64ToHex = (str) => {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
        var tmp = bin.charCodeAt(i).toString(16);
        if (tmp.length === 1) tmp = "0" + tmp;
        hex[hex.length] = tmp;
    }
    return hex.join(" ");
}

const hashStringToHex = (str) => {
    switch(HASH_ALGORITHM) {
    case "SHA-256":
        var md = forge.md.sha256.create();
        md.update(str, 'utf8');
        return md.digest().toHex();
    default:
        console.error("Algorithm not handled.");
        return undefined;
    }
    
}

const genSignedHash = (submitSigningData, pri) => {
//    console.log("!!!!()");
    var j = JSON.stringify(submitSigningData);
//    console.log(j);

    let hashToSign = hashStringToHex(j);
    console.log(hashToSign);    

    let privateKeyPem = forge.pki.privateKeyToPem(pri);

    var sig = new KJUR.crypto.Signature({"alg": "SHA256withRSA"});
    sig.init(privateKeyPem);
    sig.updateString(hashToSign);
    var hSigVal = sig.sign();
    let signedHash = hexToBase64(hSigVal);
   console.log(signedHash);
    return signedHash;
}



export default {
    hashStringToHex: hashStringToHex,
    signPdfHash: signPdfHash,
    signHKIDHash: signHKIDHash,
    genSignedHash: genSignedHash,
};