import React from 'react';

import '../../css/input.css'
import { IconContext } from "react-icons";

export default class IconButton extends React.Component {

	handleChange=e=>{
		this.props.onChange(e.target.value);
	}

	render() {

		let { onClick, buttonClass, label, icon, leftIcon, iconStyle, render, dataTip } = this.props;
		
		if (iconStyle===undefined) {
			iconStyle = { color: "green", size:"30px" }
		}

		if (render==="true") {
			return (
				<button className={"iconButton " + buttonClass} onClick={onClick} data-tip={dataTip}>
	    			<IconContext.Provider value={iconStyle}>
						{leftIcon}{label}{icon}
					</IconContext.Provider>
	    		</button>
			);
		}
		else {
			return null;
		}
	
	}
}

IconButton.defaultProps = {
	render: "true",
	buttonClass: "",
}
