import React from 'react';
import { PaymentMethodData } from '../../../entities';
import { OnePointToNote } from '../points-to-note';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../layout/core/reducers/wfp-core-store.type';
import { WebsiteLocale } from '../../../../layout/core/website-locale.enum';

export function PaymentMethod(props: Props) {
    const { data, selected, onSelected } = props;
    const { pointsToNote, code, active } = data;
    const label = data.getLabel();
    const id = data.getId();

    const websiteLang = useSelector((store: RootState) => store.session.curLanguage);
    let langClassName = "lang-"
    switch (websiteLang) {
        case WebsiteLocale.TRADITIONAL_CHINESE:
        case WebsiteLocale.SIMPLIFIED_CHINESE:
            langClassName += "tc";
            break;
        case WebsiteLocale.ENGLISH:
            langClassName += "en";
            break;
    }

    return (
        <div
            className={`btn paymentMethod ${id}-group${selected ? ' active' : ''}`}
            onClick={onSelected}
            aria-presssed={selected}
        >
            <div className="method disable-icon hidden"></div>
            <div className={`method ${id}-icon ${langClassName} payment-icon${selected ? ' focus' : ''}`} />
            <label className="sr-only">
                {label}
                <input
                    type="radio"
                    id={id}
                    className={id}
                    value={code}
                    name="payOptions"
                    data-active={active ? 'Y' : 'N'}
                    tabIndex={0}
                    checked={selected}
                />
            </label>
            <span id="paymentMethodNote" className="hidden">
                <ul>
                    {pointsToNote.map((data, idx) => (
                        <OnePointToNote key={idx} data={data} />
                    ))}
                </ul>
            </span>
        </div>
    );
}

type Props = {
    data: PaymentMethodData;
    selected: boolean;
    onSelected: () => void;
};
