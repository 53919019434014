import { cleanseStringForAlphabets } from "../../../utilities/commonUtil";
import { cleanseIdentityNum } from "../../../utilities/commonUtil";
import hashUtil from "./hashUtil";
import pkiHelper from "./pkiHelper";

const verifyIAMSmartIdNum = (v, form, formDetail) => {
    const verifySignIdNumId     = form.verifySignIdNumId && typeof(form.verifySignIdNumId) === 'function' ? form.verifySignIdNumId(form.submitTransformFunc(formDetail.formData)) : form.verifySignIdNumId;
    let valueCleansed = cleanseStringForAlphabets(v);
    let signedID = null;
    let signedIDWithoutPunc = null;
    let signedIDWithPunc = null;

    if (verifySignIdNumId) {
        signedID = cleanseStringForAlphabets(form.submitTransformFunc(formDetail.formData)[verifySignIdNumId]);
        
        signedIDWithoutPunc = signedID 
            ? signedID.replace(/[-\(\)]/g,"")
            : null;
        
        const _lastCharacterWithBrackets = "(" + signedID.match(/.$/)[0] + ")";
        signedIDWithPunc =  signedID
            ? signedID.replace(/.$/, _lastCharacterWithBrackets)
            : null;
    }

    if (!v
        || !verifySignIdNumId
        || valueCleansed == signedID 
        || valueCleansed == signedIDWithoutPunc
        || valueCleansed == signedIDWithPunc
    ) {
        return null;
    } else {
        return { key: "validation_signature_signerIdMismatch", args: [] };

    }
}  

const verifySignIdOrgName = (verifySignOrgNameId, cert, signedOrgNameCleansed, orgNamePassed) => {
    if (verifySignOrgNameId) {
        cert.subject.attributes.every(o => {
            if (cleanseStringForAlphabets(o.value) === signedOrgNameCleansed ?? "") {
                orgNamePassed = true;
                return false;
            }
            return true;
        });
    }
    return orgNamePassed;
}

const verifySignIdOrgIdentityNum = (verifySignOrgIdentityNumId, identityType, cert, signedOrgIdentityNumCleansed, orgIdentityNumPassed) => {
    let minLength = 8;
    let issuerHKP = "hongkongpost";
    let issuerHKP_BRN = null;
    let issuerHKP_CICR = null;
    const issuerDS_BRNCICR = ["BRN", "CI", "CR"];
    let certIssuer = null;
    let certIdentityType = null;
    let certIdentityNum = null;

    if (verifySignOrgIdentityNumId) {
        // check the length of input identity fulfill the required length
        if (signedOrgIdentityNumCleansed.length >= minLength) {
            // check if the input identity values are not all zeroes
            if (Number(signedOrgIdentityNumCleansed) === 0) {
                return orgIdentityNumPassed;
            }

            // retrieve the issuer of uploaded eCert (Hongkong Post or DIGI-SIGN)
            cert.issuer.attributes.every(o => {
                if (o.shortName === "O") {
                    certIssuer = cleanseStringForAlphabets(o.value);
                    return false;
                }
                return true;
            });

            // determine the issuer of eCert in order to process defined validations
            if (certIssuer === issuerHKP ?? "") {
                // Hongkong Post - BRN, CI/CR are conbined into one value with total fixed length at least 24 alphanumerics (BRN=16, CI/CR=8, Others=30 max)
                cert.subject.attributes.every(o => {
                    if (o.shortName === "OU") {
                        certIdentityNum = cleanseIdentityNum(o.value).toUpperCase();

                        if (certIdentityNum.length >= 24) {
                            issuerHKP_BRN = certIdentityNum.substring(0, 16);
                            issuerHKP_CICR = certIdentityNum.substring(16, 24);

                            switch(identityType) {
                                case "BRN":
                                    // check with BRN, all zeroes means not available
                                    if ((issuerHKP_BRN.indexOf(signedOrgIdentityNumCleansed.toUpperCase()) >= 0) && Number(issuerHKP_BRN) != 0) {
                                        orgIdentityNumPassed = true;
                                        return false;
                                    }
                                    break;
                                case "CICR'":
                                    // check with CI/CR, all zeroes mean not available
                                    if ((issuerHKP_CICR === signedOrgIdentityNumCleansed.toUpperCase()) && Number(issuerHKP_CICR) != 0) {
                                        orgIdentityNumPassed = true;
                                        return false;
                                    }
                                    break;
                              }
                        }
                    }
                    return true;
                });                
            } else {
                // Digi-Sign - BRN, CI and CR are stored separatedly with Prefix BRN, CI, CR
                cert.subject.attributes.every(o => {
                    certIdentityNum = cleanseIdentityNum(o.value).toUpperCase();
                    certIdentityType = issuerDS_BRNCICR.find(el => certIdentityNum.includes(el));

                    if (o.shortName === "OU" && certIdentityType) {
                        certIdentityNum = certIdentityNum.substring(certIdentityType.length);

                        if ((identityType.indexOf(certIdentityType) >= 0) && certIdentityNum === signedOrgIdentityNumCleansed.toUpperCase()) {
                            orgIdentityNumPassed = true;
                            return false;
                        }
                    }
                    return true;
                });                   
            }
        }
    }
    return orgIdentityNumPassed;
}

const verifySignIdOrgAuthUserName = (verifySignOrgAuthUserNameId, cert, signedOrgAuthUserNameCleansed, orgAuthUserNamePassed) => {
    orgAuthUserNamePassed = verifySignIdPersonalName(verifySignOrgAuthUserNameId, cert, signedOrgAuthUserNameCleansed, orgAuthUserNamePassed);
    return orgAuthUserNamePassed;
}

const verifySignIdPersonalName = (verifySignNameId, cert, signedNameCleansed, namePassed) => {
    if (verifySignNameId) {
        cert.subject.attributes.forEach(o => {
            if (cleanseStringForAlphabets(o.value) === signedNameCleansed ?? "") {
                namePassed = true;
            }
        });
    }
    return namePassed;
}

const verifySignIdPersonalNum = (verifySignIdNumId, pkBase64, pkpin, cert, signedID, signedIDWithoutPunc, signedIDWithPunc, idPassed) => {
    if (verifySignIdNumId && pkBase64 && pkpin) {
        cert.getExtension("subjectAltName").altNames.forEach(o => {
            if (o.value === signedID || o.value === signedIDWithoutPunc|| o.value === signedIDWithPunc) {
                idPassed = true;
            }
        });
    }
    return idPassed;
}

const verifySignId = ({formSpec, submitSigningData, pkBase64, pkpin, pdfData}) => {
    let namePassed = false;
    let idPassed = false;

    let orgNamePassed = false;
    let orgAuthUserNamePassed = false;
    let orgBRPassed = false;
    let orgCICRPassed = false;

    let isPersonalCert = true;

    const allowPersonalCert = formSpec.allowPersonalCert ? formSpec.allowPersonalCert(submitSigningData) : false;
    const allowOrganisationalCert = formSpec.allowOrganisationalCert ? formSpec.allowOrganisationalCert(submitSigningData) : false;
    const isCheckCertType = allowPersonalCert | allowOrganisationalCert; // If not limit cert type, default = allow all cert type

    let signIdErrs = [];

    let signedID = null;
    let signedIDWithoutPunc = null;
    let signedIDWithPunc = null;
    let signedNameCleansed = null;

    let signedOrgNameCleansed = null;
    let signedOrgAuthUserNameCleansed = null;
    let signedOrgBRCleansed = null;
    let signedOrgCICRCleansed = null;

    const verifySignIdNumId      = formSpec.verifySignIdNumId && typeof(formSpec.verifySignIdNumId) === 'function' ? formSpec.verifySignIdNumId(submitSigningData) : formSpec.verifySignIdNumId;
    const verifySignNameId      = formSpec.verifySignNameId && typeof(formSpec.verifySignNameId) === 'function' ? formSpec.verifySignNameId(submitSigningData) : formSpec.verifySignNameId;

    // following are used for Org certs
    //const verifySignOrgId       = formSpec.verifySignOrgId && typeof(formSpec.verifySignOrgId) === 'function' ? formSpec.verifySignOrgId(submitSigningData) : formSpec.verifySignOrgId;
    const verifySignOrgNameId   = formSpec.verifySignOrgNameId && typeof(formSpec.verifySignOrgNameId) === 'function' ? formSpec.verifySignOrgNameId(submitSigningData) : formSpec.verifySignOrgNameId;
    const verifySignOrgAuthUserNameId   = formSpec.verifySignOrgAuthUserNameId && typeof(formSpec.verifySignOrgAuthUserNameId) === 'function' ? formSpec.verifySignOrgAuthUserNameId(submitSigningData) : formSpec.verifySignOrgAuthUserNameId;
    const verifySignOrgBRId   = formSpec.verifySignOrgBRId && typeof(formSpec.verifySignOrgBRId) === 'function' ? formSpec.verifySignOrgBRId(submitSigningData) : formSpec.verifySignOrgBRId;
    const verifySignOrgCICRId   = formSpec.verifySignOrgCICRId && typeof(formSpec.verifySignOrgCICRId) === 'function' ? formSpec.verifySignOrgCICRId(submitSigningData) : formSpec.verifySignOrgCICRId;


    // look for specific attributes in the cert that pertains to personal / org certs
 
    // org cert org name: cert.subject.attributes.name === "organizationUnitName" and value === companyName
    // org cert org Id (digi-sign only): cert.subject.attributes.name === "organizationUnitName" and value like companyId
    // org cert auth rep name / per cert holder name: cert.subject.attributes.name === "commonName" and value === rep name
    // org cert auth rep ID (digi-sign only) / per cert holder ID: cert.getExtension("subjectAltName").altNames.any(a => a.value === ecrypted ID)
 

//-------------------------------------------------------------------------------------------------------------------------------------------------
// Massage required fields before validations
//-------------------------------------------------------------------------------------------------------------------------------------------------

    // For e-cert, verify both name and identity number
    if (verifySignIdNumId && submitSigningData[verifySignIdNumId]) {
        const valueCleansed = cleanseStringForAlphabets(submitSigningData[verifySignIdNumId]).toUpperCase();
        // Try original
        signedID = (pkBase64 && pkpin) 
            ? hashUtil.signHKIDHash(valueCleansed?? "", pkiHelper.loadPrivateKeyFromBase64(pkBase64, pkpin)) :
            valueCleansed;
        
        // Try auto format - Hong Kong Post
        signedIDWithoutPunc = valueCleansed.replace(/[-\(\)]/g,"");
        if(pkBase64 && pkpin) {
            signedIDWithoutPunc = hashUtil.signHKIDHash(signedIDWithoutPunc ?? "", pkiHelper.loadPrivateKeyFromBase64(pkBase64, pkpin));
        }

        // Try auto format - Digital Sign
        var _lastCharacterWithBrackets = "(" + valueCleansed.match(/.$/)[0] + ")";
        signedIDWithPunc = valueCleansed.replace(/.$/, _lastCharacterWithBrackets);
        if(pkBase64 && pkpin) {
            signedIDWithPunc =  hashUtil.signHKIDHash(signedIDWithPunc ?? "", pkiHelper.loadPrivateKeyFromBase64(pkBase64, pkpin));
        }
    }

   // console.log("signedHKIDHash="+signedHKIDHash);

    if (verifySignNameId) {
        signedNameCleansed = cleanseStringForAlphabets(submitSigningData[verifySignNameId]);
    }

    if (verifySignOrgNameId) {
        signedOrgNameCleansed = cleanseStringForAlphabets(submitSigningData[verifySignOrgNameId]);
    }

    if (verifySignOrgAuthUserNameId) {
        signedOrgAuthUserNameCleansed = cleanseStringForAlphabets(submitSigningData[verifySignOrgAuthUserNameId]);
    }

    if (verifySignOrgBRId) {
        signedOrgBRCleansed = cleanseIdentityNum(submitSigningData[verifySignOrgBRId]);
    }

    if (verifySignOrgCICRId) {
        signedOrgCICRCleansed = cleanseIdentityNum(submitSigningData[verifySignOrgCICRId]);
    }


//-------------------------------------------------------------------------------------------------------------------------------------------------
// Validate required input fields against with information stored on eCert
//-------------------------------------------------------------------------------------------------------------------------------------------------

    const certs = pkiHelper.getPDFSignatureCertificates(atob(pdfData));
    // console.log(certs);

    certs.forEach(p => p.forEach(cert => {
        // look for the name in subject attribute entries. (refer to HKPost and Digi-sign Cert Practise Statement)
        namePassed = verifySignIdPersonalName(verifySignNameId, cert, signedNameCleansed, namePassed);
        // look for the ID hash in subjectAltName entries. (refer to HKPost and Digi-sign Cert Practise Statement)
        idPassed = verifySignIdPersonalNum(verifySignIdNumId, pkBase64, pkpin, cert, signedID, signedIDWithoutPunc, signedIDWithPunc, idPassed);


        // following are used for Org certs
        orgNamePassed = verifySignIdOrgName(verifySignOrgNameId, cert, signedOrgNameCleansed, orgNamePassed);
        orgAuthUserNamePassed = verifySignIdOrgAuthUserName(verifySignOrgAuthUserNameId, cert, signedOrgAuthUserNameCleansed, orgAuthUserNamePassed);
        orgBRPassed = verifySignIdOrgIdentityNum(verifySignOrgBRId, "BRN", cert, signedOrgBRCleansed, orgBRPassed);
        orgCICRPassed = verifySignIdOrgIdentityNum(verifySignOrgCICRId, "CICR", cert, signedOrgCICRCleansed, orgCICRPassed);


        cert.subject.attributes.forEach(o => {
            if (o.shortName === "O" 
                && ["DS ID-CERT CLASS 2",
                    "DS ID-CERT CLASS 5",
                    "DS ID-CERT CLASS 9",
                    "DS ID-CERT CLASS 11",
                    "e-Cert (Organisational)",
                    ].some(v => o.value.includes(v))) {
                isPersonalCert = false;
            }
        });
    }))
    console.log(certs[0][0]);


    if (verifySignNameId && !namePassed) signIdErrs.push("validation_signature_signerNameMismatch");
    if (verifySignIdNumId && !idPassed) signIdErrs.push("validation_signature_signerIdMismatch");

    if (verifySignOrgNameId && !orgNamePassed) signIdErrs.push("validation_signature_signerOrgNameMismatch");
    if (verifySignOrgAuthUserNameId && !orgAuthUserNamePassed) signIdErrs.push("validation_signature_signerOrgAuthNameMismatch");

    if(isCheckCertType) {
        if(allowPersonalCert && !allowOrganisationalCert && !isPersonalCert) { signIdErrs.push("validation_signature_nonPersonal"); }
        if(!allowPersonalCert && allowOrganisationalCert && isPersonalCert) { signIdErrs.push("validation_signature_nonOrganisation"); }
    }

    // determine if the eCert is Individual or Organizational cert in order to show proper messages
    if(!isPersonalCert) {
        // determine if BRN, CI/CR are combined into one field when the eCert is Organizational Cert
        if(verifySignOrgBRId === verifySignOrgCICRId) {
            if(verifySignOrgBRId && !orgBRPassed) { signIdErrs.push("validation_signature_signerOrgIdNumMismatch"); }
        } else {
            if(verifySignOrgBRId && !orgBRPassed) { signIdErrs.push("validation_signature_signerOrgBRNMismatch"); }
            if(verifySignOrgCICRId && !orgCICRPassed) { signIdErrs.push("validation_signature_signerOrgCICRMismatch"); }
        }
    }

    return {
        signIdErrs: signIdErrs,
    };
}

export default {
    verifyIAMSmartIdNum: verifyIAMSmartIdNum, 
    verifySignId: verifySignId,
};

