import { Expose } from 'class-transformer';
import { PaymentMethodSubtype, PaymentLocale } from '../../../enums';

export class QueryPaymentAvailabilityRequest {
    constructor(input: Partial<QueryPaymentAvailabilityRequest> = {}) {
        Object.assign(this, input);
    }

    locale: PaymentLocale;

    amount?: number; // If amount <= 0 will be default to 1

    @Expose({ name: 'preferpaymentmethods' })
    preferredPaymentMethods: PaymentMethodSubtype[]; // Validated against INPUT_VALIDATE_PAYMENT_METHOD

    @Expose({ name: 'eserviceids' })
    eServiceIds?: string[]; // optional, mapped to EGIS_APPLICATION_ID & BRANCH_CD
}
