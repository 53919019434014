import { plainToInstance } from 'class-transformer';

import { instance } from '../../instance';
import { WfpApiUrl } from '../../../constants';

import { CreateTransactionResponse as Response } from './response';
import { CreateTransactionRequest as Request } from './request';

const url = WfpApiUrl.CREATE_TRANSACTION;

export async function createTransaction(amount: number, submissionId?: string): Promise<Response> {
    const data = await instance.post(url, new Request({ paymentAmount: amount, submissionId }));
    return plainToInstance(Response, data);
}
