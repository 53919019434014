import { payByFps, payByOnlineMethod, payByWallet } from '../api/services';
import { PayByFpsRequest, PayByOnlineMethodRequest, PayByWalletRequest } from '../api/services/requests';
import { OnlineMethodType, WalletType } from '../enums';
import { CombinedData } from '../entities';
import { Payment } from '../payment.proxy';
import { WEB_TOKEN_EXPIRED } from '../constants';

import { Expose } from 'class-transformer';
import { PayByFpsResponse } from '../api/services/pay-by-fps/response';

export class SubmitPaymentResponsePayload {
    // redirectUrl?: string;

    @Expose({ name: 'redirectUrl' })
    redirectUrl?: string;

    @Expose({ name: 'fpsResponse' })
    fpsResponse?: PayByFpsResponse;
};


export async function startPayment(
    combinedData: CombinedData,
    paymentGateway: string
): Promise<SubmitPaymentResponsePayload> {
    // Set the payload to match the latest locale
    combinedData.setLocale(Payment.getLocale());

    const walletRequest = PayByWalletRequest.fromData(combinedData);
    const fpsRequest = PayByFpsRequest.fromData(combinedData, OnlineMethodType.FPS);

    if (!combinedData.paymentMethod) throw 'No PaymentMethod';

    switch (paymentGateway) {
        case 'androidpay':
            return await googlePay(walletRequest);
        case 'applepay':
            return await applePay(walletRequest);
        case 'creditcard-jcb':
        case 'creditcard-visa':
        case 'creditcard-unionpay':
        case 'creditcard-mastercard':
        case 'mastercard':
        case 'visa':
            return await payByCreditCard(combinedData);
        case 'pps':
            return await payByPps(combinedData);
        case 'fps':
            return await payByFpsStart(combinedData);
        default:
            throw 'Payment Gateway not recognized';
    }
}

// #1 Pay by Online
async function payByPps(combinedData: CombinedData) {
    return await payOnlineAndRedirect(combinedData, OnlineMethodType.PPS);
}

async function payByCreditCard(combinedData: CombinedData) {
    return await payOnlineAndRedirect(combinedData, OnlineMethodType.CREDIT_CARD);
}

async function payOnlineAndRedirect(
    combinedData: CombinedData,
    onlineMethodType: OnlineMethodType
) {
    return await payByOnlineMethod(
        PayByOnlineMethodRequest.fromData(combinedData, onlineMethodType)
    )
        .then((res) => {
            return { redirectUrl: res.redirectUrl };
        })
        .catch((e) => {
            if (e?.response?.status === 403 && e?.response?.data === 'Webtoken is expired.') {
                throw WEB_TOKEN_EXPIRED;
            }
            throw e;
        });
}

// #2 Pay by Wallet
async function applePay(walletRequest: PayByWalletRequest) {
    return await Payment.applePay(walletRequest)
        .then((paymentData) =>
            payByWallet(
                new PayByWalletRequest({
                    ...walletRequest,
                    paymentData,
                    type: WalletType.APPLE_PAY,
                })
            )
        )
        .then(() => ({}));
}

async function googlePay(walletRequest: PayByWalletRequest) {
    return await Payment.googlePay(walletRequest)
        .then((paymentData) =>
            payByWallet(
                new PayByWalletRequest({
                    ...walletRequest,
                    paymentData,
                    type: WalletType.GOOGLE_PAY,
                })
            )
        )
        .then(() => ({}));
}

// 202309: #3 Pay by FPS
async function payByFpsStart(combinedData: CombinedData) {
    return await payByFps(
        PayByFpsRequest.fromData(combinedData, OnlineMethodType.FPS)
    )
        .then((res) => {
            return { fpsResponse:res };
        })
        .catch((e) => {
            // [temp]
            // const _fpsRes = new PayByFpsResponse();
            // _fpsRes.fpsqrcodeurl = "https://www.google.com.hk/";
            // _fpsRes.fpsqrcodeimgbase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcwAAAHMAQMAAABiFQrFAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURQAAAP///6XZn90AAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGzSURBVHja7dtPjoIwFMfxEhdsTDiCR5mjydHmKBwBdy7MvOFPSx+lToxMhJbvbyOiH6poXlqemkiu8jPcXqRLt1mKNOa1QMMUm4y6ghabULMJLVIbNbkzvIJW79Pz+7TPSXSanna5h/Sqn/UYX/Dn6YoXfNpk1G3OcJHa57oNPaf2lYB+iLa+ODa2mJbLzYeroVAoFHo42qU2hS+xUCgUelA6xs0poVAo9DBUpfHP/2MvFAqF7pguEl81vxQoFApNmcqT3O1RnqaFQqHQ3GhYHJugmIpvOy9XzVAoFJoZHRfc33b3JWinqCbLdMERCoVCM6bdni8Ri82snTI1WYZUwwPTXSgUCs2SGn9Jse7vlDN6sQ+5MayBQg9KBZopdVNFt0etsocU4YIbCoVCM6aRxC5B1noeCoXuhbZQ6D/SSGYLbvEda9WUESgUCs2SnkQnrKDzA7ZQKBSaBHWFMb5qVgcU35Q2UCgUegg6b6fEryO2UCgUehTq/ro376xUQzEdDgiFQqF5UhVVTF1ck2UqpsGPu6FQKDQbuoiaXrqfJortNau00P3SW2qj3niv0B1Quf0CBnJITOthZ+QAAAAASUVORK5CYII=";
           
            // return { fpsResponse:_fpsRes };

            // 202309
            if (e?.response?.status === 403 && e?.response?.data === 'Webtoken is expired.') {
                throw WEB_TOKEN_EXPIRED;
            }
            throw e;
        });
}