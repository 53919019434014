import { Expose } from 'class-transformer';

export class Unit {
    @Expose({ name: 'UnitDescriptor' })
    descriptor: string;

    @Expose({ name: 'UnitNo' })
    number: string;

    @Expose({ name: 'UnitPortion' })
    portion?: string; //eg. 東部, 東南部, N for North, CL for attic..
}
