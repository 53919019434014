import React, { useState, useEffect, useContext } from 'react';
import lo from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {WfpFrame} from "./core/wfpFrame";
import { IntroStep, ConfirmationStep, PaymentStep, AcknowledgementStep, SubmissionStep } from './core/layoutElements';
import { getForms, setFormObj, setIsFetching } from './core/reducers/wfpSessionReducer';

import '../css/index.css';
import '../css/wfp/index.css';
import '../css/wfp/input.css';
import '../css/wfp/nav.css';
import { RootContext } from './root';

export const Form = ({ formId, form /*intro, steps, l10n, submitTransformFunc, signRequired*/, currentLang, validation }) => {
	const [curlang, setCurlang] = useState(); 
	const { language, formList } = useSelector(store => store.session, lo.isEqual);
	const { recaptcha } = useContext(RootContext);
	const dispatch = useDispatch();
	const { localization, steps, signRequired, signFields, paymentRequired} = form;
	const { formData } = useSelector(store => store.formDetail);

	if (currentLang !== curlang) {
		setCurlang(currentLang);
		localization.setLanguage(currentLang);

		document.title = localization.formTitle + " - " + localization.documentTitle;
	}
/*
	useEffect(() => {
		dispatch(setIsFetching());
		window.grecaptchaOnVerify = token => {
 			dispatch(getForms(token));
		}
		recaptcha().reset();
		recaptcha().execute();
	}, [dispatch]);

	useEffect(() => {
		for (let i=0; i<formList.length; i++) {
			if (formList[i].code === formId) {
				dispatch(setFormObj(formList[i]));
			}
		}
	}, [formId, formList, dispatch]);
*/

	useEffect(() => {
		dispatch(setFormObj(form));
	},[])
	let formSteps = [];
	
	if (!form.simplifiedForm) {
		// Standard
		formSteps.push(IntroStep(language));
		formSteps.push(...steps);
		formSteps.push(ConfirmationStep(steps, signRequired, paymentRequired, localization, formId, signFields, form));
		formSteps.push(SubmissionStep(form, formData));
		// if (paymentRequired) { formSteps.push(PaymentStep) }
		formSteps.push(AcknowledgementStep);	
	} else {
		// Customized
		//formSteps.push(IntroStep(language));
		formSteps.push(...steps);
		//formSteps.push(ConfirmationStep(steps, signRequired, paymentRequired, localization, formId, signFields, form));
		//formSteps.push(SubmissionStep(form, formData));
		// if (paymentRequired) { formSteps.push(PaymentStep) }
		formSteps.push(AcknowledgementStep);
	}

	return (
		<WfpFrame screenTitle={localization.formTitle}
			steps={formSteps}
			form={form}
			formData={formData}
			/*
			intro={intro} l10n={l10n} submitTransformFunc={submitTransformFunc} validation={validation} signRequired={signRequired}
			*/  
			/>
	)
}

export default Form;
