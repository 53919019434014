import React from 'react';
import moment from 'moment';
import { range } from 'lodash';
import { DATE_FORMAT } from './constant';
import { clonePartialObject } from './object.util';

export function extractDate(form: Record<string, any>, key: string) {
    const value = form[key];
    if (!value) return null;
    return moment(value, DATE_FORMAT, true);
}

export function extractTime(form: Record<string, any>, key: string) {
    const value = form[key];
    if (!value) return null;
    if (typeof value !== 'string') return null;

    const formats = ['h:mm a', 'hh:mm a'];
    const parsed = formats.map((fmt) => moment(value.toLowerCase(), fmt, true));
    return parsed.find((m) => m.isValid());
}

export function textArrArrToElem(a: Array<Array<string|JSX.Element>>) {
    return a.map((b, idx) => (
        <p>
            {b.map((a, idx) => (
                <>{a} {idx < (b.length-1) ? <br/>:null}</>
            ))}
        </p>
    ));
}

// key does NOT need to start with NAN_
export function strikeForm(
    formData: Record<string, any>,
    options: Array<String>,
    selected: String
) {
    const strikeAll = options
        .map((opt) => ({ [`${opt}$strike`]: 'strike' }))
        .reduce((acc, curr) => Object.assign(curr, acc), {});
    return { ...formData, ...strikeAll, [`${selected}$strike`]: 'keep' };
}

type Input = {
    key: string;
    option: number | string;
    repeat?: number;
    optKey?: string; // 'Opt_' for old forms
};

// Assumes workin on clean formData, hence no need to write 'keep' values;
// key MUST start with NaN_ to work properly
export function strikeFormMultiple(formData: Record<string, any>, input: Input) {
    const { key, option, repeat = 1, optKey = '' } = input;
    let mask: Record<string, any> = {};
    if (repeat === 1) {
        mask = { [`${key}_${optKey}${option}$strike`]: 'strike' };
    } else {
        for (let i of range(1, repeat + 1)) {
            mask[`${key}_${optKey}${option}.${i}$strike`] = 'strike';
        }
    }
    return { ...formData, ...mask };
}

type ExInput = Input & {
    allOptions: number[] | string[];
};

export function strikeAllBut(formData: Record<string, any>, input: ExInput) {
    const { allOptions, option, ...rest } = input;
    for (const opt of allOptions) {
        if (option === opt) continue;
        formData = strikeFormMultiple(formData, { ...rest, option: opt });
    }
    return formData;
}

export const clonePartialFormData = clonePartialObject;

export function markCustomRemark(formData: Record<string, any>, customRemark: any) {
    return { ...formData, customRemark };
}
