import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/wfp-core-store.type';
import { PrimaryButton } from './primary-button';

type Props = { onNext: () => void };

export function NextButton(props: Props) {
    const { onNext } = props;
    const language = useSelector((store: RootState) => store.session.language);
    return (
        <PrimaryButton id={'nextBtn'} onClick={onNext}>
            <FaAngleRight /> {language.next}
        </PrimaryButton>
    );
}
