import { Expose, Type } from 'class-transformer';
import { AddressSearchLanguage } from '../../service/address-lookup.request';
import { PremisesAddressWrapper } from '../premises-address';

export class AddressWrapper {
    @Expose({ name: 'PremisesAddress' })
    @Type(() => PremisesAddressWrapper)
    premisesAddress: PremisesAddressWrapper;

    getString(language: AddressSearchLanguage) {
        return this.premisesAddress.getString(language);
    }

    getObject(language: AddressSearchLanguage) {
        return this.premisesAddress.getObject(language);
    }
}
