import React from 'react';
import { DefaultButton } from '../default-buttons';

type Props = React.PropsWithChildren<{ onClick: () => void; id: string }>;

export function PrimaryButton(props: Props) {
    const { onClick, id, children } = props;
    return (
        <DefaultButton id={id} onClick={onClick} className="primaryButton">
            {children}
        </DefaultButton>
    );
}
