import { Expose, Type } from 'class-transformer';
import { Currency } from '../enums';
import { OrderDetail } from './order-detail.entity';

export class Order {
    constructor(input: Partial<Order> = {}) {
        Object.assign(this, input);
    }

    @Expose({ name: 'totalamount' })
    totalAmount: number;

    currency: Currency;

    @Expose({ name: 'orderdetail' })
    @Type(() => OrderDetail)
    orderDetails: OrderDetail[]; // Contains B/D transaction specific data. Claims to be not used but actually used.
}
