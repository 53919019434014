import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row' 

import Button from '../../component/button.js';

import '../../../css/input.css'

export default class GeneralModal extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = { show:false };
	}

	handleClose = () => {
		this.setState({ show: false });
		if (this.props.handleCancel) {
			this.props.handleCancel();
		}
		if (this.props.onHide) {
			this.props.onHide();
		}
	}

	render() {
		let {
			id,
			title,
			headerClass,
			show,
			mode,
			msgArr,
			body,
			closeLabel,
			confirmLabel,
			cancelLabel,
			handleConfirm,
			handleCancel,
			children,
			customFooter,
			disableHeaderClose = false,
		} = {...this.props};

		let msg = [];
		//for (let i=0; i<msgArr.length; i++) {
		// msgArr.forEach(element => {
			// msg.push(<p key={}></p>)
		// });
		if (msgArr) {
			for (let i in msgArr) {
				msg.push(<p key={i}>{msgArr[i]}</p>);
			}
		}

		const getFooterButton = ()=>{
			switch(mode) {
				case "confirm":
					return (
						<span>
							<Button label={cancelLabel}  buttonClass={id+"-cancel-btn"}isPrimary={false} onClick={handleCancel} /> 
							<Button label={confirmLabel}  buttonClass={id+"-confirm-btn"}onClick={handleConfirm} />
						</span>
					);
				case "custom":
					return customFooter ?? null;
				default:
					return <Button label={closeLabel}  buttonClass={id+"-close-btn"} onClick={() => {
						this.handleClose();
						if (this.props.handleClose) {
								this.props.handleClose();
							}
						} }/>
			}
		}

		return (
			<Modal id={id} show={show} onHide={this.handleClose} centered backdrop="static" style={{borderRadius: "0 !important", padding:"0.5em"}}>
				<Modal.Header closeButton={!disableHeaderClose} className={headerClass}>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>{body ?? msg}</div>
					{children}
				</Modal.Body>
				<Modal.Footer style={{padding:"0.5em", borderTop: "0px"}}>
					{
						getFooterButton()
					}
				</Modal.Footer>
			</Modal>
		);
	}
}

GeneralModal.defaultProps={
	id:"",
}