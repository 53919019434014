import { instance } from '../../instance';
import { plainToInstance } from 'class-transformer';
import { PayByFpsRequest as Input } from './request';
import { PayByFpsResponse as Response } from './response';
import { PaymentApiUrl } from '../../../constants';

const url = PaymentApiUrl.PAY_BY_WALLET;

export async function payByFps(input: Input) {
    const type = input.extractType();
    const data = await instance.post(`${url}/${type}`, input);
    return plainToInstance(Response, data);
}
