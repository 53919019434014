import { Expose, Transform } from 'class-transformer';
import { booleanYNTransform } from '../../utils';
import { AddressSearchLanguage } from '../service/address-lookup.request';
import { NumberString, Stringable } from '../types';
import { joinString } from '../../../utilities/commonUtil';

export class Block implements Stringable {
    @Expose({ name: 'BlockDescriptor' })
    descriptor: string;

    @Expose({ name: 'BlockNo' })
    number: NumberString;

    @Expose({ name: 'BlockDescriptorPrecedenceIndicator' })
    @Transform(booleanYNTransform)
    isDescriptorGoFirst: boolean;

    getString(language: AddressSearchLanguage = AddressSearchLanguage.ENGLISH) {
        let arr = [];
        if (this.isDescriptorGoFirst) {
            arr = [this.descriptor??"", this.number??""];
        } else {
            arr = [this.number??"", this.descriptor??""];
        }
        return joinString(arr, (language === AddressSearchLanguage.ENGLISH ? " " : ""));
    }
}
