import React, {useState, useContext, useEffect} from 'react';

import Row from 'react-bootstrap/Row';
 
import {detect} from 'detect-browser';

import {
	FaExclamationTriangle } from 'react-icons/fa';
import { TiArrowBack } from 'react-icons/ti';
import { useSelector, useDispatch } from 'react-redux';

import * as Constant from '../../../utilities/constant';
import { Section, FormContext, GetCaptchForm } from '../layoutElements';
import { getCaptchaChallenge } from '../reducers/wfpSessionReducer';
import { 
	removeFormData, clearFormData, updateFormData, cacheFormPage, updateValidation,
	printOnPaper, submitForm, clearSignedFields, saveDataFile, loadDataFile,
	genPdfForReview, clearPdfNeedUpdate } from '../reducers/formDetail';
import {
	dialogHelper
} from '../dialogHelper';
import { RootContext } from '../../root';
import { Field } from '../inputElements'; 
import SysParam from '../../../utilities/sysParam.js';
import { setMessagePaneVisible, scrollToTop } from './utils';
import { Inner } from './components/inner';

import { useSaveForm } from '../hooks/use-save-form.hook';
import { useIsSigned } from '../hooks/use-is-signed.hook';
import { useUpdateAndValidate } from '../hooks/use-update-and-validate.hook';

import { clearPaymentSlice } from '../../../gcis-payment/redux/slice';

const StepMenuItem = ({l10n, title, titleDirect, index, changeStep, canBack}) => { 
	const {stepIdx} = useContext(FormContext);

    return (
		<button className={"stepMenuItem " + (stepIdx === index ? "selectedSideMenuButton " : "" )} href="#" onClick={() => { 
				if(canBack(stepIdx)) { 
					changeStep(index); 
				}
			}}>
			{/* <span className={(stepIdx === index ? "selectedSideMenuText " : "")}> {index+1}. { titleDirect?titleDirect:l10n.getString(title)}</span>
			{stepIdx > index ? <div className="icon icon-tick "></div> : null}  */}

			<div className="d-flex">
				<div className="note-bullet">{index + 1}.</div>
				<div style={{paddingLeft:"5px", textAlign: "left"}}>
					<span className={(stepIdx === index ? "selectedSideMenuText " : "")}>{titleDirect ? titleDirect : l10n.getString(title)}</span>
					{stepIdx > index ? <div className="icon icon-tick "></div> : null}
				</div>
			</div>
		</button>
	);
}

export const WfpFrame = ({ form, steps, validation, children, screenTitle, showHamburger, hamburgerAction }) => {
	const session = useSelector(store => store.session);
	const { showDialog, dialogOpts } = useSelector(store => store.dialogReducer);
	const { 
		showErrorDialog,
		showCaptchaDialog,
		showCompleteDialog,
		showLoadDialog,
		showSaveDialog,		
		closeDialog,
	 } = dialogHelper;
	const { setFormStepIndex, recaptcha } = useContext(RootContext);
	const {sourceType} = useContext(RootContext);
	const { formData } = useSelector(store => store.formDetail); //(oldd, newd) => JSON.stringify(oldd)==JSON.stringify(newd));
	const { language, curLanguage } = session;
	const [ stepIdx, setStepIdx ] = useState(0);
	const dispatch = useDispatch();
	// const [ showDialog, setShowDialog ] = useState(false);
	const [ menuExpanded, setMenuExpanded ] = useState(false);
	const [ activeFieldIds, setActiveFieldIds ] = useState(new Set());
	const [ fieldIdsByStep, setFieldIdsByStep ] = useState([]);
	
	const browser = detect();

	const {	localization, intro, signRequired, signDeclaration, paymentRequired, displayReference, reference,
			signFields, submitTransformFunc, resumeFromStepIdx,
			resumeRetainFormDataKeys, screenId, submissionType,
			paymentMethodId, paymentAmountId, onlinePaymentList, offlinePaymentList, skipAckEmail,
			} = form;

	const defaultDialogOpts = {
		id:"dialog",
		title: <><FaExclamationTriangle /> Validation Error</>,
		// msgArr: [ 'Validation error exists!' ],
		body: '',
		mode: 'message', // not 'confirm' -> two buttons, need to provide handleConfirm and handleCancel functions
		closeLabel: language.close,
		cancelLabel: language.cancel,
		confirmLabel: language.confirm,
		headerClass: '',
		handleConfirm: () => { setShowDialog(false) },
		handleCancel: () => { setShowDialog(false) },
		onHide: () => { setShowDialog(false) },
	}
	// const [ dialogOpts, setDialogOpts ] = useState({...defaultDialogOpts});
	const setShowDialog = s => {};
	const setDialogOpts = s => {};

	const [ formStep, setFormStep ] = useState(steps);
	
	const [ formValErrs, setFormValErrs ] = useState({});
	
	const { onSave } = useSaveForm(form);
	const isSigned = useIsSigned(form);
	const {
		updateAndValidate, updateValidationErrMsg,
		validationErrMsg, isSavePrintValErr, registeredValidations,
		setValidationErrMsg, setRegisteredValidations
	} = useUpdateAndValidate(form, registeredValidations);

	useEffect(() => { 
		updateValidationErrMsg(true, false, true, false);
	}, [curLanguage, registeredValidations]);

	const performClear = () => {
		messagePaneVisible(false);
		const dataToBeCleared = {};
		activeFieldIds.forEach(k=>{
			dataToBeCleared[k] = undefined;
		})

		dispatch(removeFormData(dataToBeCleared));
	}

	const performComplete = (formCode) => {
		messagePaneVisible(true);
		const errMsg = updateValidationErrMsg(true, true, false, false);
		if (errMsg.length > 0) {
			scrollToTop();
		}
		else { 
			dispatch(showCompleteDialog({
				language: form.localization, 
				includeSignature: stepIdx === formStep.length-2,
				formCode: formCode,
				lr173IndividualOwner: (!formData.NaN_ApplicationType || formData.NaN_ApplicationType === "0"),
				performPrint: () => {
					if (!session.captchaToken
						&& !Constant.NO_CAPTCHA) {
						dispatch(showCaptchaDialog({
							children: <GetCaptchForm />, 
							language: language,
							isCompletedForm: true,
							performPrint: performPrint,
						}));
					} else {
						performPrint(true);
					}
				}}));
		}
	}

	const goToReviewStep = (forced) => {
		messagePaneVisible(true);
		if (!forced && updateValidationErrMsg(true, true).length > 0) {
			scrollToTop();
			return;
		}

		const performReview = () => {
			try {
			
				dispatch(genPdfForReview(
					form, 
					() => { changeStep(formStep.length-3, true, true); dispatch(closeDialog()); }, 
					err => { dispatch(showErrorDialog(err, language)) }, 
					(o) => {
						dispatch(showErrorDialog({
							err: o.err,
							handleClose: () => dispatch(showCaptchaDialog({children: <GetCaptchForm />, language: language, performPrint: performReview}))
					}, language))
				}));
			} catch (err) {
				console.log(err);
				dispatch(showErrorDialog({err: err}, language));
			}
		}

		if (!session.captchaToken
			&& !Constant.NO_CAPTCHA) {
			dispatch(showCaptchaDialog({
				children: <GetCaptchForm />, 
				language: language,
				performPrint: performReview,
			}));
		} else {
			performReview()
		}
		
	}

	const performPrint = (isCompletedForm) => {
		messagePaneVisible(true);
		try {
			dispatch(printOnPaper(
				form,
				isCompletedForm,
				() => {
					if (isCompletedForm && !form.noAck) {
						// Go to Acknowledgement
						changeStep(formStep.length-1, true);
					}
					dispatch(closeDialog());
				}, 
				err => { dispatch(showErrorDialog(err, language)) }, 
				(o) => {
					dispatch(showErrorDialog({
						err: o.err,
						handleClose: () => dispatch(showCaptchaDialog({children: <GetCaptchForm />, language: language, performPrint: performPrint}))
				}, language))
			}));
		} catch (err) {
			console.log(err);
			dispatch(showErrorDialog({err: err}, language));
		}
	}

	const onPrint = () => {
		const errMsg = updateValidationErrMsg(
			true,
			true,
			true,
			true
		);
		if (errMsg.length > 0) {
			scrollToTop();
		} else {
			if (
				!session.captchaToken &&
				!Constant.NO_CAPTCHA
			) {
				dispatch(
					showCaptchaDialog({
						children: <GetCaptchForm />,
						language: language,
						performPrint: performPrint,
					})
				);
			} else {
				performPrint();
			}
		}
	}

	const performSubmitForm = () => {
		messagePaneVisible(true);
		if (updateValidationErrMsg(true, true).length > 0 ) {
			scrollToTop(); 
		} else { 
			dispatch(
				submitForm(
					form,
					() => {dispatch(closeDialog());nextStep();},
					err => {
						console.log(err);
						dispatch(showErrorDialog(err, language));
					},
					() => dispatch(showCaptchaDialog({children: <GetCaptchForm />, language: language, performPrint: performSubmitForm})) 
				)
			); 
		}
	}

	const hasError = () =>{
		messagePaneVisible(true);
		const errLength = updateValidationErrMsg(true, true).length;
		if (errLength > 0){
			scrollToTop(); 
			return true;
		} 
		return false;
	}

	const performLoad = (obj) => {
			try {
				
				if (!obj.loadPw) {
					throw { message:"bad key"};
				}
			
				dispatch(loadDataFile(form, formData, language, showErrorDialog, obj, err => {
					dispatch(showErrorDialog(err, language)); 
				}, setStepIdx, sourceType,
				(o) => {
					dispatch(showErrorDialog({
						err: o.err, 
						handleClose: () => {
							dispatch(showLoadDialog({
								children: <LoadDialogChildren returnObj={obj} />, 
								returnObj: obj,
								handleConfirm: performLoad,
								language:language }));
						}
					}, language));
				}));

			} catch (err) {
				if ((err.message && err.message.indexOf('bad key') >= 0)) { 
					window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:language.passwordError}, language)));
				} 
				else {
					window.requestAnimationFrame(()=>dispatch(showErrorDialog({err:JSON.stringify(err)}, language)));
				}
			}

		return true;
	}

	const prevStep = () => { 
		messagePaneVisible(true);
		changeStep(stepIdx-1);
	}
	const nextStep = () => { 
		messagePaneVisible(true);
		changeStep(stepIdx+1, true); 
	}

	const messagePaneVisible = setMessagePaneVisible;

	const changeStep = (toStepIdx, forced, pdfIsLoaded) => {
		let _pdfIsLoaded = pdfIsLoaded;

		if (stepIdx <= toStepIdx && !forced) return;
		if (stepIdx > toStepIdx) {
			// setCurrentStepTitle(formStep[toStepIdx].title);

			if (signPerformed() && !_pdfIsLoaded) {
				for (let signField of form.signFields(formData)) {
					dispatch(removeFormData({[signField]:null}));
					dispatch(clearSignedFields()); 
				}
			}

			// Clear payment data when back
			dispatch(clearPaymentSlice());

			if (toStepIdx === formStep.length-3 && !_pdfIsLoaded && !form.skipReview) {
				goToReviewStep(true);
				return;
			}

			setValidationErrMsg([]);
			setStepIdx(toStepIdx);
			setFormStepIndex(toStepIdx);
			setMenuExpanded(false);
			scrollToTop();		
			dispatch(clearPdfNeedUpdate());	
			return;
		}

		if ( stepIdx === 1  		// T&C Page
			&& isSigned ) {			// contains signature
			// Force to navigate to Review page
			toStepIdx = formStep.length-3;
			_pdfIsLoaded = true;
		}

		if (toStepIdx === formStep.length-3 && !_pdfIsLoaded) {
			goToReviewStep();
			return;
		}

		if (updateValidationErrMsg(true, true).length > 0 && toStepIdx > stepIdx) {
			scrollToTop();
			// showDialogByType(Constant.DIALOG_ERR, { body: language.correctValidationError, children: <div style={{paddingTop:'16px', color:'red'}}>{validationErrMsg}</div> });
		} else {
			// setCurrentStepTitle(formStep[toStepIdx].title);

			// cache form page for submission
			const reviewContent = document.getElementById('confirmation-step-body');
			if (reviewContent) {
				dispatch(cacheFormPage('confirmation-step-body', reviewContent.innerHTML));
				dispatch(getCaptchaChallenge());
			}

			setStepIdx(toStepIdx);
			setFormStepIndex(toStepIdx);
			setMenuExpanded(false);
			scrollToTop();
			dispatch(clearPdfNeedUpdate());	
		} 
	}

    const menu = [];
    for (let i=0; i<formStep.length; i++) {
		menu.push(<StepMenuItem 
					key={"stepMenuItem_"+i} 
					index={i} 
					title={formStep[i].title} 
					titleDirect={formStep[i].titleDirect}
					l10n={localization} 
					canBack={i => !formStep[i].hideBack}
					changeStep={changeStep} />)
	}
	
	const currentStep = formStep[stepIdx];

	const startNewForm = () => {
		dispatch(clearFormData(resumeRetainFormDataKeys));
		setStepIdx(resumeFromStepIdx ?? 0);
		setFormStepIndex(resumeFromStepIdx ?? 0);
		setMenuExpanded(false);
		scrollToTop();
	};

	const signPerformed = () => {
		if (form.signFields) {
			for (let signField of form.signFields(formData)) {
				if (formData[signField]) return true;
			}
		}
		
		return false;
	}

	const handleLoad = ()=> {
		const returnObj = {};
		const children = <LoadDialogChildren returnObj={returnObj} />
		// showDialogByType(Constant.DIALOG_LOAD, {handleConfirm:performLoad, children:children, returnObj: returnObj});
		dispatch(showLoadDialog({
			language: language,
			children: children,
			handleConfirm: performLoad,
			returnObj: returnObj,
		}));
	}

	const LoadDialogChildren = (props) => {
		const { formData, completedFields } = useSelector(store => store.formDetail); //(oldd, newd) => JSON.stringify(oldd)==JSON.stringify(newd));
		const { language } = useSelector(store => store.session);
		const session = useSelector(store => store.session);
		const {returnObj} = props;
		 
		return (
			<>
				<Section classes="no-padding-top">
					<Field.FileUpload id='savedFormLoad' label={language.savedFormFile} onValChange={v=>returnObj.loadFile=v} 
									required filter=".lrwf,.txt" limit={Constant.UPLOAD_FILE_LIMIT} />
				</Section>
				<Section classes="no-padding-top">
					<Field.Password id='dialogLoadPw' nonformdata onValChange={v=>{
						returnObj.loadPw=v
					}}/>
					{/* <div style={{padding:"5px 0px 20px 0px"}}>- or -</div>
					<Field.FileUpload id='eCertLoad' label={language.uploadECert} nonformdata onValChange={v=>returnObj.loadECert=v}
										filter=".p12" limit="10240" />
					<Field.Password id='eCertPin' label={language.pin} nonformdata onValChange={v=>returnObj.pin=v} /> */}
				</Section>
				<Field.Captcha
					captchaToken={session.captchaToken}
					captchaGetChallengeInProgress={session.captchaGetChallengeInProgress}
					captchaChallenge={session.captchaChallenge}
				/> 
				
			</>
		);
	}

	// Skip Introduction Page
	const query = new URLSearchParams(window.location.search);
	if (query.get(Constant.SKIP_INTRO_STEP) === "true") {
		// Skip the first step
		changeStep(1, true);
	}
	else {
		// Do nothing
	}
	query.delete(Constant.SKIP_INTRO_STEP);
	const newRelativePathQuery = window.location.pathname + '?' + query.toString();
	window.history.pushState(null, '', newRelativePathQuery);

	return (
		<Row className="body wfpRootContainer">

			<div className="topNavBar">
				{/* <div className="small" style={{cmarginBottom: '0.5em'}}><FaRegFileAlt />&nbsp;</div> */}
				<h1 className="formTitle">
					{window.WFP_CONFIG?.ENV == "UAT" ?
                    "[UAT]"
                    :
                    ""}
					{ /*showHamburger ? */}
					{/*: null }*/} {screenTitle} <span style={{color:'green'}}>[{ displayReference ?? reference}]</span>
				</h1>
				<div className="topNavBarBottom">
					{ SysParam.getSourceType(sourceType) ?
						<>
							{(form.code === "LREEA1" || form.simplifiedForm) ?
								null
								:
								<button className="return-btn" onClick={()=>{
									window.location.href=SysParam.getSourceType(sourceType).url(curLanguage)
								}}>
									<TiArrowBack style={{fontSize:'1.5em', marginTop:'-4px'}} />
									{language[SysParam.getSourceType(sourceType).returnText]}
								</button>
							}
						</>
						:
						null
					}
					{!document.querySelector('div.required')?
						null:
						<div className="mandatoryNote">
							<div className="required-label" style={{ display: "inline", marginRight: "5px" }}>
								{language.mandatoryHint}
							</div>
						</div>
					}		

				</div>
					
			</div>
			<FormContext.Provider value={{
				grecaptcha: () => recaptcha, 
				steps: formStep,
				stepIdx: stepIdx,
				paymentRequired: paymentRequired,
				paymentMethodId: paymentMethodId,
				paymentAmountId: paymentAmountId,
				onlinePaymentList: onlinePaymentList,
				offlinePaymentList: offlinePaymentList,
				submissionType: submissionType,
				signRequired: signRequired,
				signFields: signFields,
				signDeclaration: signDeclaration,
				currentStepTitle:formStep[0].title, 
				formCode: screenId,
				formValErrs:formValErrs, 
				formLocalization: localization,
				resumeFromStepIdx: resumeFromStepIdx,
				resumeRetainFormDataKeys: resumeRetainFormDataKeys,
				submitTransformFunc: submitTransformFunc,
				form:form,
				activeFieldIds: activeFieldIds,
				skipAckEmail: skipAckEmail,
				startNewForm: startNewForm,
				// showDialog: showDialogByType,
				// showErrorDialog: showErrorDialog,
				handleLoad: handleLoad,
				prevStep: prevStep,
				nextStep: nextStep,
				updateStepIdx: setStepIdx,
				// updateCurrentStepTitle: setCurrentStepTitle, 
				updateFormValErrs: setFormValErrs,
				updateAndValidate: updateAndValidate,
				registerValidationRule: ({datumId, validations, callback}) => {
					setRegisteredValidations(existingValidations => ({...existingValidations, [datumId]: validations}));
				},
				deregisterValidationRule: ({datumId}) => {
					setRegisteredValidations(existingValidations => {
						return Object.fromEntries(Object.entries(existingValidations).filter(([key, value]) => key !== datumId));
					});
				},
			}}>
				<Inner
					menuExpanded = {menuExpanded}
					setMenuExpanded = {setMenuExpanded}
					validationErrMsg = {validationErrMsg}
					isSavePrintValErr={isSavePrintValErr}
					onSave={onSave}
					onPrint={onPrint}
					performComplete={performComplete}
					performClear={performClear}
					performSubmitForm={performSubmitForm}
					hasError={hasError}
					changeStep={changeStep}
				/>
			</FormContext.Provider>
		</Row>
	);
}
