import React, { useContext } from 'react';
import { FormContext } from '../../../../layout/core/layoutElements';
import { FormContext as TFormContext } from '../../../../layout/core/utilities/form-context/types';
import { TransactionData } from '../../../entities';
import { WebToAppButton } from './web-to-app-button';
import { isMobile, payment } from '../../../given';

type Props = {
    transactionData: TransactionData;
};

export function FpsPanel(props: Props) {
    const { transactionData } = props;
    const { formLocalization } = useContext<TFormContext>(FormContext);

    return (
        <>
            {isMobile() && transactionData?.fpsResponse.fpsqrcodeurl ? 
                <>
                    <span>{ formLocalization?.GcisPayment?.FPS_PAYMENT_MOBILE_FOOTER }</span>
                    <br/><br/>
                    <WebToAppButton redirectUrl={ payment.pasgUrl(transactionData?.fpsResponse.fpsqrcodeurl) } />
                    <br/><br/>
                    <div className='fpsQRCodeSeparate'>{ formLocalization?.GcisPayment?.FPS_PAYMENT_SEPARATE }</div>
                    <br/><br/>
                </>
                : null
            }
            <span>{ formLocalization?.GcisPayment?.FPS_PAYMENT_FOOTER }</span>
            <br/><br/>
            <img    id='gcis-payment-fps-base64image' 
                    className='fpsQRCodeImg fpsQRCodePanel' 
                    src={ transactionData?.fpsResponse.fpsqrcodeimgbase64 } />
            
            
            {  
                //@ts-ignore
                window.WFP_CONFIG?.TEST_FPS_PAYMENT ? 
                <>
                    <div>[UAT]</div>
                    <div>Please click the link below to proceed on the simulator:</div>
                    <a href="https://epaygateway1.gcisdctr.gov.hk/o/paymentserver/fi_simulator/bcfp_simulator/authBCFP.jsp" target="_blank">FPS Simulator</a>
                    <div>{ formLocalization?.GcisPayment?.FPS_PAYMENT_ID + transactionData?.fpsResponse.paymentid }</div>
                </>
                : null
            }
        </>
    );
}