// Custom errors used only in web app for alternative non-primary use cases

export const POLLING_CANCELLED = 'POLLING_CANCELLED';

export const PAYMENT_CANCELLED = 'PAYMENT_CANCELLED';

export const WEB_TOKEN_EXPIRED = 'WEB_TOKEN_EXPIRED';

export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

export const ERROR_NO_PAYMENT_METHODS_AVAILABLE = 'ERROR_NO_PAYMENT_METHODS_AVAILABLE';
