import { useContext } from 'react';
import { useSelector } from 'react-redux';
import lo from 'lodash';

import { FormContext } from '../../layout/core/layoutElements';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { extractPaymentMethods } from '../../layout/core/utilities/form-context';
import { GCIS_PAYMENT_HANDLER } from '../constants';

export function useIsPaymentHandledByGcis() {
    const language = useSelector((store: RootState) => store.session.language, lo.isEqual);
    const formData = useSelector((store: RootState) => store.formDetail.formData);
    const formContext = useContext(FormContext);
    const paymentMethods = extractPaymentMethods({ formData, formContext, language });
    //@ts-ignore
    const paymentMethodId = formContext.paymentMethodId ?? 'CDPaymentMethod';
    const paymentMethodValue = formData[paymentMethodId];

    //@ts-ignore
    const paymentMethod = paymentMethods.find((pm) => pm.value === paymentMethodValue);
    return !!paymentMethod && paymentMethod?.meta?.handler === GCIS_PAYMENT_HANDLER;
}
