import { Expose, Type } from 'class-transformer';
import { CombinedData, Order } from '../../../entities';
import { OnlineMethodType, PaymentMethodCode, PaymentLocale } from '../../../enums';

export class PayByOnlineMethodRequest {
    constructor(input: Partial<PayByOnlineMethodRequest> = {}) {
        Object.assign(this, input);
    }
    @Expose({ name: 'transactionid' })
    transactionId: string;

    @Expose({ name: 'apprefid' })
    appRefId?: string; // transactionId will be used if not provided

    @Expose({ name: 'webtoken' })
    webToken: string;

    @Expose({ name: 'paymentmethod' })
    paymentMethod: PaymentMethodCode;

    @Type(() => Order)
    order: Order;

    locale: PaymentLocale;

    @Expose({ name: 'returnurl' })
    returnUrl: string; // return url after completion of payment

    @Expose({ name: 'eserviceid' })
    eServiceId?: string; // for use cases with more than 1 e-services setup

    type: OnlineMethodType; // Not in API doc

    extractType(): OnlineMethodType {
        const type = this.type;
        delete this.type;
        return type;
    }

    static fromData(combinedData: CombinedData, type: OnlineMethodType) {
        const input = new PayByOnlineMethodRequest();
        Object.assign(input, combinedData, { type });
        return input;
    }
}
