const userAgent = navigator.userAgent;
let appScheme = '';
function locationPullApp() {
  window.location.href = appScheme;
}

function windowOpenPullApp() {
  const state = window.open(appScheme, '_blank');
}

function iframePullApp() {
  const ifr = document.createElement('iframe');
  setTimeout(() => {
    document.body.removeChild(ifr);
  }, 2000);
  ifr.setAttribute('id', 'testIfr');
  ifr.setAttribute('src', appScheme);
  ifr.setAttribute('style', 'display:none');
  document.body.appendChild(ifr);
}

function myBrowser() {
  if (userAgent.indexOf('UCBrowser') > -1 || userAgent.indexOf('UCWEB') > -1) {
    return 'UC';
  }
  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome';
  }
}

function androidOpenApp() {
  const mb = myBrowser();
  if (mb === 'Chrome') {
    windowOpenPullApp();
  } else {
    iframePullApp();
  }
}

export function check() {
  const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1; // android终端
  const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isAndroid) {
    return 1;
  }
  if (isiOS) {
    return 2;
  }
}

export function checkOutApp(scheme) {
  const mobileType = check();
  appScheme = scheme;
  if (mobileType === 1) {
    androidOpenApp();
  }
  if (mobileType === 2) {
    locationPullApp();
  }
}

