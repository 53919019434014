import React from 'react';
import { DeliveryMethod, PropertyType, SubscriberType } from '../eal1_const';
import { CoreStrings } from '../../core/utilities/coreLocalization';

const notesi     = () => 
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(i)</div>
        <div>The Subscriber must be the current owner of the property(ies) as specified in this application form and (where applicable) the Additional Order(s) as shown on the land register(s) maintained by the Land Registry. If there is any discrepancy in the name between the proof of identity and the land register concerned, additional supporting document is required to be produced to our satisfaction.</div>
    </div>
const notesii    = () =>
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(ii)</div>
        <div>If the identification document number provided is different from that contained in the registered instrument under which the Subscriber acquired the property concerned, additional supporting document is required to be produced to our satisfaction.</div>
    </div>
    
const notesiii     = () => 
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(iii)</div>
        <div>Provision of a copy of identification document and (where applicable) an additional document for identity proof is required for every new application or new order (except for online application digitally signed with iAM Smart+ or digital certificate issued by the Hongkong Post Certification Authority or Digi-Sign Certification Services Limited and submitted by individual owner). Such copy(ies) will be destroyed after expiry of the subscription period.</div>
    </div>

const notesiv = (deliveryMethod) => {
    let content = '';
    switch (deliveryMethod){
        case DeliveryMethod.POST_OR_COURIER:
            content = <><b><i>(For new application/new order)</i> For application by post/courier service,</b> please submit your application together with copy of your identification document and an additional document for identity proof to our Customer Centre or any of our New Territories Search Offices. Examples of the additional document for identity proof include travel documents issued by the HKSAR Government, passports or travel documents issued by a government authority outside Hong Kong, Business Registration Certificate (for corporate owner), utility bill, Demand for Rates and/or Government Rent, tax return, bank statement, etc. As for other types of identity proof, they shall be considered on a case-by-case basis. For application by post, please ensure that sufficient postage is paid to avoid unsuccessful delivery. Any underpaid mails will be handled by the Hongkong Post and the Land Registry will not settle the relevant surcharge payments for underpaid mails.</>
            break;
        case DeliveryMethod.BY_OWNER:
            content = <><b><i>(For new application/new order)</i> For application in person,</b> please visit our Customer Centre or any of our New Territories Search Offices and produce your identification document for verification to our satisfaction.</>
            break;
        case DeliveryMethod.BY_AUTHORIZED_REP:
            content = <><b><i>(For new application/new order)</i> For application in person (by the representative of a corporate owner),</b> your representative is required to produce his/her identification document for our verification together with an authorization form marked Annex II duly signed by you. Your representative is also required to make a statutory declaration marked Annex III to support your application. </>
            break;
        case DeliveryMethod.ONLINE:
            content = <><b><i>(For new application/new order)</i></b> <b>For online application by corporate owner,</b> please complete the online form, digitally sign the form with your organizational digital certificate issued by the Hongkong Post Certification Authority or Digi-Sign Certification Services Limited and submit your application together with copy of your certificate of incorporation and an additional document for identity proof. Examples of the additional document for identity proof include travel documents issued by the HKSAR Government, passports or travel documents issued by a government authority outside Hong Kong, Business Registration Certificate (for corporate owner), utility bill, Demand for Rates and/or Government Rent, tax return, bank statement, etc. As for other types of identity proof, they shall be considered on a case-by-case basis. <b>For online application by individual owner,</b> please complete the online form and digitally sign the form with iAM Smart+ or digital certificate issued by the Hongkong Post Certification Authority or Digi-Sign Certification Services Limited. The provision of copy of your identification document and an additional document for identity proof as stated above may be dispensed with.</>
            break;
        default:
            content = <><b><i>(For new application/new order)</i></b> You can submit the application by post/courier service, in person or online.</>
            break;
    }
    return [
        <><div className="d-flex" style={{paddingBottom:"20px"}}>
            <div className="note-bullet">(iv)</div>
            <div>{content}</div>
        </div></>
    ];
}

const notesv = () => 
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(v)</div>
        <div>
            <b><i>(For service renewal)</i></b> Application for renewal of subscription should be made in the same form used for new application (Form LR/EAL/1 (AF) of 06/2024 Edition) and delivered to the Land Registry not less than 7 calendar days before the expiry of the current subscription period.
                <br/><br/>
            <div className="d-flex" style={{paddingBottom:"20px"}}>
                <div className="note-bullet">(a)</div>
                <div>Your application can be submitted by post/courier service, in person or online according to the same procedure applicable to a new application/new order as stated in Note (iv) above.</div>
            </div>
            <div className="d-flex" style={{paddingBottom:"20px"}}>
                <div className="note-bullet">(b)</div>
                <div>If you have a Verification Code and a correct and valid Verification Code is provided in this application form, the requirement of production of identification document, additional document for identity proof, (where applicable) authorization form and statutory declaration as stated in Note (iv) above may be dispensed with.</div>
            </div>
        </div>
    </div>

const notesvi    = () =>
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(vi)</div>
        <div>If you wish to make subscription in respect of more than one land register, you should complete the Additional Order for additional land register(s).</div>
    </div>

const notesvii   = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(vii)</div>
        <div>The one-off subscription will be valid as long as there is no change of property ownership and service renewal is not required.</div>
    </div>

const notesviii     = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(viii)</div>
        <div>Property Alert will be provided in respect of the land register of the property selected by you with its address (as shown on the relevant register) and the PRN of that register specified in the Customer Payment Receipt issued to you.</div>
    </div>

const notesix    = () => 
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(ix)</div>
        <div>You are required to provide only one Property Alert Email Address in this application form and (where applicable) the Additional Order(s) for each land register being selected. You also have an option to provide one Property Alert Supplementary Email Address for your authorized recipient to receive copy of Property Alert notifications and other correspondence issued by the Land Registry for each land register being selected. If there is more than one selected land register, the several Property Alert Email Addresses and (where applicable) Property Alert Supplementary Email Addresses provided by you for those land registers may be same or different.</div>
    </div>

const notesx     = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(x)</div>
        <div>You have an option to provide one Hong Kong mobile phone number for receiving SMS reminder upon issuance of Property Alert notifications and correspondence by the Land Registry to the Property Alert Email Address in this application form and (where applicable) the Additional Order(s) for each land register being selected. If there is more than one selected land register, the several mobile phone numbers provided by you for those land registers may be same or different.</div>
    </div>

const notesxi      = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(xi)</div>
        <div>For application delivered by post/courier service, you may make payment by crossed cheque or bank draft. Do NOT send cash by post/courier service.</div>
    </div>

const notesxii   = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(xii)</div>
        <div>For payment by crossed cheque, bank draft or e-Cheque, it should be made payable to “The Government of the Hong Kong SAR” or “The Land Registry”.</div>
     </div>

const notesxiii     = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(xiii)</div>
        <div>For payment by personal cheque, the service may only be provided after the cheque is cleared and the subscription fee is actually received in full by the Land Registry, which normally takes about 3 working days.</div>
    </div>

const notesxiv    = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(xiv)</div>
        <div>If you have selected more than one land register in this application form and designated the same Property Alert Email Address and (where applicable) the same Property Alert Supplementary Email Address for receiving Property Alert notifications and correspondence from the Land Registry, and/or (where applicable) the same mobile phone number for receiving SMS reminder, you have an option to consolidate all Property Alert notifications, correspondence and messages of the same type issued on the same day relating to these land registers in one email notification and (where applicable) in one SMS reminder.</div>
    </div>

const notesxv    = () =>  
    <div className="d-flex" style={{paddingBottom:"20px"}}>
        <div className="note-bullet">(xv)</div>
        <div>The language you have chosen for email and (where applicable) SMS reminder will be applied to all orders in this application form. Request for change of language during the subscription period should be made by submitting an application for change of particulars (Form LR/EAL/2 of 06/2023 Edition) to the Land Registry.</div>
    </div>

export default {
    Eng: {

        // override common terms checkbox text
        
        termsAndConditionsOf: "Terms and Conditions for Subscription to Property Alert",
        readAndUnderstandTerms_EAL1: "I/We have read and understand the above \"General Terms of Use\" and \"Terms and Conditions for Subscription to the Property Alert\", and agree to be bound by them.",
        readAndUnderstandPICS_EAL1: "I/We have read and understand the above \"Personal Information Collection Statement\", and agree to be bound by it.",

        formTitle: "APPLICATION FOR SUBSCRIPTION TO PROPERTY ALERT", 

        
        steps_Particulars: "Subscription Particulars",
        steps_SuppSheets: "Additional Order(s) ",
        steps_SuppSheets_sublabel: "[Note (vi)] ",
        signNotesTitle: formData => {
            let title = "[Note "
            title += "(iii) & (vi)"
            title += "]"
            return title;
        },
 
        notesi      : notesi,
        notesii     : notesii,
        notesiii    : notesiii,
        notesiv     : notesiv,
        notesv      : notesv,
        notesvi     : notesvi,
        notesvii    : notesvii,
        notesviii   : notesviii,
        notesix     : notesix,
        notesx      : notesx,
        notesxi     : notesxi,
        notesxii    : notesxii,
        notesxiii   : notesxiii,
        notesxiv    : notesxiv,
        notesxv     : notesxv,
        /* SCR-12625: Removed
        notesxvi    : notesxvi,
        notesxvii   : notesxvii,
        */

        notes: () => [
            notesi(),
            notesii(),
            notesiii(),
            notesiv(),
            notesv(),
            notesvi(),
            notesvii(),
            notesviii(),
            notesix(),
            notesx(),
            notesxi(),
            notesxii(),
            notesxiii(),
            notesxiv(),
            notesxv(),
            /* SCR-12625: Removed
            notesxvi(),
            notesxvii(),
            */
        ],

        paymentMethod_notes: () => {
            return <>
                <p>{notesxi()}</p>
                <p>{notesxii()}</p>
                <p>{notesxiii()}</p>
            </>;
        },

        paymentSubLabel: "[Note (xi) to (xiii)]",

        annexII:"Form for authorization for individual owners (Annex II)",
        annexIII:"Form for authorization for corporate owners (Annex III)",
        annexIV:"Form for declaration by a person authorized by an individual owner (Annex IV)",
        annexV:"Form for declaration by a person authorized by a corporate owner (Annex V)",

        requirements_esign_desp: ()=>(<>{CoreStrings.Eng.requirements_esign_desp()}<br/>For application by corporate owner, the completed online form should be digitally signed by the <b>organizational</b> digital certificate.</>),
        
        // Form fields
        part_a_particulars: "Subscriber's Particulars", 
        //part_b_service: "Service Details", 
        part_c_application_delivery: "Application Delivery Method", 
        part_d_subscription: "Subscription Details ", 
        part_d_subscription_sublabel: "[Note (vi)] ", 
        part_e_declaration: "Upload Supporting Document(s) (If necessary)", 
        part_e_declaration_sublabel: "[Note (i)] ", 
        part_e1_declaration: "Upload Identification Document", 
        questionnaire_option: "Source of knowing Property Alert", 
        additional_title: "Additional Order(s)", 
        additional_part_a_particulars: "Subscriber's Particulars", 
        additional_part_b_service: "Service Details", 
        additional_part_c_subscription: "Subscription Details",
        
        // Form - Part1
        SubscriberNameEng: "Name of Subscriber (English)", SubscriberNameEngSubLabel: "[Note (i)]", 
        SubscriberNameChi: "Name of Subscriber (Chinese)", SubscriberNameChiSubLabel: "[Note (i)]", 
        IdDoc: "Type of Identification Document", 
        RepresentativeIdDoc: "Type of Identification Document of the Authorized Representative",
        field_IdDoc_list:[
            {id: "IdDoc", value: "IdDoc_HKID_Strike", text: "HKID Card"},
            {id: "IdDoc", value: "IdDoc_CR_Strike", text: "Company Registration"},
            {id: "IdDoc", value: "IdDoc_Other_Strike", text: "Other Identification Document (please specify)", },
        ],
        field_Representative_IdDoc_list:[
            {id: "RepresentativeIdDoc", value: "RepresentativeIdDoc_Opt_1", text: "HKID Card"},
            {id: "RepresentativeIdDoc", value: "RepresentativeIdDoc_Opt_2", text: "Other Identification Document (please specify)", },
        ],
        IdDocOther: "Other Identification Document (please specify) Number", IdDocOtherSubLabel: "[Note (ii) & (iii)]",
        IdNo: "Identity Number", IdNoSubLabel: "[Note (ii) & (iii)]", 
        RepresentativeIdNo: "Identity Number",
        OtherIdDoc: "Type and Document No. of Additional Identity Proof", OtherIdDocSubLabel: "[Note (iv) & (v)]", 
        CorrAddress: "Postal Correspondence Address", 
        RepresentativeAddress: "Postal Correspondence Address of the Authorized Representative", 
        ContactTel: "Contact Phone Number", 
        //Fax: "Fax Number", 
        //ContactPersonTitle: "Contact Person Salutation", 
        // field_ContactPersonTitle_list:[
        //     {id: "ContactPersonTitle", value: "NaN_Choice2_Opt_1", text: "Mr"},
        //     {id: "ContactPersonTitle", value: "NaN_Choice2_Opt_2", text: "Ms"},
        // ],
        ContactPerson: "Name of Contact Person", 

        // Form - Part2
        SubscriberType: "Type of Subscriber", 
        field_SubscriberType_list:[
            {id: "SubscriberType", value: "New Applicant / 新申請人", text: "New Applicant"},
            {id: "SubscriberType", value: "Current Subscriber / 現有用戶", text: "Current Subscriber"},
        ],
        AccountNo: "Account Number", 
        AccountNo1: "Account Number(Prefix)", 
        AccountNo2: "Account Number(Suffix)", 
        
        /* SCR-12625: Removed
        SubscriptionType: "Type of Subscription", SubscriptionTypeSubLabel: "[Note (iii) & (iv)]",
        field_SubscriptionType_list:[
            {id: "SubscriptionType", value: "New Application / New Order / 新申請 / 新訂單", text: "New Application / New Order [Note (iii)]"},
            {id: "SubscriptionType", value: "Service Renewal / 服務續期", text: "Service Renewal [Note (iv)]"},
        ],
        field_SubscriptionType0_list:[
            {id: "SubscriptionType", value: "New Application / New Order / 新申請 / 新訂單", text: "New Application / New Order"},
        ],
        */
        SubscriptionFee: "Subscription Period & Fee (fee per land register)",
        SubscriptionFeeSubLabel: (subscriberType) => subscriberType === SubscriberType.NEW_SUBSCRIBER ? "[Note (vii)]" : "[Note (v) & (vii)]",
        field_SubscriptionFee0_list:[
            {id: "SubscriptionFee", value: "One-off Subscription(HK$380) / 一次過訂購方式(港幣$380)", text: "New Application / New Order: One-off Subscription (HK$380)"},
            {id: "SubscriptionFee", value: "24 months(HK$250) / 24 個月(港幣$250)", text: "New Application / New Order: 24-Month Subscription (HK$250)"},
        ],
        field_SubscriptionFee_list:[
            {id: "SubscriptionFee", value: "One-off Subscription(HK$380) / 一次過訂購方式(港幣$380)", text: "New Application / New Order: One-off Subscription (HK$380)"},
            {id: "SubscriptionFee", value: "24 months(HK$250) / 24 個月(港幣$250)", text: "New Application / New Order: 24-Month Subscription (HK$250)"},
            {id: "SubscriptionFee", value: "24 months(HK$160) / 24 個月(港幣$160)", text: "Service Renewal: 24 months (HK$160)"},
        ],

        OrderNo: "Current Order No.", 
        VeriCode: "Verification Code", 
        /* SCR-12625: Removed
        VeriCodeSubLabel: "[Note (v)]", 
        NewSubFee: "Subscription Period & Subscription Fee (fee per land register)", NewSubFeeSubLabel: "[Note (vi)]",
        field_NewSubFee_list:[
            {id: "NewSubFee", value: "One-off Subscription(HK$580) / 一次過訂購方式(港幣$580)", text: "One-off Subscription (HK$580) [Note (vi)]"},
            {id: "NewSubFee", value: "24 months(HK$250) / 24 個月(港幣$250)", text: "24 months (HK$250)"},
        ],
        ReSubFee: "Subscription Period & Subscription Fee (fee per land register)", 
        field_ReSubFee_list:[
            {id: "ReSubFee", value: "24 months(HK$160) / 24 個月(港幣$160)", text: "24 months (HK$160)"},
        ],
        */
        "Subscription Service Starting Date": "Subscription Service Starting Date", 
        //AdditionOrder: "Additional Order(s)", AdditionOrderSubLabel: "[Note (vii)]", 
        NumAdditionalOrder: "Number of Additional Order(s)",
        OrderSheetNo1: "From", 
        OrderSheetNo2: "to", 
        Language: "Language for email and (where applicable) SMS reminder", LanguageSubLabel: "[Note (xv)]", 
        field_Language_list:[
            {id: "Language", value: "English / 英文", text: "English"},
            {id: "Language", value: "Chinese / 中文", text: "Chinese"},
        ],
        EalertNotification: "Consolidate Property Alert notifications and (where applicable) SMS reminders", EalertNotificationSubLabel: "[Note (xiv)]", 
        field_EalertNotification_list:[
            {id: "EalertNotification", value: "Yes / 是", text: "Yes"},
            {id: "EalertNotification", value: "No / 否", text: "No"},
        ],
        TotalFee: "GRAND TOTAL of Subscription Fees Payable (HK$)", 
        PaymentMethod: "Payment Method", PaymentMethodSubLabel: "[Note (xi)-(xiii)]", 
        field_PaymentMethod_list: [
            {id: "PaymentMethod", value: "Cash / 現金", text: "Cash"},
            {id: "PaymentMethod", value: "Cheque / 支票", text: "Cheque", extraField: "Cheque Number", extraFieldId: "ChequeBankNo", extraFieldProps: { numericHyphen: true }},
            {id: "PaymentMethod", value: "Bank Draft / 銀行匯票", text: "Bank Draft", extraField: "Bank Draft Number", extraFieldId: "ChequeBankNo", extraFieldProps: { numericHyphen: true }},
            {id: "PaymentMethod", value: "EPS / 易辦事 (EPS)", text: "EPS"},
            {id: "PaymentMethod", value: "MasterCard / 萬事達卡(MasterCard)", text: "MasterCard"},
            {id: "PaymentMethod", value: "VISA / VISA 卡", text: "VISA"},
            {id: "PaymentMethod", value: "Octopus / 八達通卡", text: "Octopus"},
            {id: "PaymentMethod", value: "FPS / 轉數快", text: "Faster Payment System (FPS)"},
        ],
        field_PaymentMethod_list_byPost: [
            {id: "PaymentMethod", value: "Cheque / 支票", text: "Cheque", extraField: "Cheque Number", extraFieldId: "ChequeBankNo", extraFieldProps: { numericHyphen: true }},
            {id: "PaymentMethod", value: "Bank Draft / 銀行匯票", text: "Bank Draft", extraField: "Bank Draft Number", extraFieldId: "ChequeBankNo", extraFieldProps: { numericHyphen: true }},
        ],
        ChequeBankNo: "Number", 
        
        // Form - Part3
        DeliveryMethod: "Application Delivery Method", DeliveryMethodSubLabel: "[Note (iv) & (v)]", 
        field_DeliveryMethod_list:[
            {id: "DeliveryMethod", value: "DeliveryMethod3", text: "By Online Submission"},
            {id: "DeliveryMethod", value: "DeliveryMethod1", text: "By Post/Courier Service"},
            {id: "DeliveryMethod", value: "DeliveryMethod2", text: "In Person (by Owner)"}, // SCR-10899
            {id: "DeliveryMethod", value: "DeliveryMethod4", text: "In Person (by Corporate Owner's Authorized Representative)"}, // SCR-10899
        ],
        
        // Form - Part4
        ProvideRelation: "Provide Address of the property or Property Reference Number (PRN) of the land register", 
        field_ProvideRelation_list:[
            {id: "ProvideRelation", value: "Address", text: "Address of the property [Note (viii)]"},
            {id: "ProvideRelation", value: "PRN", text: "Property Reference Number (PRN) (if known) of the land register being selected [Note (viii)]"}, 
        ],

        Address_of_Property: "Address of the property", Address_of_PropertySubLabel: "[Note (viii)]", 
        validation_Address_of_Property: "Address of the property",
        field_Address_of_Property_list:[
            {id: "Address_of_Property", value: PropertyType.BUILD, text: "For Building Unit/Shop/Carpark/Village/Small House"},
            // {id: "Address_of_Property", value: "Village", text: "For Village / Small House"},
            {id: "Address_of_Property", value: PropertyType.LOT, text: "For Lot"},
        ],
        field_district_list:[
            {id: "Build_District", value: "HK", text: "H.K."},
            {id: "Build_District", value: "KLN", text: "KLN"},
            {id: "Build_District", value: "NT", text: "N.T."},
        ],
        Build_Blk: "Block Number", 
        Build_F: "Floor Number", 
        Build_Flat: "Flat/Shop/Carpark Number", 
        Build_Add2Line: "House/Building/Street Number", 
        Build_Add3Line: "Street Name", 
        Build_Add4Line: "Development/Building Name", 
        /* SCR-12625: Removed
        Village_F: "Floor Number", 
        Village_Add2Line: "House/Building/Street Number", 
        Village_Add3Line: "Street Name", 
        Village_Add4Line: "Development/Building Name", 
        Village_LotType: "Lot Type", 
        Village_LotNo: "Lot Number", 
        */
        Village_LotType: "Lot Type (if applicable)", 
        Village_LotNo: "Lot Number (if applicable)", 
        LotType: "Lot Type", 
        LotNo: "Lot Number", 
        PRN: "Property Reference Number (PRN) (if known) of the land register being selected", PRNSubLabel: "[Note (viii)]", 
        AlterEmail: "Property Alert Email Address (for receiving Property Alert notifications and correspondence from the Land Registry)", AlterEmailSubLabel: "[Note (ix)]", 
        AlterEmailCc: "Property Alert Supplementary Email Address (for authorized recipient to receive copy of Property Alert notifications and correspondence from the Land Registry)", AlterEmailCcSubLabel: "[Note (ix)]", 
        validation_AlterEmailCcDuplicate: "The Property Alert email address and the Property Alert supplementary email address should be different.",
        AlterSMS: "Hong Kong Mobile Phone Number (for receiving SMS reminder)", AlterSMSSubLabel: "[Note (x)]", 

        // Form - Part5
        OwnerType: "Owner Type", 
        field_OwnerType_list:[
            {id: "OwnerType", value: "Individual", text: "For individual owner"},
            {id: "OwnerType", value: "Corporate", text: "For corporate owner"},
        ],
        IndividualOwner: "Name of the Individual Owner", 
        //SubscriberName: "Full Name of Subscriber", 
        CorporateOwner: "Name of the Corporate Owner", 
        AuthorizedName: "Full Name(s) of Director(s)", 
        PeronsalIdDocAttach: "Upload Supporting Document(s) (If necessary)",
        IdDocAttach: "Upload Identification Document",
        OtherIdDocAttach: "Upload Additional Document for Identity Proof", OtherIdDocAttachSubLabel: "[Note (iii)]", 
        //Org: "Submitted by Authorized Representative", OrgSubLabel: "[Note (iv)]", 
        OrgRepresentative: "Name of the Authorized Representative", 
        //OrgAttach: "Authorization Form", 
        individualSignature: "Subscriber's Signature",
        corporateSignature: "Signature(s) of Subscriber's Director(s)", 
        
        // Form - Questionnaire_Option
        questionnaire_option_list: [
            {id: "Questionnaire", value:"Questionnaire_Option1", text: "Land Registry Website"}, 
            {id: "Questionnaire", value:"Questionnaire_Option2", text: "Solicitors' Firm"}, 
            {id: "Questionnaire", value:"Questionnaire_Option3", text: "Land Registry Information / Service Counter Staff"}, 
            {id: "Questionnaire", value:"Questionnaire_Option4", text: "Publicity Materials / Mass Media (Poster/Banner, Leaflet, TV, Radio)"}, 
            {id: "Questionnaire", value:"Questionnaire_Option5", text: "Friend / Relative"}, 
            {id: "Questionnaire", value:"Questionnaire_Option6", text: "Land Registry Customer Service Hotline"}, 
            {id: "Questionnaire", value:"Questionnaire_Option7", text: "Press Release"}, 
            {id: "Questionnaire", value:"Questionnaire_Option8", text: "Others (Please specify)"}, 
        ],
        Questionnaire_Other: "Please specify", 
        questionnaire_option4_option_list: [
            {id: "Questionnaire_Option4_Options", value:"Poster", text: "Poster/Banner"},
            {id: "Questionnaire_Option4_Options", value:"Leaflet", text: "Leaflet"},
            {id: "Questionnaire_Option4_Options", value:"TV", text: "TV"},
            {id: "Questionnaire_Option4_Options", value:"Radio", text: "Radio"},
        ],
        Questionnaire_Option4_Options: "Please specify publicity material/channel",

        // Form - Part6
        /* SCR-12625: Removed
        field_AI_SubscriptionType_list:[
            {id: "AI_SubscriptionType", value: "New Application / New Order / 新申請 / 新訂單", text: "New Application / New Order"},
            {id: "AI_SubscriptionType", value: "Service Renewal / 服務續期", text: "Service Renewal"},
        ],
        field_AI_NewSubFee_list:[
            {id: "AI_NewSubFee", value: "One-off Subscription(HK$580) / 一次過訂購方式(港幣$580)", text: "One-off Subscription (HK$580) [Note (vi)]"},
            {id: "AI_NewSubFee", value: "24 months(HK$250) / 24 個月(港幣$250)", text: "24 months (HK$250)"},
        ],
        field_AI_ReSubFee_list:[
            {id: "AI_ReSubFee", value: "24 months(HK$160) / 24 個月(港幣$160)", text: "24 months (HK$160)"},
        ],
        */
        field_AI_Address_of_Property_list:[
            {id: "AI_Address_of_Property", value: "Build", text: "For Building Unit/Shop/Carpark/Village/Small House"},
            // {id: "AI_Address_of_Property", value: "Village", text: "For Village / Small House"},
            {id: "AI_Address_of_Property", value: "Lot", text: "For Lot"},
        ],
        
        field_AI_district_list:[
            {id: "AI_Build_District", value: "HK", text: "H.K."},
            {id: "AI_Build_District", value: "KLN", text: "KLN"},
            {id: "AI_Build_District", value: "NT", text: "N.T."},
        ],
        
        confirmationMessage_signing2: null,

        acknowledgement_offline_para : (formData) => {
            let content = '';
            switch (formData.DeliveryMethod){
                case DeliveryMethod.POST_OR_COURIER:
                    content = 
                    <>
                        <p>Your form has been successfully printed.</p>
                        <p>To complete your application, please deliver the printed form to the Land Registry by post/courier service.</p>
                    </>
                    break;
                case DeliveryMethod.BY_OWNER:
                    content = 
                    <>
                        <p>Your form has been successfully printed.</p>
                        <p>To complete your application, please deliver the printed form to the Land Registry in person.</p>
                    </>
                    break;
                case DeliveryMethod.BY_AUTHORIZED_REP:
                    content = 
                    <>
                        <p>Your form has been successfully printed.</p>
                        <p>To complete your application, please deliver the printed form to the Land Registry in person by your authorized representative.</p>
                    </>
                    break;
                case DeliveryMethod.ONLINE:
                default:
                    content = 
                    <>
                        <p>Your form has been successfully printed.</p>
                        <p>To complete your application, please deliver the printed form to the Land Registry by post/courier service, or in person.</p>
                    </>
                    break;
            }
            return [
                <div>{content}</div>
            ];
        },

         // 19 July 2023
        // additionalNotes: ()=>(
        //     <p style={{lineHeight:"1.5"}}><b>Online application will only be accepted if it is made by the registered owner</b>
        //     <br/><br/>Any application submitted by the corporate owner’s authorized representative should be <b><u>submitted in person</u></b> to the Land Registry. If you want to fill in the application form online and then print it for submission by your authorized representative, please select “In Person (by Corporate Owner’s Authorized Representative)” in the “Application Delivery Method” field to start filling the online form (which includes form of authorization and declaration).</p>
        // ),
        // additionalNotesTitle: 'Points to Note',

        owner_notes: formData => (
        <div>
            <ol>
                { formData.OwnerType === "0" ?
                        <li>
                                I/We, <span className="output-text">{formData.SubscriberNameEng}</span>, confirm that I am/we are the current owner as shown on the land register(s) maintained by the Land Registry in respect of the property(ies) as specified in this application form and (where applicable) the Additional Order(s).
                        </li>
                : null }
                { formData.OwnerType === "1" ? 
                        <li>
                                <b><i>(For corporate owner) </i></b>
                                We confirm that <span className="output-text">{formData.SubscriberNameEng}</span> is the current owner as shown on the land register(s) maintained by the Land Registry in respect of the property(ies) as specified in Subscription Details of this application form and (where applicable) the Additional Order(s).
                        </li>
                : null }    
                { formData.SubscriberType === SubscriberType.NEW_SUBSCRIBER ? 
                        <li>
                                <b><i>(For new application / new order) </i></b><br />
                                (For application submitted online without providing Verification Code (except for online submission by individual owner)) I/We have provided a copy of the Hong Kong Identity Card / Certificate of Incorporation / identification document and (where applicable) the additional document for identity proof stated in this application form. [Note (iii)]
                        </li>
                : null }
                { formData.Org ?
                        <li>
                                <b><i>(For application submitted by corporate owner in person) </i></b><br />
                                We hereby authorize <span className="output-text">{formData.AuthorizedName}</span> to submit this application form for us. The completed authorization form duly signed by us is attached to this application form. [Note (iii)]
                        </li>
                : null }
                <li>I/We have read and understand the <b>“Terms and Conditions for Subscription to Property Alert” (LR/EAL/1 (T&C) of 01/2023 Edition) and the “Personal Information Collection Statement” (LR/EAL (PICS) of 06/2023 Edition)</b> and agree to be bound by them.</li> 
                <li>(Where applicable) By providing the Property Alert Supplementary Email Address in this application form and (where applicable) the Additional Order(s), I/we hereby authorize and instruct the Land Registry to make a copy of all Property Alert notifications and other correspondence issued to me/us to the Property Alert Supplementary Email Address.</li> 
                <li>I/We confirm that all information provided by me/us in this application form and (where applicable) the Additional Order(s) is true and correct.</li>
                <li>I/We hereby apply for subscription to Property Alert in respect of the land register(s) of the property(ies) specified in this application form and (where applicable) the Additional Order(s) [Note (vi)]. I/We understand that if my/our application is unsuccessful, the subscription fee(s) paid as specified in above will be refunded to me/us without interest.</li>
            </ol>
            <p style={{paddingLeft:"15px", paddingTop:"10px"}}>For application by corporate owner, the completed online form should be digitally signed by the <b>organizational</b> digital certificate.</p>
        </div>
        ), 

        PICSContent: ()=>      [<> 
            <p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Purpose of Collection</span></p>
            <p>
                1. The personal data collected will be used by the Land Registry for the following purposes:
                <div className="d-flex" style={{paddingTop:"15px"}} >
                    <div className="note-bullet">1.1</div>
                    <div>to carry out activities and the Land Registry’s functions relating to the provision of services by the Land Registry;</div>
                </div>
                <div className="d-flex" style={{paddingTop:"15px"}} >
                    <div className="note-bullet">1.2</div>
                    <div>to facilitate communications; and</div>
                </div>
                <div className="d-flex" style={{paddingTop:"15px"}} >
                    <div className="note-bullet">1.3</div>
                    <div>to produce statistics relating to the Land Registry’s services.</div>
                </div>
            </p>
            <p>
                You understand that the provision of personal data is obligatory. If you fail to provide information as required, the Land Registry may not be able to provide the requested service.
            </p>
            <p>
                Please do NOT provide any personal data (including personal data relating to third parties) which are not specifically required to be submitted. Where information of any third party is included in this form or any document(s) filed in relation to it, the Land Registry will treat that you have obtained consent from such third party to disclose such information for the purposes above.
            </p>

            <p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Disclosure of Personal Data</span></p>
            <p>
                2. You understand that the personal data provided may be disclosed or transferred to relevant parties where such disclosure or transfer is necessary for the purposes as stated in paragraph 1 above. Such personal data may also be disclosed or transferred to law enforcement agencies as permitted under the Personal Data (Privacy) Ordinance (Cap. 486) (“PDPO”).
            </p>
            
            <p><span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>Access to Personal Data</span></p>
            <p>
                3. You understand that pursuant to Sections 18 and 22 and Principle 6 of Schedule 1 to the PDPO, you have the right to request access to and correction of your personal data held by the Land Registry. The Land Registry may charge a fee to process the said request in accordance with the PDPO. Any such request shall be made to the Personal Data (Privacy) Officer of the Land Registry at 28th Floor, Queensway Government Offices, 66 Queensway, Hong Kong.
            </p>
        </>],

        terms: () => [
        <div className="divTandC">
                <h5 style={{ textAlign: 'center' }}>Terms and Conditions<br />for Subscription to the Property Alert</h5>
                <ol>
                    <li>Upon approval of the application made by the Subscriber in the application form, there shall constitute an agreement between the Land Registry and the Subscriber (hereinafter called <b>“this agreement”</b>) that the Land Registry shall supply to the Subscriber the service as defined in Clause 3 (hereinafter called <b>“Property Alert”</b>) for such periods and at such subscription fees as specified in the application form subject to the terms and conditions herein contained. This agreement comprises the following documents, namely, the application form signed by the Subscriber (including its attachment), this Terms and Conditions, and the Personal Information Collection Statement referred to in the Subscriber’s application form. The personal data provided by the Subscriber will be governed by the policy stated in the Personal Information Collection Statement.</li>
                    <li>In this Terms and Conditions, unless the context otherwise requires, 
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">2.1</div>
                            <div><b>“land register”</b>, in relation to any particular land property, refers to the portion of the record of a register computer kept by the Land Registrar for the purpose of regulation 14 of the Land Registration Regulations (Cap.128A) (<b>“LRR”</b>) for that land property;</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">2.2</div>
                            <div><b>“Property Reference Number” (“PRN”) </b>of a land register in relation to any particular land property has the same meaning as stated in regulation 2 of the LRR;</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">2.3</div>
                            <div><b>“days”</b> refers to calendar days;</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">2.4</div>
                            <div><b>“application form”</b> refers to the application form prescribed by the Land Registry for subscription to the Property Alert;</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">2.5</div>
                            <div>Reference to any email address (including Property Alert Email Address and Property Alert Supplementary Email Address (where applicable)),  correspondence address or fax number shall be a reference to the last known email address, correspondence address and fax number on the records of the  Land Registry; and</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">2.6</div>
                            <div>Words and expressions importing masculine gender shall be interpreted as including neuter or feminine gender and vice versa; and words and expressions  in the singular shall include the plural and vice versa.</div>
                        </div>
                    </li>
                    <li>The <b>“Property Alert”</b> means a notification service provided by the Land Registry to a Subscriber in respect of the land register of a particular land property for a certain subscription period. The service is to facilitate the Subscriber to monitor if any instrument affecting his property has been lodged for registration. To subscribe to the service in respect of the land register of a particular property <b>(“Selected Register”)</b>, the Subscriber must be the current owner of that property as shown on the land register maintained by the Land Registry. To identify that particular property and the Selected Register, the Subscriber is required to provide the Land Registry with the address of that property or PRN of the land register of that property. Upon approval of the Subscriber’s application, the Property Alert will be provided in respect of the Selected Register and the PRN thereof and the address of the property shown thereon will be specified in the Customer Payment Receipt issued to the Subscriber. During the subscription period, when an instrument in respect of that property is delivered to the Land Registry or is being processed by the Land Registry for registration against the Selected Register under the Land Registration Ordinance (Cap.128), the Land Registry will send a notification to the Subscriber by email <b>(“Property Alert notification”)</b> providing the Subscriber with certain basic particulars of that instrument at one or more of the following different stages, where applicable, in the course of such registration process: 
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">3.1</div>
                            <div>The instrument appearing in the “Deeds Pending Registration” section of the land register, namely, the information of the instrument is entered on the  Selected Register as required under regulation 10(d)(i) of the LRR.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">3.2</div>
                            <div>Removal of the instrument from the “Deeds Pending Registration” section of the land register, namely, the information of the instrument that has previously been entered as required under regulation 10(d)(i) of the LRR is removed from the Selected Register for whatever reason.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">3.3</div>
                            <div>Completion of registration and imaging of the instrument, namely, the instrument is registered under regulation 14 and becomes available for search under the provision of regulation 21(1)(a)(ii) of the LRR.</div>
                        </div>
                        After the completion of registration of the instrument, no further Property Alert notification will be issued in relation to that registered instrument.
                    </li>
                    <li>Upon application for subscription to the Property Alert, the Subscriber shall pay the Land Registry a subscription fee for each Selected Register for the whole subscription period in full in advance. Such fee varies according to the type of subscription and the duration of the subscription period. Details of the types of subscription, subscription periods and subscription fees are specified in the application form and they are subject to changes from time to time without prior notice. All subscription fees paid are not refundable unless otherwise provided in this agreement.</li>
                    <li>If the subscription fee is paid by personal cheque, the Property Alert may only be provided to the Subscriber after the cheque is cleared and the subscription fee is actually received in full by the Land Registry. Although such time may be later than the “subscription service starting date” as specified in the application form, no partial refund or pro-rata deduction of the subscription fee will be provided by the Land Registry.</li>
                    <li>Fees for Renewal of Subscription and Additional Order: 
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">6.1</div>
                            <div>For a current Property Alert Subscriber, if an application for renewal of subscription for a Selected Register for another subscription period is duly made and delivered to the Land Registry before the deadline for renewal, namely, not less than 7 days before the expiry of the current subscription period <b>(“renewal deadline”)</b>, the subscription fee will be charged at the rate for service renewal.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">6.2</div>
                            <div>After the renewal deadline for a Selected Register or after the termination of the Property Alert for a Selected Register for whatever reason, if the Subscriber is desirous of subscribing to the Property Alert for that same land register again, he is required to submit a fresh application and the subscription fee will be charged at the same rate as a new application.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">6.3</div>
                            <div>Subsequent addition of any order under the same Subscriber account will be regarded as a new application and the subscription fee will be charged at the same rate as a new application.</div>
                        </div>
                    </li>
                    <li>The Subscriber shall be solely responsible for the acquisition of his own computer equipment, mobile device (where applicable), software, skills or other technical support in order to have access to the notice, message, information or data provided by the Land Registry under the Property Alert.</li>
                    <li>Email Address for Receiving Property Alert Notification and Other Correspondence:
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">8.1</div>
                            <div>The Subscriber must provide a valid email address for receiving Property Alert notification and other correspondence issued by the Land Registry under the Property Alert <b>(“Property Alert Email Address”)</b>. The notice, information or data provided by the Land Registry under the Property Alert will be sent to the Subscriber’s Property Alert Email Address by electronic means.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">8.2</div>
                            <div>The Subscriber has an option to provide an additional valid email address for his authorized recipient to receive copy of Property Alert notification and other correspondence issued by the Land Registry <b>(“Property Alert Supplementary Email Address”)</b>. All Property Alert notifications and other correspondence issued by the Land Registry to the Subscriber may be copied to the Property Alert Supplementary Email Address at the absolute discretion of the Land Registry without any legal obligation to do so.</div> 
                        </div>
                    </li>
                    <li>Mobile Phone Number for Receiving Short Message Service (“SMS”) Reminder:
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">9.1</div>
                            <div>The Subscriber has an option to provide a valid SMS-enabled Hong Kong mobile phone number for receiving SMS reminder upon issuance of Property Alert notification and other correspondence by the Land Registry to the Property Alert Email Address, but such reminder will be issued only at the absolute discretion of the Land Registry without any legal obligation to do so. The mobile phone number so provided will only be used by the Land Registry for the purposes of sending SMS reminder and any other communications between the Land Registry and the Subscriber. Without prejudice to Clause 22, the Land Registry shall not be liable for any failure or delay in sending SMS reminder caused by any inaccurate or incomplete information provided by the Subscriber.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">9.2</div>
                            <div>The Subscriber is required to check with his mobile service provider to obtain information on the availability and charges of roaming service relating to his place of travel before leaving Hong Kong. The Subscriber may not be able to receive SMS reminder outside Hong Kong in the absence of roaming service. The Land Registry shall in no event be liable for any costs, charges, losses or expenses of any kind arising or resulting from the Subscriber’s use of the SMS reminder service.</div> 
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">9.3</div>
                            <div>The third parties (including without limitation, telecommunications companies and internet service providers) supporting the provision of SMS reminder service are not agents or partners of the Land Registry and do not represent the Land Registry. There is no agency, partnership, joint venture or other relationship between these third parties with the Land Registry. The Land Registry is not liable for any loss or damages, direct, indirect or consequential caused by any acts or omissions of the third parties.</div> 
                        </div>
                    </li>
                    <li>The Subscriber warrants that all email addresses (including the Property Alert Email Address and Property Alert Supplementary Email Address (where applicable)) and the mobile phone number for receiving SMS reminder (where applicable) provided by him to the Land Registry from time to time in the application form or any other form prescribed by the Land Registry are valid and that the Subscriber and/or his authorized recipient (where applicable) has/have full right or authority to use them. The Subscriber acknowledges and consents to that the Land Registry may act on the assumption that such email addresses and mobile phone number are for the own use of the Subscriber and/or his authorized recipient (where applicable) and for the purpose of receiving the notice, message, information or data to be sent by the Land Registry in connection with this agreement. The Subscriber shall keep the Land Registry no harm and fully indemnified for any claim against the Land Registry resulting from his and/or his authorized recipient’s (where applicable) breach of this warranty.</li>
                    <li>The Subscriber acknowledges and consents to that for the purposes of sending SMS reminder, the mobile phone number provided by the Subscriber will be passed to the SMS service provider of the Land Registry and that the notice, message, information or data to be delivered by the Land Registry under the Property Alert will be disclosed to the holder(s) of the email account(s) of any email address(es) (including the Property Alert Supplementary Email Address)/mobile phone number provided by the Subscriber on records. If the Land Registry is obliged to send any Property Alert notification or correspondence to the Subscriber under this agreement, the Land Registry’s obligation will be discharged by sending it to the Property Alert Email Address.</li>
                    <li>The Land Registry does not warrant the reliability of the online network through which information, notices, notifications or messages are transmitted to and from the Land Registry. In providing the Property Alert, the Land Registry is only responsible for sending out the Property Alert notification from the electronic system of the Land Registry to the Property Alert Email Address and there is no warranty that the notification must reach the Property Alert Email Address or the Subscriber.</li>
                    <li>The Use of Verification Code by the Subscriber:
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">13.1</div>
                            <div>Upon approval of the Subscriber’s first application for the Property Alert by the Land Registry, an account (<b>“Subscriber Account”</b>) will be opened and maintained by the Land Registry for the Subscriber.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">13.2</div>
                            <div>For Subscriber Accounts opened between 20 July 2015 to 27 January 2019 in respect of the e-Alert Service (renamed “Property Alert” with effect from 28 January 2019), the Subscriber has an option to apply for a unique verification code relating to his Subscriber Account (<b>“Verification Code”</b>) to enable him to make application by post or courier service or online in respect of an application for service renewal, application for change of particulars or such other types of application announced by the Land Registry from time to time. But if an incorrect or invalid Verification Code is given in such an application, it will be rejected and the Subscriber will be notified to submit a fresh application. Request for checking accuracy or correction of a wrong code number given in an application submitted to the Land Registry will not be entertained.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">13.3</div>
                            <div>The Subscriber shall take all possible measures to prevent abuse or leakage of the Verification Code assigned to him, and shall bear the risk of all adverse consequences due to any abuse or leakage. The Subscriber shall report any abuse or leakage of the Verification Code to the Land Registry as soon as he comes to knowledge of it or has reasonable suspicion of it. The report shall be made in writing by email, by fax or by post quoting the address of the relevant property, the PRN or the Subscriber Account number. The Land Registry may act on the Verification Code until there is a report of abuse or leakage which has led to the blockage of its further use.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">13.4</div>
                            <div>With effect from 28 January 2019, Subscribers will no longer be given the option to apply for a Verification Code. No new Verification Code will be issued and request for replacement of a lost Verification Code will not be entertained. However, for Subscribers currently holding a Verification Code, Clauses 13.2 and 13.3 shall continue to apply.</div>
                        </div>
                    </li>
                    <li>Upon the Land Registry’s approval of the Subscriber’s application for the Property Alert in respect of a Selected Register or for renewal of subscription service, a verification email and a verification SMS (where applicable) will be sent to the Property Alert Email Address, Property Alert Supplementary Email Address (where applicable) and mobile phone number for receiving SMS reminder (where applicable) provided by the Subscriber in the application form. If the Subscriber and/or his authorized recipient (where applicable) do/does not receive the verification email and/or the verification SMS (where applicable) within 3 days from the date of the Customer Payment Receipt issued for the Property Alert in respect of that Selected Register, the Subscriber should immediately notify the Land Registry in writing by email, by fax or by post quoting the address of the relevant property, the PRN or the Subscriber Account number for a double-check.</li>
                    <li>Termination of Service upon Change of Ownership:
                        <div>The Property Alert for a Selected Register may be terminated by the Land Registry as soon as:-</div>
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">15.1</div>
                            <div>The Subscriber ceases to be the current owner as shown on the records mentioned in regulation 21(1)(ea)(ii) of the LRR in respect of the Selected Register; or</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">15.2</div>
                            <div>The Selected Register has been closed by the Land Registry and no instrument will normally be registered against that closed Selected Register.</div>
                        </div>
                        A termination notice will be sent by the Land Registry to the Subscriber’s Property Alert Email Address by electronic means to inform the Subscriber of the termination of the service. There will be no refund of any part of the subscription fee for a termination of the service pursuant to this clause.
                    </li>  
                    <li>Termination of Service by the Subscriber: 
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">16.1</div>
                            <div>The Subscriber shall notify the Land Registry in the prescribed form to terminate the Property Alert for a Selected Register as soon as he ceases to be the current owner as shown on the records mentioned in regulation 21(1)(ea)(ii) of the LRR in respect of the Selected Register.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">16.2</div>
                            <div>The Subscriber may also terminate the Property Alert for a Selected Register at any time by delivering an application in the prescribed form to the Land Registry not less than 7 days before the intended date of termination. If the specified intended date of termination of service falls on a holiday/non-working day or amid successive holidays/non-working days of the Land Registry, the Land Registry shall have the absolute discretion to effect the requested termination of service within 2 working days <i>after</i> the specified intended date of termination of service or the period of successive holidays/non-working days.</div>
                        </div>
                        There will be no refund of any part of the subscription fee for a termination of the service pursuant to this clause.
                    </li>
                    <li>Termination of Service by Notice from the Land Registry or for Breach by the Subscriber:
                        <div>The Land Registry may terminate the Property Alert in respect of a Selected Register in any one of the following manners:-</div>
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">17.1</div>
                            <div>By Unilateral Notice from the Land Registry: Without assigning any reason, by giving not less than such number of days’ prior notice to the Subscriber’s Property Alert Email Address as set out below: 
                                <div className="d-flex">
                                    <div className="note-bullet">(i)</div>
                                    <div>
                                        <div>For agreement with 24 months’ subscription: Not less than 18 days.</div>
                                        <div>For termination by notice from the Land Registry under this sub-clause, a refund of the subscription fee on a pro-rata daily basis for the unexpired period will be made to the Subscriber without any interest within a reasonable time after termination of the service.</div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="note-bullet">(ii)</div>
                                    <div>
                                        <div>For agreement with one-off subscription: Not less than 21 days.</div>
                                        <div>For termination by notice from the Land Registry under this sub-clause, a refund of the subscription fee on a pro-rata daily basis for the unexpired period on the assumption that the subscription period is 9 years will be made to the Subscriber without any interest within a reasonable time after termination of the service.</div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">17.2</div>
                            <div>For Breach by the Subscriber: If there is a breach of any term or condition of this agreement on the part of the Subscriber, to terminate the Property Alert forthwith by giving a notice in writing stating the nature of breach <b>(“Notice of Breach”)</b> and such notice will be effective upon being sent to the Subscriber’s Property Alert Email Address or on such later date as otherwise stated in the notice. In any action based on the Notice of Breach, the Land Registry may rely on any breach of agreement actually committed by the Subscriber despite that such breach has not been stated or accurately stated in the Notice of Breach. There will be no refund of any part of the subscription fee for a termination of the service pursuant to this sub-clause.</div>
                        </div>
                    </li>
                    <li>Any termination of service by the Land Registry or the Subscriber pursuant to Clauses 15, 16 or 17 above shall be without prejudice to any rights or claims which have accrued or may have accrued to the Land Registry against the Subscriber by reason of any antecedent or other breach of any term or condition on the part of the Subscriber.</li>
                    <li>Any notice, message, information or data provided by the Land Registry under the Property Alert shall not be used by any person other than the Subscriber, nor shall it be used for any purpose other than the Subscriber’s own private use. The Subscriber and/or his authorized recipient (where applicable) shall not lend, lease, license, transfer or re-sell any notice, message, information or data provided by the Land Registry under the Property Alert in its original form or in any other form, whether for valuable consideration or not. The Subscriber and/or his authorized recipient (where applicable) shall not copy, reproduce or transmit such notice, message, information or data or any part thereof in any form or by any means for use by any other person if such use is not forming part of the Subscriber’s own private use.</li>
                    <li>The Subscriber and/or his authorized recipient (where applicable) shall not use any notice, message, information or data provided by the Land Registry under the Property Alert for any activity which is unlawful or objectionable as determined by the Land Registry absolutely. In particular, they shall not be used for any activity in violation of any provision of the Personal Data (Privacy) Ordinance (Cap.486) or the Copyright Ordinance (Cap.528). The Subscriber shall be liable for any breach done by his authorized recipient under this agreement.</li>
                    <li>The Land Registry shall not be responsible for the accuracy, completeness or consistency of the notice, message, information or data provided under the Property Alert, nor shall the Land Registry be obliged to make any subsequent amendments to or rectification of such notice, message, information or data after they have been sent to the Subscriber and/or the authorized recipient (where applicable).</li>
                    <li>To the extent permissible under the law, the Land Registry or any of the telecommunications companies which may be designated by the Land Registry for the purposes of providing the SMS reminder service shall not be liable to the Subscriber or any other person for any loss or damage arising directly or indirectly from the use of the Property Alert or from any error, omission, deficiency or fraud therein or in connection therewith or from any failure or delay in the supply of the Property Alert and the SMS reminder service, whether such loss or damages is caused by any act, omission or mistake on the part of the Land Registry and/or the SMS service provider or by any other cause whatsoever including negligence.</li>
                    <li>The Land Registry has an absolute discretion in choosing the mode of delivery considered by it to be reliable and of reasonable despatch, and there is no warranty that the Property Alert or the SMS reminder will be delivered in time or be suitable for any specific purpose of the Subscriber.</li>
                    <li>Change in Particulars or Details of the Subscriber Account: 
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">24.1</div>
                            <div>The Subscriber shall notify the Land Registry of any change of his account particulars, service details or subscription details on records by submitting an application for change of particulars (Form LR/EAL/2) according to the procedure stated therein. Unless otherwise specified in this agreement, the completed application form for change of particulars shall be delivered to the Land Registry not less than 7 days before the intended date of change specified in the form. The Land Registry is not obliged to act on the Subscriber’s request for changes before the expiry of 7 days after actual receipt of the application for change of particulars. Subject to Clause 16.2 above, if the specified intended date of change falls on a holiday/non-working day or amid successive holidays/non-working days of the Land Registry, the Land Registry shall have the absolute discretion to effect the requested changes within 2 working days either before or after the specified intended date of change or the period of successive holidays/non-working days.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">24.2</div>
                            <div>Upon the Land Registry’s approval of the Subscriber’s request for change of the Property Alert Email Address, Property Alert Supplementary Email Address and/or mobile phone number for receiving SMS reminder on records and updating of the information in the computer system, a verification email and a verification SMS (where applicable) will be sent to both the Subscriber’s and/or his authorized recipient’s new email address(es) and new mobile phone number (where applicable) and the previous one(s) being replaced. If the Subscriber and/or his authorized recipient do/does not receive the verification emails and/or the verification SMS (where applicable) latest by the time of 3 days after the specified intended date of change, the Subscriber should immediately notify the Land Registry in writing by email, by fax or by post quoting the address of the relevant property, the PRN or the Subscriber Account number for a double-check.</div>
                        </div>
                    </li>
                    <li>Without prejudice to other mode of correspondence or communication permitted under this agreement, any written notice required to be given by the Land Registry to the Subscriber may also be sent by post to the postal correspondence address or by fax to the fax number of the Subscriber.</li>
                    <li>Rights of the Land Registry to vary contract term, class of data to be provided, etc.: 
                        <div className="d-flex" style={{paddingTop:"15px"}}>
                            <div className="note-bullet">26.1</div>
                            <div>The Land Registry reserves the right to amend from time to time any particular format, layout and/or transmission media of the notice, message, information and data provided under the Property Alert and to add, delete and/or vary the types of information provided under the Property Alert by giving the Subscriber not less than 14 days’ prior notice in writing to the Subscriber’s Property Alert Email Address.</div>
                        </div>
                        <div className="d-flex">
                            <div className="note-bullet">26.2</div>
                            <div>The Land Registry reserves the right to amend from time to time the terms and conditions contained in this agreement by giving the Subscriber not less than 7 days’ prior notice in writing to the Subscriber’s postal correspondence address or Property Alert Email Address. The Subscriber who does not raise his objection before the effective date of such change or continues to use the Property Alert after the effective date of such change shall be deemed to have accepted such change by conduct and so be bound by it.</div>
                        </div>
                    </li>  
                    <li>The Subscriber’s rights under this agreement shall be personal to the Subscriber. Those rights and any subscription fee paid to the Land Registry cannot be assigned or transferred by the Subscriber to any third party.</li>
                    <li>This agreement shall be governed by and construed according to the laws of Hong Kong. Any disputes between the parties arising out of this agreement shall be submitted to the adjudication by the court or tribunal of competent jurisdiction in Hong Kong.</li>
                </ol>
        </div>
        ],
    }
};