import { WebsiteLocale } from '../../layout/core/website-locale.enum';
import { AddressSearchLanguage } from '../api/service/address-lookup.request';

export function websiteLocaleToAddressLocale(locale: WebsiteLocale) {
    switch (locale) {
        case WebsiteLocale.SIMPLIFIED_CHINESE:
            return AddressSearchLanguage.SIMPLIFIED_CHINESE
        case WebsiteLocale.TRADITIONAL_CHINESE:
            return AddressSearchLanguage.CHINESE;
        case WebsiteLocale.ENGLISH:
        default:
            return AddressSearchLanguage.ENGLISH;
    }
}
