import { createSlice } from '@reduxjs/toolkit';
import { PaymentData, TransactionData, PaymentMethodData } from '../entities';
import { PaymentMethodCode } from '../enums';
import { PayByFpsResponse } from '../api/services/pay-by-fps/response';

const initialState: PaymentState = {
    paymentId: undefined,
    transactionData: null,
    paymentData: null,
    fi: undefined,
    paymentGateway: undefined,
    paymentMethodIndex: -1,
    isPaymentMethodChosen: false,
    availablePaymentMethods: [],
    referenceNumber: '',
    submissionTime: undefined,
    submissionId: undefined,
};

type PaymentState = {
    paymentId: string;
    transactionData: TransactionData;
    paymentData: PaymentData;
    fi: PaymentMethodCode;
    paymentGateway: string;
    paymentMethodIndex: number;
    isPaymentMethodChosen: boolean;
    availablePaymentMethods: PaymentMethodData[];
    referenceNumber: string;
    submissionTime: number;
    submissionId?: string; // Submission reference obtained before any attempt of payment & actual submission through actions such as signing
};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPaymentId: (state, action) => {
            const { payload: paymentId } = action;
            state.paymentId = paymentId;
        },
        // 202309: response listener
        updateTransactionDataFpsResponse: (state, action) => {
            const { payload: fpsResponse } = action;
            state.transactionData.addFpsResponse(fpsResponse);
        },
        upsertTransactionData: (state, action) => {
            const { payload: transactionData } = action;
            state.transactionData = transactionData;
        },
        clearTransactionData: (state) => {
            state.transactionData = initialState.transactionData;
        },
        upsertPaymentData: (state, action) => {
            const { payload: paymentData } = action;
            state.paymentData = paymentData;
        },
        setFinancialInstitute: (state, action) => {
            const { payload: fi } = action;

            //console.log("setFinancialInstitute");
            //console.log(fi);
            state.fi = fi;
        },
        setPaymentGateway: (state, action) => {
            const { payload: paymentGateway } = action;

            //console.log("setPaymentGateway");
            //console.log(paymentGateway);
            state.paymentGateway = paymentGateway;
        },
        setPaymentMethodIndex: (state, action) => {
            const { payload: idx } = action;

            //console.log("setPaymentMethodIndex");
            //console.log(idx);
            state.paymentMethodIndex = idx;
            state.isPaymentMethodChosen = true;
        },
        setAvailablePaymentMethods: (state, action) => {
            const { payload: availablePaymentMethods } = action;
            state.availablePaymentMethods = availablePaymentMethods;
        },
        setSubmissionTime: (state, action) => {
            const { payload: submissionTime } = action;
            state.submissionTime = submissionTime;
        },
        clearPaymentSlice: () => {
            return initialState;
        },
        markTransactionDataAsUsed: (state) => {
            state.transactionData.markAsUsed();
        },
        upsertSubmissionId: (state, action) => {
            const { payload: submissionId } = action;
            if (!submissionId) {
                return;
            }
            state.submissionId = submissionId;
        },
        clearSubmissionId: (state) => {
            state.submissionId = undefined;
        },
    },
});

export const {
    setPaymentId,
    upsertTransactionData,
    updateTransactionDataFpsResponse,
    clearTransactionData,
    upsertPaymentData,
    setFinancialInstitute,
    setPaymentGateway,
    setPaymentMethodIndex,
    setAvailablePaymentMethods,
    setSubmissionTime,
    clearPaymentSlice,
    markTransactionDataAsUsed,
    upsertSubmissionId,
    clearSubmissionId,
} = paymentSlice.actions;

export type PaymentSliceType = typeof paymentSlice.reducer;

export default paymentSlice.reducer;
