export enum DbPaymentMethod {
    E_CHEQUE = 'EC',
    PPS = 'PP',
    VISA = 'VI',
    MASTER_CARD = 'MS',
    JCB = 'JB',
    UNION_PAY = 'UP',
    ANDROID_PAY = 'GP',
    APPLE_PAY = 'AP',
    FPS = 'FP',  // 202309: todo, need add to WFP DBDD, and related trigger
}
