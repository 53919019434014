import {lazy} from 'react';

const LREAL1=lazy(() => import('../layout/LREAL1'));
const LREAL2=lazy(() => import('../layout/LREAL2'));
const LREEA1=lazy(() => import('../layout/LREEA1'));
//const LRSSS11=lazy(() => import('../layout/LRSSS11')); // SCR-14106
const LRSI1=lazy(() => import('../layout/LRSI1'));
const LROLS1=lazy(() => import('../layout/LROLS1'));
const LROLS7=lazy(() => import('../layout/LROLS7'));
const LROLS8=lazy(() => import('../layout/LROLS8'));
const LROLS9=lazy(() => import('../layout/LROLS9'));
const LRCRT1=lazy(() => import('../layout/LRCRT1'));
const LR173=lazy(() => import('../layout/LR173'));
const LR124=lazy(() => import('../layout/LR124'));
const LR126=lazy(() => import('../layout/LR126'));
const LR176=lazy(() => import('../layout/LR176'));
const LR164=lazy(() => import('../layout/LR164'));
const LR161=lazy(() => import('../layout/LR161'));
const LR125=lazy(() => import('../layout/LR125'));
const LR167=lazy(() => import('../layout/LR167'));
const LR171=lazy(() => import('../layout/LR171'));
const LR175=lazy(() => import('../layout/LR175'));
const LR166=lazy(() => import('../layout/LR166'));
const LR172=lazy(() => import('../layout/LR172'));
const ATI=lazy(() => import('../layout/ATI'));
const LFSC=lazy(() => import('../layout/LFSC'));
const COAP=lazy(() => import('../layout/COAP'));
const LRMDB1=lazy(() => import('../layout/LRMDB1'));
const LRMDB2=lazy(() => import('../layout/LRMDB2'));
const LRMMIM1=lazy(() => import('../layout/LRMMIM1'));
const LRWS1=lazy(() => import('../layout/LRWS1'));
const LRWS3=lazy(() => import('../layout/LRWS3'));
const LRWS2=lazy(() => import('../layout/LRWS2'));


const LR111=lazy(() => import('../layout/LR111'));
const LODLIST=lazy(() => import('../layout/LODLIST'));

const LRTEST=lazy(() => import('../layout/LRTEST')); // Try to switch it by config

const SUPPINFO=lazy(() => import('../layout/SUPPINFO'));

const getLRWebsite = (curLanguage) => {
	return 	(window.WFP_CONFIG.LR_WEBSITE_BASE_URL ?? "http://www0.landreg.gov.hk/" )
			+ (
				curLanguage === "Eng" 
				? "en" 
				: curLanguage === "TradChn"
				? "tc"
				: "chs"
			)
			// + "/pforms/form_demo.htm#!"
			;
}

const getIAMSmartWebsite = (curLanguage) => {
	return 	("https://www.iamsmart.gov.hk/")
			+ (
				curLanguage === "Eng" 
				? "en" 
				: curLanguage === "TradChn"
				? "tc"
				: "sc"
			)
			;
}



const SysParam = {

	SYSTEM_PHASE:                   "DEV",
	SYSTEM_DEPLOYMENT:              "INT",
	RECAPTCHA_SECRET:				"6LeYo-wZAAAAANVEN0-Jai_ERawI3-GBySZUWome",

	DEFAULT_LANG: 					0, // Index of LANG_OPTIONS
	LANG_OPTIONS: 					["Eng", "TradChn", "SimpChn"],
	
	EXP_DATA_SERVER_KEY_PREFIX:     "LREFPDEV",
	FORM_VERSIONS:                  { 
										"LR152C": 4 
									},

	FORM_MAP:						{
										LR172: LR172,
										LRSI1: LRSI1,
										LRCRT1: LRCRT1,
										//LRSSS11: LRSSS11,
										LROLS1: LROLS1,
										LROLS7: LROLS7,
										LROLS8: LROLS8,
										LROLS9: LROLS9,
										LREAL1: LREAL1,
										LREAL2: LREAL2,
										LREEA1: LREEA1,
										LR173: LR173,
										LR124: LR124,
										LR126: LR126,
										LR176: LR176,
										LR164: LR164,
										LR161: LR161,
										LR125: LR125,
										LR167: LR167,
										LR171: LR171,
										LR175: LR175,
										LR166: LR166,
										ATI: ATI,
										LFSC: LFSC,
										COAP: COAP,
										LRMDB1: LRMDB1,
										LRMDB2: LRMDB2,
										LRMMIM1: LRMMIM1,
										LRWS1: LRWS1,
										LRWS3: LRWS3,
										LRWS2: LRWS2,
										LR111: LR111,
										LODLIST: LODLIST,
										LRTEST: (window.WFP_CONFIG.TEST_PAYMENT ? LRTEST : null),
										SUPPINFO: SUPPINFO,
									},

	SOURCE_TYPE:					{
										lrw: {
											url: curLanguage => getLRWebsite(curLanguage) + "/pforms/" + (window.WFP_CONFIG.LR_WEBSITE_FORMS_PAGE ?? "form_demo.htm"),
											returnText:'returnToFormList',
										},
										eea: {
											url: curLanguage => '/',
											returnText:'',
										},
										dev: {
											url: curLanguage => '/',
											returnText:'',
										},
									},
	getSourceType:					type=>{return SysParam.SOURCE_TYPE[type]},

	LR_WEBSITE:						(curLanguage)=> { return getLRWebsite(curLanguage) },
	LR_WEBSITE_HOME:				(curLanguage)=> { return getLRWebsite(curLanguage) + "/home/index.htm" },
	LR_WEBSITE_FAQ:					(curLanguage)=> { return getLRWebsite(curLanguage) + "/faq/faq_onlineform.htm" },
	LR_WEBSITE_TERMS:				(curLanguage)=> { return "/form/terms?lang=" + curLanguage},
	LR_WEBSITE_FORM:				(curLanguage)=> { return getLRWebsite(curLanguage) + "/pforms/" + (window.WFP_CONFIG.LR_WEBSITE_FORMS_PAGE ?? "form_demo.htm") },

	iAM_SMART_WEBSITE:				(curLanguage)=> { return getIAMSmartWebsite(curLanguage) },
	

};

export default SysParam;