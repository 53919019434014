import { PaymentData } from '../entities';
import { PaymentMethod } from '../enums';
import { Payment } from '../payment.proxy';

//TODO: cleanup
export function getNonExpectedPaymentMethods(paymentData?: PaymentData) {
    const ret = [];
    if (!Payment.isApplePayExpected()) {
        ret.push(PaymentMethod.APPLE_PAY);
    }
    if (!Payment.isGooglePayExpected()) {
        ret.push(PaymentMethod.ANDROID_PAY);
    }
    if (!Payment.isPpsExpected(paymentData)) {
        ret.push(PaymentMethod.PPS);
    }
    if (!Payment.isUnionPayExpected(paymentData)) {
        ret.push(PaymentMethod.UNION_PAY);
    }
    return ret;
}
