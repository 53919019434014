import React, { useContext } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import SysParam from '../../../../utilities/sysParam';
import { StepMenuItem } from './step-menu-item';
import { FormContext as TFormContext } from '../../utilities/form-context/types';
import { FormContext } from '../../layoutElements';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/wfp-core-store.type';

type Props = {
    menuExpanded: boolean;
    setMenuExpanded: (newValue: boolean) => void;
    changeStep: (toStepIdx: number, forced:boolean, pdfIsLoaded:boolean) => void;
};

export function SideMenu(props: Props) {
    const { menuExpanded, setMenuExpanded, changeStep } = props;

    const { curLanguage } = useSelector((store: RootState) => store.session);

    const { steps: formStep, stepIdx, form } = useContext<TFormContext>(FormContext);

    const { localization, intro } = form;

    const currentStep = formStep[stepIdx];

    return (
        <aside className="stepMenu col-xl-4">
            <div
                className={'blockHead showOnTablet ' + (menuExpanded ? 'expand ' : '')}
                onClick={() => {
                    setMenuExpanded(!menuExpanded);
                }}
            >
                <h2 className="stepMenuBlkHead" >
                    {stepIdx + 1}
                    {' ) '}
                    {formStep[stepIdx].titleDirect ?? localization[formStep[stepIdx].title]}
                </h2>
            </div>
            <div style={{ height: stepIdx > 0 ? '5px' : '0px' }}>
                <ProgressBar
                    animated={false}
                    variant="success"
                    min={0}
                    max={formStep.length}
                    now={stepIdx}
                    style={{ backgroundColor: 'lightgrey', height: '5px' }}
                />
            </div>
            <nav className={menuExpanded ? 'expand ' : ''}>
                <div>
                    {formStep.map((step: any, i: number) => (
                        <StepMenuItem
                            key={'stepMenuItem_' + i}
                            index={i}
                            title={step.title}
                            titleDirect={step.titleDirect}
                            l10n={localization}
                            canBack={(i: number) => !formStep[i].hideBack}
                            changeStep={changeStep}
                        />
                    ))}
                    <div className="sideButtonDiv">
                        {form.code === 'LREEA1' ? null : (
                            <>
                                <a
                                    className="stepMenuItem sideMenuButton"
                                    style={{ backgroundColor: 'white', color: 'green' }}
                                    href={SysParam.LR_WEBSITE_FAQ(curLanguage)}
                                    target="_blank"
                                    role="button"
                                >
                                    <span className="selectedSideMenuText ">
                                        {' '}
                                        {localization.generalFaq} <FaExternalLinkAlt />
                                    </span>
                                </a>
                            </>
                        )}
                        <a
                            className="stepMenuItem sideMenuButton"
                            style={{ backgroundColor: 'white', color: 'green' }}
                            href={SysParam.LR_WEBSITE_TERMS(curLanguage)}
                            target="_blank"
                            role="button"
                        >
                            <span className="selectedSideMenuText ">
                                {' '}
                                {localization.generalTerms} <FaExternalLinkAlt />
                            </span>
                        </a>
                    </div>
                </div>
            </nav>
        </aside>
    );
}
