import { ALLOW_ESUBM, SIGN_ONLY } from '../../../../utilities/constant';
import { getPaymentMethod } from '../../utilities/form-context';

type Input = {
    form: any;
    formData: any;
    stepIdx: number;
    totalStep: number;
};

export function shouldShowPrintButton(input: Input) {
    const { form, formData, stepIdx, totalStep } = input;

    const submissionType = form.submissionType ? form.submissionType(formData) : ALLOW_ESUBM;

    if (stepIdx === totalStep - 3) {
        if (submissionType === SIGN_ONLY) {
            return false;
        }
        if (submissionType !== ALLOW_ESUBM) {
            return true;
        }

        if (!form.paymentRequired || form.ignorePayment?.(formData)) {
            return false;
        }

        const paymentMethod = getPaymentMethod({ form, formData });
        if (!paymentMethod) {
            return true;
        }

        return parseInt(paymentMethod) >= 0;
    }

    if (stepIdx === totalStep - 2) {
        if (submissionType === SIGN_ONLY) {
            return true;
        }
    }

    return false;
}
