import * as $ from 'jquery';

export var payment = {
    availablepaymentmethodsJson: [],
    googlePaymentsClient: null,
    googlePaymentData: {},
    applePaymentReq: {},
    config: {
        isDebug: true,
        domain: document.location.origin,
        locale: 'en-us',
        availabilityPath: '/api/payment/availability',
        transactionPath: '/api/payment/transaction',
        visaPath: '/api/payment/web/visa',
        masterPath: '/api/payment/web/master',
        creditcardPath: '/api/payment/web/creditcard',
        ppsPath: '/api/payment/web/pps',
        echqPath: '/api/payment/echq',
        androidpayPath: '/api/payment/wallet/androidpay',
        googlePayPath: '/api/payment/wallet/googlepay',
        applePayPath: '/api/payment/wallet/applepay',
        fpsPath : '/api/payment/wallet/fps',
		// pasgPath : 'https://sim.fps.payapps.hkicl.com.hk', //Testing
		// pasgPath : 'https://fps.payapps.hkicl.com.hk', //PRD
		cancelPaymentPath : '/api/payment/cancelpayment',
        updateWebPaymentPath: '/api/payment/updatepayment',
        updateeChqPaymentPath: '/api/payment/updateechqpayment',
        ackPagePath : '/payment/html/ack.html',
		fpscallbackPagePath : '/payment/html/fpscallback.html',
		fpsQrCodePagePath : '/payment/html/fpsqrcode.html',
		fpsPollInterval: 3000,
		cartPagePath : '/payment/html/cart.html',
		errPagePath : '/payment/html/err.html',
		retryPagePath : '/payment/html/index.html?CANRETRY=true',
        paymentEnquiryPath: '/api/payment/status/',
        paymenteChqEnquiryPath: '/api/payment/echqstatus/',
        googlePaySetting: {
            environment: window.WFP_CONFIG.GOOGLE_PAY_ENV,
            merchantId: window.WFP_CONFIG.GOOGLE_PAY_MERCHANT_ID,
            merchantName: 'LAND REGISTRY',
            apiVersion: 2,
            apiVersionMinor: 0,
            tokenizationType: 'PAYMENT_GATEWAY',
            gateway: 'mpgs',
            gatewayMerchantId: window.WFP_CONFIG.GOOGLE_PAY_GATEWAY_MERCHANT_ID,
            type: 'CARD',
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            cardNameMap: { MasterCard: 'MASTERCARD', Visa: 'VISA' },
            totalPriceStatus: 'FINAL',
            version: '1.0',
        },
        appleSetting: {
            countryCode: 'HK',
            supportedMethods: 'https://apple.com/apple-pay',
            merchantCapabilities: ['supports3DS'],
            startAppleSessionPath: '/rm-payment/startapplepaysession',
            cardNameMap: { MasterCard: 'masterCard', Visa: 'visa' },
            merchantIdentifier: window.WFP_CONFIG.APPLE_PAY_MERCHANT_ID,
            version: 3,
        },
        paymentSetting: {
            preferpaymentmethods: [
                'visa',
                'mastercard',
                'pps',
                'androidpay',
                'applepay',
                'creditcard',
                'echq',
                'fps'
            ],
        },
        msg: {
            'en-us': {
                applePayNotCapable:
                    'Device is not capable of making Apple Pay payments.',
                applePayBrowserNotsupport:
                    'Apple Play is not supported by this browser.',
                androidPayNotCapable:
                    'Device is not capable of making Android Pay payments.',
                androidPayBrowserNotsupport:
                    'Android Pay is not supported by this browser.',
                googlePayNotCapable:
                    'Device is not capable of making Google Pay payments.',
                googlePayBrowserNotsupport:
                    'Google Pay is not supported by this browser.',
                dataNotFound: 'Error - data not found.',
                paymentNotAvailable: 'Payment is not available now.',
                totalAmount: 'Payment Amount:',
                walletPayLabel: 'HKSAR government',
                transicationId: 'Transaction Reference No.:',
                btnClose: 'Close',
                btnPay: 'Pay',
                btnRetry: 'Retry',
                btnRefresh: 'Refresh',
                btnB: 'Back',
                modalTitle: 'Select a payment method',
                txtLoading: 'Loading...',
                successPayment: 'Successful Payment',
                transicationDateTime: 'Transaction Date & Time:',
                paymentRefNo: 'Payment Reference No.:',
                paymentMethod: 'Payment Method:',
                unsuccessfulPayment: 'Unsuccessful Payment',
                payment: 'Payment',
                paymentRejected: 'Your payment was rejected.',
                paymentCancelled: 'Your payment was cancelled.',
                paymentTimeOut: 'Your payment was cancelled.',
                paymentProcessing: 'Your payment is processing.',
                paymentQrCode : 'Payment Code',
				paymentQrCodeNote : 'Scan the payment code by GovHk Pay mobile app.',
                androidpay: 'Google Pay',
                applepay: 'Apple Pay',
                jcb: 'JCB card',
                mastercard: 'MasterCard',
                visa: 'VISA card',
                unionpay: 'unionpay card',
                pps: 'PPS',
                echq: 'eCheque',
				fps: 'FPS',
				continueToPay: 'Continue to pay ',
				paymentDetailsTitle: 'Use FPS to process the payment',
				paymentAmount: 'Payment Amount',
				scanQRCode: 'Please scan following QR code',
				qrCodeValidTime: 'QR code valid 3 minutes\nPlease complete the payment process within the time',
            },
            'zh-hk': {
                applePayNotCapable: '設備無法進行Apple Pay付款。',
                applePayBrowserNotsupport: '此瀏覽器不支持Apple Play。',
                androidPayNotCapable: '設備無法進行Android Pay付款。',
                androidPayBrowserNotsupport: '此瀏覽器不支持 Android Pay。',
                dataNotFound: '錯誤 - 找不到數據。',
                paymentNotAvailable: '付款功能現在不可用。',
                totalAmount: '付款金額:',
                walletPayLabel: '香港特別行政區政府',
                transicationId: '交易參考編號:',
                btnClose: '關閉',
                btnPay: '付款',
                btnRetry: '重試',
                btnRefresh: '刷新',
                btnBack: '返回',
                modalTitle: '選擇付款方式',
                txtLoading: '載入中',
                successPayment: '付款成功',
                transicationDateTime: '交易日期及時間：',
                paymentRefNo: '付款參考編號：',
                paymentMethod: '付款方法：',
                unsuccessfulPayment: '付款失敗',
                payment: '付款',
                paymentRejected: '你的付款已被拒絕。',
                paymentCancelled: '你的付款已取消。',
                paymentTimeOut: '你的付款已超時。',
                paymentProcessing: '你的付款正在處理中。',
                paymentQrCode : '付款碼',
				paymentQrCodeNote : 'Scan the payment code by GovHk Pay mobile app',
                androidpay: 'Google Pay',
                applepay: 'Apple Pay',
                jcb: 'JCB信用卡',
                mastercard: '萬事達信用卡',
                visa: 'VISA信用卡',
                unionpay: '銀聯信用卡',
                pps: '繳費靈',
                echq: '電子支票',
                fps: '轉數快',
				continueToPay: '繼續付款 ',
				paymentDetailsTitle: '使用轉數快付款',
				paymentAmount: '付款金額',
				scanQRCode: '請掃描以下二維碼',
				qrCodeValidTime: '二維碼有效時間為3分鐘\n請盡快完成付款',
            },
            'zh-cn': {
                applePayNotCapable: '设备无法进行Apple Pay付款。',
                applePayBrowserNotsupport: '此浏览器不支持Apple Play。',
                androidPayNotCapable: '設備無法進行Android Pay付款。',
                androidPayBrowserNotsupport: '此浏览器不支持Android Play。',
                dataNotFound: '錯誤 - 找不到數據。',
                paymentNotAvailable: '付款功能現在不可用。',
                totalAmount: '付款金额:',
                walletPayLabel: '香港特别行政区政府',
                transicationId: '交易参考编号:',
                btnClose: '关闭',
                btnPay: '付款',
                btnRetry: '重试',
                btnRefresh: '刷新',
                btnBack: '返回',
                modalTitle: '选择付款方式',
                txtLoading: '载入中',
                successPayment: '付款成功',
                transicationDateTime: '交易日期及时间：',
                paymentRefNo: '付款参考编号：',
                paymentMethod: '付款方法：',
                unsuccessfulPayment: '付款失败',
                payment: '付款',
                paymentRejected: '你的付款已被拒绝。',
                paymentCancelled: '你的付款已取消。',
                paymentTimeOut: '你的付款已超时。',
                paymentProcessing: '你的付款正在处理中。',
                paymentQrCode : '付款码',
				paymentQrCodeNote : 'Scan the payment code by GovHk Pay mobile app',
                androidpay: 'Google Pay',
                applepay: 'Apple Pay',
                jcb: 'JCB信用卡',
                mastercard: '万事达信用卡',
                visa: 'VISA信用卡',
                unionpay: '银联信用卡',
                pps: '缴费灵',
                echq: '电子支票',
                fps: '转数快',
				continueToPay: '继续付款 ',
				paymentDetailsTitle: '使用转数快付款',
				paymentAmount: '付款金额',
				scanQRCode: '请扫描以下二维码',
				qrCodeValidTime: '二维码有效时间为3分钟\n请尽快完成付款',
            },
        },
    },

    texts: '',

    init: function (settings) {
        $.extend(payment.config, settings);
        payment.texts = payment.config.msg[payment.config.locale];
    },

    setup: function () {},

    getTransactionData: function () {
        var url = payment.config.domain + payment.config.transactionPath;
        payment.showLog('getTransactionData: ' + url);
        var jqxhr = $.getJSON(url, function (data) {})
            .fail(function (data, textStatus, error) {
                var msg = 'getTransactionData err: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                var msg = 'getTransactionData resp: ' + JSON.stringify(data);
                payment.showLog(msg);
            });

        return jqxhr;
    },

    getAvailableMethod: function (amount) {
        var url = payment.config.domain + payment.config.availabilityPath;
        var egisReqJson = {
            locale: payment.config.locale,
            amount: amount,
            preferpaymentmethods:
                payment.config.paymentSetting.preferpaymentmethods,
        };
        payment.showLog(
            'getAvailableMethod: ' +
                url +
                '\n egisReqJson:' +
                JSON.stringify(egisReqJson)
        );

        var jqxhr = $.ajax({
            url: url,
            data: JSON.stringify(egisReqJson),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
        })
            .fail(function (data, textStatus, error) {
                var msg = 'getAvailableMethod: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                var msg = 'getAvailableMethod resp: ' + JSON.stringify(data);
                payment.availablepaymentmethodsJson = data;
                payment.showLog(msg);
            });
        return jqxhr;
    },

    webPayment: function (egisReqJson, url) {
        payment.showLog(
            'webPayment: ' +
                url +
                '\n egisReqJson:' +
                JSON.stringify(egisReqJson)
        );
        var jqxhr = $.ajax({
            url: url,
            data: JSON.stringify(egisReqJson),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
        })
            .fail(function (data, textStatus, error) {
                var msg = 'webPayment err: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                payment.showLog(data.redirecturl);
                window.top.location.href = data.redirecturl;
            });
        return jqxhr;
    },

    walletPayment: function (egisReqJson, url) {
        payment.showLog(
            'walletPayment: ' +
                url +
                '\n egisReqJson: ' +
                JSON.stringify(egisReqJson)
        );
        var jqxhr = $.ajax({
            url: url,
            data: JSON.stringify(egisReqJson),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
        })
            .fail(function (data, textStatus, error) {
                var msg = 'walletPayment err: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                var msg = 'walletPayment rsp: ' + JSON.stringify(data);
                payment.showLog(msg);
            });
        return jqxhr;
    },

    visa: function (egisReqJson) {
        var url = payment.config.domain + payment.config.visaPath;
        payment.webPayment(egisReqJson, url);
    },

    master: function (egisReqJson) {
        var url = payment.config.domain + payment.config.masterPath;
        payment.webPayment(egisReqJson, url);
    },

    creditcard: function (egisReqJson) {
        var url = payment.config.domain + payment.config.creditcardPath;
        payment.webPayment(egisReqJson, url);
    },

    pps: function (egisReqJson) {
        var url = payment.config.domain + payment.config.ppsPath;
        payment.webPayment(egisReqJson, url);
    },

    fps: function(egisReqJson) {
		let url = payment.config.domain + payment.config.fpsPath;
		payment.walletPayment(egisReqJson, url).always(function (data, textStatus, err) {
			if (textStatus === 'success') {
				// rsp.complete('success');
				console.log('egisReqJson: ', egisReqJson);
				let param = {
					'transactionid' : egisReqJson.transactionid,
					'webtoken' : egisReqJson.webtoken,
					'totalamount' : egisReqJson.order.totalamount,
				}
				sessionStorage.setItem('walletPayment', JSON.stringify(param));
				sessionStorage.setItem('walletRsp', JSON.stringify(data));
				// if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
				// 	const timestamp = '?t=' + Date.now();
				// 	console.log('open URL: ', payment.config.pasgPath + '?pay_req_obj=' + encodeURIComponent(data.fpsqrcodeurl)
				// 		+ '&callback=' + encodeURIComponent(payment.config.domain + payment.config.fpscallbackPagePath));
				// 	window.top.location.href = payment.config.domain + payment.config.fpsQrCodePagePath;
				// 	window.open(payment.config.pasgPath + '?pay_req_obj=' + encodeURIComponent(data.fpsqrcodeurl)
				// 		+ '&callback=' + encodeURIComponent(payment.config.domain + payment.config.fpscallbackPagePath), '_blank');
				// } else {
				window.top.location.href = payment.config.domain + payment.config.fpsQrCodePagePath;
				// }
			} else {
				payment.showLog('payment failed');
				// rsp.complete('fail');
				window.top.location.href = payment.config.domain + payment.config.errPagePath;
			}

		});
	},

    pasgUrl: function(_fpsqrcodeurl) {
        let openPASGUrl = window.WFP_CONFIG.PASG_PATH + '?'
        openPASGUrl += 'pay_req_obj=' + encodeURIComponent(_fpsqrcodeurl??"");
        openPASGUrl += "&callback=" + encodeURIComponent(window.location.origin + "/fps_completed.html");
        return _fpsqrcodeurl ? openPASGUrl : "";
    },

	cancelPayment: function(reqJson) {
		const url = payment.config.domain + payment.config.cancelPaymentPath;

		payment.showLog('cancelPayment: '+ url + '\n reqJson:'+JSON.stringify(reqJson));
		const jqxhr =  $.ajax({
			url: url,
			data:JSON.stringify(reqJson),
			type:"POST",
			dataType:'json',
			contentType: "application/json"
		}).fail( function(data, textStatus, error) {
			// const msg = 'cancelPayment err: ' + data.responseText
			const msg = 'cancelPayment err: ' + JSON.stringify(data);
			payment.showLog(msg);
			// if (redirect) {
			// 	window.top.location.href = payment.config.domain + payment.config.errPagePath;
			// }
		}).done(function(data) {
			const msg = "cancelPayment resp: " + JSON.stringify(data);
			payment.showLog(msg);
			// if (redirect) {
			// 	window.top.location.href = payment.config.domain + payment.config.cartPagePath;
			// }
		});

		return jqxhr;
	},

    echq: function (egisReqJson) {
        var url = payment.config.domain + payment.config.echqPath;
        payment.webPayment(egisReqJson, url);
    },

    googleIsReadyToPay: function () {
        var deferred = $.Deferred();

        if (!window.PaymentRequest) {
            payment.showLog(payment.texts.googlePayBrowserNotsupport);
            deferred.resolve(false);
            return deferred.promise();
        }

        var supportedcard = [];
        $.each(
            payment.availablepaymentmethodsJson.availablepaymentmethods,
            function (i, v) {
                if (v.subtype.search(new RegExp(/AndroidPay/i)) != -1) {
                    supportedcard = v.supportedcard;
                    $.each(supportedcard, function (idx, card) {
                        var cardStr =
                            payment.config.googlePaySetting.cardNameMap[card];
                        if (cardStr != null) {
                            supportedcard[idx] = cardStr;
                        }
                    });
                }
            }
        );

        payment.googlePaymentData = {
            apiVersion: payment.config.googlePaySetting.apiVersion,
            apiVersionMinor: payment.config.googlePaySetting.apiVersionMinor,
            allowedPaymentMethods: [
                {
                    type: payment.config.googlePaySetting.type,
                    parameters: {
                        allowedAuthMethods:
                            payment.config.googlePaySetting.allowedAuthMethods,
                        allowedCardNetworks: supportedcard,
                    },
                },
            ],
        };

        var jqxhr = payment.googlePaymentsClient.isReadyToPay(
            payment.googlePaymentData
        );
        jqxhr
            .then(function (response) {
                deferred.resolve(true);
            })
            .catch(function (err) {
                deferred.resolve(false);
                payment.showLog('Error:' + err);
            });

        return deferred.promise();
    },

    googlepay: function (egisReqJson, callback, callbackErr) {
        payment.showLog('googlepay--------------------------->');

        var url = payment.config.domain + payment.config.googlePayPath;

        payment.googlePaymentData.allowedPaymentMethods[0].tokenizationSpecification =
            {
                type: payment.config.googlePaySetting.tokenizationType,
                parameters: {
                    gateway: payment.config.googlePaySetting.gateway,
                    gatewayMerchantId:
                        payment.config.googlePaySetting.gatewayMerchantId,
                },
            };

        payment.googlePaymentData.transactionInfo = {
            currencyCode: egisReqJson.order.currency,
            totalPriceStatus: payment.config.googlePaySetting.totalPriceStatus,
            totalPrice: String(egisReqJson.order.totalamount),
        };

        payment.googlePaymentData.merchantInfo = {
            merchantId: payment.config.googlePaySetting.merchantId,
            merchantName: payment.config.googlePaySetting.merchantName,
        };

        payment.googlePaymentsClient
            .loadPaymentData(payment.googlePaymentData)
            .then(function (rsp) {
                payment.showLog('googlePay rsp data: \n' + JSON.stringify(rsp));
                var paymentDataToken = JSON.parse(
                    rsp.paymentMethodData.tokenizationData.token
                );

                egisReqJson.paymentdata = {
                    data: paymentDataToken,
                    version: payment.config.googlePaySetting.version,
                };

                payment.showLog(
                    'egisReqJson: \n' + JSON.stringify(egisReqJson)
                );
                if (callback instanceof Function) {
                    // rsp.complete('success');
                    payment.showLog('payment success');
                    callback(egisReqJson);
                    return;
                }

                payment
                    .walletPayment(egisReqJson, url)
                    .always(function (data, textStatus, err) {
                        var timestamp = '?t=' + Date.now();
                        if (textStatus == 'success') {
                            // rsp.complete('success');
                            var param = {
                                transactionid: egisReqJson.transactionid,
                                webtoken: egisReqJson.webtoken,
                            };
                            sessionStorage.setItem(
                                'walletPayment',
                                JSON.stringify(param)
                            );
                            sessionStorage.setItem(
                                'walletRsp',
                                JSON.stringify(data)
                            );
                            window.top.location.href =
                                payment.config.domain +
                                payment.config.ackPagePath +
                                timestamp;
                        } else {
                            payment.showLog('payment failed');
                            // rsp.complete('fail');
                            window.top.location.href =
                                payment.config.domain +
                                payment.config.errPagePath;
                        }
                    });
            })
            .catch(function (err) {
                callbackErr(err);
                payment.showLog(err);
            });
    },

    appleIsReadyToPay: function (egisReqJson) {
        var deferred = $.Deferred();

        if (!window.PaymentRequest || !window.ApplePaySession) {
            payment.showLog(payment.texts.applePayBrowserNotsupport);
            deferred.resolve(false);
            return deferred.promise();
        }

        var supportedcard = [];
        $.each(
            payment.availablepaymentmethodsJson.availablepaymentmethods,
            function (i, v) {
                if (v.subtype.search(new RegExp(/ApplePay/i)) != -1) {
                    supportedcard = v.supportedcard;
                    $.each(supportedcard, function (idx, card) {
                        var cardStr =
                            payment.config.appleSetting.cardNameMap[card];
                        if (cardStr != null) {
                            supportedcard[idx] = cardStr;
                        }
                    });
                }
            }
        );

        var paymentMethod = [
            {
                supportedMethods: payment.config.appleSetting.supportedMethods,
                data: {
                    version: payment.config.appleSetting.version,
                    merchantIdentifier:
                        payment.config.appleSetting.merchantIdentifier,
                    merchantCapabilities:
                        payment.config.appleSetting.merchantCapabilities,
                    supportedNetworks: supportedcard,
                    countryCode: payment.config.appleSetting.countryCode,
                },
            },
        ];

        var paymentDetail = {
            total: {
                label: egisReqJson.label,
                amount: {
                    value: egisReqJson.order.totalamount,
                    currency: egisReqJson.order.currency,
                },
            },
        };

        var options = {};

        payment.applePaymentReq = new PaymentRequest(
            paymentMethod,
            paymentDetail,
            options
        );

        var jqxhr = payment.applePaymentReq.canMakePayment();
        jqxhr
            .then(function (response) {
                deferred.resolve(true);
            })
            .catch(function (err) {
                deferred.resolve(false);
                payment.showLog('Error:' + err);
            });

        return deferred.promise();
    },

    applepayWebPamentAPI: function (egisReqJson, callback, callbackErr) {
        payment.showLog('applepayWebPamentAPI--------------------------->');

        var url = payment.config.domain + payment.config.applePayPath;

        payment.applePaymentReq.onmerchantvalidation = function (event) {
            const startAppleSessionPath =
                payment.config.appleSetting.startAppleSessionPath;
            const promise = fetch(startAppleSessionPath, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    domainName: window.location.hostname,
                    validationurl: event.validationURL,
                }),
            }).then(res => res.json());
            event.complete(promise);
        };

        payment.applePaymentReq
            .show()
            .then(function (paymentResponse) {
                payment.showLog(
                    'apple rsp data: \n' + JSON.stringify(paymentResponse)
                );

                var paymentData = paymentResponse.details.token.paymentData;

                payment.showLog('paymentdata: ' + JSON.stringify(paymentData));
                payment.showLog(
                    'mobileWalletPaymentRequest: ' + JSON.stringify(egisReqJson)
                );
                egisReqJson.paymentdata = paymentData;

                if (callback instanceof Function) {
                    paymentResponse.complete('success');
                    payment.showLog('payment success');
                    callback(egisReqJson);
                    return;
                }

                payment
                    .walletPayment(egisReqJson, url)
                    .always(function (data, textStatus, err) {
                        var timestamp = '?t=' + Date.now();
                        if (textStatus == 'success') {
                            paymentResponse.complete('success');
                            var param = {
                                transactionid: egisReqJson.transactionid,
                                webtoken: egisReqJson.webtoken,
                            };
                            sessionStorage.setItem(
                                'walletPayment',
                                JSON.stringify(param)
                            );
                            sessionStorage.setItem(
                                'walletRsp',
                                JSON.stringify(data)
                            );
                            window.top.location.href =
                                payment.config.domain +
                                payment.config.ackPagePath +
                                timestamp;
                        } else {
                            payment.showLog('payment failed');
                            paymentResponse.complete('fail');
                            window.top.location.href =
                                payment.config.domain +
                                payment.config.errPagePath;
                        }
                    });
            })
            .catch(function (err) {
                payment.showLog('Error:' + err);
                callbackErr(err);
            });
    },

    updateWebPayment: function (transactionid, receipttoken) {
        var url = payment.config.domain + payment.config.updateWebPaymentPath;

        var reqJson = {
            transactionid: transactionid,
            receipttoken: receipttoken,
        };

        payment.showLog(
            'updateWebPayment: ' + url + '\n reqJson:' + JSON.stringify(reqJson)
        );
        var jqxhr = $.ajax({
            url: url,
            data: JSON.stringify(reqJson),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
        })
            .fail(function (data, textStatus, error) {
                var msg = 'updateWebPayment err: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                var msg = 'updateWebPayment resp: ' + JSON.stringify(data);
                payment.showLog(msg);
            });

        return jqxhr;
    },

    paymentEnquiry: function (transactionid, webtoken) {
        var url =
            payment.config.domain +
            payment.config.paymentEnquiryPath +
            transactionid;

        var reqJson = {
            webtoken: webtoken,
        };
        payment.showLog(
            'paymentEnquiry: ' + url + '\n reqJson:' + JSON.stringify(reqJson)
        );
        var jqxhr = $.ajax({
            url: url,
            data: JSON.stringify(reqJson),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
        })
            .fail(function (data, textStatus, error) {
                var msg = 'paymentEnquiry err: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                var msg = 'paymentEnquiry resp: ' + JSON.stringify(data);
                payment.showLog(msg);
            });
        return jqxhr;
    },

    updateeChqPayment: function (transactionid, receipttoken, eserviceid) {
        var url = payment.config.domain + payment.config.updateeChqPaymentPath;

        var reqJson = {
            transactionid: transactionid,
            receipttoken: receipttoken,
            eserviceid: eserviceid,
        };

        payment.showLog(
            'updateeChqPayment: ' +
                url +
                '\n reqJson:' +
                JSON.stringify(reqJson)
        );
        var jqxhr = $.ajax({
            url: url,
            data: JSON.stringify(reqJson),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
        })
            .fail(function (data, textStatus, error) {
                var msg = 'updateeChqPayment err: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                var msg = 'updateeChqPayment resp: ' + JSON.stringify(data);
                payment.showLog(msg);
            });

        return jqxhr;
    },

    paymenteChqEnquiry: function (transactionid, webtoken, eserviceid) {
        var url =
            payment.config.domain +
            payment.config.paymenteChqEnquiryPath +
            transactionid;

        var reqJson = {
            webtoken: webtoken,
            eserviceid: eserviceid,
        };
        payment.showLog(
            'paymenteChqEnquiry: ' +
                url +
                '\n reqJson:' +
                JSON.stringify(reqJson)
        );
        var jqxhr = $.ajax({
            url: url,
            data: JSON.stringify(reqJson),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
        })
            .fail(function (data, textStatus, error) {
                var msg = 'paymenteChqEnquiry err: ' + data.responseText;
                payment.showLog(msg);
            })
            .done(function (data) {
                var msg = 'paymenteChqEnquiry resp: ' + JSON.stringify(data);
                payment.showLog(msg);
            });
        return jqxhr;
    },

    showLog: function (msg) {
        if (payment.config.isDebug) {
            console.log(msg);
        }
    },
};
