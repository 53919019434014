import { AxiosResponse } from 'axios';
import { setCaptchaToken } from '../../../layout/core/reducers/wfpSessionReducer';
import store from '../../../layout/core/reducers/wfpCoreStore';

export const onSuccess = (res: AxiosResponse) => {
    const accessToken = res?.headers?.['access-token'];
    if (accessToken) {
        store.dispatch(setCaptchaToken(accessToken));
    }
    return res.data;
};
