import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';
import { WebsiteLocale } from '../../layout/core/website-locale.enum';
import { SuggestedAddress } from '../api/entities';
import { AddressSearchLanguage } from '../api/service/address-lookup.request';
import { traditionalToSimplified } from '../utils/simplified-traditional-chinese-convert.util';
import { websiteLocaleToAddressLocale } from '../utils/website-locale-to-address-locale.util';

type Props = {
    suggestion: SuggestedAddress;
};

export function Suggestion(props: Props) {
    const websiteLang = useSelector((store: RootState) => store.session.curLanguage);
    const { suggestion } = props;
    const str = suggestion.getString(websiteLocaleToAddressLocale(websiteLang));
    return (
        <>{websiteLang === WebsiteLocale.SIMPLIFIED_CHINESE ? traditionalToSimplified(str) : str}</>
    );
}
