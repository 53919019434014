import lodash from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { dialogHelper } from '../../layout/core/dialogHelper';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';

export function useShowErrorWithCallback() {
    const { showErrorDialog } = dialogHelper;
    const dispatch = useDispatch();
    const language = useSelector((store: RootState) => store.session.language);
    return (key: string | string[], description?: string) =>
        (callback = () => {}) => {
            let err = lodash.get(language, key);
            err = language.formatString(err, description);

            window.requestAnimationFrame(() =>
                dispatch(showErrorDialog({ err, handleClose: callback }, language))
            );
        };
}
