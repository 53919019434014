import { ChiPremisesAddress } from "../api/entities/premises-address/chinese-premises-address.entity";

const OpenCC = require('opencc-js');
const t_to_s_converter = OpenCC.Converter({ from: 'hk', to: 'cn' });
const s_to_t_converter = OpenCC.Converter({ from: 'cn', to: 'hk' });


export function simplifiedToTraditional(s: string) {
    return s_to_t_converter(s);
}

export function traditionalToSimplified(s: string) {
    return t_to_s_converter(s);
}