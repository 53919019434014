import { isEmpty } from 'lodash';
import { PayByFpsResponse } from '../api/services/pay-by-fps/response';

export class TransactionData {
    constructor(input: Partial<TransactionData> = {}) {
        Object.assign(this, input);
    }

    transactionId: string;

    webToken: string;

    // 202309
    fpsResponse? :PayByFpsResponse;

    isValid() {
        return !isEmpty(this.transactionId) && !isEmpty(this.webToken);
    }

    used: boolean = false;

    isUsed() {
        return this.used;
    }

    markAsUsed() {
        this.used = true;
    }

    addFpsResponse(_fpsResponse:PayByFpsResponse) {
        this.fpsResponse = _fpsResponse;
    }
}
