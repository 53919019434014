import { getCurrency } from '../../../given';

type Input = { total: number; currency: string };

export function formatTotalAmount(input: Input) {
    const { total, currency } = input;
    return (
        getCurrency(currency) +
        Number(total).toLocaleString(undefined, { minimumFractionDigits: 2 })
    );
}
