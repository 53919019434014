import { PaymentMethod, PaymentMethodSubtype, CardType } from '../enums';

export class EnumTransform {
    static paymentMethodToPaymentMethodSubtype(pm: PaymentMethod): PaymentMethodSubtype {
        switch (pm) {
            case PaymentMethod.APPLE_PAY:
                return PaymentMethodSubtype.APPLE_PAY;
            case PaymentMethod.ANDROID_PAY:
                return PaymentMethodSubtype.ANDROID_PAY;
            case PaymentMethod.E_CHEQUE:
                return PaymentMethodSubtype.E_CHEQUE;
            case PaymentMethod.MASTER_CARD:
                return PaymentMethodSubtype.MASTER_CARD;
            case PaymentMethod.PPS:
                return PaymentMethodSubtype.PPS;
            case PaymentMethod.VISA:
                return PaymentMethodSubtype.VISA;
            case PaymentMethod.JCB:
            case PaymentMethod.UNION_PAY:
                return PaymentMethodSubtype.CREDIT_CARD;
            case PaymentMethod.FPS:
                return PaymentMethodSubtype.FPS; // 202309
        }
    }

    static paymentMethodToCardType(pm: PaymentMethod): CardType {
        switch (pm) {
            case PaymentMethod.VISA:
                return CardType.VISA;
            case PaymentMethod.MASTER_CARD:
                return CardType.MASTER_CARD;
            case PaymentMethod.JCB:
                return CardType.JCB;
            case PaymentMethod.UNION_PAY:
                return CardType.UNION_PAY;
        }
    }

    static paymentMethodSubtypeToPaymentMethods(subtype: PaymentMethodSubtype): PaymentMethod[] {
        switch (subtype) {
            case PaymentMethodSubtype.ANDROID_PAY:
                return [PaymentMethod.ANDROID_PAY];
            case PaymentMethodSubtype.APPLE_PAY:
                return [PaymentMethod.APPLE_PAY];
            case PaymentMethodSubtype.VISA:
                return [PaymentMethod.VISA];
            case PaymentMethodSubtype.MASTER_CARD:
                return [PaymentMethod.MASTER_CARD];
            case PaymentMethodSubtype.CREDIT_CARD:
                return [
                    PaymentMethod.JCB,
                    PaymentMethod.MASTER_CARD,
                    PaymentMethod.UNION_PAY,
                    PaymentMethod.VISA,
                ];
            case PaymentMethodSubtype.PPS:
                return [PaymentMethod.PPS];
            case PaymentMethodSubtype.E_CHEQUE:
                return [PaymentMethod.E_CHEQUE];
            case PaymentMethodSubtype.FPS:
                return [PaymentMethod.FPS];
        }
    }
}
