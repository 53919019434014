import { Payment } from '../../../gcis-payment/payment.proxy';
import { websiteLocaleToPaymentLocale } from '../../../gcis-payment/utils';
import { AppDispatch } from './wfp-core-store.type';
import { setLanguage } from './wfpSessionReducer';
import * as Constant from '../../../utilities/constant.js';

export function setLanguageThunk(language: string) {
    return function (dispatch: AppDispatch) {
        dispatch(setLanguage(language));
        Payment.setLocale(websiteLocaleToPaymentLocale(language));

        switch (language) {
            case Constant.ENG:
                document.documentElement.lang = "en"
                break;
            case Constant.TRADCHN:
                document.documentElement.lang = "zh-hk"
                break;
            case Constant.SIMPCHN:
                document.documentElement.lang = "zh-cn"
                break;
        }
    };
}
