import React, { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../layout/core/reducers/wfp-core-store.type';
import { FormContext } from '../../../../layout/core/layoutElements';
import { FormContext as TFormContext } from '../../../../layout/core/utilities/form-context/types';

import { calculateUnavailablePaymentMethods } from '../../../utils/calculate-unavailable-payment-methods.util';
import { PAYMENT_CANCELLED, POLLING_CANCELLED, WEB_TOKEN_EXPIRED } from '../../../constants';
import { PollStatusResponse } from '../../../api-wfp/services/poll-status';
import { PaymentStatusCode } from '../../../types';
import { useSubmitForm, useGcisPayment, useGetAvailablePaymentMethods } from '../../../hooks';
import { useShowPaymentError, useShowGeneralError } from '../../../hooks-dialog';
import { PaymentMethodSubtype } from '../../../enums';
import { clearTransactionData } from '../../../redux/slice';

import { PaymentPanel } from '../payment-panel';
import { PointsToNote } from '../points-to-note';
import { TransactionPanel } from '../transaction-panel';
import { NormalFooter } from './normal-footer';
import { CancelFooter } from './cancel-footer';

import '../payby.css';
import { PayByHeader } from './pay-by-header';
import { Loader } from './loader';
import { FpsPanel } from './pay-by-qr-code';
import { useGetTransactionData } from '../../../hooks/use-get-transaction-data.hook';

type Props = { closeModal: () => void };

export function PayByContent(props: Props) {
    const { closeModal } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const { formLocalization } = useContext<TFormContext>(FormContext);
    const timeoutRef = useRef(null);

    const { isPaymentMethodChosen, paymentGateway, paymentData } =
        useSelector((state: RootState) => state.payment) || {};
    const dispatch = useDispatch();

    const showPaymentError = useShowPaymentError();
    const showGeneralError = useShowGeneralError();
    const submitForm = useSubmitForm();

    const { submitPayment, loading: loadingPayment , transactionData, cancelPayment } = useGcisPayment();
    const [loadingPaymentMethod, availablePaymentMethods] = useGetAvailablePaymentMethods();
    const loading = loadingPayment || loadingPaymentMethod;

    const startTimeout = () => {
        stopTimeout();
        timeoutRef.current = setTimeout(() => {
            setDisplayCancel(true);
        }, 10000);
    };

    const stopTimeout = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        setDisplayCancel(false);
    };

    const onCancel = () => {
        cancelPayment();
        closeModal();
    };

    const onSubmit = async () => {
        setIsSubmitting(true);
        startTimeout();

        let windowRef: Window;
        if (
            paymentGateway !== PaymentMethodSubtype.ANDROID_PAY &&
            paymentGateway !== PaymentMethodSubtype.APPLE_PAY
        ) {
            windowRef = window.open('about:blank', '_blank');
        }

        await submitPayment(windowRef)
            .then((res: PollStatusResponse | void) => {
                if (!res) {
                    //polling cancelled
                    return;
                } else {
                    if (res.status === PaymentStatusCode.APPROVED) {
                        
                        submitForm();
                        closeModal();
                    } else {
                        switch (res.status) {
                            case PaymentStatusCode.CANCELLED:
                                showPaymentError('ERROR_PAYMENT_CANCELLED', res.description);
                                break;
                            case PaymentStatusCode.REJECTED:
                                showPaymentError('ERROR_PAYMENT_REJECTED', res.description);
                                break;
                            default:
                                showGeneralError();
                                break;
                        }
                    }
                }
            })
            .catch((e) => {
                windowRef?.close();
                switch (e) {
                    case POLLING_CANCELLED:
                        return;
                    case PAYMENT_CANCELLED:
                        showPaymentError('ERROR_PAYMENT_CANCELLED_USER');
                        return;
                    case WEB_TOKEN_EXPIRED:
                        showPaymentError('ERROR_PAYMENT_EXPIRED');
                        dispatch(clearTransactionData());
                        return;
                    default:
                        showGeneralError();
                        return;
                }
            })
            .finally(() => {
                setIsSubmitting(false);
                stopTimeout();
            });
    };

    const missingPaymentMethods = calculateUnavailablePaymentMethods(
        availablePaymentMethods,
        paymentData
    );
    const disabledPaymentMethods = missingPaymentMethods.map(
        (pm) => formLocalization?.GcisPayment?.PAYMENT_METHODS[pm]
    );

    return (
        <>
            <PayByHeader loading={loading} disabledPaymentMethods={disabledPaymentMethods} />

            <div className="row">
                <div className="col-md-12">
                    {loading ? (
                        // 202309: Handle fps payment response
                        (transactionData?.fpsResponse) ? (
                            <div id="paymentPanel">
                                <FpsPanel transactionData={transactionData} />
                            </div>
                        ) : (
                            <div id="paymentPanel">
                                <Loader />
                            </div>
                        )
                    ) : (
                        <>
                            <div className="" id="paymentPanel">
                                <TransactionPanel />
                                <p></p>
                                <PaymentPanel availablePaymentMethods={availablePaymentMethods} />
                                <p></p>
                            </div>
                            <PointsToNote />
                        </>
                    )}
                </div>
            </div>

            {!loading && (
                <NormalFooter
                    onClose={closeModal}
                    canClose={!isSubmitting}
                    onSubmit={onSubmit}
                    canSubmit={!isSubmitting && !!isPaymentMethodChosen}
                />
            )}
            {loading && (transactionData?.fpsResponse || displayCancel) && <CancelFooter customClass={transactionData?.fpsResponse ? 'fpsQRCodePanel' : null} onCancel={onCancel} />}
        </>
    );
}
