//TODO: Placeholders. Replace with actual types.
export type ChannelType = string;

export type ResultCode = string;

export enum PaymentStatusCode {
    APPROVED = 'APPR',
    REJECTED = 'REJT',
    CANCELLED = 'CANC',
    IN_PROCESS = 'INPR',
    NOT_FOUND = 'NOTF',
    DUPLICATE = 'DUPA',
    PAYMENT_SERVER_ERROR = 'PSER',
    FI_ERROR = 'FIER',
    TIMEOUT = 'PSTO',
}

export type RejectReasonCode = string;
