import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormContext } from '../../../layoutElements';
import { RootState } from '../../../reducers/wfp-core-store.type';
import { shouldShowPrintButton } from '../../utils/should-show-print-button.util';
import { SIGN_ONLY } from '../../../../../utilities/constant';
import { FormContext as TFormContext } from '../../../utilities/form-context/types';

import { SubmitButton, CheckButton, NextButton } from './buttons/primary-buttons';
import { BackButton, SaveButton, PrintButton, ClearButton } from './buttons/default-buttons';
import { useIsPaymentHandledByGcis, useStartGcisPayment } from '../../../../../gcis-payment/hooks';
import { isSecondLastPage } from '../../utils/is-second-last-page.util';
import { allowESubmission } from '../../utils/allow-e-subm.util';
import { useIsOnlineSubmit } from '../../../hooks/use-is-online-submit.hook';
import { isiOS } from '../../../../../utilities';
import { isConfirmPage, isParticularsPage } from '../../utils';
import { isLREAL1 } from '../../../../LREAL1/util';

type Props = {
    onSave: () => void;
    onPrint: () => void;
    performClear: () => void;
    performComplete: (formCode: string) => void;
    performSubmitForm: () => void;
    hasError: () => boolean;
};

export function Footer(props: Props) {
    const { onSave, onPrint, performClear, performComplete, performSubmitForm, hasError } = props;

    const { formData, signedFields } = useSelector((store: RootState) => store.formDetail);
    const { steps, stepIdx, form, prevStep, nextStep } = useContext<TFormContext>(FormContext);

    const paymentHandledByGcis = useIsPaymentHandledByGcis();
    const startGcisPayment = useStartGcisPayment();
    const isOnlineSubmit = useIsOnlineSubmit();

    const renderFunctionalButtons = () => {
        if (form.simplifiedForm && stepIdx === 0) return <ClearButton onClear={performClear} />;
        if (steps[stepIdx].hideSavePrint) return null;
        if (form.simplifiedForm) return null;
        return (
            <div className="savePrintBtn-div">
                <SaveButton onSave={onSave} />
                {
                    form.hidePrintBtn ? 
                        null
                        :
                        <PrintButton onPrint={onPrint} />
                }
                {stepIdx < steps.length - 3 && <ClearButton onClear={performClear} />}
            </div>
        );
    };

    const renderBackButton = () => {
        if (stepIdx > 0 && !steps[stepIdx].hideBack) return <BackButton onBack={prevStep} />;
    };

    const renderPrimaryButton = () => {
        if (
            isSecondLastPage({ stepIdx, totalStep: steps.length }) &&
            allowESubmission({ form, formData })
        ) {
            if (paymentHandledByGcis) {
                return (
                    <NextButton
                        onNext={() => {
                            if (!hasError()) {
                                startGcisPayment();
                            }
                        }}
                    />
                );
            } else {
                return <SubmitButton onSubmit={performSubmitForm} />;
            }
        } else if (steps[stepIdx].hideNext || stepIdx > steps.length - 2) {
            return null;
        } else {
            return renderNextButton();
        }
    };

    const renderNextButton = () => {
        //show if no val errs, current step not set to hide next, and is not last step (where submit button shall be shown)
        // TODO: extract into 2 render functions - one for next button; one for check button; Each with simple logic function to determine should / should not render
        const showPrintButton = shouldShowPrintButton({
            form,
            stepIdx,
            totalStep: steps.length,
            formData,
        });
        const signOnly = form.submissionType?.(formData) === SIGN_ONLY;
        const hasSigned = Object.keys(signedFields).length > 0;

        if (showPrintButton) {
            if (signOnly && !hasSigned) {
                return null;
            } else {
                return <CheckButton onComplete={() => performComplete(form.code)} />;
            }
        } else {
            // HOTFIX: Stop LREAL1 online submission through iPhone since failure rate too high
            // if (isOnlineSubmit && isiOS() && isLREAL1(form) && isParticularsPage(steps, stepIdx)) {
            //     return null;
            // }
            return <NextButton onNext={nextStep} />;
        }
    };

    return (
        <div className="btns">
            {renderFunctionalButtons()}
            <div className="nextBackBtn-div">
                {renderBackButton()}
                {renderPrimaryButton()}
            </div>
        </div>
    );
}
