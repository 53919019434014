import { PaymentMethod } from '../../../../../gcis-payment/enums';

const paymentMethods: Record<PaymentMethod, string> = {
    PPS: 'PPS',
    JCB: 'Credit Card (JCB)',
    MASTER_CARD: 'Credit Card (Mastercard)',
    UNION_PAY: 'Credit Card (Union Pay)',
    VISA: 'Credit Card (VISA)',
    E_CHEQUE: 'e-Cheque',
    ANDROID_PAY: 'Google Pay',
    APPLE_PAY: 'Apple Pay',
    FPS: 'FPS',// 202309
};

export const GCIS_PAYMENT_EN = {
    PRE_PAYMENT_MESSAGE:
        'Online payment (PPS / Credit Card / FPS / Mobile Payment) has been selected. Please click the “Next” button below to continue.',
    METHOD_SUMMARY: 'PPS / Credit Card / FPS / Mobile Payment (Online Submission)',
    MODAL_HEADER: 'Online Payment',
    ERROR_PAYMENT_EXPIRED: 'Payment session has expired. Please try again.',
    ERROR_PAYMENT_REJECTED:
        'Your payment has been rejected by the payment system. Please contact your card issuing bank.\n\nDetail: {0}',
    ERROR_PAYMENT_CANCELLED: 'Payment has been cancelled. Please try again later.\n\nDetail: {0}',
    ERROR_PAYMENT_CANCELLED_USER: 'Payment has been cancelled. Please try again later.',
    ERROR_NO_PAYMENT_METHODS_AVAILABLE:
        'Online payment service is temporarily suspended. You can still settle the payment by e-Cheque or try again later.',
    PAYMENT_METHODS: paymentMethods,
    PAYMENT_DISABLED_BROADCAST_TITLE: 'Temporary Suspension of Online Payment Method - {0}',
    PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER:
        'Due to service maintenance, Online Payment Method - {0} will be temporarily unavailable during the following period:',
    PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER:
        'You can still perform online payment by using other payment methods during the above periods.  Alternatively, you can save the inputted data and perform online payment using {0} afterwards. For enquiries, please call the Land Registry Customer Service Hotline at 3105 0000.',
    ALL_PAYMENT_DISABLED_BROADCAST_TITLE: 'Temporary Suspension of Online Payment Methods',
    ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_HEADER:
        'Due to service maintenance, Online Payment Methods will be temporarily unavailable during the following period:',
    ALL_PAYMENT_DISABLED_BROADCAST_TEMPLATE_FOOTER:
        'You can save the inputted data and perform online payment afterwards. For enquiries, please call the Land Registry Customer Service Hotline at 3105 0000.',
    PAYMENT_DISABLED_MESSAGE_TEMPLATE:
        'Online payment by {0} is temporarily unavailable. You can still pay by other online payment methods or try again later. For enquiries, please contact the Land Registry Customer Service Hotline at 3105 0000.',

    // 202309
    FPS_PAYMENT_MOBILE_FOOTER: "Please click the \"Open Mobile App\" button to open the mobile banking app or e-wallet on mobile device direct.",
    FPS_PAYMENT_MOBILE_BUTTON: "Open Mobile App",
    FPS_PAYMENT_SEPARATE: "- OR -",
    FPS_PAYMENT_FOOTER: "Please use any mobile banking app or e-wallet that supports FPS QR code payment to scan the QR code to make payment.\n\nThe QR code will expire in 3 minutes.",
    FPS_PAYMENT_ID: "Payment ID: ",
};
