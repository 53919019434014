import { AxiosRequestConfig } from 'axios';
import { instanceToPlain } from 'class-transformer';
import qs from 'qs';
import { InstanceFactory } from '../../../utilities';

export const onRequest = (config: AxiosRequestConfig) => {
    const { data, ...rest } = config;
    return {
        ...rest,
        data: data ? qs.stringify(instanceToPlain(data)) : data,
    };
};

export const instance = InstanceFactory.getInstance({
    baseUrl: 'https://www.als.ogcio.gov.hk',
    onRequest,
});
