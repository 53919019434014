import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch } from 'react-redux';
import { checkBrowserRequirement } from './core/reducers/wfpSessionReducer';
import { FaBars } from 'react-icons/fa';
import IconButton from './component/iconButton';
import forge from 'node-forge';

forge.options.usePureJavaScript = true;

export default ({ props }) => {
	// const session = useSelector(store => store.session, (a,b) => {
	// 	return lo.isEqual(a,b);
	// });
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkBrowserRequirement("chrome 20+")); 
	}, []);

	const demonstrateSignature = () => {
		try {
			// replace with your actual String
			let exampleString =
				"Text that should be signed to prevent unknown tampering with its content.";

			// generate a keypair, in asynchronous encryption both keys need to be related
			// and cannot be independently generated keys
			// keylength adheres to the "ECRYPT-CSA Recommendations" on "www.keylength.com"
			// not needed if you already posses public and private key
			let keypair = forge.pki.rsa.generateKeyPair({ bits: 3072, e: 0x10001 });

			// SIGN the string
			let pss = forge.pss.create({
				md: forge.md.sha512.create(),
				mgf: forge.mgf.mgf1.create(forge.md.sha512.create()),
				saltLength: 20
			});
			let md = forge.md.sha512.create();
			md.update(exampleString, "utf8");
			let signature = forge.util.encode64(keypair["privateKey"].sign(md, pss));

			// VERIFY the String
			pss = forge.pss.create({
				md: forge.md.sha512.create(),
				mgf: forge.mgf.mgf1.create(forge.md.sha512.create()),
				saltLength: 20
			});
			md = forge.md.sha512.create();
			md.update(exampleString, "utf8");
			let verified = keypair["publicKey"].verify(
				md.digest().getBytes(),
				forge.util.decode64(signature),
				pss
			);
 
			return "signature: " + signature + ", verify: " + verified;
		} catch (error) {
			return error;
		}
	};

	return (
		<Col className="body">
			<h2 className="app-title">
				<IconButton iconStyle={{ color: "green" }} label="" icon={<FaBars />} />&emsp;Browser Requirements</h2>
			<h1 className="formTitle"></h1>
			<Row >
			</Row>
		</Col>
	);
};