import CoreLocalization from '../utilities/coreLocalization';
import 'system-requirements';
import moment from 'moment';
import hash from 'hash.js';
import axios from 'axios';
import { QUERY_FOR_NO_CAPTCHA } from '../../../utilities/constant';
import store from './wfpCoreStore'

const CHECK_BROWSER_REQUIREMENT_START = 'WFP_SESSION_CHECK_BROWSER_REQUIREMENT_START';
const CHECK_BROWSER_REQUIREMENT_DONE = 'WFP_SESSION_CHECK_BROWSER_REQUIREMENT_DONE';

const GET_FORMS_START = "WFP_SESSION_GET_FORMS_START";
const GET_FORMS_DONE = "WFP_SESSION_GET_FORMS_DONE";
const GET_FORMS_ERROR = "WFP_SESSION_GET_FORMS_ERROR";

const SET_ISFETCHING = "WFP_SESSION_SET_ISFETCHING";
const UNSET_ISFETCHING = "WFP_SESSION_UNSET_ISFETCHING";

const SET_FORM_OBJ = "WFP_SESSION_SET_FORM_OBJ";

export const SET_LANGUAGE = "WFP_SESSION_SET_LANGUAGE";

const ACCEPT_TERMS_CONDITIONS = "WFP_SESSION_ACCEPT_TERMS_CONDITIONS";
const SET_FINGERPRINT = "WFP_SESSION_SET_FINGERPRINT";

const SET_CAPTCHA_CHALLENGE = "SET_CAPTCHA_CHALLENGE";
const SET_CAPTCHA_CHALLENGE_ANSWER = "SET_CAPTCHA_CHALLENGE_ANSWER";
const SET_CAPTCHA_TOKEN = "SET_CAPTCHA_TOKEN";
const GET_CAPTCHA_CHALLENGE_START = "GET_CAPTCHA_CHALLENGE_START";
const GET_CAPTCHA_CHALLENGE_SUCCESS = "GET_CAPTCHA_CHALLENGE_SUCCESS";
const GET_CAPTCHA_CHALLENGE_ERROR = "GET_CAPTCHA_CHALLENGE_ERROR";

export const initialState = {
	isCheckingBrowserRequirement: false,
	isPassedBrowserRequirement : false,
	isAcceptTAndC: null,
	// curLanguageIdx: 0,
	curLanguage: "",
	language: CoreLocalization,
	langBtnLanguage: "繁",
	curSize: 12.0,
	formList: [],
	formId: null,
	formObj: null,

	isFetching: false, 
	loadingKey: null,
	loadingMessage: null,
	loadingData: [],
 
	fingerprint: null,
	udid: null,

	// captcha implementation
	captchaGetChallengeInProgress: false,
	captchaChallenge: null,
	captchaChallengeAnswer: null,
	captchaToken: null,
	// end captcha
};

// actions
export const checkBrowserRequirement = (requirements) => {
	return dispatch => {
		dispatch(checkBrowserRequirementStart());
		dispatch(checkBrowserRequirementComplete(window.systemRequirements(requirements)));
	};
};

// action creators
export const checkBrowserRequirementStart = () => ({ type: CHECK_BROWSER_REQUIREMENT_START });
export const checkBrowserRequirementComplete = (result) => ({ type: CHECK_BROWSER_REQUIREMENT_DONE, payload: result});
export const setLanguage = (lang) => ({ type: SET_LANGUAGE, payload: lang });
export const acceptTAndC = (ts) => {
	const timestamp = moment(ts) ?? moment();
	window.localStorage.setItem("acceptTC", timestamp);
	return ({ type: ACCEPT_TERMS_CONDITIONS, payload: timestamp });
}
export const setFormObj = (form) => {
	return ({type: SET_FORM_OBJ, payload: form});
}
export const setFingerprint = (id) => {

	return ({type: SET_FINGERPRINT, payload: id});
}
export const setIsFetching = (loadingKey, loadingMessage, loadingData) => ({type: SET_ISFETCHING, payload: {loadingKey:loadingKey,loadingMessage:loadingMessage, loadingData:loadingData}});
export const unsetIsFetching = () => ({type: UNSET_ISFETCHING});

// captcha
export const setCaptchaChallenge = (captchaChallenge) => ({type: SET_CAPTCHA_CHALLENGE, payload: captchaChallenge});
export const setCaptchaChallengeAnswer = (answer) => ({type: SET_CAPTCHA_CHALLENGE_ANSWER, payload: answer});
export const setCaptchaToken = (token) => ({type: SET_CAPTCHA_TOKEN, payload: token});

export const getCaptchaChallengeStart = () => ({type: GET_CAPTCHA_CHALLENGE_START});
export const getCaptchaChallengeSuccess = (challenge) => ({type: GET_CAPTCHA_CHALLENGE_SUCCESS, payload: challenge});
export const getCaptchaChallengeError = (err) => ({type: GET_CAPTCHA_CHALLENGE_ERROR, payload: err});
export const getCaptchaChallenge = () => {
	return dispatch => {
		dispatch(getCaptchaChallengeStart());

		axios
		.post('/api/wfp/challenge/'+store.getState().session.curLanguage)
		.then(response => {
			switch(response.status) {
				case 200:
					dispatch(getCaptchaChallengeSuccess(response.data));
					break;
				default:
					dispatch(getCaptchaChallengeError(response.data));
					break;
			}
		})
		.catch(err => {
			dispatch(getCaptchaChallengeError(err));
			console.error("getCaptchaChallenge: [ERROR] " + err);
		})
	}
}
// end captcha

export const getFormsStart = () => ({ type: GET_FORMS_START });
export const getFormsSuccess = (data) => ({ type: GET_FORMS_DONE, payload: data });
export const getFormsError = (err) => ({ type: GET_FORMS_ERROR, payload: err });
export const getForms = (token) => {
	return dispatch => {
		dispatch(getFormsStart());

		axios
		.get('/api/wfp/forms'+QUERY_FOR_NO_CAPTCHA, {headers:{"Access-Token":token}})
		.then(response => {
			switch(response.status) {
				case 200:
					dispatch(getFormsSuccess(response.data)); 
					break;
				default:
					dispatch(getFormsError(response.data));
					break;
			}
			dispatch(unsetIsFetching());

		})
		.catch (err => {
			dispatch(getFormsError(err));
			dispatch(unsetIsFetching());
			console.error("getForms: [ERROR] " + err);
		});
	}
}

// reducer
export default (state=initialState, action) => { 
	switch (action.type) { 
		case CHECK_BROWSER_REQUIREMENT_START:
		{
			return { ...state, isCheckingBrowserRequirement: true };	
		}
		case CHECK_BROWSER_REQUIREMENT_DONE:
		{
			return { ...state, isCheckingBrowserRequirement: false, isPassedBrowserRequirement: action.payload };
		}		
		case SET_LANGUAGE:
		{
			// let {curLanguage} = state;
			// curLanguageIdx++;
			// if (curLanguageIdx >= Object.keys(CoreLocalization).length)
			// 	curLanguageIdx = 0;
			
			var searchParams = new URLSearchParams(window.location.search)
			searchParams.set("lang", action.payload);
			var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
			window.history.pushState(null, '', newRelativePathQuery);
			
			CoreLocalization.setLanguage(action.payload);

			return { ...state, curLanguage: action.payload, language: CoreLocalization }
		}		
		case ACCEPT_TERMS_CONDITIONS:
		{
			return { ...state, isAcceptTAndC: action.payload, udid: hash.sha256().update(btoa(action.payload.format() + JSON.stringify(state.fingerprint))).digest('hex') };
		}		
		case SET_FINGERPRINT:
		{
			return { ...state, fingerprint: action.payload };
		}		
		case SET_FORM_OBJ:
		{
			return { ...state, formObj: action.payload };
		}
		case SET_ISFETCHING:
		{
			return { ...state, isFetching: true,
				loadingKey: action.payload?action.payload.loadingKey:null,
				loadingMessage:action.payload?action.payload.loadingMessage:null,
				loadingData:action.payload?action.payload.loadingData:null  
			};
		}	
		case UNSET_ISFETCHING:
		{
			return { 
				...state, isFetching: false, 
				
			};
		}	
		case GET_FORMS_START: {
			return { ...state, isFetching: true};
		}
		case GET_FORMS_DONE: {
			return { ...state, isFetching: false, formList: action.payload};
		}
		case GET_FORMS_ERROR: {
			return { ...state, isFetching: false, formList: [{ reference: action.payload }] }
		}
		// captcha
		case SET_CAPTCHA_CHALLENGE: {
			return { ...state, captchaChallenge: action.payload };
		}
		case SET_CAPTCHA_CHALLENGE_ANSWER: {
			return { ...state, captchaChallengeAnswer: action.payload };
		}
		case SET_CAPTCHA_TOKEN: {
			return { ...state, captchaToken: action.payload };
		}

		case GET_CAPTCHA_CHALLENGE_START: {
			return { ...state, captchaGetChallengeInProgress: true };
		}
		case GET_CAPTCHA_CHALLENGE_SUCCESS: {
			return { ...state, captchaGetChallengeInProgress: false, captchaChallenge: action.payload};
		}
		case GET_CAPTCHA_CHALLENGE_ERROR: {
			return { ...state, captchaGetChallengeInProgress: false, captchaChallenge: null };
		}
		// end captcha
		default:
			return state;
	}
};
