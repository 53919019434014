import { Expose, Type } from 'class-transformer';
import { AddressSearchLanguage } from '../service/address-lookup.request';
import { ValidationInformation, AddressWrapper } from './low-level';

export class SuggestedAddress {
    @Expose({ name: 'Address' })
    @Type(() => AddressWrapper)
    address: AddressWrapper;

    @Expose({ name: 'ValidationInformation' })
    @Type(() => ValidationInformation)
    validationInformation: ValidationInformation;

    getString(language: AddressSearchLanguage = AddressSearchLanguage.ENGLISH) {
        return this.address.getString(language);
    }

    getObject(language: AddressSearchLanguage = AddressSearchLanguage.ENGLISH) {
        return this.address.getObject(language);
    }
}
