export const PaymentApiUrl = {
    GET_TRANSACTION_ID: '/transaction',
    QUERY_PAYMENT_METHOD: '/availability',
    PAY_ONLINE: '/web',
    PAY_BY_WALLET: '/wallet',
    START_APPLE_PAY: '/startapplepaysession',
    UPDATE_ONLINE_PAYMENT: '/updatepayment',
};

export const WfpApiUrl = {
    POLL: '/poll',
    GET_TRANSACTION_ID: '/getTransactionId',
    CREATE_TRANSACTION: '/transaction',
};
