export async function updateTransaction(input: Input) {
    const { transactionId, receiptToken } = input;
    localStorage.setItem(transactionId, receiptToken);
    return;
}

type Input = {
    transactionId: string;
    receiptToken: string;
};
