import React from 'react';
import SysParam from '../../../../utilities/sysParam';
import { GCIS_PAYMENT_HANDLER } from '../../../../gcis-payment/constants';
import { GCIS_PAYMENT_SC as GCIS_PAYMENT } from './gcis-payment';

export default {
	loadingPleaseWait: "正在载入...",
	nav_fontSize: "字型大小",
	logoAltText: "香港特别行政区政府 - 土地注册处",
	title_formMenu: "公用表格",
	title_TandC: "条款及条件",
	requirements: "是项申请所需配套",
	requirements_printer: "打印机",
	requirements_printer_desp: "用于打印表格",
	requirements_reader: "Adobe Reader",
	requirements_reader_desp: "用于检视 / 打印已填妥及下载的表格",	
	requirements_esign: "电子签署(如适用)",
	requirements_esign_desp: ()=> "申请人须使用「智方便+」或由香港邮政核证机关／电子核证服务有限公司发出的数码证书签署网上表格",
	//requirements_ecertOnly_desp: "申请人须使用由香港邮政核证机关∕电子核证服务有限公司发出的数码证书签署网上表格。",
	// requirements_ecert: "由香港邮政核证机关或电子核证服务有限公司发出的数码证书",
	// requirements_ecert_desp: ()=>"用于以数码证书签署及网上递交表格",
	// requirements_iamsmart: ()=>"已安装「智方便」及「智方便+」使用者账户的流动装置",
	// requirements_iamsmart_desp: "以「智方便」签署作网上递交申请",
	requirements_echeque: "网上付款",
	requirements_echeque_desp: "我们接受缴费灵、信用卡 (万事达卡／VISA／银联／JCB)、转数快、Apple Pay 或 Google Pay (只限万事达卡／VISA) 及电子支票用作缴付服务费用",

	documentTitle: "土地注册处",

	steps_Notes: "重要事项",
	steps_Particulars: "申请详情",
	steps_UploadECheque: "上载电子支票",
	steps_Confirmation: "覆核",
	steps_Submission:"递交",
	steps_Signing:"请签署表格",
	steps_PaymentMethod: "付款方式",
	steps_Payment: "网上付款",

	wantTo: "我想",
	wantTo_startFilling : "填写新表格",
	wantTo_continueFilling: "继续填写表格",
	wantTo_loadSaved: "填写已储存的表格",
	
	backToTop: "返回页首",
	acknowledgement: "确认",
	importantNotes: "简介",
	mandatoryHint: "必须填写",
	submissionAcknowledgement: "确认电邮",
	receiveAckEmail:"本人希望收到是次递交申请的确认电邮。",

	ackEmailIncludeFilledForm:"本人希望收取包含已填妥表格的确认电邮。",
	submissionRecord: "递交记录",
	submissionTime: "递交时间",
	signAndSubmit: "签署并递交申请",
	signOnly: "签署表格",
	successfullySigned: "已成功签署",
	saveForSubmission: "多谢填写此表格。请按「下载已签署的表格」按钮下载已签署的表格，以电子邮件方式递交至本处。",
	printForSubmission: "多谢填写此表格。请按「{0}」按钮列印已填妥的表格，以供递交至本处。",
	printForSubmissionAlt: "多谢填写此表格。你须亲自递交此申请。请按「{0}」按钮列印填妥的表格，并亲身到土地注册处客户服务中心或新界查册中心办理申请。",
	printForSubmissionAlt2: "多谢填写此表格。你须亲自递交此申请。请按「{0}」按钮列印填妥的表格，并连同相关证明文件，亲身到土地注册处客户服务中心或新界查册中心办理申请。",
	notesSection: "注意事项",

	error: "错误",
	generalErrMsg: "系统出现错误，请联络客户服务热线 3105-0000 寻求协助。",
	generalErrMsgWithSubmissionId: "系统出现错误，请联络客户服务热线 3105-0000 寻求协助。编号:{0}",
	correctValidationError: "输入的资料有错误，请修改后继续。",
	savePrintValidationError: "请修正错误，然后再储存或列印表格。",
	passwordError: "载入表格资料失败。请检查你所选择的是一个表格资料档，而你提供的密码是与储存该表格资料档时提供的相同。",
	fileNotSelected: "请选择一个表格资料档案。",
	captchaMessage: "需要进行人机验证。请输入图中的4个英文字或数字。   ", 
	captchaRequired: "输入的英文字或数字不正确，请重新输入。", 
	captchaNotMatch: "输入的英文字或数字不正确，请重新输入。",
	captchaError: "输入的英文字或数字不正确，请重新输入。",
	captchaExpired: "验证已逾时。请再次进行人机验证。",
	captchaRefresh1: "(如果你看不清图中的英文字/数字，请按“", 
	captchaRefresh2: "” )",
	captchaRetry: "未能取得验证码。请按此处重试。",
	
	loadFormCodeError: (sourceType, formRef, formCode) => <span>不能从所选的档案载入表格资料。因为此档是由另一表格 (<a target="_blank" href={"/"+sourceType+"/form/"+formCode}>{formRef}</a>) 储存。</span>,
	loadFormOldVersion: "所选的档案载有较旧版本的表格资料。部分资料可能未能正确载入。请检查已载入的资料以确保资料正确。",
	saveCertPasswordError: "储存表格资料失败。  数码证书密码不正确。",
	savePasswordNotConfirm: "储存表格资料失败。输入的密码和确认密码不相同。",
	passwordEmpty: "储储存表格资料失败。  未输入密码。",
	passwordWrongLength: "储存表格资料失败。  输入的密码必须介乎 {0} 至 {1} 个字元之间。",

	chooseAFileToUpload: "选择要上传的档案",
	acceptFileFormat: "接受档案格式",
	sizeUpTo: "最大档案大小为 ",

	captcha:"验证码",
	clear:"清除",
	new:"新表格",
	save: "储存",
	saveAgain: "请再次列印",
	saveSignedForm: "下载已签署的表格",
	load: "载入",
	return: "返回",
	returnToFormList: "返回表格列表",
	print: "列印",
	loadForm: "开启已储存的表格",
	providePwECert: "请输入密码以储存表格资料档。",
	dontForgetPw: "注意：请保存密码，以载入已储存的表格资料档。",
	back: "返回",
	next: "继续",
	close: "关闭",
	zoomIn: "放大",
	zoomOut: "缩小",
	confirm: "确认",
	footNote: "备注",
	complete: "完成",
	clickToExpand: "了解更多",
	clickToCollapse: "隐藏详情",
	fillIn: "开始填写表格",
	head_formTitle: "表 格 名 称",
	formNo: "表格编号",
	startFilling: "开 始 填 写",
	submit: "递交",
	signIAS: "智方便数码签署",
	signIASTitle: "授权「智方便」签署你的表格",
	ias_text: "请确保「智方便」显示的识别码相同，然后点击「签署」以完成数码签署",
	ias_continue: "以智方便继续",
	signECert: "数码证书签署",
	signLater: "储存予下一位签署人",
	signPrinted: "在列印版本签署",
	saveECert: "使用数码证书储存",
	importantTitle: "*** 重要提示 ***",
	submissionMessage1: "*** 重要提示 ***",
	submissionMessage2: "请按「递交」以完成你的网上申请。",
	submissionMessage2A: "请按"+String.fromCharCode(10004)+"按钮以储存已签署的表格。",
	submissionMessage2B: "请按「继续」按钮进行网上付款 (缴费灵／信用卡／转数快／流动支付) 以完成你的网上申请。",
	eCheque: "电子支票",
	echequeMessage2: "If you choose e-Cheque as the payment method, please upload your e-Cheque file in this step.  Otherwise, please click \"Next\" button to proceed, and submit your payment to Land Registry separately.",
	echequeMessage3: "请勿邮寄现金至本处。",
	uploadECheque:"上载电子支票",
	cheque: "支票",
	cash: "现金",
	bankDraft: "银行本票",
	cancel: "取消",
	addARecord: "新增一个项目",
	removeARecord: "移除最后纪录",
	pleaseChoose: "请选择...",
	password: "密码",
	confirmPassword: "确认密码",
	passwordCondition: "({0}-{1} 个字元)",
	uploadECert:"选取数码证书 (*.p12)",
	certificate:"证书",
	certificatePublic:"公用密钥档 (*.cer)",
	certificatePrivate:"选择数码证书档",
	pin:"输入数码证书密码",
	signAndCont:"签署并继续",
	savedFormFile:"已储存的表格资料档",
	signECertNote:"本人已细阅及明白「条款及条件」及「收集个人资料声明」",
	signECertNote2_PICSonly:"本人已细阅及明白「收集个人资料声明」。",
	signEIDNote:"请输入你的香港身份证号码以使用「智方便+」进行签署。你输入的资料只会于你的电腦上进行处理，并不会上传至本系统。请确保你已登记成为「智方便+」用户并已在你的流动电话安装「智方便」流动应用程式。",
	signEIDWaiting:"请在弹出式视窗中完成「智方便」认证程序。此对话框会在你完成「智方便」认证程序后自动关闭。",
	generalFaq: "常见问题",
	generalTerms: "一般使用条款",
	termsAndConditions: "条款及条件",
	termsAndConditionsOf: "本表格的使用条款及条件",
    readAndUnderstandTerms: "本人/我们已细阅及明白上述的「一般使用条款」及「本表格的使用条款及条件」，并同意受其约束。",
	readAndUnderstandGeneralTerms: "本人/我们已细阅及明白上述的「一般使用条款」，并同意受其约束。",
	PICS: "收集个人资料声明",
    readAndUnderstandPICS: "本人/我们已细阅及明白上述的「收集个人资料声明」，并同意受其约束。",
	noteTitle: "注意事项",
    readAndUnderstandNotes: "本人确认已阅读、明白及同意上述注意事项。",
	confirmationMessage: "递交申请前，请检查下列资料是否正确无误。如需修改，你可以返回先前步骤。",
	confirmationMessageAlt: "递交申请前，请检查下列资料是否正确无误。如需修改，你可以返回先前步骤。",
	confirmationMessage_signing: "当你经网上递交申请后，所有申请事项将不能于网上作出修改。你需要以书面方式向本处提交任何修改。",
	confirmationMessage_signing2: "如果要亲身递交，或以邮寄/传递公司或传真方式递交申请，请按下方功能表的「列印」以列印已填写的表格。",
	confirmationMessage_signing2Alt: "如果要亲身递交此申请，请按最下方「列印」按钮以列印已填写的表格。",
	acknowledgement_para1: "本处将处理你已递交的申请。",
	acknowledgement_para2: ()=> (<>如欲就是次申请作出查询，请提供以下参考编号。</>),
	acknowledgement_offline_para: ()=>(
		<>
			<p>你的表格已成功列印。</p>
			<p>请亲身递交，或以邮寄/传递公司或传真方式传送表格至本处以递交申请。</p>
		</>
	),
	ack_refNum: "申请参考编号",
	paymentTxnNum: "付款编号",
	paymentMethod: "付款方式",
	paymentAmount: "付款金额",
	printOrSavePage: "列印 / 储存此页面",
	noFileSelected: "没有文件 (0KB)",
	ackEmail: "接收确认电邮地址",
	signed: "已签署",
	signedBy: "{0} 已签署",
	signError: "错误",
	changeSignature:"重新签署",
	departmentName: "部门名称",
	serviceName: "服务名称",
	documentName: "文件名称",
	signCode: "识别编码",
	landRegistry:"土地注册处",
	webFormPortal:"土地注册处的公用表格",
	sameDevice:"在此装置上签署",
	diffDevice:"在另一装置上签署",
	eCertError:"数码证书档或密码不正确",
	additionalDocuments:"附加文件",
	sessionTimeout: "操作逾时",
	sessionGoingToTimeout: "本页面将于1分钟后逾时。请按「确认」继续。",
	timeRemaining: "剩余时间: {0} 秒",
	sessionHasTimedout: "本页面已逾时。请重新开始。",
	refresh: "重新开始",
	refreshPdf: "更新表格",
	serverBusy: "系统目前比较繁忙。请稍候然后再试一次。",

	paymentMethod_list:[
		{name:"电子支票"},
	],

	emailForAcknowledgement: "请提供你的电子邮件地址以接收确认电邮。",
	passwordForAcknowledgement: "请提供密码以加密已填写的档案。",
	
	steps_Acknowledgement: "确认",

	field_ChnName: "中文姓名 (如适用)",
	field_EngName: "英文姓名",
	field_Salutation: "称呼",
	field_DayTimePhone: "日间联络电话",
	field_HomePhone: "住宅电话",
	field_WorkPhone: "办公室电话",
	field_MobilePhone: "手提电话",
	field_ContactPhone: "联络电话",
	field_Fax: "传真号码",         
	field_Email: "电邮地址",
	field_RetypeEmail: "重新输入电邮地址",
	field_HKID: "香港身份证号码",
	field_HKID_withCheckDigit: "香港身份证号码 (不包括括号, 例如: A1234567)",
	field_IdType: "身分识别种类",
	field_IdNum: "身分识别号码",
	field_Gender: "性别",
	field_DOB: "出生日期",
	field_CorrespondenceAddress: "通讯地址 (请以英文正楷输入)",
	field_acceptNotes: "本人确认已阅读、明白及同意上述重要事项及注意事项",
	field_Date: "日期",
	field_Time: "时间",
	field_Name: "名称",
	field_NameOfDeclarant: "声明人名称",
	field_BusinessReg: "商业登记 / 公司注册",

	field_Salutation_Mr: "先生",
	field_Salutation_Mrs: "太太",
	field_Salutation_Ms: "女士",
	field_Salutation_Miss: "小姐",

	field_signatoryName: "签署人名称",

	field_AddressFlat: "Flat",
	field_AddressFloor: "Floor",
	field_AddressBlock: "Block",
	field_AddressBuilding: "Building Name",
	field_AddressEstate: "Estate Name",
	field_AddressStreet: "No. and name of Street",
	field_AddressDistrict: "District",
	field_AddressHongKong: "Hong Kong",
	field_AddressKowloon: "Kowloon",
	field_AddressNewTerritories: "New Territories",

	field_IdType_HKID: "香港特别行政区身份证", 
	field_IdType_PRCID: "中华人民共和国居民身份证", 
	field_IdType_PRCHKMAC: "中华人民共和国往来港澳通行证", 
	field_IdType_CR: "商业登记证", 
	field_IdType_PASSPORT: "护照", 
	field_IdType_NA: "不适用", 
	Signature: "签署",

	field_paymentMethod: "付款方式",
	field_paymentMethod_list:(extraFieldIds) => [
		{value:"cash", text:"现金"},
		{value:"cheque", text:"支票", extraField:"支票号码", extraFieldId: extraFieldIds[0], extraFieldProps: { numericHyphen: true }},
		// {value:"cheque", text:"e-Cheque"},
		{value:"bank draft", text:"银行本票", extraField:"号码", extraFieldId: extraFieldIds[1], extraFieldProps: { numericHyphen: true }},
	],
	field_paymentMethod_list_negotiable:(extraFieldIds) => [
		{value:"cheque", text:"支票", extraField:"支票号码", extraFieldId: extraFieldIds[0], extraFieldProps: { numericHyphen: true }},
		{value:"bank draft", text:"银行本票", extraField:"号码", extraFieldId: extraFieldIds[1], extraFieldProps: { numericHyphen: true }},
	],
	field_paymentMethod_list_cash:(extraFieldIds) => [
		{value:"cash", text:"现金"},
	],
	field_onlinePayment_list:[
		{text: GCIS_PAYMENT.METHOD_SUMMARY, meta: {handler: GCIS_PAYMENT_HANDLER}},
		{value: "cheque", text: "电子支票 (网上递交申请)"}
	],
	field_amount: "金额",
	field_amount_prepend: "HK$", 
	field_Gender_List: [
		{name:"男"},
		{name:"女"},
		{name:"其他"}
	],

	field_AddressFlat: "室",
	field_AddressFloor: "楼层",
	field_AddressBlock: "座",
	field_AddressBuilding: "大厦名称",
	field_AddressEstate:"屋邨/村",
	field_AddressStreet:"街号及街名",
	field_AddressDistrict:"地区",
	field_AddressRegionHongKong:"香港",
	field_AddressRegionKowloon:"九龙",
	field_AddressRegionNT:"新界",

	validation_required: "必须填写",
	validation_file_required: "必须提供",
	validation_lengthMax: "不可多于 {0} 个字元",
	validation_lengthMin: "不可少于 {0} 个字元",
	validation_lengthRange: "必须介乎 {0} 至 {1} 个字元之间",
	validation_lengthExact: "必须为 {0} 个字元",
	validation_decimalMax: "不可多于 {0}",
	validation_decimalMin: "不可少于 {0}",
	validation_decimalRange: "必须介乎 {0} 至 {1} 之间",
	validation_chineseOnly: "只可输入中文字元",
	validation_englishOnly: "只可输入英文字元",
	validation_numberOnly: "只可输入数字",
	validation_integerOnly: "只可输入整数",
	validation_phonenumber: "不符合电话号码格式",
	validation_faxnumber: "不符合传真号码格式",
	validation_addressBldgEstateOrStreet: "必须最少输入 \"大厦\", \"屋邨/村\", \"街号及街名\" 中的其中一项",
	validation_districtRequired: "必须输入地区",
	validation_multiSelected: "不可多于一个选项",
	validation_invalidHKID: "身份证号码不正确",
	validation_invalidEmail: "电邮地址不正确",
	validation_invalidDate: "有效日期的格式须为{0}",
	validation_invalidTime: "须为格式 h:mm 上午/下午 的有效时间",
	validation_confirmPasswordDiff: "密码及确认密码不相同",
	validation_alphanumeric: "只可输入英数字元 (0-9, A-Z, a-z)",
	validation_alphanumericWithHyphen: "只可输入英数字元 (0-9, A-Z, a-z) 及连字符号 (-)",
	validation_onOrAfterToday: "日期须为今日或之后",
	validation_onOrBeforeToday: "日期须为今日或之前",
	validation_onOrBeforeDate: "日期须为{0}或之前",
	validation_numericWithComma: "只可输入数字及逗号 (,)",
	validation_paymentAmount: "无效的格式",
	validation_numericWithHyphen: "只可输入数字及连字符号 (-)",
	validation_fileSizeExceed:"档案大小不可大于 {0}",
	validation_wrongFileType:"档案格式不正确",
	validation_futureEnough: "日期须为今日之后{0}日",
	validation_selectAtLeastOne:"请选择最少一项。",
    
    validation_eChequeInvalid:"电子支票无效，请重试。",
    validation_eChequeInvalidAmount:"电子支票的面额不正确，请重试。",
    validation_eChequeInvalidCurrency:"电子支票的货币只接受港币，请重试。",
    validation_eChequeInvalidPayee:"电子支票的收款人不正确，请重试。",
    validation_eChequeExpired:"电子支票已过期，请重试。",
	
	validation_eCertInvalid:"数码证书无效，请重试。",
    validation_eCertExpired:"数码证书已过期，请重试。",
    validation_eCertRevoked:"数码证书已被撤销，请重试。",
    validation_eCertNotEffective:"数码证书未生效，请重试。",

	validation_ias_unknownException:"[D20000] 与智方便联系时发生未知错误，请联络客户服务热线 3105-0000 寻求协助。",
	validation_ias_accTokenNotExist:"[D20009] 与智方便联系时发生认证问题，请联络客户服务热线 3105-0000 寻求协助。",
	validation_ias_authTimeout:"[D40003] 认证超时，请重试。",
	validation_ias_signingCanceled:"[D70000] 签署已取消。",
	validation_ias_signingRejected:"[D70001] 签署已被拒绝。",
	validation_ias_signingFailed:"[D70002] 签署失败，请重试。",
	validation_ias_signingTimeout:"[D70003] 签署超时，请重试。",
	validation_ias_signingNotAllowed:"[D70004] 用户不允许使用「智方便」进行签署。",
	validation_ias_signingWrongHKID:"[D70005] 身份证号码不正确，请确认签署者身分后再试。",
	validation_ias_signingProcessFailed:"[D70006] 无法处理签名确认，请重试。",
	validation_ias_signingGetResultError:"[D79402] 获取签名结果失败，请重试。",

	validation_signature_verificationFailed:"签署失败，请重试。",

	validation_signature_signerNameMismatch:"签署者的英文姓名与申请人的姓名不符",
	validation_signature_signerIdMismatch:"签署者的身份证明文件号码与申请人的身分证明文件号码不符",
	validation_signature_nonPersonal:"请使用个人数码证书为此申请进行数码签署",
	validation_signature_nonOrganisation:"请使用机构数码证书为此申请进行电子签名",
	validation_signature_specifyNonHKID:"请在上方栏位选择「香港身分证」，或更改输入的身分证明文件类别",
	validation_signature_signerOrgNameMismatch:"签署者的英文机构名称与申请人的机构名称不符",
	validation_signature_signerOrgAuthNameMismatch:"签署者的英文受权人姓名与申请人的受权人姓名不符",
	validation_signature_signerOrgIdNumMismatch:"签署者的机构证明号码与申请人的机构证明号码不符",
	validation_signature_signerOrgBRNMismatch:"签署者的商业证记证号码与申请人的商业证记证号码不符",
	validation_signature_signerOrgCICRMismatch:"签署者的公司注册证明书号码与申请人的公司注册证明书号码不符",

	validation_max_records: "项目上限: ",

	personalParticulars: "个人资料",

	CDPaymentMethod: "付款方式",
	CDChequeOrBankDraftNo: "支票或本票号码", 

	confirmationMessage_signOnly: ()=> [<>签署表格前，请检查下列资料是否正确无误。如需修改，你可以返回先前步骤。</>],
	confirmationMessage_offlineSubmit: ()=> [<>请检查下列资料是否正确无误，然后按 {String.fromCharCode(10004)} 按钮列印填妥的表格，以供递交至本处。</>],
	confirmationMessage_noESubmit: ()=> [<>本表格不接受网上递交申请。请检查下列资料是否正确无误，然后按 {String.fromCharCode(10004)} 按钮列印填妥的表格，以供递交至本处。</>],
	
	PICSContent: ()=>      [<> 
		<p>1. <span style={{textDecoration:"underline"}}>收集目的</span></p>
		<p>
			土地注册处将会把本表格所收集的个人资料用于以下其中一个／多个目的：
			<ol style={{paddingTop:"15px", listStyleType: "lower-latin"}}>
				<ul>(a) 处理与提供土地注册处服务有关的事宜；</ul>
				<ul>(b) 方便进行联络；以及</ul>
				<ul>(c) 制备与土地注册处服务有关的统计资料。</ul>
			</ol>
		</p>
		<p>
			你明白提供个人资料与否纯属自愿。若你未能提供本表格所要求的资料，本处可能无法提供所要求的服务。
		</p>
		<p>
			请勿提供任何没有明确规定必须提交的个人资料(包括关乎第三者的个人资料)。如本表格或所提交的任何与其有关的文件中包含任何第三者的资料，本处将视作你已获该第三者同意披露该等资料，以用于上述目的。
		</p>
		
		<p>2. <span style={{textDecoration:"underline"}}>获转交资料人士的类别</span></p>
		<p>
			你明白为达到上文第一段中所述明的任何目的而有需要时，你在本表格内所提供的个人资料可能会披露或转交予相关人士。
		</p>    
		<p>3. <span style={{textDecoration:"underline"}}>查阅个人资料</span></p>
		<p>
		你明白根据《个人资料(私隐)条例》(第486章)第18和第22条，以及附表1第6原则，你有权要求查阅及更正本处所持有你的个人资料。根据该条例，本处有权就处理该等要求收取费用。任何该等要求可向本处的个人资料(私隐)主任提出(地址：香港金钟道66号金钟道政府合署28楼土地注册处)。
		</p>
	</>],

	BMOPICSContent: ()=> [<ol> 
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>收集目的</span>
			<p>
				土地注册处将会把本表格所收集的个人资料用于以下目的：
				<ol style={{paddingTop:"15px", listStyleType: "lower-latin"}}> 
					<li>处理与土地注册处提供服务有关的事宜及执行有关职能；</li>
					<li>方便进行联络；以及</li>
					<li>制备与土地注册处服务有关的统计资料。</li>
				</ol>
			</p>
			<p>
				你明白提供个人资料是必须的。倘若你不提供本表格所要求的资料，本处可能无法提供所要求的服务。
			</p>
			<p>
				<b>请勿</b>提供任何没有明确规定必须提交的个人资料（包括关乎第三者的个人资料）。如本表格或与其有关而存档的文件中包含任何第三者的资料，本处将视作你已获该第三者同意披露该等资料，以用于上述目的。 
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>个人资料的披露</span>
			<p>
				你明白为达到上文第一段中所述明目的而及有需要时，你在本表格内所提供的个人资料可能会披露或转交予相关人士，该等个人资料亦可能会在《个人资料（私隐）条例》（第486章）许可下披露或转交予执法机关。
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>查阅个人资料</span>
			<p>
				你明白根据《个人资料（私隐）条例》（第486章)第18和第22条，以及附表1第6原则，你有权要求查阅及更正本处所持有你的个人资料。本处可根据该条例就处理该等要求收取费用。任何该等要求可向本处的个人资料（私隐)主任提出(地址：香港金钟道66号金钟道政府合署28楼土地注册处)。
			</p>
		</li>
	</ol>],

	SSS_PICSContent: ()=>      [<> 
		<p><span style={{textDecoration:"underline"}}>收集目的</span></p>
		<p>
			1. 土地注册处将会把所收集的个人资料用于以下目的:
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.1</div>
				<div>处理与土地注册处提供服务有关的事宜及执行有关的职能；</div>
			</div>
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.2</div>
				<div>方便进行联络；以及</div>
			</div>
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.3</div>
				<div>制备与土地注册处服务有关的统计资料。</div>
			</div>
		</p>
		<p>
			你明白提供个人资料是必须的。倘若你不提供所要求的资料，本处可能无法提供所要求的服务。
		</p>
		<p>
			请勿提供任何没有明确规定必须提交的个人资料(包括关乎第三者的个人资料)。如于本表格或与其有关而存档的文件中包含任何第三者的资料，本处将视作你已获该第三者同意披露该等资料，以用于上述目的。
		</p>
		
		<p><span style={{textDecoration:"underline"}}>个人资料的披露</span></p>
		<p>
			2. 你明白为达到上文第一段中所述明的目的而有需要时，你所提供的个人资料可能会披露或转交予相关人士。该等个人资料亦可能会在《个人资料(私隐)条例》(第486章)许可下披露或转交予执法机关。
		</p>
		
		<p><span style={{textDecoration:"underline"}}>查阅个人资料</span></p>
		<p>
			3. 你明白根据《个人资料(私隐)条例》(第486章)第18和第22条及附表1第6原则，你有权要求查阅及更正本处所持有你的个人资料。本处可根据该条例就处理该等要求收取费用。任何该等要求可向本处的个人资料(私隐)主任提出(地址：香港金钟道66号金钟道政府合署28楼土地注册处)。
		</p>
	</>],

	FSD_PICSContent: ()=> [
		<> 
			<p><span style={{textDecoration:"underline"}}>收集目的</span></p>
			<p>
				1. 土地注册处将会把所收集的个人资料用于以下目的：-
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.1</div>
					<div>处理与土地注册处提供服务有关的事宜及执行有关的职能；</div>
				</div>
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.2</div>
					<div>方便进行联络；以及</div>
				</div>
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.3</div>
					<div>制备与土地注册处服务有关的统计资料。</div>
				</div>
			</p>
			<p>
				你明白提供个人资料是必须的。倘若你不提供所要求的资料，本处可能无法提供所要求的服务。
			</p>
			<p>
				请勿提供任何没有明确规定必须提交的个人资料(包括关乎第三者的个人资料)。如于本表格或与其有关而存档的文件中包含任何第三者的资料，本处将视作你已获该第三者同意披露该等资料，以用于上述目的。
			</p>
			
			<p><span style={{textDecoration:"underline"}}>个人资料的披露</span></p>
			<p>
				2. 你明白为达到上文第一段中所述明的目的而有需要时，你所提供的个人资料可能会披露或转交予相关人士。该等个人资料亦可能会在《个人资料(私隐)条例》(第486章)许可下披露或转交予执法机关。
			</p>
			
			<p><span style={{textDecoration:"underline"}}>查阅个人资料</span></p>
			<p>
				3. 你明白根据《个人资料(私隐)条例》(第 486章)第18和第22条及附表1第6原则，你有权要求查阅及更正本处所持有你的个人资料。本处可根据该条例就处理该等要求收取费用。任何该等要求可向本处的个人资料(私隐)主任提出(地址：香港金钟道66号金钟道政府合署28楼土地注册处)。
			</p>
		</>
	],

	howToChangeFontSize: "如何变更字型大小",
	FontSizeContent: (browserName) => [ 
		<>
			<p>采用了适应性网页设计后，你可简易地变更字型大小，配合个人需要。</p>
			<h4>快速键</h4>
			<p>假如你使用桌上型电脑浏览「土地注册处」网页，近代的浏览器可让用家按着Ctrl键（在Macintosh键盘上是Command键）和+／-键来放大或缩小字型，而按着Ctrl键和0则可回复预设大小。</p>
			<h4>选项单</h4>
			<p>使用智能电话和平板电脑时，你只需在萤幕上滑动手指便能放大或缩小图文。</p>
		</>,
		browserName === "chrome" ? <><h5>Chrome</h5><p>在页面的选项单选择「缩放」，然后按「+」放大。</p></> : null,
		browserName === "firefox" ? <><h5>Firefox</h5>
			<p>在「检视」一栏点选「缩放」，再选择「放大」。</p>
			<p>你也可在页面的选项单按「+」或「-」来放大或缩小字型。</p></> : null,
		browserName === "edge" ? <><h5>Edge</h5>
			<p>在「检视」一栏点选「文字大小」，选择「较大」或「最大」。</p>
			<p>你也可按Edge的设定标记点选「缩放」，再选择合适的百分比或「放大」。</p></> : null,
		browserName === "opera" ? <><h5>Opera</h5>
			<p>在「检视」一栏点选「缩放」，再选择合适的百分比。</p></> : null,
		browserName === "safari" ? <><h5>Safari</h5>
			<p>在「检视」一栏选择「放大文字」。 </p></> : null,
		<>
			<p style={{fontStyle:"italic"}}>
				免责声明<br />
				土地注册处并没有认可特定的浏览器或建议使用哪一个浏览器。本文内容包括部分常用的浏览器，但提及某一个浏览器并没有暗示任何认可或建议的意思。
			</p>
		</>
	
	],

	wfpMaintenanceContent : () => [
		<p>土地注册处的网上表格服务正在进行维修。</p>, 
        <p>请于土地注册处网站 (<a target="_blank" href={SysParam.LR_WEBSITE_FORM("SimpChn")}>{SysParam.LR_WEBSITE_FORM("SimpChn")}</a>) 下载表格。</p>,
		<p>如有任何查询，请致电 3105-0000 或电邮至<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>与我们联络。</p>,
	],

	generalTermsContent: (inSection)=> [
		<>
			{ inSection ?
				null
				:
				<h1 className="formTitle">
					土地注册处网上表格<br/>
					一般使用条款 (「一般条款」)
				</h1>
			}
			<ol>
				<li>土地注册处提供的「网上表格」，可供公众人士就本处的服务及／或产品或与其相关的服务提交网上申请。申请人可于土地注册处网页内网上填写相关表格，并（如适用）提交申请及以电子支付方式缴交服务费用。</li>
				<li>
					在一般条款内：
					<ol>
						<li>「网上表格」指就申请土地注册处的公众服务及／或产品或与其相关的服务而提供、可供在土地注册处网页内网上填写的任何申请表格；</li>
						<li>「服务申请」包括申请土地注册处提供的服务及／或产品或与其相关的服务，该等服务可因应土地注册处酌情决定，不时作出更改；</li>
						<li>「重要须知」指就服务申请而言，该服务申请的特定条款及条件和收集个人资料声明；</li>
						<li>「申请人」指任何接达或使用「网上表格」(包括但不限于使用土地注册处网页的「网上表格」作服务申请，或用于该表格指定的任何用途)的人士。</li>
					</ol>
				</li>
				<li>申请人接达或使用「网上表格」，将被当作无条件和不可撤回地同意和接受一般条款。土地注册处可在没有事先通知下不时修改或修订一般条款，申请人其后再接达或使用「网上表格」，即构成他们已接纳经修改或修订的一般条款。</li>
				<li>申请人每次作出服务申请时，必须阅读「重要须知」和一般条款，并于相关的方格填上「{String.fromCharCode(10004)}」，以确定同意受一般条款以及「重要须知」下的特定条款及条件和收集个人资料声明的约束，方能继续填写申请资料。</li>
				<div className="generalTermsSubtitle">连接「网上表格」</div>
				<li>
					<ol>
						<li>申请人如须透过互联网连接「网上表格」，必须自行就连接「网上表格」和日后切断接驳的事宜与有关互联网、流动通讯或其他电讯服务供应商作出安排，并须自行承担有关费用。申请人亦须自行安排接达所需的电脑硬件、软件、通讯链路、设备及／或装置(「硬件及／或软件」)，并须自行承担有关开支。土地注册处不会就申请人为连接「网上表格」而引致或承担的任何责任，开支或法律责任负责。</li>
						<li> 如土地注册处认为有以下情况，可随时拒绝或限制申请人使用「网上表格」：
							<div className="d-flex">
								<div className="note-bullet">(i)</div>
								<div>申请人的硬件及／或软件已引致或很可能引致土地注册处的资讯系统失灵、受阻、失误或损毁；或</div>
							</div>
							<div className="d-flex">
								<div className="note-bullet">(ii)</div>
								<div>申请人违反一般条款的任何规定。</div>
							</div>
						</li>
						<li>申请人不得使用或意图使用或允许他人使用任何自动化软件或恶意软件于「网上表格」。</li>
					</ol>
				</li>
				<li>除进行维修外，「网上表格」通常每星期7日每日24小时可供使用。土地注册处会不时在其网站<a target="_blank" href="https://www.landreg.gov.hk/chs/home/index.htm"> http://www.landreg.gov.hk </a>公布「网上表格」可供使用的时间，但也可能在没有事先通知下作出更改。</li>

				<div className="generalTermsSubtitle">网上提交表格</div>
				<li>
					<ol>
						<li>「网上表格」可供网上提交服务申请。</li>
						<li>土地注册处享有绝对酌情权决定可使用「网上表格」在网上提交的服务申请。对于不能网上提交的服务申请，相关表格也可供打印和下载为PDF格式，申请人可将填妥的表格下载及打印为纸本和签署，并按照适用的法例规定及土地注册处现行的做法，将表格以邮寄或电子邮递或亲身交回本处。</li>
					</ol>
				</li>
				<div className="generalTermsSubtitle">电子签署</div>
				<li>「网上表格」接受两种数码签署方式，即由认可核证机关(例如香港邮政或电子核证服务有限公司)发出的个人或机构数码证书及「智方便+」。如网上提交的表格需要签署，申请人须先取得数码证书或登记成为「智方便+」用户，并把「智方便」的流动应用程式安装于其流动电话，以进行数码签署。有关使用「智方便」流动应用程式的系统要求，申请人可参考相关的连结：<a target="_blank" href="https://www.iamsmart.gov.hk/sc/">https://www.iamsmart.gov.hk/sc/</a>。</li>

				<div className="generalTermsSubtitle">付款</div>
				<li>如使用桌面版「网上表格」，土地注册处接受以电子支票、信用卡 (万事达卡 / VISA / 银联 / JCB)、转数快或缴费灵作网上提交服务申请的网上付款方法。如使用流动版，本处则接受以电子支票、信用卡 (万事达卡 / VISA / 银联 / JCB)、转数快、 Apple Pay 或 Google Pay (只限万事达卡 / VISA) 作网上付款方法。如欲知悉更多关于电子支票的资料，申请人应联络其银行或浏览香港金融管理局的网站：<a target="_blank" href="https://www.hkma.gov.hk/gb_chi/key-functions/international-financial-centre/financial-market-infrastructure/other-retail-payment-infrastructure/">https://www.hkma.gov.hk/gb_chi/key-functions/international-financial-centre/financial-market-infrastructure/other-retail-payment-infrastructure/</a>。</li>
				
				<div className="generalTermsSubtitle">申请人的责任</div>
				<li>申请人有责任在「网上表格」提供清晰、完整、正确和真实的资料，并夹附土地注册处指定的一切所需证明文件，如有关资料有任何失实陈述或遗漏，可能导致申请延误或被拒。</li>
				<li>在办理服务申请的过程中提供「网上表格」所需的资料纯属自愿。然而，申请人如没有提供所需资料，土地注册处也许未能处理有关申请。在任何情况下，土地注册处保留不接受任何服务申请的权利。土地注册处并非必须接受任何使用「网上表格」提交的服务申请。</li>
				<li>申请人不得使用或允许他人使用「网上表格」作任何欺诈或非法用途或活动。</li>
				<li>申请人同意就以下情况引起或造成的申索、损害赔偿、损失及法律责任向土地注册处作出弥偿，并使土地注册处获得弥偿：
					<ol>
						<li>申请人使用「网上表格」；或</li>
						<li>申请人违反一般条款的任何规定。</li>
					</ol>
				</li>
				<li>申请人明白无论因任何原因未能使用「网上表格」，申请人不会获免除任何法例所订明的责任。</li>
				
				<div className="generalTermsSubtitle">声明和责任限制</div>
				<li>土地注册处不保证传送往来土地注册处的资料、讯息或指示的在线网络的可靠性。如因通讯设施发生故障或失灵或任何其他原因导致任何资料、讯息或指示的传送、接收或处理延误或失败，土地注册处或其职员或雇员无须承担任何因此而承受或引致的损失、收费、费用、开支、损害赔偿和法律责任。</li>
				<li>土地注册处保留绝对酌情权修改、更改或中断「网上表格」的设置、功能及提供而无需事先发出通知。土地注册处不会就「网上表格」的更改、暂停或中断使用对申请人或任何第三者承担任何责任。</li>
				<li>土地注册处就提供「网上表格」并没有任何种类的保证，也不会构成或产生土地注册处的任何种类的保证或其他责任。土地注册处不会就其网页的「网上表格」的提供或使用所引致或与其相关的损失负责。土地注册处保留绝对酌情权随时省略、暂停或编辑「网上表格」而无需给予任何理由或事先发出通知。申请人须就「网上表格」的所载的资料自行评估。</li>
				<li>土地注册处不会就任何因申请人直接或间接接达或使用「网上表格」而引致的损毁或申索或损失负责，当中包括但不限于申请人的硬件及／或软件的损毁或储存在内的资料的损失或损毁。</li>
				
				<div className="generalTermsSubtitle">其他事宜</div>
				<li>若对「网上表格」有任何查询或意见，请以电邮(电邮地址：<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>)与土地注册处联络。</li>
				<li>一般条款须受香港特别行政区的法律规管。</li>
				<li>一般条款的英文版和中文版如有任何抵触或含糊之处，以英文版为准。</li>
			</ol>
		</>
	],

	GcisPayment: GCIS_PAYMENT,
	
	broadcastMessage: form => window.WFP_CONFIG.BROADCAST_MSG_HTML_SC ? window.WFP_CONFIG.BROADCAST_MSG_HTML_SC(form) : null,
	broadcastMessageContent: (index) => window.WFP_BROADCAST.broadcastList[index].SC ?? null,
	ios_online_disabled_title: '网上递交申请未能支援iOS装置',
	ios_online_disabled_content: '网上递交订购｢物业把关易｣申请現时未能支援iOS装置。如你想在网上递交申请，请改用个人电脑或安卓(Android)装置递交申请。如有查询，请致电土地注册处客户服务热线 3105 0000。',
}