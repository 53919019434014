import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../layout/core/reducers/wfp-core-store.type';
import { Payment, TextKey } from '../../../payment.proxy';
import { useGetPaymentData } from '../../../hooks';
import { formatTotalAmount } from './format-total-amount.util';
import { Order, PaymentData } from '../../../entities';

export function TransactionPanel() {
    const transactionId = useSelector(
        (state: RootState) => state.payment?.transactionData?.transactionId
    );
    const language = useSelector((state: RootState) => state.session.language);

    const { order = new Order() } = useGetPaymentData() || new PaymentData();
    const { totalAmount, currency } = order;

    const total = formatTotalAmount({ total: totalAmount, currency });
    return (
        <div className="transactionGroup list-group">
            <div className="list-group-item">
                <div className="row">
                    <div className="col-xs-5 col-sm-4 col-md-4">
                        <strong>
                            <span id="transactionLabel">{language.paymentTxnNum}:</span>
                        </strong>
                    </div>
                    <div className="col-xs-7 col-sm-8 col-md-8">
                        <span id="transactionId">{transactionId}</span>
                    </div>
                </div>
            </div>
            <div className="list-group-item">
                <div className="row">
                    <div className="col-xs-5  col-sm-4 col-md-4">
                        <strong>
                            <span id="totalAmtLabel">{Payment.getText(TextKey.TOTAL_AMOUNT)}</span>
                        </strong>
                    </div>
                    <div className="col-xs-7 col-sm-8 col-md-8">
                        <span id="totalAmt">{total}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
