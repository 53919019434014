import { waitForWithBreak } from '../../utilities';
import { pollStatus as api } from '../api-wfp/services';
import { POLLING_CANCELLED } from '../constants';
import { PaymentStatusCode } from '../types';

export const pollStatus = (transactionId: string) => (value?: { windowRef?: Window }) => {
    const { windowRef } = value || {};
    const getStatus = async () => {
        const res = await api(transactionId).catch(() => {}); // Allow polling to continue for occasional network errors
        if (res && res.status && res.status !== PaymentStatusCode.IN_PROCESS) {
            windowRef?.close();
            return res;
        }
        return null;
    };
    return waitForWithBreak(getStatus, 2000, POLLING_CANCELLED);
};
