import { useSelector } from 'react-redux';
import { RootState } from '../reducers/wfp-core-store.type';

export function useIsSigned() {
    const { signedFields } = useSelector((store: RootState) => store.formDetail);
    
    if (signedFields && Object.keys(signedFields).length > 0) {
        return true;
    }
    else {
        return false;
    }
}