import { FormContext, Language } from './types';
import { ALLOW_ESUBM } from '../../../../utilities/constant';
import { GCIS_PAYMENT_HANDLER } from '../../../../gcis-payment/constants';
import { isGcisPaymentSetToDisable } from './is-gcis-payment-set-to-disable.util';
import { isDuringDisablePeriod } from '../../../../gcis-payment/utils';

type Input = {
    formContext: FormContext;
    language: Language;
    formData: FormData;
};

export function getOnlinePaymentMethods(input: Input) {
    const { formContext, language, formData } = input;
    const submissionType = formContext.submissionType
        ? formContext.submissionType(formData)
        : ALLOW_ESUBM;
    const onlinePayments =
        submissionType !== ALLOW_ESUBM
            ? []
            : formContext.formLocalization[formContext.onlinePaymentList] ??
              language.field_onlinePayment_list;

    if (isGcisPaymentSetToDisable() && isDuringDisablePeriod()) {
        const idx = onlinePayments.findIndex(
            (pm: any) => pm?.meta?.handler === GCIS_PAYMENT_HANDLER
        );
        if (idx !== -1) {
            onlinePayments.splice(idx, 1);
        }
    }
    return onlinePayments;
}
