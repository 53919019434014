import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { FormContext } from '../layoutElements';
import { RootState } from '../reducers/wfp-core-store.type';
import { OFFLINE_SUBM } from '../../../utilities/constant';

export function useIsOnlineSubmit() {
    const { form } = useContext(FormContext);
    const { formData } = useSelector((store: RootState) => store.formDetail);
    const isOfflineSubmit =
        //@ts-ignore
        form.submissionType && form.submissionType(formData) === OFFLINE_SUBM;
    return !isOfflineSubmit;
}
