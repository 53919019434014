import { Period } from './period.type';

import { EngTimeHelper } from './english.time-helper';
import { SimpChnTimeHelper } from './simplified-chinese.time-helper';
import { TradChnTimeHelper } from './traditional-chinese.time-helper';
import { WebsiteLocale } from '../website-locale.enum';

export function formatPeriod(p: Period, language: string) {
    switch (language) {
        case WebsiteLocale.TRADITIONAL_CHINESE:
            return TradChnTimeHelper.format(p);
        case WebsiteLocale.SIMPLIFIED_CHINESE:
            return SimpChnTimeHelper.format(p);
        case WebsiteLocale.ENGLISH:
        default:
            return EngTimeHelper.format(p);
    }
}
