// adapted from LR152C Adobe XFA form version 2017 -> myMFI module

/////////////////////////////////////////////////////////////////////////////////////
//  mfiCheckDigit will generate one char (0 - 9) from input mfi as followings:     //
//  (1) use SHA-1 method to hash the input mfi                                     //
//  (2) take the first 2 bytes from the hash value (HEX) and convert it to DEC     //
//  (3) calculate the check digit = MOD result in (2) by 10                        //
/////////////////////////////////////////////////////////////////////////////////////

const chrsz   = 8;  /* bits per input character. 8 - ASCII; 16 - Unicode      */

//////////////////////////////////////////////////////////////////////////////
//  The following functions are copied from internet for SHA-1 calculations //
//////////////////////////////////////////////////////////////////////////////


const hex_sha1 = (s) => {return binb2hex(core_sha1(str2binb(s),s.length * chrsz));}

// const sha1_vm_test = () => (hex_sha1("abc") == "a9993e364706816aba3e25717850c26c9cd0d89d");

const core_sha1 = (x, len) =>
{
    x[len >> 5] |= 0x80 << (24 - len % 32);
    x[((len + 64 >> 9) << 4) + 15] = len;

    var w = Array(80);
    var a =  1732584193;
    var b = -271733879;
    var c = -1732584194;
    var d =  271733878;
    var e = -1009589776;

    for(var i = 0; i < x.length; i += 16)
    {
        var olda = a;
        var oldb = b;
        var oldc = c;
        var oldd = d;
        var olde = e;

        for(var j = 0; j < 80; j++)
        {
        if(j < 16) w[j] = x[i + j];
        else w[j] = rol(w[j-3] ^ w[j-8] ^ w[j-14] ^ w[j-16], 1);
        var t = safe_add(safe_add(rol(a, 5), sha1_ft(j, b, c, d)),
                        safe_add(safe_add(e, w[j]), sha1_kt(j)));
        e = d;
        d = c;
        c = rol(b, 30);
        b = a;
        a = t;
        }

        a = safe_add(a, olda);
        b = safe_add(b, oldb);
        c = safe_add(c, oldc);
        d = safe_add(d, oldd);
        e = safe_add(e, olde);
    }
    return [a, b, c, d, e];
}

const sha1_ft = (t, b, c, d) =>
{
    if(t < 20) return (b & c) | ((~b) & d);
    if(t < 40) return b ^ c ^ d;
    if(t < 60) return (b & c) | (b & d) | (c & d);
    return b ^ c ^ d;
}

const sha1_kt = (t) =>
{
    return (t < 20) ?  1518500249 : (t < 40) ?  1859775393 :
            (t < 60) ? -1894007588 : -899497514;
}

const safe_add = (x, y) =>
{
    var lsw = (x & 0xFFFF) + (y & 0xFFFF);
    var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xFFFF);
}

const rol = (num, cnt) =>
{
    return (num << cnt) | (num >>> (32 - cnt));
}

const str2binb = (str) =>
{
    var bin = [];
    var mask = (1 << chrsz) - 1;
    for(var i = 0; i < str.length * chrsz; i += chrsz)
        bin[i>>5] |= (str.charCodeAt(i / chrsz) & mask) << (32 - chrsz - i%32);
    return bin;
}

const binb2hex = (binarray) =>
{
    var hex_tab = "0123456789abcdef";
    var str = "";
    for(var i = 0; i < binarray.length * 4; i++)
    {
        str += hex_tab.charAt((binarray[i>>2] >> ((3 - i%4)*8+4)) & 0xF) +
            hex_tab.charAt((binarray[i>>2] >> ((3 - i%4)*8  )) & 0xF);
    }
    return str;
}
//////////////////////////////////////////////////////////////////////////////
//  End of SHA-1 functions                                                  //
//////////////////////////////////////////////////////////////////////////////

const mfiCheckDigit = (mfi) => {
    return parseInt(hex_sha1(mfi).substring(0,2),16) % 10;
};

const verifyMfiCheckDigit = (mfi) => {
    let mfiBody = mfi.substring(0,7);
    let checkDigit = mfi.substring(7);
    return mfiCheckDigit(mfiBody).toString() === checkDigit;
};

export const mfiHelper = {
    verifyMfiCheckDigit: verifyMfiCheckDigit,
    mfiCheckDigit: mfiCheckDigit,
};

export default mfiHelper; 