import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector, useDispatch } from 'react-redux';
import { acceptTAndC, setFingerprint } from './core/reducers/wfpSessionReducer';
import lo from 'lodash';
import Button from './component/button';
import fingerprint from 'fingerprintjs2';
import moment from 'moment';

export default ({ props }) => {
	const session = useSelector(store => store.session, lo.isEqual);
	const {language} = session;
	const dispatch = useDispatch();

	useEffect(() => {
        const timestamp = window.localStorage.getItem("acceptTC");
        if (window.requestIdleCallback) {
            requestIdleCallback(function () {
                fingerprint.get(function (components) {
                  dispatch(setFingerprint(components)); // an array of components: {key: ..., value: ...}
                })
            })
        } else {
            setTimeout(function () {
                fingerprint.get(function (components) {
                  dispatch(setFingerprint(components)) // an array of components: {key: ..., value: ...}
                })  
            }, 500)
        }
        // if terms was accepted on same day, no need to be accepted again 
        if (timestamp && (moment(timestamp)).isSame(moment(), 'day')) {
            dispatch(acceptTAndC(timestamp));
        }
	}, [dispatch]);

	return (
		<Row className="body wfpRootContainer">
			<div className="topNavBar">
				<h1 className="formTitle">
					{ language.title_TandC }
				</h1>
			
			</div>
            <Row className="contentPane">
                <Col className="inputForm">
                    <Button label="Accept and Continue" onClick={() => dispatch(acceptTAndC())} /> 
                </Col>
            </Row>
        </Row>
	);
};