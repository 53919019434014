import { ADDRESS_TYPE_FIELD_KEY, LREAL1_CODE, PropertyType } from './eal1_const';
import Locale from './localization/locale.en';

function getIndexOfBuildInDropdown() {
    const arr = Locale?.Eng?.[ADDRESS_TYPE_FIELD_KEY] || [];
    return arr.findIndex((item) => item.value === PropertyType.BUILD);
}

function getIndexOfLotInDropdown() {
    const arr = Locale?.Eng?.[ADDRESS_TYPE_FIELD_KEY] || [];
    return arr.findIndex((item) => item.value === PropertyType.LOT);
}

class AddressType {
    type?: number;
    isChosen: boolean;
    isLot: boolean;
    isBuild: boolean;

    constructor(type: string | number | undefined) {
        if (type !== undefined) {
            this.type = typeof type === 'string' ? parseInt(type) : type;
        }
        this.isChosen = this.type !== undefined;
        this.isLot = this.type == getIndexOfLotInDropdown();
        this.isBuild = this.type == getIndexOfBuildInDropdown();
    }
}
class Parsed {
    formData: Record<string, any>;
    addressType: AddressType;

    constructor(formData: Record<string, any>) {
        this.formData = formData;
        this.addressType = new AddressType(this.formData.Address_of_Property);
    }
}

export function parse(formData: Record<string, any>) {
    return new Parsed(formData);
}

export function isLREAL1(form?: Record<string, any>) {
    const bool = form?.code === LREAL1_CODE;
    return bool;
}
