import React from 'react';
import { Payment, TextKey } from '../../../payment.proxy';

export function Loader() {
    return (
        <>
            <div className="loader gcis-payment offset-md-3"></div>
            <span className="loading sr-only">{Payment.getText(TextKey.LOADING)}</span>
        </>
    );
}
