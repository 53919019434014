import { Expose, Transform } from 'class-transformer';
import { PaymentStatusCode, RejectReasonCode, ResultCode } from '../types';

export class Result {
    constructor(input: Partial<Result> = {}) {
        Object.assign(this, input);
    }
    code: ResultCode; // Response Code in PSDR

    @Expose({ name: 'paymentstatuscode' })
    paymentStatusCode: PaymentStatusCode; // Payment Status Code in PSDR. Postive is APPR

    description: string;

    @Expose({ name: 'rejectreasoncode' })
    rejectReasonCode: RejectReasonCode;

    @Expose({ name: 'canretry' })
    @Transform(({ value }) => value == 'Y', { toClassOnly: true })
    canRetry: boolean;
}
