import React, { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { FormContext } from '../../../../layout/core/layoutElements';
import { FormContext as TFormContext } from '../../../../layout/core/utilities/form-context/types';

type Props = {
    disabledPaymentMethods: string[];
    loading: boolean;
};

export function PayByHeader(props: Props) {
    const { disabledPaymentMethods, loading } = props;
    const { formLocalization } = useContext<TFormContext>(FormContext);
    if (loading) return null;
    if (disabledPaymentMethods.length <= 0) return null;

    return (
        <Alert variant="warning">
            {formLocalization?.formatString(
                formLocalization?.GcisPayment?.PAYMENT_DISABLED_MESSAGE_TEMPLATE,
                disabledPaymentMethods.join(', ')
            )}
        </Alert>
    );
}
