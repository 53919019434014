import { useSelector } from 'react-redux';
import { PaymentMethod } from '../enums';

import { DbPaymentMethod } from '../api-wfp/enums';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';

export function useGetFinalSummary() {
    const {
        referenceNumber,
        submissionTime,
        paymentTxnNum,
        paymentMethod: dbPaymentMethod,
        gcisWallet: dbGcisWallet,
        paymentAmount,
    } = useSelector((state: RootState) => state.formDetail);

    const gcisWallet = dbPaymentMethodToPaymentMethod(dbGcisWallet);
    const paymentMethod = dbPaymentMethodToPaymentMethod(dbPaymentMethod);

    return {
        transactionId: paymentTxnNum,
        paymentMethod,
        gcisWallet,
        referenceNumber,
        submissionTime,
        paymentAmount,
    };
}

function dbPaymentMethodToPaymentMethod(subtype: DbPaymentMethod): PaymentMethod | void {
    switch (subtype) {
        case DbPaymentMethod.PPS:
            return PaymentMethod.PPS;
        case DbPaymentMethod.E_CHEQUE:
            return PaymentMethod.E_CHEQUE;
        case DbPaymentMethod.VISA:
            return PaymentMethod.VISA;
        case DbPaymentMethod.UNION_PAY:
            return PaymentMethod.UNION_PAY;
        case DbPaymentMethod.JCB:
            return PaymentMethod.JCB;
        case DbPaymentMethod.MASTER_CARD:
            return PaymentMethod.MASTER_CARD;
        case DbPaymentMethod.ANDROID_PAY:
            return PaymentMethod.ANDROID_PAY;
        case DbPaymentMethod.APPLE_PAY:
            return PaymentMethod.APPLE_PAY;
        case DbPaymentMethod.FPS:// 202309
            return PaymentMethod.FPS;
    }
}
