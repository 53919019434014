import axios from 'axios';
import { API_PREFIX } from '../../config';
import { onSuccess } from './on-success.interceptor';
import { onRequest } from './on-request.interceptor';

export const instance = axios.create({
    baseURL: API_PREFIX,
});

instance.interceptors.response.use(onSuccess);

instance.interceptors.request.use(onRequest);
