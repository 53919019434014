import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getTransactionData } from '../utils';
import {
    useShowCaptcha,
    useShowPaymentOptions,
    useShowErrorWithCallback,
    useShowGeneralError,
} from '../hooks-dialog';
import { TransactionData } from '../entities';

import { isCaptchaError, statusCodeToErrorKey } from '../api-wfp/utils';
import { upsertTransactionData } from '../redux/slice';
import { updateFormData } from '../../layout/core/reducers/formDetail';
import { RootState } from '../../layout/core/reducers/wfp-core-store.type';

export function useGetTransactionData(): {
    loading: boolean;
    transactionData: TransactionData;
} {
    const [loading, setLoading] = useState(false);
    const transactionData = useSelector((state: RootState) => state.payment.transactionData);
    const paymentData = useSelector((state: RootState) => state.payment.paymentData);
    const submissionId = useSelector((state: RootState) => state.payment.submissionId);

    const dispatch = useDispatch();
    const showGeneralError = useShowGeneralError();
    const showErrorWithCallback = useShowErrorWithCallback();
    const showCaptcha = useShowCaptcha();
    const showPaymentOptions = useShowPaymentOptions();

    useEffect(() => {
        // Reuse transaction data (id) if it is valid and unused
        if (transactionData && transactionData.isValid() && !transactionData.isUsed()) return;
        if (!paymentData || !paymentData.isValid()) return;

        const api = () => {
            setLoading(true);
            return getTransactionData(paymentData.order.totalAmount, submissionId)
                .then((transactionData) => {
                    if (transactionData.isValid()) {
                        dispatch(upsertTransactionData(transactionData));
                        const { transactionId } = transactionData;
                        dispatch(updateFormData(null, null, { gcisTxnId: transactionId }));
                    } else {
                        showGeneralError();
                    }
                })
                .catch(errHandling)
                .then(() => {
                    setLoading(false);
                });
        };

        const errHandling = (err: any) => {
            const statusCode = err?.response?.status;
            if (isCaptchaError(statusCode)) {
                const errKey = statusCodeToErrorKey(statusCode);
                const afterCaptcha = () => api().then(() => showPaymentOptions());
                showErrorWithCallback(errKey)(() => showCaptcha(afterCaptcha));
            } else {
                showGeneralError();
            }
        };

        api();
    }, [paymentData, transactionData]);

    return { loading, transactionData };
}
