import { getOfflinePaymentMethods } from './get-offline-payment-methods.util';
import { getOnlinePaymentMethods } from './get-online-payment-methods.util';
import { FormContext, FormData, Language } from './types';

type Input = {
    formContext: FormContext;
    formData: FormData;
    language: Language;
};

export function extractPaymentMethods(input: Input): any[] {
    const { formContext, formData, language } = input;

    let payMethods: any[] = [];
    if (!formContext.form.paymentRequired) {
        return payMethods;
    }

    if (formContext.form.customizedPaymentMethod) {
        payMethods = formContext.form.customizedPaymentMethod(formData);
    } else {
        const opm = getOnlinePaymentMethods(input);
        const ofpm = getOfflinePaymentMethods({ formContext, language });

        for (let i = 0; i < opm.length; i++) {
            payMethods.push({ ...opm[i], value: i - opm.length + '' });
        }
        for (let i = 0; i < ofpm.length; i++) {
            payMethods.push({ ...ofpm[i], value: i + '' });
        }
    }

    return payMethods;
}
