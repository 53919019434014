import { BasePremisesAddress } from './base-premises-address.entity';
import { EngDistrict, EngRegion } from '../../../enums';
import { EngEstate } from '../estate';
import { Street } from '../low-level';
import { Expose, Transform, Type } from 'class-transformer';
import { Block } from '../block.entity';
import { EngThreeDAddress } from '../three-d-address';
import { Stringable } from '../../types';
import { joinString } from '../../../../utilities/commonUtil';

export class EngPremisesAddress extends BasePremisesAddress {
    @Expose({ name: 'EngBlock' })
    @Type(() => Block)
    block: Block;

    @Expose({ name: 'EngDistrict' })
    @Type(() => DistrictWrapper)
    district: DistrictWrapper;

    @Expose({ name: 'EngEstate' })
    @Type(() => EngEstate)
    estate: EngEstate;

    @Expose({ name: 'EngStreet' })
    @Type(() => Street)
    street: Street;

    @Expose({ name: 'Region' })
    @Transform(({ value }) => new EngRegionWrapper(value))
    @Type(() => EngRegionWrapper)
    region: EngRegionWrapper;

    @Expose({ name: 'Eng3dAddress' })
    @Type(() => EngThreeDAddress)
    threeDAddress?: EngThreeDAddress[];
    
    getObject() {
        const blockDescArr = this.block?.isDescriptorGoFirst?
        {
            blockDesc : this.block?.descriptor??"",
            blockNum : this.block?.number??""
        }
        :
        {
            blockNum : this.block?.number??"",
            blockDesc : this.block?.descriptor??""
        }
        const obj = {
            "buildingName": this.buildingName?.getString()??"",
            ...blockDescArr,
            "estate": this.estate?.getString()??"",
            "streetNumber": this.street?.getStreetNumber()??"",
            "streetName": this.street?.getEnglishStreetName()??"",
            "district": this.district?.getString()??"",
            "region": this.region?.getString()??"",
        }

        return obj;
    }

    getString() {
        const fragments = this.getObject();
        const _addressValues = Object.values(fragments).filter((s) => !!s);
        return joinString(_addressValues, " ");
    }

    getDevelopmentName() {
        return joinString([this.buildingName?.getString(), this.estate?.getString()], ", ");
    }
}

class DistrictWrapper implements Stringable {
    @Expose({ name: 'DcDistrict' })
    district: EngDistrict;

    getString() {
        // console.log('this.district', this.district);
        return (this.district as any as string);
    }
}

class EngRegionWrapper implements Stringable {
    region: EngRegion;
    constructor(region: keyof typeof EngRegion) {
        this.region = EngRegion[region];
    }

    getString() {
        return this.region;
    }
}
