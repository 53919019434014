import { plainToInstance } from 'class-transformer';
import { PaymentApiUrl } from '../../../constants';
import { instance } from '../../instance';
import { GetTransactionIdResponse as Response } from './response';

const url = PaymentApiUrl.GET_TRANSACTION_ID;

export async function getTransactionId(): Promise<Response> {
    const data = await instance.get(url);
    return plainToInstance(Response, data);
}
