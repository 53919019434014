import React from 'react';

import '../../css/input.css'

export default class Button extends React.Component {

	handleChange=e=>{
		this.props.onChange(e.target.value);
	}

	render() {

		let { onClick, buttonClass, alignLeft ,label, isPrimary, render, disabled } = this.props;
		
		buttonClass = buttonClass + " btn normalButton normalFont";
		buttonClass = buttonClass + (alignLeft==="true" ? " normalButton_Left" : "");
		buttonClass = buttonClass + (isPrimary==="true" ? " primaryButton" : "")

		if (render==="true") {
			return (
				<button type="button" className={buttonClass} onClick={onClick} disabled={disabled}>{label}</button>
			);
		}
		else {
			return null;
		}
	
	}
}

Button.defaultProps = {
	render: "true",
	isPrimary: "true",
	buttonClass: "",
	disabled: undefined
}
