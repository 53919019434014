export enum TcDistrict {
    '中西區',
    '東區',
    '離島區',
    '九龍城區',
    '葵青區',
    '觀塘區',
    '北區',
    '西貢區',
    '沙田區',
    '深水埗區',
    '南區',
    '大埔區',
    '荃灣區',
    '屯門區',
    '灣仔區',
    '黃大仙區',
    '油尖旺區',
    '元朗區',
}
