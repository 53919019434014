import React from 'react';
import { Row } from 'react-bootstrap';
import { FaLaptop } from 'react-icons/fa';

export default ({language, formScreenId, formStepIndex}) => {
    return (
    
        <Row className=" footerBackground" style={{fontSize: "small",clear: "both",width:"90%",maxWidth:1200,alignItems: "center",display: "inline-flex"}}>
            <div style={{width:"50%"}}>&copy; 2021&emsp;
            </div>
            <div style={{width:"50%",position:"relative"}}>
                <div style={{padding:"15px 0px", textAlign:"right"}}><FaLaptop alt={language.screenId} /> WFP-{formScreenId}-{("00" + (formStepIndex+1)).substr(-2)}</div>
                    
            </div>
        </Row> 
    );
}