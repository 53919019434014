import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { onSuccess } from './on-success.interceptor';
import { onError } from './on-error.interceptor';
import {
    CardType,
    Currency,
    MessageType,
    PaymentMethodCode,
    PaymentMethodSubtype,
} from '../../enums';
import { PaymentApiUrl } from '../../constants';
import { onRequest } from './on-request.interceptor';

export const instance = axios.create();

instance.interceptors.response.use(onSuccess, onError);

instance.interceptors.request.use(onRequest);

export const mock = new MockAdapter(instance, { delayResponse: 100 });

mock.onGet(PaymentApiUrl.GET_TRANSACTION_ID).reply(200, {
    transactionid: 'mock-trans-id',
    webtoken: 'mock-token',
});

mock.onPost(new RegExp(`${PaymentApiUrl.PAY_ONLINE}/*`)).reply(200, {
    transactionid: 'mock-trans-id',
    redirecturl: 'mock-redirect-url',
});

mock.onPost(new RegExp(`${PaymentApiUrl.PAY_BY_WALLET}/*`)).reply(200, {
    transactionid: 'mock-trans-id',
    result: {
        code: 'mock-code',
        description: 'mock-desc',
    },
});

mock.onPost(PaymentApiUrl.QUERY_PAYMENT_METHOD).reply(200, {
    availablepaymentmethods: [
        {
            code: PaymentMethodCode.APPLE_PAY,
            subtype: PaymentMethodSubtype.APPLE_PAY,
            active: 'Y',
            pointstonote: [
                {
                    type: MessageType.INFO,
                    content: 'apple-pay-content',
                    order: 1,
                },
            ],
            supportedcard: [CardType.MASTER_CARD],
        },
        {
            code: PaymentMethodCode.ANDROID_PAY,
            subtype: PaymentMethodSubtype.ANDROID_PAY,
            active: 'Y',
            pointstonote: [
                {
                    type: MessageType.INFO,
                    content: 'android-pay-content',
                    order: 1,
                },
            ],
            supportedcard: [CardType.MASTER_CARD],
        },
        {
            code: PaymentMethodCode.CREDIT_CARD,
            subtype: PaymentMethodSubtype.CREDIT_CARD,
            active: 'Y',
            pointstonote: [
                {
                    type: MessageType.INFO,
                    content: 'creditcard-content',
                    order: 1,
                },
            ],
            supportedcard: [CardType.MASTER_CARD, CardType.JCB, CardType.VISA],
        },
        {
            code: PaymentMethodCode.UNION_PAY,
            subtype: PaymentMethodSubtype.CREDIT_CARD,
            active: 'Y',
            pointstonote: [
                {
                    type: MessageType.INFO,
                    content: 'unionpay-content',
                    order: 1,
                },
            ],
            supportedcard: [CardType.UNION_PAY],
        },
        {
            code: PaymentMethodCode.PPS,
            subtype: PaymentMethodSubtype.PPS,
            active: 'Y',
            pointstonote: [
                {
                    type: MessageType.INFO,
                    content: 'pps-content',
                    order: 1,
                },
            ],
        },
    ],
});

mock.onPost(PaymentApiUrl.START_APPLE_PAY).reply(200, {
    epochTimestamp: 100,
    merchantSessionIdentifier: 'mock-session-identifie',
    nonce: 'mock-nonce',
    mechantIdentifier: 'mock-merhcant-identifier',
    domainName: 'mock-domain-name',
    displayName: 'mock-display-name',
    signature: 'mock-signature',
});

mock.onPost(PaymentApiUrl.UPDATE_ONLINE_PAYMENT).reply(200, {
    transactionid: 'mock-transId',
    currencycode: Currency.HKD,
    amount: 100,
    paymentid: 'mock-payId',
    time: 10,
    brandname: 'mock-brand',
    result: {
        code: 'mock-code',
        description: 'mock-desc',
    },
});
