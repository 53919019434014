export function statusCodeToErrorKey(statusCode: number): string {
    switch (statusCode) {
        case 777:
            return 'captchaError';
        case 778:
            return 'captchaRequired';
        case 779:
            return 'captchaExpired';
        case 780:
            return 'captchaNotMatch';
        default:
        case 502:
            return 'serverBusy';
    }
}
