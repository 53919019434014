import React, { useContext } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useSelector } from 'react-redux';

import { FormContext, MessagePane } from '../../layoutElements';
import GeneralModal from '../../../component/Modal/generalModal';

import { FormContext as TFormContext } from '../../utilities/form-context/types';
import { RootState } from '../../reducers/wfp-core-store.type';
import { SideMenu } from './side-menu';
import { Footer } from './footer/footer';

type Props = {
    menuExpanded: any;
    setMenuExpanded: any;
    validationErrMsg: any;
    isSavePrintValErr: any;
    changeStep: (toStepIdx: number, forced:boolean, pdfIsLoaded:boolean) => void;
    onSave: () => void;
    onPrint: () => void;
    performClear: () => void;
    performComplete: (formCode: string) => void;
    performSubmitForm: () => void;
    hasError: () => boolean;
};

export function Inner(props: Props) {
    const {
        menuExpanded,
        setMenuExpanded,
        validationErrMsg,
        isSavePrintValErr,
        changeStep,
        onSave,
        onPrint,
        performClear,
        performComplete,
        performSubmitForm,
        hasError,
    } = props;

    const { language } = useSelector((store: RootState) => store.session);
    const { showDialog, dialogOpts } = useSelector((store: RootState) => store.dialogReducer);

    const { steps, stepIdx, form } = useContext<TFormContext>(FormContext);

    const { intro } = form;

    const currentStep = steps[stepIdx];

    return (
        <>
            <Row>
                <SideMenu menuExpanded={menuExpanded} setMenuExpanded={setMenuExpanded} changeStep={changeStep} />
                <Col id="stepBody" className="col-xl-8">
                    {validationErrMsg.length > 0 ? (
                        <MessagePane id="inputValidationMsgPane" mode="danger" hideWhen={false}>
                            {isSavePrintValErr
                                ? language.savePrintValidationError
                                : language.correctValidationError}
                            <div style={{ paddingTop: '10px', color: '#C90000' }}>
                                {validationErrMsg}
                            </div>
                        </MessagePane>
                    ) : null}
                    <div id="stepBody-form">
                        {React.createElement(currentStep.content, {
                            language: language,
                            introBody: React.createElement(intro),
                        })}
                    </div>
                    <div>
                        {!steps[stepIdx].hideNavigation && (
                            <Footer
                                onPrint={onPrint}
                                onSave={onSave}
                                performClear={performClear}
                                performComplete={performComplete}
                                performSubmitForm={performSubmitForm}
                                hasError={hasError}
                            />
                        )}
                    </div>
                </Col>
            </Row>
            <GeneralModal show={showDialog} {...dialogOpts} />{' '}
        </>
    );
}
