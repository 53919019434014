import { PaymentLocale, PaymentMethodCode } from '../enums';
import { Payment } from '../payment.proxy';

import { TransactionData } from './transaction-data.entity';
import { Order } from './order.entity';
import { Expose } from 'class-transformer';
import { PaymentData } from './payment-data.entity';

export class CombinedData {
    constructor(input: Partial<CombinedData> = {}) {
        Object.assign(this, input);
    }

    @Expose({ name: 'transactionid' })
    transactionId: string;

    @Expose({ name: 'webtoken' })
    webToken: string;

    @Expose({ name: 'paymentmethod' })
    paymentMethod: PaymentMethodCode;

    order: Order;

    locale: PaymentLocale;

    @Expose({ name: 'returnurl' })
    returnUrl: string;

    @Expose({ name: 'eserviceid' })
    eServiceId: string;

    label?: string;

    static fromPaymentTransactionData(
        paymentData: PaymentData,
        transactionData: TransactionData
    ): CombinedData {
        const combined = new CombinedData();
        const { transactionId, webToken } = transactionData;

        const domain = Payment.getConfig('domain');
        const ackPath = Payment.getConfig('ackPagePath');
        const returnUrl = `${domain}${ackPath}?t=${Date.now()}`;

        Object.assign(combined, paymentData, { transactionId, webToken }, { returnUrl });
        return combined;
    }

    setLocale(locale: PaymentLocale) {
        this.locale = locale;
    }
}
