import lodash from 'lodash';
import { instanceToPlain } from 'class-transformer';

import { PayByWalletRequest } from './api/services/requests';
import { API_PREFIX } from './config';
import { PAYMENT_CANCELLED } from './constants';
import { CombinedData, PaymentData, TransactionData } from './entities';
import { PaymentLocale, PaymentMethodSubtype } from './enums';
import { isMobile, payment } from './given';

export class Payment {
    static config = {
        locale: PaymentLocale.ENGLISH,
        ackPagePath: API_PREFIX + '/callback',
        PPS_MAX_AMOUNT: 100000,
        UNION_PAY_MAX_AMOUNT: 300000,
    };

    static getText(key: string) {
        const locale = Payment.getLocale();
        const msgLocale = Payment.getMsgLocale(locale);
        return Payment.getConfig(['msg', msgLocale, key]) || ' NOT FOUND ';
    }

    static getMsgLocale(locale: PaymentLocale) {
        switch (locale) {
            case PaymentLocale.CHINESE_TRADITIONAL:
                return 'zh-hk';
            case PaymentLocale.CHINESE_SIMPLIFIED:
                return 'zh-cn';
            case PaymentLocale.ENGLISH:
            default:
                return 'en-us';
        }
    }

    static setLocale(locale: PaymentLocale) {
        Payment.config.locale = locale;
    }

    static getLocale(): PaymentLocale {
        return Payment.config.locale;
    }

    static upsertConfig(override: Partial<typeof Payment.config & typeof payment.config> = {}) {
        Object.assign(Payment.config, override);
    }

    static getConfig(key: string[] | string) {
        return lodash.get(Payment.config, key) || lodash.get(payment.config, key);
    }

    static getPreferredPaymentMethods(): PaymentMethodSubtype[] {
        return [
            PaymentMethodSubtype.VISA,
            PaymentMethodSubtype.MASTER_CARD,
            PaymentMethodSubtype.PPS,
            PaymentMethodSubtype.APPLE_PAY,
            PaymentMethodSubtype.ANDROID_PAY,
            PaymentMethodSubtype.CREDIT_CARD,
            PaymentMethodSubtype.FPS, // 202309
        ];
    }

    static recordAvailablePaymentMethods(data: Record<string, any>) {
        //@ts-ignore
        payment.availablepaymentmethodsJson = data;
    }

    static async isApplePayReady(paymentData: PaymentData, transactionData: TransactionData) {
        const combined = CombinedData.fromPaymentTransactionData(paymentData, transactionData);
        combined.label = Payment.getText('walletPayLabel');
        //@ts-ignore
        if (!window?.ApplePaySession?.canMakePayments()) {
            return false;
        }
        return await payment.appleIsReadyToPay(instanceToPlain(combined));
    }

    static async isGooglePayReady() {
        //@ts-ignore
        payment.googlePaymentsClient = new google.payments.api.PaymentsClient({
            //@ts-ignore
            environment: window.WFP_CONFIG.GOOGLE_PAY_ENV,
        });
        return await payment.googleIsReadyToPay();
    }

    static async applePay(request: PayByWalletRequest) {
        return await new Promise((resolve, reject) =>
            payment.applepayWebPamentAPI(
                request.toRaw(),
                (resp: any) => {
                    resolve(resp.paymentdata);
                },
                (err: Error) => {
                    if (err.name === 'AbortError') {
                        reject(PAYMENT_CANCELLED);
                    }
                    reject(err);
                }
            )
        );
    }

    static async googlePay(request: PayByWalletRequest) {
        return await new Promise((resolve, reject) => {
            payment.googlepay(
                request.toRaw(),
                (resp: any) => {
                    resolve(resp.paymentdata);
                },
                (err: Error) => {
                    if (err.name === 'AbortError') {
                        reject(PAYMENT_CANCELLED);
                    }
                    reject(err);
                }
            );
        });
    }

    static redirectErrorPage() {
        throw Error('Unimplmented');
        // document.location.href = Payment.getConfig('domain') + Payment.getConfig('errPagePath');
    }

    static showErrMsg(msg: string) {
        throw Error('Unimplmented');
        // document.getElementsByTagName('body')[0].innerHTML = '<h2>' + msg + '</h2>';
    }

    // Non proxy functionalities
    static isApplePayExpected() {
        // @ts-ignore
        return window.PaymentRequest && window?.ApplePaySession?.canMakePayments();
    }

    static isGooglePayExpected() {
        // @ts-ignore
        return window.PaymentRequest;
    }

    static isPpsExpected(paymentData?: PaymentData) {
        if (paymentData?.order?.totalAmount > Payment.config.PPS_MAX_AMOUNT) {
            return false;
        }
        return !isMobile();
    }

    static isUnionPayExpected(paymentData?: PaymentData) {
        if (paymentData?.order?.totalAmount > Payment.config.UNION_PAY_MAX_AMOUNT) return false;
        return true;
    }
}

export enum TextKey {
    DATA_NOT_FOUND = 'dataNotFound',
    PAYMENT_NOT_AVAILABLE = 'paymentNotAvailable',
    CLOSE = 'btnClose',
    LOADING = 'loading',
    PAY = 'btnPay',
    TRANSACTION_ID = 'transicationId',
    TOTAL_AMOUNT = 'totalAmount',
}
