import moment from 'moment';
import {dialogHelper} from '../layout/core/dialogHelper'

export default ()=>{}

export const SESSION_TIME_OUT_LIMIT = 15; // Minutes

export var TIMER = null;

const getNextTimeoutTarget = () => { return moment().add(SESSION_TIME_OUT_LIMIT, 'm') }

export const UPDATE_TIMER = (dispatch, language) => {
    updateTimer(getNextTimeoutTarget(), dispatch, language);
}

const updateTimer = (tt, dispatch, language) => {
    const timer = setTimeout(()=>{
        // Wait 1 minute
        if (moment() - tt > 60000) {
            dispatch(dialogHelper.showSessionTimedoutDialog(language));
        }
        else if (moment() - tt > 0) {

            dispatch(dialogHelper.showSessionTimeoutWarningDialog(language, parseInt((tt+60000-moment())/1000) ));
        }
        
        updateTimer(tt, dispatch, language);
    }, 1000);
    
    clearTimeout(TIMER);
    TIMER = timer;
}