import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers/wfp-core-store.type';
import { DefaultButton } from './default-button';

type Props = { onBack: () => void };

export function BackButton(props: Props) {
    const { onBack } = props;
    const language = useSelector((store: RootState) => store.session.language);
    return (
        <DefaultButton id={'backBtn'} onClick={onBack}>
            <FaAngleLeft /> {language.back}
        </DefaultButton>
    );
}
