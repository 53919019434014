import { Expose, Type } from 'class-transformer';
import { PaymentMethodCode, PaymentMethodSubtype } from '../enums';
import { ChannelType } from '../types';
import { Result } from './payment-result.entity';

export class PaymentDetail {
    @Expose({ name: 'paymentid' })
    paymentId: string;

    @Expose({ name: 'paymentmethod' })
    paymentMethod: PaymentMethodCode; // Uncertain

    time: number; // Epoch, ms

    @Expose({ name: 'brandname' })
    brandName: string;

    channel: ChannelType;

    @Expose({ name: 'subtype' })
    subType: PaymentMethodSubtype; // Uncertain

    @Expose({ name: 'attemptno' })
    attemptNo: number;

    @Type(() => Result)
    result: Result;
}
