import React, { useContext } from 'react';
import { FormContext } from '../../../../layout/core/layoutElements';
import { FormContext as TFormContext } from '../../../../layout/core/utilities/form-context/types';
import '../payby.css';

export function WebToAppButton(props: Props) {
    const { redirectUrl, disabled } = props;
    const { formLocalization } = useContext<TFormContext>(FormContext);

    const onClick = () => {
        console.log("redirectUrl: " + redirectUrl);
        window.open(redirectUrl, '_blank');
    };
    
    return (
        <div className={`col-xs-6 col-md-6 fpsQRCodePanel`}>
            <button
                type="button"
                className="btn btn-primary primaryButton fpsQRCodeBtn" 
                onClick={onClick}
                id="btnClose"
                disabled={disabled}>
                <span className="glyphicon glyphicon-remove"></span>{' '}
                <span>{ formLocalization?.GcisPayment?.FPS_PAYMENT_MOBILE_BUTTON }</span>
            </button>
        </div>
    );
}

type Props = {
    redirectUrl?: string;
    disabled?: boolean;
};
