import { Expose, Type } from 'class-transformer';
import { Currency } from '../../../enums';
import { PaymentDetail } from '../../../entities';

export class PayByWalletResponse {
    @Expose({ name: 'transactionid' })
    transactionId: string;

    @Expose({ name: 'currencycode' })
    currency: Currency;

    amount: number;

    @Expose({ name: 'paymentdetail' })
    @Type(() => PaymentDetail)
    paymentDetail: PaymentDetail;
}
