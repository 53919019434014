import { Moment } from "moment";

export function momentToDateParts(m: Moment) {
    // 2023/2/22
    if (!m) return {};

    const [year, month, day] = m.format('YYYY/M/D').split('/');
    return { year, month, day };
}

export function momentToFullDateParts(m: Moment) {
    // 2023/February/22nd
    if (!m) return {};

    const [year, month, day] = m.format('YYYY/MMMM/Do').split('/');
    return { year, month, day };
}

export function momentToDMY(m: Moment) {
    // 22/2/2023
    if (!m) return '';
    const { year, month, day } = momentToDateParts(m);

    if (!year || !month || !day) {
        return '';
    } else {
        return `${day}/${month}/${year}`;
    }
}

export function momentToTimeParts(m: Moment) {
    // 1:02 pm
    if (!m) return {};

    const [hour, minute, period] = m.format('h:mm:A').split(':');
    const isAM = period === 'AM';
    return { hour, minute, isAM };
}