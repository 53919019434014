import axios from 'axios';
import { PAYMENT_BASE_URL } from '../../config';
import { onSuccess } from './on-success.interceptor';
import { onError } from './on-error.interceptor';
import { onRequest } from './on-request.interceptor';

export const instance = axios.create({
    baseURL: PAYMENT_BASE_URL,
});

instance.interceptors.response.use(onSuccess, onError);

instance.interceptors.request.use(onRequest);
