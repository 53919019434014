import { Expose, instanceToPlain, Type } from 'class-transformer';
import { CombinedData, Order } from '../../../entities';
import { OnlineMethodType, PaymentMethodCode } from '../../../enums';

export class PayByFpsRequest {
    constructor(input: Partial<PayByFpsRequest> = {}) {
        Object.assign(this, input);
    }
    @Expose({ name: 'transactionid' })
    transactionId: string;

    @Expose({ name: 'webtoken' })
    webToken: string;

    @Expose({ name: 'paymentmethod' })
    paymentMethod: PaymentMethodCode;

    @Type(() => Order)
    order: Order;

    @Expose({ name: 'eserviceid' })
    eServiceId?: string; // for use cases with more than 1 e-services setup

    type: OnlineMethodType; // Not in API doc

    toRaw() {
        return instanceToPlain(this);
    }

    extractType(): OnlineMethodType {
        const type = this.type;
        delete this.type;
        return type;
    }

    static fromData(combinedData: CombinedData, type: OnlineMethodType) {
        const input = new PayByFpsRequest();
        const { returnUrl, ...rest } = combinedData; // 202309: Removed returnUrl property
        const returnedTarget = Object.assign(input, rest, { type });

        return returnedTarget;
    }
}
