import React from 'react';
import SysParam from '../../../../utilities/sysParam';
import { GCIS_PAYMENT_HANDLER } from '../../../../gcis-payment/constants';
import { GCIS_PAYMENT_TC as GCIS_PAYMENT } from './gcis-payment';

export default {
	loadingPleaseWait: "正在載入...",
	nav_fontSize: "字型大小",
	logoAltText: "香港特別行政區政府 - 土地註冊處",
	title_formMenu: "公用表格",
	title_TandC: "條款及條件",
	requirements: "是項申請所需配套",
	requirements_printer: "打印機",
	requirements_printer_desp: "用於打印表格",
	requirements_reader: "Adobe Reader",
	requirements_reader_desp: "用於檢視 / 打印已填妥及下載的表格",	
	requirements_esign: "電子簽署(如適用)",
	requirements_esign_desp: ()=> "申請人須使用「智方便+」或由香港郵政核證機關／電子核證服務有限公司發出的數碼證書簽署網上表格",
	//requirements_ecertOnly_desp: "申請人須使用由香港郵政核證機關∕電子核證服務有限公司發出的數碼證書簽署網上表格。",
	// requirements_ecert: "由香港郵政核證機關或電子核證服務有限公司發出的數碼證書",
	// requirements_ecert_desp: ()=>"用於以數碼證書簽署及網上遞交表格",
	// requirements_iamsmart: ()=>"已安裝「智方便」及「智方便+」使用者賬戶的流動裝置",
	// requirements_iamsmart_desp: "以「智方便」簽署作網上遞交申請",
	requirements_echeque: "網上付款",
	requirements_echeque_desp: "我們接受繳費靈、信用卡 (萬事達卡／VISA／銀聯／JCB)、轉數快、Apple Pay 或 Google Pay (只限萬事達卡／VISA) 及電子支票用作繳付服務費用",

	documentTitle: "土地註冊處",

	steps_Notes: "重要事項",
	steps_Particulars: "申請詳情",
	steps_UploadECheque: "上載電子支票",
	steps_Confirmation: "覆核",
	steps_Submission:"遞交",
	steps_Signing:"請簽署表格",
	steps_PaymentMethod: "付款方式",
	steps_Payment: "網上付款",

	wantTo: "我想",
	wantTo_startFilling : "填寫新表格",
	wantTo_continueFilling: "繼續填寫表格",
	wantTo_loadSaved: "填寫已儲存的表格",
	
	backToTop: "返回頁首",
	acknowledgement: "確認",
	importantNotes: "簡介",
	mandatoryHint: "必須填寫",
	submissionAcknowledgement: "確認電郵",
	receiveAckEmail:"本人希望收到是次遞交申請的確認電郵。",

	ackEmailIncludeFilledForm:"本人希望收取包含已填妥表格的確認電郵。",
	submissionRecord: "遞交記錄",
	submissionTime: "遞交時間",
	signAndSubmit: "簽署並遞交申請",
	signOnly: "簽署表格",
	successfullySigned: "已成功簽署",
	saveForSubmission: "多謝填寫此表格。請按「下載已簽署的表格」按鈕下載已簽署的表格，以電子郵件方式遞交至本處。",
	printForSubmission: "多謝填寫此表格。請按「{0}」按鈕列印已填妥的表格，以供遞交至本處。",
	printForSubmissionAlt: "多謝填寫此表格。你須親自遞交此申請。請按「{0}」按鈕列印填妥的表格，並親身到土地註冊處客戶服務中心或新界查冊中心辦理申請。",
	printForSubmissionAlt2: "多謝填寫此表格。你須親自遞交此申請。請按「{0}」按鈕列印填妥的表格，並連同相關證明文件，親身到土地註冊處客戶服務中心或新界查冊中心辦理申請。",
	notesSection: "注意事項",

	error: "錯誤",
	generalErrMsg: "系統出現錯誤，請聯絡客戶服務熱線 3105-0000 尋求協助。",
	generalErrMsgWithSubmissionId: "系統出現錯誤，請聯絡客戶服務熱線 3105-0000 尋求協助。編號:{0}",
	correctValidationError: "輸入的資料有錯誤，請修改後繼續。",
	savePrintValidationError: "請修正錯誤，然後再儲存或列印表格。",
	passwordError: "載入表格資料失敗。請檢查你所選擇的是一個表格資料檔，而你提供的密碼是與儲存該表格資料檔時提供的相同。",
	fileNotSelected: "請選擇一個表格資料檔案。",
	captchaMessage: "需要進行人機驗證。請輸入圖中的4個英文字或數字。   ", 
	captchaRequired: "輸入的英文字或數字不正確，請重新輸入。", 
	captchaNotMatch: "輸入的英文字或數字不正確，請重新輸入。",
	captchaError: "輸入的英文字或數字不正確，請重新輸入。",
	captchaExpired: "驗證已逾時。請再次進行人機驗證。",
	captchaRefresh1: "(如果你看不清圖中的英文字/數字，請按“", 
	captchaRefresh2: "” )",
	captchaRetry: "未能取得驗證碼。請按此處重試。",
	
	loadFormCodeError: (sourceType, formRef, formCode) => <span>不能從所選的檔案載入表格資料。因為此檔是由另一表格 (<a target="_blank" href={"/"+sourceType+"/form/"+formCode}>{formRef}</a>) 儲存。</span>,
	loadFormOldVersion: "所選的檔案載有較舊版本的表格資料。部分資料可能未能正確載入。請檢查已載入的資料以確保資料正確。",
	saveCertPasswordError: "儲存表格資料失敗。  數碼證書密碼不正確。",
	savePasswordNotConfirm: "儲存表格資料失敗。輸入的密碼和確認密碼不相同。",
	passwordEmpty: "储儲存表格資料失敗。  未輸入密碼。",
	passwordWrongLength: "儲存表格資料失敗。  輸入的密碼必須介乎 {0} 至 {1} 個字元之間。",

	chooseAFileToUpload: "選擇要上傳的檔案",
	acceptFileFormat: "接受檔案格式",
	sizeUpTo: "最大檔案大小為 ",

	captcha:"驗證碼",
	clear:"清除",
	new:"新表格",
	save: "儲存",
	saveAgain: "請再次列印",
	saveSignedForm: "下載已簽署的表格",
	load: "載入",
	return: "返回",
	returnToFormList: "返回表格列表",
	print: "列印",
	loadForm: "開啟已儲存的表格",
	providePwECert: "請輸入密碼以儲存表格資料檔。",
	dontForgetPw: "注意：請保存密碼，以載入已儲存的表格資料檔。",
	back: "返回",
	next: "繼續",
	close: "關閉",
	zoomIn: "放大",
	zoomOut: "縮小",
	confirm: "確認",
	footNote: "備註",
	complete: "完成",
	clickToExpand: "了解更多",
	clickToCollapse: "隱藏詳情",
	fillIn: "開始填寫表格",
	head_formTitle: "表 格 名 稱",
	formNo: "表格編號",
	startFilling: "開 始 填 寫",
	submit: "遞交",
	signIAS: "智方便數碼簽署",
	signIASTitle: "授權「智方便」簽署你的表格",
	ias_text: "請確保「智方便」顯示的識別碼相同，然後點擊「簽署」以完成數碼簽署",
	ias_continue: "以智方便繼續",
	signECert: "數碼證書簽署",
	signLater: "儲存予下一位簽署人",
	signPrinted: "在列印版本簽署",
	saveECert: "使用數碼證書儲存",
	importantTitle: "*** 重要提示 ***",
	submissionMessage1: "*** 重要提示 ***",
	submissionMessage2: "請按「遞交」以完成你的網上申請。",
	submissionMessage2A: "請按"+String.fromCharCode(10004)+"按鈕以儲存已簽署的表格。",
	submissionMessage2B: "請按「繼續」按鈕進行網上付款 (繳費靈／信用卡／轉數快／流動支付) 以完成你的網上申請。",
	eCheque: "電子支票",
	echequeMessage2: "If you choose e-Cheque as the payment method, please upload your e-Cheque file in this step.  Otherwise, please click \"Next\" button to proceed, and submit your payment to Land Registry separately.",
	echequeMessage3: "請勿郵寄現金至本處。",
	uploadECheque:"上載電子支票",
	cheque: "支票",
	cash: "現金",
	bankDraft: "銀行本票",
	cancel: "取消",
	addARecord: "新增一個項目",
	removeARecord: "移除最後紀錄",
	pleaseChoose: "請選擇...",
	password: "密碼",
	confirmPassword: "確認密碼",
	passwordCondition: "({0}-{1} 個字元)",
	uploadECert:"選取數碼證書 (*.p12)",
	certificate:"證書",
	certificatePublic:"公用密鑰檔 (*.cer)",
	certificatePrivate:"選擇數碼證書檔",
	pin:"輸入數碼證書密碼",
	signAndCont:"簽署並繼續",
	savedFormFile:"已儲存的表格資料檔",
	signECertNote:"本人已細閱及明白「條款及條件」及「收集個人資料聲明」",
	signECertNote2_PICSonly:"本人已細閱及明白「收集個人資料聲明」。",
	signEIDNote:"請輸入你的香港身份證號碼以使用「智方便+」進行簽署。你輸入的資料只會於你的電腦上進行處理，並不會上傳至本系統。請確保你已登記成為「智方便+」用戶並已在流動電話安裝「智方便」流動應用程式。 ",
	signEIDWaiting:"請在彈出式視窗中完成「智方便」認證程序。此對話框會在你完成「智方便」認證程序後自動關閉。",
	generalFaq: "常見問題",
	generalTerms: "一般使用條款",
	termsAndConditions: "條款及條件",
	termsAndConditionsOf: "本表格的使用條款及條件",
    readAndUnderstandTerms: "本人/我們已細閱及明白上述的「一般使用條款」及「本表格的使用條款及條件」，並同意受其約束。",
	readAndUnderstandGeneralTerms: "本人/我們已細閱及明白上述的「一般使用條款」，並同意受其約束。",
	PICS: "收集個人資料聲明",
    readAndUnderstandPICS: "本人/我們已細閱及明白上述的「收集個人資料聲明」，並同意受其約束。",
	noteTitle: "注意事項",
    readAndUnderstandNotes: "本人確認已閱讀、明白及同意上述注意事項。",
	confirmationMessage: "遞交申請前，請檢查下列資料是否正確無誤。如需修改，你可以返回先前步驟。",
	confirmationMessageAlt: "遞交申請前，請檢查下列資料是否正確無誤。如需修改，你可以返回先前步驟。",
	confirmationMessage_signing: "當你經網上遞交申請後，所有申請事項將不能於網上作出修改。你需要以書面方式向本處提交任何修改。",
	confirmationMessage_signing2: "如果要親身遞交，或以郵寄/傳遞公司或傳真方式遞交申請，請按下方功能表的「列印」以列印已填寫的表格。",
	confirmationMessage_signing2Alt: "如果要親身遞交此申請，請按最下方「列印」按鈕以列印已填寫的表格。",
	acknowledgement_para1: "本處將處理你已遞交的申請。",
	acknowledgement_para2: ()=> (<>如欲就是次申請作出查詢，請提供以下參考編號。</>),
	acknowledgement_offline_para: ()=>(
		<>
			<p>你的表格已成功列印。</p>
			<p>請親身遞交，或以郵寄/傳遞公司或傳真方式傳送表格至本處以遞交申請。</p>
		</>
	),
	ack_refNum: "申請參考編號",
	paymentTxnNum: "付款編號",
	paymentMethod: "付款方式",
	paymentAmount: "付款金額",
	printOrSavePage: "列印 / 儲存此頁面",
	noFileSelected: "沒有文件 (0KB)",
	ackEmail: "接收確認電郵地址",
	signed: "已簽署",
	signedBy: "{0} 已簽署",
	signError: "錯誤",
	changeSignature:"重新簽署",
	departmentName: "部門名稱",
	serviceName: "服務名稱",
	documentName: "文件名稱",
	signCode: "識別編碼",
	landRegistry:"土地註冊處",
	webFormPortal:"土地註冊處的公用表格",
	sameDevice:"在此裝置上簽署",
	diffDevice:"在另一裝置上簽署",
	eCertError:"數碼證書檔或密碼不正確",
	additionalDocuments:"附加文件",
	sessionTimeout: "操作逾時",
	sessionGoingToTimeout: "本頁面將於1分鐘後逾時。請按「確認」繼續。",
	timeRemaining: "剩餘時間: {0} 秒",
	sessionHasTimedout: "本頁面已逾時。請重新開始。",
	refresh: "重新開始",
	refreshPdf: "更新表格",
	serverBusy: "系統目前比較繁忙。請稍候然後再試一次。",

	paymentMethod_list:[
		{name:"電子支票"},
	],

	emailForAcknowledgement: "請提供你的電子郵件地址以接收確認電郵。",
	passwordForAcknowledgement: "請提供密碼以加密已填寫的檔案。",
	
	steps_Acknowledgement: "確認",

	field_ChnName: "中文姓名 (如適用)",
	field_EngName: "英文姓名",
	field_Salutation: "稱呼",
	field_DayTimePhone: "日間聯絡電話",
	field_HomePhone: "住宅電話",
	field_WorkPhone: "辦公室電話",
	field_MobilePhone: "手提電話",
	field_ContactPhone: "聯絡電話",
	field_Fax: "傳真號碼",         
	field_Email: "電郵地址",
	field_RetypeEmail: "重新輸入電郵地址",
	field_HKID: "香港身份證號碼",
	field_HKID_withCheckDigit: "香港身份證號碼 (不包括括號, 例如: A1234567)",
	field_IdType: "身分識別種類",
	field_IdNum: "身分識別號碼",
	field_Gender: "性別",
	field_DOB: "出生日期",
	field_CorrespondenceAddress: "通訊地址 (請以英文正楷輸入)",
	field_acceptNotes: "本人確認已閱讀、明白及同意上述重要事項及注意事項",
	field_Date: "日期",
	field_Time: "時間",
	field_Name: "名稱",
	field_NameOfDeclarant: "聲明人名稱",
	field_BusinessReg: "商業登記 / 公司註冊",

	field_Salutation_Mr: "先生",
	field_Salutation_Mrs: "太太",
	field_Salutation_Ms: "女士",
	field_Salutation_Miss: "小姐",

	field_signatoryName: "簽署人名稱",

	field_AddressFlat: "Flat",
	field_AddressFloor: "Floor",
	field_AddressBlock: "Block",
	field_AddressBuilding: "Building Name",
	field_AddressEstate: "Estate Name",
	field_AddressStreet: "No. and name of Street",
	field_AddressDistrict: "District",
	field_AddressHongKong: "Hong Kong",
	field_AddressKowloon: "Kowloon",
	field_AddressNewTerritories: "New Territories",

	field_IdType_HKID: "香港特別行政區身份証", 
	field_IdType_PRCID: "中華人民共和國居民身份証", 
	field_IdType_PRCHKMAC: "中華人民共和國往來港澳通行証", 
	field_IdType_CR: "商業登記證", 
	field_IdType_PASSPORT: "護照", 
	field_IdType_NA: "不適用", 
	Signature: "簽署",

	field_paymentMethod: "付款方式",
	field_paymentMethod_list:(extraFieldIds) => [
		{value:"cash", text:"現金"},
		{value:"cheque", text:"支票", extraField:"支票號碼", extraFieldId: extraFieldIds[0], extraFieldProps: { numericHyphen: true }},
		// {value:"cheque", text:"e-Cheque"},
		{value:"bank draft", text:"銀行本票", extraField:"號碼", extraFieldId: extraFieldIds[1], extraFieldProps: { numericHyphen: true }},
	],
	field_paymentMethod_list_negotiable:(extraFieldIds) => [
		{value:"cheque", text:"支票", extraField:"支票號碼", extraFieldId: extraFieldIds[0], extraFieldProps: { numericHyphen: true }},
		{value:"bank draft", text:"銀行本票", extraField:"號碼", extraFieldId: extraFieldIds[1], extraFieldProps: { numericHyphen: true }},
	],
	field_paymentMethod_list_cash:(extraFieldIds) => [
		{value:"cash", text:"現金"},
	],
	field_onlinePayment_list:[
		{text: GCIS_PAYMENT.METHOD_SUMMARY, meta: {handler: GCIS_PAYMENT_HANDLER}},
		{value: "cheque", text: "電子支票 (網上遞交申請)"}
	],
	field_amount: "金額",
	field_amount_prepend: "HK$", 
	field_Gender_List: [
		{name:"男"},
		{name:"女"},
		{name:"其他"}
	],

	field_AddressFlat: "室",
	field_AddressFloor: "樓層",
	field_AddressBlock: "座",
	field_AddressBuilding: "大廈名稱",
	field_AddressEstate:"屋邨/村",
	field_AddressStreet:"街號及街名",
	field_AddressDistrict:"地區",
	field_AddressRegionHongKong:"香港",
	field_AddressRegionKowloon:"九龍",
	field_AddressRegionNT:"新界",

	validation_required: "必須填寫",
	validation_file_required: "必須提供",
	validation_lengthMax: "不可多於 {0} 個字元",
	validation_lengthMin: "不可少於 {0} 個字元",
	validation_lengthRange: "必須介乎 {0} 至 {1} 個字元之間",
	validation_lengthExact: "必須為 {0} 個字元",
	validation_decimalMax: "不可多於 {0}",
	validation_decimalMin: "不可少於 {0}",
	validation_decimalRange: "必須介乎 {0} 至 {1} 之間",
	validation_chineseOnly: "只可輸入中文字元",
	validation_englishOnly: "只可輸入英文字元",
	validation_numberOnly: "只可輸入數字",
	validation_integerOnly: "只可輸入整數",
	validation_phonenumber: "不符合電話號碼格式",
	validation_faxnumber: "不符合傳真號碼格式",
	validation_addressBldgEstateOrStreet: "必須最少輸入 \"大廈\", \"屋邨/村\", \"街號及街名\" 中的其中一項",
	validation_districtRequired: "必須輸入地區",
	validation_multiSelected: "不可多於一個選項",
	validation_invalidHKID: "身份證號碼不正確",
	validation_invalidEmail: "電郵地址不正確",
	validation_invalidDate: "有效日期的格式須為{0}",
	validation_invalidTime: "須為格式 h:mm 上午/下午 的有效時間",
	validation_confirmPasswordDiff: "密碼及確認密碼不相同",
	validation_alphanumeric: "只可輸入英數字元 (0-9, A-Z, a-z)",
	validation_alphanumericWithHyphen: "只可輸入英數字元 (0-9, A-Z, a-z) 及連字符號 (-)",
	validation_onOrAfterToday: "日期須為今日或之後",
	validation_onOrBeforeToday: "日期須為今日或之前",
	validation_onOrBeforeDate: "日期須為{0}或之前",
	validation_numericWithComma: "只可輸入數字及逗號 (,)",
	validation_paymentAmount: "無效的格式",
	validation_numericWithHyphen: "只可輸入數字及連字符號 (-)",
	validation_fileSizeExceed:"檔案大小不可大於 {0}",
	validation_wrongFileType:"檔案格式不正確",
	validation_futureEnough: "日期須為今日之後{0}日",
	validation_selectAtLeastOne:"請選擇最少一項。",
    
    validation_eChequeInvalid:"電子支票無效，請重試。",
    validation_eChequeInvalidAmount:"電子支票的面額不正確，請重試。",
    validation_eChequeInvalidCurrency:"電子支票的貨幣只接受港幣，請重試。",
    validation_eChequeInvalidPayee:"電子支票的收款人不正確，請重試。",
    validation_eChequeExpired:"電子支票已過期，請重試。",
	
	validation_eCertInvalid:"數碼證書無效，請重試。",
    validation_eCertExpired:"數碼證書已過期，請重試。",
    validation_eCertRevoked:"數碼證書已被撤銷，請重試。",
    validation_eCertNotEffective:"數碼證書未生效，請重試。",

	validation_ias_unknownException:"[D20000] 與智方便聯繫時發生未知錯誤，請聯絡客戶服務熱線 3105-0000 尋求協助。",
	validation_ias_accTokenNotExist:"[D20009] 與智方便聯繫時發生認証問題，請聯絡客戶服務熱線 3105-0000 尋求協助。",
	validation_ias_authTimeout:"[D40003] 認證超時，請重試。",
	validation_ias_signingCanceled:"[D70000] 簽署已取消。",
	validation_ias_signingRejected:"[D70001] 簽署已被拒絕。",
	validation_ias_signingFailed:"[D70002] 簽署失敗，請重試。",
	validation_ias_signingTimeout:"[D70003] 簽署超時，請重試。",
	validation_ias_signingNotAllowed:"[D70004] 用戶不允許使用「智方便」進行簽署。",
	validation_ias_signingWrongHKID:"[D70005] 身份證號碼不正確，請確認簽署者身分後再試。",
	validation_ias_signingProcessFailed:"[D70006] 無法處理簽名確認，請重試。",
	validation_ias_signingGetResultError:"[D79402] 獲取簽名結果失敗，請重試。",

	validation_signature_verificationFailed:"簽署失敗，請重試。",

	validation_signature_signerNameMismatch:"簽署者的英文姓名與申請人的姓名不符",
	validation_signature_signerIdMismatch:"簽署者的身份證明文件號碼與申請人的身分證明文件號碼不符",
	validation_signature_nonPersonal:"請使用個人數碼證書為此申請進行數碼簽署",
	validation_signature_nonOrganisation:"請使用機構數碼證書為此申請進行數碼簽署",
	validation_signature_specifyNonHKID:"請在上方欄位選擇「香港身分證」，或更改輸入的身分證明文件類別",
	validation_signature_signerOrgNameMismatch:"簽署者的英文機構名稱與申請人的機構名稱不符",
	validation_signature_signerOrgAuthNameMismatch:"簽署者的英文受權人姓名與申請人的受權人姓名不符",
	validation_signature_signerOrgIdNumMismatch:"簽署者的機構證明號碼與申請人的機構證明號碼不符",
	validation_signature_signerOrgBRNMismatch:"簽署者的商業登記證號碼與申請人的商業登記證號碼不符",
	validation_signature_signerOrgCICRMismatch:"簽署者的公司註冊證明書號碼與申請人的公司註冊證明書號碼不符",

	validation_max_records: "項目上限: ",

	personalParticulars: "個人資料",

	CDPaymentMethod: "付款方式",
	CDChequeOrBankDraftNo: "支票或本票號碼", 

	confirmationMessage_signOnly: ()=> [<>簽署表格前，請檢查下列資料是否正確無誤。如需修改，你可以返回先前步驟。</>],
	confirmationMessage_offlineSubmit: ()=> [<>請檢查下列資料是否正確無誤，然後按 {String.fromCharCode(10004)} 按鈕列印填妥的表格，以供遞交至本處。</>],
	confirmationMessage_noESubmit: ()=> [<>本表格不接受網上遞交申請。請檢查下列資料是否正確無誤，然後按 {String.fromCharCode(10004)} 按鈕列印填妥的表格，以供遞交至本處。</>],
	
	PICSContent: ()=>      [<> 
		<p>1. <span style={{textDecoration:"underline"}}>收集目的</span></p>
		<p>
			土地註冊處將會把本表格所收集的個人資料用於以下其中一個／多個目的：
			<ol style={{paddingTop:"15px", listStyleType: "lower-latin"}}>
				<ul>(a) 處理與提供土地註冊處服務有關的事宜；</ul>
				<ul>(b) 方便進行聯絡；以及</ul>
				<ul>(c) 製備與土地註冊處服務有關的統計資料。</ul>
			</ol>
		</p>
		<p>
			你明白提供個人資料與否純屬自願。若你未能提供本表格所要求的資料，本處可能無法提供所要求的服務。
		</p>
		<p>
			請勿提供任何沒有明確規定必須提交的個人資料(包括關乎第三者的個人資料)。如本表格或所提交的任何與其有關的文件中包含任何第三者的資料，本處將視作你已獲該第三者同意披露該等資料，以用於上述目的。
		</p>
		
		<p>2. <span style={{textDecoration:"underline"}}>獲轉交資料人士的類別</span></p>
		<p>
			你明白為達到上文第一段中所述明的任何目的而有需要時，你在本表格內所提供的個人資料可能會披露或轉交予相關人士。
		</p>    
		<p>3. <span style={{textDecoration:"underline"}}>查閱個人資料</span></p>
		<p>
		你明白根據《個人資料(私隱)條例》(第486章)第18和第22條，以及附表1第6原則，你有權要求查閱及更正本處所持有你的個人資料。根據該條例，本處有權就處理該等要求收取費用。任何該等要求可向本處的個人資料(私隱)主任提出(地址：香港金鐘道66號金鐘道政府合署28樓土地註冊處)。
		</p>
	</>],

	BMOPICSContent: ()=> [<ol> 
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>收集目的</span>
			<p>
				土地註冊處將會把本表格所收集的個人資料用於以下目的：
				<ol style={{paddingTop:"15px", listStyleType: "lower-latin"}}> 
					<li>處理與土地註冊處提供服務有關的事宜及執行有關職能；</li>
					<li>方便進行聯絡；以及</li>
					<li>製備與土地註冊處服務有關的統計資料。</li>
				</ol>
			</p>
			<p>
				你明白提供個人資料是必須的。倘若你不提供本表格所要求的資料，本處可能無法提供所要求的服務。
			</p>
			<p>
				<b>請勿</b>提供任何沒有明確規定必須提交的個人資料（包括關乎第三者的個人資料）。如本表格或與其有關而存檔的文件中包含任何第三者的資料，本處將視作你已獲該第三者同意披露該等資料，以用於上述目的。 
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>個人資料的披露</span>
			<p>
				你明白為達到上文第一段中所述明目的而及有需要時，你在本表格內所提供的個人資料可能會披露或轉交予相關人士，該等個人資料亦可能會在《個人資料（私隱）條例》（第486章）許可下披露或轉交予執法機關。
			</p>
		</li>
		<li>
			<span style={{textDecoration:"underline", paddingBottom: "0.5rem", display: "inline-block"}}>查閱個人資料</span>
			<p>
				你明白根據《個人資料（私隱）條例》（第486章)第18和第22條，以及附表1第6原則，你有權要求查閱及更正本處所持有你的個人資料。本處可根據該條例就處理該等要求收取費用。任何該等要求可向本處的個人資料（私隱)主任提出(地址：香港金鐘道66號金鐘道政府合署28樓土地註冊處)。
			</p>
		</li>
	</ol>],

	SSS_PICSContent: ()=>      [<>
		<p><span style={{textDecoration:"underline"}}>收集目的</span></p>
		<p>
			1. 土地註冊處將會把所收集的個人資料用於以下目的:
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.1</div>
				<div>處理與土地註冊處提供服務有關的事宜及執行有關的職能；</div>
			</div>
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.2</div>
				<div>方便進行聯絡；以及</div>
			</div>
			<div className="d-flex" style={{paddingTop:"15px"}} >
				<div className="note-bullet">1.3</div>
				<div>製備與土地註冊處服務有關的統計資料。</div>
			</div>
		</p>
		<p>
			你明白提供個人資料是必須的。倘若你不提供所要求的資料，本處可能無法提供所要求的服務。
		</p>
		<p>
			請勿提供任何沒有明確規定必須提交的個人資料(包括關乎第三者的個人資料)。如於本表格或與其有關而存檔的文件中包含任何第三者的資料，本處將視作你已獲該第三者同意披露該等資料，以用於上述目的。
		</p>
		
		<p><span style={{textDecoration:"underline"}}>個人資料的披露</span></p>
		<p>
			2. 你明白為達到上文第一段中所述明的目的而有需要時，你所提供的個人資料可能會披露或轉交予相關人士。該等個人資料亦可能會在《個人資料(私隱)條例》(第486章)許可下披露或轉交予執法機關。
		</p>

		<p><span style={{textDecoration:"underline"}}>查閱個人資料</span></p>
		<p>
			3. 你明白根據《個人資料(私隱)條例》(第486章)第18和第22條及附表1第6原則，你有權要求查閱及更正本處所持有你的個人資料。本處可根據該條例就處理該等要求收取費用。任何該等要求可向本處的個人資料(私隱)主任提出(地址：香港金鐘道66號金鐘道政府合署28樓土地註冊處)。
		</p>
	</>],

	FSD_PICSContent: ()=> [
		<>
			<p><span style={{textDecoration:"underline"}}>收集目的</span></p>
			<p>
				1. 土地註冊處將會把所收集的個人資料用於以下目的：-
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.1</div>
					<div>處理與土地註冊處提供服務有關的事宜及執行有關的職能；</div>
				</div>
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.2</div>
					<div>方便進行聯絡；以及</div>
				</div>
				<div className="d-flex" style={{paddingTop:"15px"}} >
					<div className="note-bullet">1.3</div>
					<div>製備與土地註冊處服務有關的統計資料。</div>
				</div>
			</p>
			<p>
				你明白提供個人資料是必須的。倘若你不提供所要求的資料，本處可能無法提供所要求的服務。
			</p>
			<p>
				請勿提供任何沒有明確規定必須提交的個人資料(包括關乎第三者的個人資料)。如於本表格或與其有關而存檔的文件中包含任何第三者的資料，本處將視作你已獲該第三者同意披露該等資料，以用於上述目的。
			</p>
			
			<p><span style={{textDecoration:"underline"}}>個人資料的披露</span></p>
			<p>
				2. 你明白為達到上文第一段中所述明的目的而有需要時，你所提供的個人資料可能會披露或轉交予相關人士。該等個人資料亦可能會在《個人資料(私隱)條例》(第486章)許可下披露或轉交予執法機關。
			</p>

			<p><span style={{textDecoration:"underline"}}>查閱個人資料</span></p>
			<p>
				3. 你明白根據《個人資料(私隱)條例》(第 486章)第18和第22條及附表1第6原則，你有權要求查閱及更正本處所持有你的個人資料。本處可根據該條例就處理該等要求收取費用。任何該等要求可向本處的個人資料(私隱)主任提出(地址：香港金鐘道66號金鐘道政府合署28樓土地註冊處)。
			</p>
		</>
	],

	howToChangeFontSize: "如何變更字型大小",
	FontSizeContent: (browserName) => [ 
		<>
			<p>採用了適應性網頁設計後，你可簡易地變更字型大小，配合個人需要。</p>
			<h4>快速鍵</h4>
			<p>假如你使用桌上型電腦瀏覽「土地註冊處」網頁，近代的瀏覽器可讓用家按著Ctrl鍵（在Macintosh鍵盤上是Command鍵）和+／-鍵來放大或縮小字型，而按著Ctrl鍵和0則可回復預設大小。</p>
			<h4>選項單</h4>
			<p>使用智能電話和平板電腦時，你只需在螢幕上滑動手指便能放大或縮小圖文。</p>
		</>,
		browserName === "chrome" ? <><h5>Chrome</h5><p>在頁面的選項單選擇「縮放」，然後按「+」放大。</p></> : null,
		browserName === "firefox" ? <><h5>Firefox</h5>
			<p>在「檢視」一欄點選「縮放」，再選擇「放大」。</p>
			<p>你也可在頁面的選項單按「+」或「-」來放大或縮小字型。</p></> : null,
		browserName === "edge" ? <><h5>Edge</h5>
			<p>在「檢視」一欄點選「文字大小」，選擇「較大」或「最大」。</p>
			<p>你也可按Edge的設定標記點選「縮放」，再選擇合適的百分比或「放大」。</p></> : null,
		browserName === "opera" ? <><h5>Opera</h5>
			<p>在「檢視」一欄點選「縮放」，再選擇合適的百分比。</p></> : null,
		browserName === "safari" ? <><h5>Safari</h5>
			<p>在「檢視」一欄選擇「放大文字」。 </p></> : null,
		<>
			<p style={{fontStyle:"italic"}}>
				免責聲明<br />
				土地註冊處並沒有認可特定的瀏覽器或建議使用哪一個瀏覽器。本文內容包括部分常用的瀏覽器，但提及某一個瀏覽器並沒有暗示任何認可或建議的意思。
			</p>
		</>
	
	],

	wfpMaintenanceContent : () => [
		<p>土地註冊處的網上表格服務正在進行維修。</p>, 
		<p>請於土地註冊處網站 (<a target="_blank" href={SysParam.LR_WEBSITE_FORM("TradChn")}>{SysParam.LR_WEBSITE_FORM("TradChn")}</a>) 下載表格。</p>,
		<p>如有任何查詢，請致電 3105-0000 或電郵至<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>與我們聯絡。</p>,
	],

	generalTermsContent: (inSection)=> [
		<>
			{ inSection ?
				null
				:
				<h1 className="formTitle">
					土地註冊處網上表格<br/>
					一般使用條款 (「一般條款」)
				</h1>
			}
			<ol>
				<li>土地註冊處提供的「網上表格」，可供公眾人士就本處的服務及／或產品或與其相關的服務提交網上申請。申請人可於土地註冊處網頁內網上填寫相關表格，並（如適用）提交申請及以電子支付方式繳交服務費用。</li>
				<li>
					在一般條款內：
					<ol>
						<li>「網上表格」指就申請土地註冊處的公眾服務及／或產品或與其相關的服務而提供、可供在土地註冊處網頁內網上填寫的任何申請表格；</li>
						<li>「服務申請」包括申請土地註冊處提供的服務及／或產品或與其相關的服務，該等服務可因應土地註冊處酌情決定，不時作出更改；</li>
						<li>「重要須知」指就服務申請而言，該服務申請的特定條款及條件和收集個人資料聲明；</li>
						<li>「申請人」指任何接達或使用「網上表格」(包括但不限於使用土地註冊處網頁的「網上表格」作服務申請，或用於該表格指定的任何用途)的人士。</li>
					</ol>
				</li>
				<li>申請人接達或使用「網上表格」，將被當作無條件和不可撤回地同意和接受一般條款。土地註冊處可在沒有事先通知下不時修改或修訂一般條款，申請人其後再接達或使用「網上表格」，即構成他們已接納經修改或修訂的一般條款。</li>
				<li>申請人每次作出服務申請時，必須閱讀「重要須知」和一般條款，並於相關的方格填上「{String.fromCharCode(10004)}」，以確定同意受一般條款以及「重要須知」下的特定條款及條件和收集個人資料聲明的約束，方能繼續填寫申請資料。</li>
				<div className="generalTermsSubtitle">連接「網上表格」</div>
				<li>
					<ol>
						<li>申請人如須透過互聯網連接「網上表格」，必須自行就連接「網上表格」和日後切斷接駁的事宜與有關互聯網、流動通訊或其他電訊服務供應商作出安排，並須自行承擔有關費用。申請人亦須自行安排接達所需的電腦硬件、軟件、通訊鏈路、設備及／或裝置(「硬件及／或軟件」)，並須自行承擔有關開支。土地註冊處不會就申請人為連接「網上表格」而引致或承擔的任何責任，開支或法律責任負責。</li>
						<li> 如土地註冊處認為有以下情況，可隨時拒絕或限制申請人使用「網上表格」：
							<div className="d-flex">
								<div className="note-bullet">(i)</div>
								<div>申請人的硬件及／或軟件已引致或很可能引致土地註冊處的資訊系統失靈、受阻、失誤或損毁；或</div>
							</div>
							<div className="d-flex">
								<div className="note-bullet">(ii)</div>
								<div>申請人違反一般條款的任何規定。</div>
							</div>
						</li>
						<li>申請人不得使用或意圖使用或允許他人使用任何自動化軟件或惡意軟件於「網上表格」。</li>
					</ol>
				</li>
				<li>除進行維修外，「網上表格」通常每星期7日每日24小時可供使用。土地註冊處會不時在其網站<a target="_blank" href="https://www.landreg.gov.hk/tc/home/index.htm"> http://www.landreg.gov.hk </a>公布「網上表格」可供使用的時間，但也可能在沒有事先通知下作出更改。</li>

				<div className="generalTermsSubtitle">網上提交表格</div>
				<li>
					<ol>
						<li>「網上表格」可供網上提交服務申請。</li>
						<li>土地註冊處享有絕對酌情權決定可使用「網上表格」在網上提交的服務申請。對於不能網上提交的服務申請，相關表格也可供打印和下載為PDF格式，申請人可將填妥的表格下載及打印為紙本和簽署，並按照適用的法例規定及土地註冊處現行的做法，將表格以郵寄或電子郵遞或親身交回本處。</li>
					</ol>
				</li>
				<div className="generalTermsSubtitle">電子簽署</div>
				<li>「網上表格」接受兩種數碼簽署方式，即由認可核證機關(例如香港郵政或電子核證服務有限公司)發出的個人或機構數碼證書及「智方便+」。如網上提交的表格需要簽署，申請人須先取得數碼證書或登記成為「智方便+」用戶，並把「智方便」的流動應用程式安裝於其流動電話，以進行數碼簽署。有關使用「智方便」流動應用程式的系統要求，申請人可參考相關的連結：<a target="_blank" href="https://www.iamsmart.gov.hk/tc/">https://www.iamsmart.gov.hk/tc/</a>。</li>

				<div className="generalTermsSubtitle">付款</div>
				<li>如使用桌面版「網上表格」，土地註冊處接受以電子支票、信用卡 (萬事達卡 / VISA / 銀聯 / JCB)、轉數快或繳費靈作網上提交服務申請的網上付款方法。如使用流動版，本處則接受以電子支票、信用卡 (萬事達卡 / VISA / 銀聯 / JCB)、轉數快、 Apple Pay 或 Google Pay (只限萬事達卡 / VISA) 作網上付款方法。如欲知悉更多關於電子支票的資料，申請人應聯絡其銀行或瀏覽香港金融管理局的網站：<a target="_blank" href="https://www.hkma.gov.hk/chi/key-functions/international-financial-centre/financial-market-infrastructure/other-retail-payment-infrastructure/">https://www.hkma.gov.hk/chi/key-functions/international-financial-centre/financial-market-infrastructure/other-retail-payment-infrastructure/</a>。</li>
				
				<div className="generalTermsSubtitle">申請人的責任</div>
				<li>申請人有責任在「網上表格」提供清晰、完整、正確和真實的資料，並夾附土地註冊處指定的一切所需證明文件，如有關資料有任何失實陳述或遺漏，可能導致申請延誤或被拒。</li>
				<li>在辦理服務申請的過程中提供「網上表格」所需的資料純屬自願。然而，申請人如沒有提供所需資料，土地註冊處也許未能處理有關申請。在任何情況下，土地註冊處保留不接受任何服務申請的權利。土地註冊處並非必須接受任何使用「網上表格」提交的服務申請。</li>
				<li>申請人不得使用或允許他人使用「網上表格」作任何欺詐或非法用途或活動。</li>
				<li>申請人同意就以下情況引起或造成的申索、損害賠償、損失及法律責任向土地註冊處作出彌償，並使土地註冊處獲得彌償：
					<ol>
						<li>申請人使用「網上表格」；或</li>
						<li>申請人違反一般條款的任何規定。</li>
					</ol>
				</li>
				<li>申請人明白無論因任何原因未能使用「網上表格」，申請人不會獲免除任何法例所訂明的責任。</li>
				
				<div className="generalTermsSubtitle">聲明和責任限制</div>
				<li>土地註冊處不保證傳送往來土地註冊處的資料、訊息或指示的在線網絡的可靠性。如因通訊設施發生故障或失靈或任何其他原因導致任何資料、訊息或指示的傳送、接收或處理延誤或失敗，土地註冊處或其職員或僱員無須承擔任何因此而承受或引致的損失、收費、費用、開支、損害賠償和法律責任。</li>
				<li>土地註冊處保留絕對酌情權修改、更改或中斷「網上表格」的設置、功能及提供而無需事先發出通知。土地註冊處不會就「網上表格」的更改、暫停或中斷使用對申請人或任何第三者承擔任何責任。</li>
				<li>土地註冊處就提供「網上表格」並沒有任何種類的保證，也不會構成或產生土地註冊處的任何種類的保證或其他責任。土地註冊處不會就其網頁的「網上表格」的提供或使用所引致或與其相關的損失負責。土地註冊處保留絕對酌情權隨時省略、暫停或編輯「網上表格」而無需給予任何理由或事先發出通知。申請人須就「網上表格」的所載的資料自行評估。</li>
				<li>土地註冊處不會就任何因申請人直接或間接接達或使用「網上表格」而引致的損毁或申索或損失負責，當中包括但不限於申請人的硬件及／或軟件的損毀或儲存在內的資料的損失或損毀。</li>
				
				<div className="generalTermsSubtitle">其他事宜</div>
				<li>若對「網上表格」有任何查詢或意見，請以電郵(電郵地址：<a href="mailto:csa@landreg.gov.hk">csa@landreg.gov.hk</a>)與土地註冊處聯絡。</li>
				<li>一般條款須受香港特別行政區的法律規管。</li>
				<li>一般條款的英文版和中文版如有任何抵觸或含糊之處，以英文版為準。</li>
			</ol>
		</>
	],

	GcisPayment: GCIS_PAYMENT,

	broadcastMessage: form => window.WFP_CONFIG.BROADCAST_MSG_HTML_TC ? window.WFP_CONFIG.BROADCAST_MSG_HTML_TC(form) : null,
	broadcastMessageContent: (index) => window.WFP_BROADCAST.broadcastList[index].TC ?? null,
	ios_online_disabled_title: '網上遞交申請未能支援iOS裝置',
	ios_online_disabled_content: '網上遞交訂購｢物業把關易｣申請現時未能支援iOS裝置。如你想在網上遞交申請，請改用個人電腦或安卓(Android)裝置遞交申請。如有查詢，請致電土地註冊處客戶服務熱線 3105 0000。',    
}
