import { Expose, Type } from 'class-transformer';
import { RequestAddress, SuggestedAddress } from '../entities';

export class LookupAddressResponse {
    @Expose({ name: 'RequestAddress' })
    @Type(() => RequestAddress)
    requestAddress: RequestAddress;

    @Expose({ name: 'SuggestedAddress' })
    @Type(() => SuggestedAddress)
    suggestedAddresses?: SuggestedAddress[];
}
