import { ALLOW_ESUBM } from '../../../../utilities/constant';

type Input = {
    form: any;
    formData: any;
};

export function allowESubmission({ form, formData }: Input) {
    return !form.submissionType || form.submissionType(formData) == ALLOW_ESUBM;
}
