import { RECLIST_ID_SEP } from '../../utilities/constant';
export const DeliveryMethod = {
    ONLINE: "DeliveryMethod3",
    POST_OR_COURIER: "DeliveryMethod1",
    BY_OWNER: "DeliveryMethod2",
    BY_AUTHORIZED_REP: "DeliveryMethod4",
};

export const SubscriberType = {
    NEW_SUBSCRIBER: "New Applicant / 新申請人",
    EXISTING_SUBSCRIBER: "Current Subscriber / 現有用戶",
};

export const PropertyType = {
    BUILD: 'Build',
    LOT: 'Lot'
};

export const SelectAddressInfo = {
    BuildingFieldId:        "Build_Add4Line",
    StreetNameFieldId:      "Build_Add3Line",
    StreetNumberFieldId:    "Build_Add2Line",
    BlockFieldId:           "Build_Blk",
    DistrictFieldId:        "Build_District",
};

export const SuppSheet_SelectAddressInfo = (idx)=>{return{
    BuildingFieldId:        "AI_" + SelectAddressInfo.BuildingFieldId + RECLIST_ID_SEP + idx,
    StreetNameFieldId:      "AI_" + SelectAddressInfo.StreetNameFieldId + RECLIST_ID_SEP + idx,
    StreetNumberFieldId:    "AI_" + SelectAddressInfo.StreetNumberFieldId + RECLIST_ID_SEP + idx,
    BlockFieldId:           "AI_" + SelectAddressInfo.BlockFieldId + RECLIST_ID_SEP + idx,
    DistrictFieldId:        "AI_" + SelectAddressInfo.DistrictFieldId + RECLIST_ID_SEP + idx,
}
};

export const ADDRESS_TYPE_FIELD_KEY = 'field_Address_of_Property_list';

export const LREAL1_CODE = 'LREAL1';